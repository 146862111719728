import React, { Component } from 'react'
import { setTitle } from '../utils/helper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { AppBar } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { connect } from 'react-redux'
import MonthlyTicketManagementContainer from '../containers/MonthlyTicketManagementContainer/MonthlyTicketManagementContainer'
import LostConnectionContainer from '../containers/LostConnectionContainer/LostConnectionContainer'
import MonthlyTickekCreatePage from './MonthlyTickekCreatePage'
import { withRouter } from 'react-router-dom'
import ManagementMonthlyTicketPage from './ManagementMonthlyTicketPage'
import { MONTHLY_TICKET_OPTION } from '../constants/Page'
import { setTabId } from '../actions/monthlyTicket.action'
import {
  initialTab,
  MonthlyTicketOptionPageWrapper,
  selectedTab,
  TabContentWrapper
} from './styles/monthly-ticket-option.style'
import { get } from '../utils/apiCallerV2'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <TabContentWrapper
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </TabContentWrapper>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class MonthlyTicketOptionPage extends Component {
  constructor(props) {
    super(props)
    setTitle(MONTHLY_TICKET_OPTION)
    this.state = {
      isOrderPage: false,
      showLoading: true,
      isLostConnection: false,
      data: {
        monthly_ticket_name: '',
        routeNumbers: [],
        expiry_date: 0,
        total_ticket: 0,
        all: false
      }
    }
  }

  componentDidMount() {
    this.onFetchData()
  }

  handleChange = (event, newValue) => {
    this.props.setTabId(newValue)
  }
  onBuyMonthlyTicket = () => {
    this.props.setTabId(1)
  }
  onReload = () => {
    this.onFetchData()
    // window.location.reload();
  }

  onFetchData = async () => {
    const res = await get(`v2/monthly-ticket/list-by-user`)
    if (!res) {
      this.setState({
        isLostConnection: true,
        showLoading: false
      })
      return
    }
    this.setState({
      data: res.data,
      showLoading: false
    })
  }

  render() {
    const { data, showLoading, isLostConnection } = this.state
    const displayNone = {
      display: 'none'
    }

    let loadingStyle = {
      display: 'block'
    }

    if (showLoading) {
    } else {
      loadingStyle = displayNone
    }
    return (
      <MonthlyTicketOptionPageWrapper>
        <AppBar color={'default'} position={'static'}>
          <Tabs
            value={this.props.selectedTab}
            TabIndicatorProps={{ style: { color: 'red' } }}
            variant="fullWidth"
            indicatorColor={'primary'}
            onChange={this.handleChange}
          >
            <Tab
              style={this.props.selectedTab === 0 ? selectedTab : initialTab}
              className="initial-tab"
              label="Quản lý vé tập"
              {...a11yProps(0)}
            />
            <Tab
              style={this.props.selectedTab === 1 ? selectedTab : initialTab}
              className="initial-tab"
              label="Mua vé tập"
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <TabPanel index={0} value={this.props.selectedTab}>
          {showLoading ? (
            <div className="diaglog-loading" style={(loadingStyle, { height: '100vh' })}>
              <div className="container-loading">
                <div></div>
                <div></div>
                <div></div>
                <p>Vui lòng chờ</p>
              </div>
            </div>
          ) : (
            <>
              {isLostConnection ? (
                <LostConnectionContainer onReload={this.onReload} />
              ) : (
                <>
                  {data && data.length > 0 ? (
                    <ManagementMonthlyTicketPage data={data} />
                  ) : (
                    <MonthlyTicketManagementContainer
                      isBuyTicket={false}
                      onBuyMonthlyTicket={this.onBuyMonthlyTicket}
                    />
                  )}
                </>
              )}
            </>
          )}
        </TabPanel>
        <TabPanel index={1} value={this.props.selectedTab}>
          <MonthlyTickekCreatePage />
        </TabPanel>
      </MonthlyTicketOptionPageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    selectedTab: state.monthlyTicketReducer.selectedTab
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setTabId: (selectedTab) => {
      dispatch(setTabId(selectedTab))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MonthlyTicketOptionPage))
