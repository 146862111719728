import React, { useEffect } from 'react'
import { setTitle } from '../../utils/helper'
import { useLocation, useHistory } from 'react-router-dom'
import { getCampaignDetail } from '../../actions/promotion.action'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../components/loading'
import Button from '@material-ui/core/Button'
import { color } from '../../utils/color'

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F2F2F2'
  },
  img: { width: '99%' },
  wrapper: {
    backgroundColor: 'white',
    marginTop: 16,
    padding: '12px 12px',
    width: '90%',
    borderRadius: 8,
    textAlign: 'left'
  },
  title: { fontSize: 20, color: color.black[600], fontWeight: 'bold' },
  expireDate: { fontSize: 12, fontWeight: 400, marginTop: 5, color: '#4E5966' },
  button: {
    margin: '20px 0px',
    width: '90%',
    backgroundColor: color.blue[350],
    color: 'white',
    fontWeight: 'bold',
    fontSize: 15,
    padding: '10px 10px'
  }
}
export default function GiftDetail() {
  setTitle('Chi tiết chương trình')
  const dispatch = useDispatch()
  const history = useHistory()
  const { campaignDetail, loading } = useSelector((state) => state.promotion)
  const location = useLocation()
  const campaign = location.state.campaign
  useEffect(() => {
    if (campaign && campaign.id) dispatch(getCampaignDetail(campaign.id))
  }, [campaign.id])
  const backHome = () => {
    history.replace('/')
  }
  return (
    <div style={styles.container}>
      <Loading loading={loading} />
      <img alt="" src={campaignDetail.image} style={styles.img} />
      <div style={styles.wrapper}>
        <div style={styles.title}>{campaignDetail.name}</div>
        <div style={styles.expireDate}>{`Thời gian: ${campaignDetail.eventTime}`}</div>
      </div>

      <div
        style={{ ...styles.wrapper, color: color.black[600] }}
        dangerouslySetInnerHTML={{
          __html: campaignDetail.description
        }}
      />
      <Button
        variant="contained"
        onClick={backHome}
        fullWidth
        style={styles.button}
        className="text-bold"
      >
        Quay lại trang chủ
      </Button>
    </div>
  )
}
