import React from 'react'
import { useSelector } from 'react-redux'
import { setTitle } from '../../utils/helper'
import RegisteredRefund from './registered-refund'
import RequestRefund from './request-refund'
import TempCloseRefund from './temp-close-refund'

export default function Refund() {
  setTitle('Đăng ký hoàn tiền')
  const { requested } = useSelector((state) => state.refund)
  return (
    <div className="container">
      {/* {requested.length ? <RegisteredRefund data={requested} /> : <RequestRefund />} */}
      <TempCloseRefund />
    </div>
  )
}
