import * as Types from '../constants/ActionType'
import * as authActionTypes from '../saga/auth/actionTypes'

export const setAuth = (user) => {
  return {
    type: Types.SET_AUTH,
    user
  }
}

export const logout = () => {
  return {
    type: Types.LOG_OUT
  }
}

export const setShowSplashing = (isShow) => {
  return {
    type: Types.SET_SHOW_SPLASHING,
    showSplashing: isShow
  }
}

export const setCustomerPhone = (customerPhone) => {
  return {
    type: Types.SET_CUSTOMER_PHONE,
    customerPhone
  }
}

export const setCustomerInfo = (data) => {
  const { customerPhone, token, customerId } = data
  return {
    type: Types.SET_CUSTOMER_INFO,
    payload: {
      customerPhone,
      token,
      customerId
    }
  }
}

export const verifyOTP = (verifyData) => {
  return {
    type: authActionTypes.VERIFY_OTP,
    verifyData
  }
}

export const setHasLoggedInByZaloOa = (hasLoggedInByZaloOa) => {
  return {
    type: Types.SET_HAS_LOGGEDIN_BY_ZALOOA,
    hasLoggedInByZaloOa
  }
}

export const setZaloIdNotFound = (zaloIdNotFound) => {
  return {
    type: Types.SET_ERROR_ZALOID_NOT_FOUND,
    zaloIdNotFound
  }
}

export const setLoginToken = (loginToken) => {
  return {
    type: Types.SET_LOGIN_TOKEN,
    loginToken
  }
}

export const loginWithZalo = (authCode) => {
  return {
    type: Types.LOGIN_WITH_ZALO,
    authCode
  }
}

export const loginWithZalopay = (data) => {
  return {
    type: Types.LOGIN_WITH_ZALOPAY,
    data
  }
}

export const requestOtp = (data) => {
  return {
    type: Types.AUTH.REQUEST_OTP,
    data
  }
}
