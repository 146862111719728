import { call, put, takeLatest } from '@redux-saga/core/effects'
import * as actionTypes from '../constants/ActionType'
import {
  checkPhoneApi,
  checkShowReferralApi,
  getInviteListApi,
  inviteFriendApi
} from '../services/invite.services'
import {
  checkPhoneDone,
  checkShowReferralDone,
  getInviteListDone,
  inviteFriendSuccess
} from '../actions/invite.action'

function* handleCheckShowReferral() {
  try {
    const res = yield call(checkShowReferralApi)
    if (res && res.error && res.error.code === 1) {
      yield put(checkShowReferralDone(res.data))
    }
  } catch (e) {
    console.log('error: ', e)
  }
}
function* handleCheckPhone(data) {
  const { phone } = data
  try {
    const body = { phone }
    const res = yield call(checkPhoneApi, body)
    let dataFormat
    if (res && res.error && res.error.code === 1) {
      dataFormat = {
        ...res.data,
        message: `Người giới thiệu ${res.data.customer_name}`,
        error: false
      }
    } else {
      dataFormat = {
        ...res.data,
        message: `Mã giới thiệu không tồn tại`,
        error: true
      }
    }
    yield put(checkPhoneDone(dataFormat))
  } catch (e) {
    console.log('error: ', e)
  }
}
function* handleGetInviteList() {
  try {
    const res = yield call(getInviteListApi)
    if (res && res.error && res.error.code === 1) {
      yield put(getInviteListDone(res.data))
    }
  } catch (e) {
    console.log('error: ', e)
  }
}
function* handleInviteFriend(payload) {
  try {
    const {
      data: { phone }
    } = payload
    const body = { inviteCode: phone }
    const res = yield call(inviteFriendApi, body)
    if (res && res.error && res.error.code === 1) {
      yield put(inviteFriendSuccess())
    } else if (res && res.error) {
      const dataFormat = {
        message: res.error.messageVN,
        error: true
      }
      yield put(checkPhoneDone(dataFormat))
    }
  } catch (e) {
    console.log('error: ', e)
  }
}
export default function* inviteSaga() {
  yield takeLatest(actionTypes.INVITE.CHECK_SHOW_REFERRAL, handleCheckShowReferral)
  yield takeLatest(actionTypes.INVITE.CHECK_PHONE, handleCheckPhone)
  yield takeLatest(actionTypes.INVITE.GET_INVITE_LIST, handleGetInviteList)
  yield takeLatest(actionTypes.INVITE.INVITE_FRIEND, handleInviteFriend)
}
