import Grid from '@material-ui/core/Grid'
import React from 'react'
import { REACT_APP_PHONE_WHITELIST } from '../../constants/Env'
import useWhiteList from '../../hooks/white-list'
import { IconWrapper, SideBarWrapper } from './style.js'

const Icon = ({ onClick, src, text }) => {
  return (
    <IconWrapper onClick={onClick}>
      <img src={src} alt="" />
      <p>{text}</p>
    </IconWrapper>
  )
}
export default function SideBar({ onChangeRoute }) {
  // const hasAccess = useWhiteList(REACT_APP_PHONE_WHITELIST);
  const hasAccess = true

  return (
    <SideBarWrapper>
      <Grid item xs={12} md={12} direction="row" container>
        <Grid item xs={3}>
          <Icon
            onClick={(e) => onChangeRoute('/user/info')}
            src="/img/new-icon-menu/account.svg"
            text="Tài khoản"
          />
        </Grid>

        <Grid item xs={3}>
          <Icon
            onClick={(e) => onChangeRoute('/card/management')}
            src="/img/new-icon-menu/card-management.svg"
            text="Quản lý thẻ"
          />
        </Grid>
        {/* <Grid item xs={3}>
          <Icon
            onClick={(e) => onChangeRoute('/monthly_ticket/option')}
            src="/img/icons/BookTicket.svg"
            text="Vé tập"
          ></Icon>
        </Grid> */}
        <Grid item xs={3}>
          <Icon
            onClick={(e) => onChangeRoute('/supported_routes')}
            src="/img/new-icon-menu/route-apply.svg"
            text="Tuyến áp dụng"
          />
        </Grid>
        <Grid item xs={3}>
          <Icon
            onClick={(e) => onChangeRoute('/transaction/history')}
            src="/img/new-icon-menu/history.svg"
            text="Lịch sử"
          />
        </Grid>
        <Grid item xs={3}>
          <Icon
            onClick={(e) => onChangeRoute('/gift')}
            src="/img/new-icon-menu/gift-icon.svg"
            text="Quà tặng"
          />
        </Grid>
        {/* <Grid item xs={3}>
          <Icon
            onClick={(e) => onChangeRoute("/following-invite")}
            src="/img/new-icon-menu/invite-friend-remind.svg"
            text="Giới thiệu"
          />
        </Grid> */}
        {hasAccess && (
          <Grid item xs={3}>
            <Icon
              onClick={(e) => onChangeRoute('/refund')}
              src="/img/new-icon-menu/refund-menu-icon.svg"
              text="Hoàn tiền"
            />
          </Grid>
        )}
      </Grid>
    </SideBarWrapper>
  )
}
