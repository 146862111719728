import styled from 'styled-components'

export const Checkbox = styled.span`
  && {
    width: 24px;
    height: 24px;
    border: 1px solid #d7d8d3;
    border-radius: 50%;
  }
`

export const CheckboxChecked = styled.span`
  && {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #008fe5;
    background-color: #008fe5;
    &:before {
      color: #fff !important;
    }
  }
`

export const CheckboxSmallChecked = styled.span`
  && {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #008fe5;
    background-color: #008fe5;
    font-size: 15px;
    &:before {
      color: #fff !important;
    }
  }
`
