import React from 'react'

import { ZPSubTitleStyle } from './style'

const ZPSubTitle = (props) => {
  const { onClick, ...restProps } = props

  return (
    <ZPSubTitleStyle
      // onClick={e => onClick()}
      variant="contained"
      color="primary"
      {...restProps}
    />
  )
}

export default ZPSubTitle
