import { all } from 'redux-saga/effects'
import authSaga from './auth/auth.saga'
import customerSaga from './customer/customer.saga'
import autodebitSaga from './autodebit'
import routeSaga from './route'
import promotionSaga from './promotion.saga'
import homeSaga from './home.saga'
import inviteSaga from './invite.saga'
import refundSaga from './refund.saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    customerSaga(),
    autodebitSaga(),
    routeSaga(),
    promotionSaga(),
    homeSaga(),
    inviteSaga(),
    refundSaga()
  ])
}
