import * as Types from './../constants/ActionType'

const initialState = { loading: false }

const rechargeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.RECHARGE.CREATE_ORDER:
      return {
        ...state,
        loading: true
      }
    case Types.RECHARGE.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case Types.RECHARGE.CREATE_ORDER_FAIL:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export default rechargeReducer
