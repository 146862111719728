const Image = ({ containerStyle, imgSrc, onRemove, ...props }) => {
  return (
    <div
      style={{ ...containerStyle, position: 'relative', backgroundColor: '#EEE', borderRadius: 8 }}
    >
      {onRemove && (
        <img
          alt="clear button"
          src="/img/icons/circle-x-close.svg"
          style={{ position: 'absolute', right: -8, top: -8 }}
          onClick={onRemove}
        />
      )}
      <img src={imgSrc} {...props} />
    </div>
  )
}

export default Image
