import * as Types from '../constants/ActionType'

export const setUnbindDialogVisibility = (isOpenUnbindDialog) => {
  return {
    type: Types.SET_UNBIND_DIALOG,
    isOpenUnbindDialog
  }
}

// export const setAutodebitBindingData = (bindingData) => {
//     return {
//         type: Types.SET_AUTODEBIT_BINDING_DATA,
//         bindingData
//     }
// };

export const initializeBindingData = () => {
  return {
    type: Types.INIT_AUTODEBIT_BINDING_DATA
  }
}
export const initializeBindingDataDone = () => {
  return {
    type: Types.INIT_AUTODEBIT_BINDING_DATA_SUCCESS
  }
}

export const checkBinding = () => {
  return {
    type: Types.CHECK_BINDING
  }
}
export const checkPayable = () => {
  return {
    type: Types.CHECK_PAYABLE
  }
}
export const checkBindingSuccess = (bindingData) => {
  return {
    type: Types.CHECK_BINDING_SUCCESS,
    bindingData
  }
}
export const checkBindingFail = () => {
  return {
    type: Types.CHECK_BINDING_FAIL
  }
}
