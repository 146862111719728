import React from 'react'
import { X } from 'react-feather'

const styles = {
  header: {
    borderBottom: '0.5px solid rgb(230, 230, 230)',
    height: 56,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 18,
    width: '100%',
    textAlign: 'center'
  },
  statusPaymentContainer: {
    backgroundColor: '#F2F2F2',
    borderRadius: 8,
    padding: 16,
    margin: '20px 16px'
  }
}
const PaymentStatusItem = ({ name, src }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <img alt="" src={src} />
      <div style={{ marginTop: 12 }}>{name}</div>
    </div>
  )
}
export default function InstructionDialog({ onChangeStatus }) {
  const onCloseDialog = () => {
    onChangeStatus(false)
  }

  return (
    <div>
      <div style={styles.header} className="text-bold">
        <X size={24} className="ml-3" onClick={onCloseDialog} style={{ position: 'absolute' }} />
        <div style={styles.title} className="text-bold">
          Cách dùng mã thanh toán
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img alt="" src="/img/home/instruction-helper.png" style={{ width: 230, marginTop: 20 }} />
        <div
          style={{
            fontStyle: 'italic',
            color: '#7A7C8B',
            fontSize: 14,
            marginTop: 8
          }}
        >
          * Di chuyển mã QR vào đầu đọc của máy
        </div>
      </div>
      <div style={styles.statusPaymentContainer}>
        <div className="text-bold" style={{ fontSize: 15 }}>
          Các trạng thái thanh toán
        </div>
        <div className="d-flex justify-content-between" style={{ marginTop: 16 }}>
          <PaymentStatusItem name="Thành công" src="/img/home/success.svg" />
          <PaymentStatusItem name="Đã thanh toán" src="/img/home/paid.svg" />
          <PaymentStatusItem name="Thất bại" src="/img/home/failed.svg" />
        </div>
      </div>
    </div>
  )
}
