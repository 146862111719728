import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@material-ui/core'
import _isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { requestOtp } from '../../actions/auth.action'
import FormBuilder from '../../common/form/FormBuilder'
import { validatePhone } from '../../constants/validation'
import { color } from '../../utils/color'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
import MobileDetect from 'mobile-detect'

const styles = {
  container: {
    paddingTop: 30
  },
  title: {
    fontSize: 18,
    marginTop: 30,
    marginBottom: 10,
    color: '#000000'
  },
  content: {
    fontSize: 15,
    fontWeight: 400,
    color: '#000000'
  },
  term: {
    fontSize: 12,
    color: color.black[550],
    marginBottom: 14
  }
}

const phoneField = {
  type: 'input',
  name: 'phone',
  label: 'Số điện thoại',
  placeholder: 'Nhập số điện thoại',
  require: true,
  props: {
    type: 'tel',
    isPhone: true,
    inputProps: { autoFocus: true }
  }
}

const requiredMsg = 'Không được để trống'
const schema = yup.object().shape({
  phone: yup.string().matches(validatePhone.reg, validatePhone.message).required(requiredMsg)
})
export default function Phone({ onChangePhone, zaloId, loginFlowId, muid, setErrorData, setStep }) {
  const isKeyboardOpen = useDetectKeyboardOpen()
  const md = new MobileDetect(window.navigator.userAgent)

  const dispatch = useDispatch()
  const form = useForm({ resolver: yupResolver(schema), mode: 'onChange' })
  const { errors } = form.formState
  const onSubmit = (data) => {
    const body = {
      phone: data.phone,
      zalo_id: zaloId,
      login_flow_id: loginFlowId,
      muid
    }
    const onSuccess = () => {
      onChangePhone(data)
    }
    const onFailed = (error) => {
      form.setError('phone', { message: error, type: 'validate' })
    }
    dispatch(requestOtp({ body, onSuccess, onFailed, setErrorData, setStep }))
  }

  const [phone] = form.watch(['phone'])

  return (
    <div style={styles.container}>
      <img alt="phone-unipass" src="img/otp-phone-verification/phone-unipass.svg" />
      <div className="text-bold" style={styles.title}>
        XÁC THỰC SỐ ĐIỆN THOẠI
      </div>
      <span style={styles.content}>
        Vui lòng nhập số điện thoại <br /> bạn muốn đăng nhập UniPass
      </span>
      <FormBuilder
        form={form}
        fields={[
          {
            ...phoneField,
            props: {
              ...phoneField.props,
              onKeyDown: (evt) => {
                if (evt.key === 'e') evt.preventDefault()
                if (evt.keyCode === 13 && phone && _isEmpty(errors)) {
                  onSubmit({ phone })
                }
              }
            }
          }
        ].filter(Boolean)}
        style={{ marginTop: 10 }}
      />
      {isKeyboardOpen && md.is('AndroidOS') ? null : (
        <div className="bottom-div">
          <div style={styles.term}>
            Khi bạn nhấn vào “Xác thực”, bạn đã đồng ý với chúng tôi về
            <a
              style={{ display: 'inline-block' }}
              href="https://unipass.vn/static/files/term.pdf"
              target="_blank"
            >
              Các điều khoản và điều kiện dịch vụ UniPass
            </a>
          </div>

          <Button
            variant="contained"
            fullWidth
            onClick={form.handleSubmit(onSubmit)}
            className="button-v2"
            disabled={!phone || !_isEmpty(errors)}
            style={{ opacity: !phone || !_isEmpty(errors) ? 0.5 : 1 }}
            type="submit"
          >
            Xác thực
          </Button>
        </div>
      )}
    </div>
  )
}
