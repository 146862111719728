import styled from 'styled-components'

export const MonthlyTicketOptionPageWrapper = styled.div`
  background: white;
  height: 100vh;
`

export const TabContentWrapper = styled.div`
  > div {
    padding: 0;
    height: 100vh;
    background: white;
  }
`

export const selectedTab = {
  color: '#0071FE',
  fontWeight: 'bold'
}

export const initialTab = {
  color: '#333333',
  fontWeight: 'bold'
}

export const LinkTitle = styled.span`
  color: #006dff;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
`

export const LinkWrapper = styled.div`
  > div {
    height: 50px;
    display: flex;
    background: #e8f2ff;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 0 30px;
  }

  margin: 50px auto auto;
  width: fit-content;
`
