import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import { withRouter } from 'react-router'
import throttle from 'lodash/throttle'
import { connect } from 'react-redux'
// import moment from "moment";

// import { ZPTextField } from "./styles/material-ui";
import ZPTextField from '../components/ZPTextField'
import { postApi } from '../utils/apiCaller'
import {
  // setTitle,
  removeSpace,
  formatPhone,
  friendlyPhone,
  // getValidCardDate,
  // systemDate,
  removeStorageLogout
} from '../utils/helper'
import ClearButton from '../components/ClearButton'
import { COLOR_BLACK } from '../constants/Styles'
// import ListRoute from "../components/ListRoute";
import { ZALOPAY_CASHIN_DESCRIPTION } from '../constants/Messages'
import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'
// import FormGroup from "@material-ui/core/FormGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
// import CheckCircle from "@material-ui/icons/CheckCircle";
import {
  REACT_APP_APP_CLIENT_KEY_SS0,
  REACT_APP_RSA_KEY,
  REACT_APP_BUS_APP_ID
} from '../constants/Env'

class PaymentFriendPage extends Component {
  constructor(props) {
    super(props)
    // setTitle("Nạp tiền UniPass");
    this.state = {
      money: [10, 20, 50, 100, 200, 500],
      lastChosenMoney: 0,
      chosenMoney: 0,
      phone: '',
      customer_name: '',
      isGetUserAlready: false,
      error: {
        isError: false,
        message: null
      },
      isShowClearButton: true,
      isDisableButton: false
    }
    this.phoneInput = React.createRef()
    this.canAction = true
    this.throttlePayment = throttle(this.payment, 500)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.isGetUserAlready && nextProps.auth.user && nextProps.auth.user.phone) {
      return {
        ...prevState,
        isGetUserAlready: true,
        phone: formatPhone(nextProps.auth.user.account_name),
        customer_name: nextProps.auth.user.customer_name
      }
    }

    return {
      ...prevState
    }
  }

  componentDidMount() {
    const lastChosenMoney = localStorage.getItem('lastChosenMoney')
    if (lastChosenMoney && this.state.money.includes(Number(lastChosenMoney))) {
      this.setState({
        lastChosenMoney,
        chosenMoney: 0
      })
    }

    const { auth } = this.props
    if (!auth.customerPhone || !auth.user) {
      removeStorageLogout()
      this.props.history.replace('/')
    }
  }

  onChange = (e) => {
    const { value } = e.target
    value = formatPhone(value)

    this.setState({
      phone: value,
      customer_name: '',
      isShowClearButton: value.length > 0
    })
    if (value.length === 11) {
      const ssoKey = REACT_APP_APP_CLIENT_KEY_SS0
      const phone = removeSpace(value)
      const RSAEncrypt = new JSEncrypt()
      RSAEncrypt.setPublicKey(REACT_APP_RSA_KEY)
      const encryptedPhone = RSAEncrypt.encrypt(phone)
      const reqTime = Date.now()
      const clientSignature = CryptoJS.HmacSHA256(`${encryptedPhone}|${reqTime}`, ssoKey).toString()
      postApi({
        endpoint: 'api/customer/checkPhone',
        body: {
          cm: 'check_phone',
          dt: {
            p: encryptedPhone,
            sso_sig: clientSignature,
            rq_t: reqTime
          }
        }
      }).then((res) => {
        if (!res.err) {
          this.setState({
            customer_name: res.dt.customer_name,
            error: {
              isError: false
            }
          })
        } else {
          this.setState({
            error: {
              isError: true,
              message: 'Số điện thoại chưa đăng ký tài khoản UniPass'
            }
          })
        }
      })
    }
  }

  toggleDisableButton = () => {
    this.setState((prevState) => ({
      isDisableButton: !prevState.isDisableButton
    }))
  }

  chooseMoney = async (money) => {
    await this.setState({
      chosenMoney: money,
      lastChosenMoney: money
    })
    this.throttlePayment()
  }

  payment = async () => {
    if (!this.canAction) return
    this.canAction = false
    this.toggleDisableButton()
    const ZaloPay = window.ZaloPay
    ZaloPay.showLoading()

    const { phone } = this.state

    phone = removeSpace(phone)
    const ssoKey = REACT_APP_APP_CLIENT_KEY_SS0
    const reqTime = Date.now()
    const RSAEncrypt = new JSEncrypt()
    RSAEncrypt.setPublicKey(REACT_APP_RSA_KEY)
    const encryptedPhone = RSAEncrypt.encrypt(phone)
    const clientSignature = CryptoJS.HmacSHA256(`${encryptedPhone}|${reqTime}`, ssoKey).toString()
    const checkPhoneResp = await postApi({
      endpoint: 'api/customer/checkPhone',
      body: {
        cm: 'check_phone',
        dt: {
          p: encryptedPhone,
          sso_sig: clientSignature,
          rq_t: reqTime
        }
      }
    })

    if (checkPhoneResp.err) {
      this.setState({
        chosenMoney: 0,
        error: {
          isError: true,
          message: 'Số điện thoại chưa đăng ký tài khoản UniPass'
        }
      })
      ZaloPay.hideLoading()
      this.toggleDisableButton()
      this.canAction = true
      return
    }

    const { chosenMoney } = this.state
    localStorage.setItem('lastChosenMoney', chosenMoney)

    const muid = ''
    const maccess_token = ''
    const amount = Number(chosenMoney) * 1000
    if (typeof ZaloPay !== 'undefined' && ZaloPay.isZaloPay) {
      ZaloPay.ready(() => {
        ZaloPay.getUserInfo(Number(REACT_APP_BUS_APP_ID), (res) => {
          if (res.data.muid && typeof res.data.muid !== 'undefined') {
            muid = res.data.muid
          }
          if (res.data.maccess_token && typeof res.data.maccess_token !== 'undefined') {
            maccess_token = res.data.maccess_token
          }
          reqTime = Date.now()
          const clientSignature = CryptoJS.HmacSHA256(
            `${encryptedPhone}|${muid}|${maccess_token}|${amount}|${reqTime}`,
            ssoKey
          ).toString()
          postApi({
            endpoint: 'api/service/createOrder',
            body: {
              cm: 'create_order',
              dt: {
                amount: amount,
                p: encryptedPhone,
                m_uid: muid,
                m_access_token: maccess_token,
                sso_sig: clientSignature,
                rq_t: reqTime,
                is_zlp: this.props.auth.hasLoggedInByZaloOa ? 0 : 1
              }
            }
          }).then((serverRes) => {
            const { dt } = serverRes
            dt.description = `${ZALOPAY_CASHIN_DESCRIPTION} - ${friendlyPhone(phone)}`
            ZaloPay.hideLoading()
            this.toggleDisableButton()
            this.canAction = true
            ZaloPay.payOrder(dt, () => {})
          })
        })
      })
    } else {
      reqTime = Date.now()
      const clientSignature = CryptoJS.HmacSHA256(
        `${encryptedPhone}|${muid}|${maccess_token}|${amount}|${reqTime}`,
        ssoKey
      ).toString()
      postApi({
        endpoint: 'api/service/createOrderV2',
        body: {
          cm: 'create_order_v2',
          dt: {
            amount: amount,
            p: encryptedPhone,
            m_uid: muid,
            m_access_token: maccess_token,
            sso_sig: clientSignature,
            rq_t: reqTime,
            is_zlp: this.props.auth.hasLoggedInByZaloOa ? 0 : 1
          }
        }
      }).then((serverRes) => {
        this.toggleDisableButton()
        this.canAction = true
        const { dt } = serverRes
        window.open(dt, '_self')
      })
    }
    // this.props.history.push(`/confirm-payment?phone=${this.state.phone}&money=${money}`);
    // return;
  }

  onClear = (name) => {
    this.setState({
      [name]: '',
      customer_name: '',
      isShowClearButton: false,
      error: {
        isError: false,
        message: null
      }
    })
    this.phoneInput.focus()
  }

  render() {
    const {
      money,
      phone,
      customer_name,
      lastChosenMoney,
      chosenMoney,
      error,
      isDisableButton,
      isShowClearButton
    } = this.state

    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <ZPTextField
                label="Tài khoản"
                onChange={this.onChange}
                isLast={true}
                type="tel"
                name="phone"
                value={phone}
                error={error}
                myRef={(ref) => (this.phoneInput = ref)}
                pattern="[0-9\s]*"
                // InputProps={{
                // 	endAdornment: <InputAdornment position="end"><img style={{width:28}} src="/images/address.png" /></InputAdornment>
                // }}
              />
              <ClearButton isShow={isShowClearButton} name="phone" onClick={this.onClear} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl style={{ backgroundColor: '#f9f9f9' }} fullWidth className="last-block">
              <ZPTextField
                value={customer_name}
                label="Thuộc về"
                name="customer_name"
                isLast={true}
                style={{ backgroundColor: '#f9f9f9' }}
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ paddingLeft: '15px' }}>
          <p style={{ margin: '15px 0', textAlign: 'left', color: COLOR_BLACK }}>
            Chọn số tiền cần nạp
          </p>
          <Grid container style={{ marginTop: 5, margin: '-6px' }}>
            {money.map((m) => {
              const lastChosenMoneyNumber = Number(lastChosenMoney)
              const chosenMoneyNumber = Number(chosenMoney)

              return (
                <Grid item key={m} xs={4} style={{ height: 80, padding: 6 }}>
                  <Button
                    style={{
                      width: '100%',
                      height: '100%',
                      fontSize: 22,
                      textTransform: 'unset',
                      border:
                        lastChosenMoneyNumber === m ? '1px solid #008FE5' : '1px solid #D7D8D3',
                      color:
                        chosenMoneyNumber === m
                          ? '#fff'
                          : lastChosenMoneyNumber === m
                          ? '#008FE5'
                          : '#000',
                      backgroundColor: chosenMoneyNumber === m ? '#008FE5' : '#fff'
                    }}
                    variant="outlined"
                    onClick={(e) => this.chooseMoney(m)}
                    disabled={isDisableButton}
                  >
                    {m}k
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </div>
        {/*<ListRoute />*/}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(withRouter(PaymentFriendPage))
