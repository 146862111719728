import React from 'react'

import { ButtonCus } from './style'

const ButtonMonthlyTicket = (props) => {
  const { onClick, ...restProps } = props

  return <ButtonCus onClick={(e) => onClick()} variant="contained" {...restProps} />
}

export default ButtonMonthlyTicket
