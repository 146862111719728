import { Modal } from 'antd'

const LoadingModal = ({ loading = true }) => {
  return (
    <Modal centered open={loading} closeIcon={false} footer={null}>
      <div className="container-loading">
        <div />
        <div />
        <div />
        <p>Vui lòng chờ</p>
      </div>
    </Modal>
  )
}

export default LoadingModal
