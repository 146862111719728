import styled from 'styled-components'
import { ZP_GRADIENT } from '../../constants/Styles'

export const Header = styled.div`
  && {
    padding: 52px 12px 68px 12px;
    background-image: linear-gradient(${ZP_GRADIENT});
    background-image: -moz-linear-gradient(${ZP_GRADIENT});
    background-image: -webkit-linear-gradient(${ZP_GRADIENT});
    background-image: -o-linear-gradient(${ZP_GRADIENT});
    text-align: left;
  }
`

export const Phone = styled.p`
  && {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    margin: 0;
  }
`

export const Inactive = styled.p`
  && {
    font-family: 'SFDisplay-Medium' !important;
    font-size: 30px;
    color: #fff;
    margin: 0;
  }
`
export const Body = styled.div`
  && {
    padding: 52px 12px;
    text-align: center;
    img {
      width: 100%;
      max-width: 168px;
      margin-bottom: 10px;
    }
    p {
      color: #77818b;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
`
