import * as Types from './../constants/ActionType'

const initialState = {
  loading: false,
  listGift: [],
  listCampaign: [],
  giftDetail: {},
  campaignDetail: {}
}

const promotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.PROMOTION.GET_LIST_GIFT:
      return {
        ...state,
        loading: true,
        listGift: []
      }
    case Types.PROMOTION.GET_LIST_GIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        listGift: action.data
      }
    case Types.PROMOTION.GET_LIST_GIFT_FAIL:
      return {
        ...state,
        loading: false,
        listGift: []
      }
    case Types.PROMOTION.GET_LIST_CAMPAIGN:
      return {
        ...state,
        loading: true,
        listCampaign: []
      }
    case Types.PROMOTION.GET_LIST_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        listCampaign: action.data
      }
    case Types.PROMOTION.GET_LIST_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        listCampaign: []
      }
    case Types.PROMOTION.GET_GIFT_DETAIL:
      return {
        ...state,
        loading: true,
        giftDetail: {}
      }
    case Types.PROMOTION.GET_GIFT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        giftDetail: action.data
      }
    case Types.PROMOTION.GET_GIFT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        giftDetail: {}
      }

    case Types.PROMOTION.GET_CAMPAIGN_DETAIL:
      return {
        ...state,
        loading: true,
        campaignDetail: {}
      }
    case Types.PROMOTION.GET_CAMPAIGN_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignDetail: action.data
      }
    case Types.PROMOTION.GET_CAMPAIGN_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        campaignDetail: {}
      }

    default:
      return state
  }
}

export default promotionReducer
