import styled from 'styled-components'

export const ReminderDialogWrapper = styled.div`
  padding: 20px 10px 20px;
`

export const HeaderWrapper = styled.div`
  text-align: center;
  > span {
    color: #333333;
    font-weight: bold;
    font-size: 16px;
  }

  > p {
    font-size: 13px;
  }

  > div {
    background: #ff6660;
  }
`

export const ReminderItemBorder = styled.div`
  border: 1px solid #919191;
  margin-top: 15px;
  border-radius: 8px;
`

export const IconWrapper = styled.div`
  display: flex;
  padding: 20px 0px;
  justify-content: center;

  > div {
    width: 100px;
    margin: auto;
    text-align: center;
  }
`

export const ImageWrapper = styled.div`
  width: 25px;
  text-align: center;
  border-radius: 50%;
  background: ${(props) => props.background};
  position: absolute;
  right: 20px;
  top: 20px;
`

export const Icon = styled.div`
  background: ${(props) => props.background};
  width: 100px;
  height: 50px;
  border-radius: 10px;
  text-align: center;
`

export const IconText = styled.div`
  font-weight: bold;
  margin-top: 10px;
`
