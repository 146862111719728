import { ADD_NOTIFICATION, CLEAR_NOTIFICAIONS } from '../constants/ActionType'

export const addNotification = (notification) => {
  return {
    type: ADD_NOTIFICATION,
    notification
  }
}

export const clearNotification = () => {
  return {
    type: CLEAR_NOTIFICAIONS
  }
}
