import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export default function useWhiteList(env = '') {
  const { customerPhone } = useSelector((state) => state.auth)

  const hasAccess = useMemo(() => {
    const whiteListPhone = env.replace(/\s/g, '').split(',')
    if (whiteListPhone.length === 0) return false
    return whiteListPhone.some((f) => f === customerPhone)
  }, [customerPhone])

  return hasAccess
}
