import { call, put, takeLatest } from '@redux-saga/core/effects'
import { getSupportedRoutes } from '../../services/route.services'
import * as actionTypes from '../../constants/ActionType'
import { getSupportedRoutesDone } from '../../actions/route.action'
function* handleGetSupportedRoutes() {
  const res = yield call(getSupportedRoutes)

  if (res.error.code === 1) {
    const uniqueRouteByRouteNumber = [
      ...new Map(res.data.map((item) => [item['route_number'], item])).values()
    ]
    yield put(getSupportedRoutesDone(uniqueRouteByRouteNumber))
  }
}
export default function* bindingSaga() {
  yield takeLatest(actionTypes.GET_SUPPORTED_ROUTES, handleGetSupportedRoutes)
}
