import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import React from 'react'
import { X } from 'react-feather'
import ReceiveGiftItem from './ReceiveGiftItem'

const styles = {
  header: {
    borderBottom: '0.5px solid rgb(230, 230, 230)',
    height: 56,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 17,
    width: '100%',
    textAlign: 'center'
  }
}
export default function SwipeableReceiveGift({ isOpen = false, onClose, receivedGifts }) {
  return (
    <SwipeableDrawer
      anchor="bottom"
      onClose={onClose}
      onOpen={() => {
        console.log('open swipeable')
      }}
      open={isOpen}
      PaperProps={{
        style: { borderRadius: '30px 30px 0px 0px' }
      }}
    >
      <div style={styles.header} className="text-bold">
        <X size={24} className="ml-3" onClick={onClose} style={{ position: 'absolute' }} />
        <div style={styles.title}>Danh sách nhận quà tặng</div>
      </div>
      <div className="p-3">
        {receivedGifts.map((item, key) => (
          <ReceiveGiftItem item={item} key={key} index={key + 1} countItem={receivedGifts.length} />
        ))}
      </div>
    </SwipeableDrawer>
  )
}
