import * as Types from './../constants/ActionType'

const initialState = {
  requested: []
}

const refund = (state = initialState, action) => {
  switch (action.type) {
    case Types.REFUND.PROCESSING:
      return {
        ...state,
        requested: []
      }
    case Types.REFUND.PROCESSING_DONE:
      return {
        ...state,
        requested: action.data
      }
    default:
      return state
  }
}

export default refund
