import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import { color } from '../../utils/color'

export const ZPDialogStyle = styled(Dialog)`
  && {
    > div > div {
      width: calc(100% - 60px);
      margin: 0;
      box-shadow: none;
      z-index: 20;
      > div {
        &.content {
          width: unset !important;
          padding: 30px 15px;
          text-align: center;
          p {
            color: #4a4a4a;
          }
          img {
            width: 50px;
            margin-bottom: 30px;
          }
        }
        &.action {
          margin: 0;
          padding: 0;
          > button {
            margin: 0;
            width: ${(props) => props.buttonwidth};
            padding: 15px 16px;
            font-size: 15px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-family: 'SFDisplay' !important;
            &.left {
              background-color: #f0f4f6;
              color: #727f8c;
              border-bottom-right-radius: 0;
              border-right: 1px solid white;
            }
            &.right {
              background-color: ${(props) =>
                props.rightbuttondisabled ? '#f0f4f6' : color.blue[500]};
              color: ${(props) => (props.rightbuttondisabled ? '#727f8c' : 'white')};
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }
    .description {
      font-size: 15px;
    }
    span[class^='icon-'],
    span[class*=' icon-'] {
      margin-bottom: 20px;
      display: block;
    }
  }
`
