import { useMemo } from 'react'
import BasicButton from '../../../../../new-components/Button'
import Image from '../../../../../new-components/Image'
import { color } from '../../../../../utils/color'
import moment from 'moment'

const Wait = ({ dateUpdate }) => {
  const forecastDateConfirm = useMemo(() => {
    if (dateUpdate) {
      const date = moment(dateUpdate, 'DD-MM-YYYY HH:mm:ss').add(14, 'day')
      return date.format('DD/MM/YYYY')
    }
  }, [dateUpdate])

  return (
    <>
      <div
        style={{
          padding: 10,
          backgroundColor: color.yellow[400],
          color: color.orange[500],
          width: '100%'
        }}
        className="text-14-20-400"
      >
        Ảnh thẻ học sinh/sinh viên của bạn đang trong quá trình phê duyệt.
      </div>
      <div className="text-center">
        Thời gian dự kiến duyệt <b>{forecastDateConfirm}</b> <br /> Mọi thắc mắc vui lòng liên hệ{' '}
        <b>1900 633 314</b>
      </div>
    </>
  )
}

const Reject = ({ reason }) => {
  return (
    <>
      <div
        style={{
          padding: 10,
          backgroundColor: color.red[400],
          color: color.red[450],
          width: '100%'
        }}
        className="text-14-20-400"
      >
        Ảnh thẻ học sinh/sinh viên của bạn đã bị từ chối.
      </div>
      <div className="text-center w-100" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
        <b>Lý do từ chối:</b> {reason}
      </div>
    </>
  )
}

export default function Result({ setStep, steps, newData, user }) {
  const data = useMemo(() => {
    return newData ?? user
  }, [user, newData])

  const reUpdate = () => {
    setStep(steps.upload)
  }

  return (
    <div
      className="d-flex flex-column w-100 justify-content-center align-items-center"
      style={{ gap: 10 }}
    >
      {user?.reject_description ? (
        <Reject reason={user?.reject_description} />
      ) : (
        <Wait dateUpdate={user?.date_update} />
      )}

      <Image
        containerStyle={{ marginTop: 17, marginBottom: 12 }}
        imgSrc={data?.student_card_image}
        style={{
          borderRadius: 8,
          height: window.innerHeight - 300,
          width: window.innerWidth - 50,
          objectFit: 'contain',
          opacity: user?.reject_description ? 0.5 : 1
        }}
      />

      {(user?.registration_confirm_status === 1 || user?.reject_description) && (
        <BasicButton
          onClick={reUpdate}
          style={{ backgroundColor: color.blue[500], color: 'white' }}
          shape="round"
        >
          Cập nhật lại
        </BasicButton>
      )}
    </div>
  )
}
