import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import React from 'react'
import { COLOR_BLUE } from '../../constants/Styles'

import { ZPDialogStyle } from './style'

const theme = createTheme({
  typography: {
    useNextVariants: true
  }
})

const DialogAlert = (props) => {
  const {
    leftButtonTitle,
    rightButtonTitle,
    open,
    description,
    onLeftConfirm,
    onRightConfirm,
    onCloseClickOut,
    logo = 'notify', // notify, warning, infor
    privatePolicy,
    rightbuttondisabled = false
  } = props

  const buttonWidth = leftButtonTitle && rightButtonTitle ? '50%' : '100%'

  return (
    <MuiThemeProvider theme={theme}>
      <ZPDialogStyle
        open={open}
        onClose={onCloseClickOut ? (e) => onCloseClickOut() : null}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        buttonwidth={buttonWidth}
        rightbuttondisabled={rightbuttondisabled}
      >
        <DialogContent className="content">
          <span className={`icon-dialoge_${logo} icon-zp-64`}></span>
          {/* <img src={`/images/dialoge_${logo}.png`} /> */}
          {!privatePolicy && (
            <DialogContentText className="description" id="alert-dialog-description">
              {description}
            </DialogContentText>
          )}
          {privatePolicy && (
            <div style={{ padding: 12 }}>
              <p style={{ textAlign: 'center', color: COLOR_BLUE }}>
                CHÍNH SÁCH BẢO VỆ QUYỀN RIÊNG TƯ
              </p>
              <p style={{ textAlign: 'left' }}>
                Bản cập nhật ngày 15/10/2017
                <br />
                Quy định này áp dụng cho dịch vụ do Công ty Cổ phần ZION (sau đây gọi tắt là “ZION”)
                cung ứng và xác định cách thức thu thập, sử dụng và bảo mật thông tin của bạn trong
                quá trình thiết lập, sử dụng dịch vụ thanh toán do ZION cung ứng (bao gồm website:
                https://zalopay.vn và/hoặc ứng dụng ZaloPay để thanh toán hàng hóa, dịch vụ.
                <br />
                Khi bạn đăng ký một tài khoản để sử dụng dịch vụ thanh toán do ZION cung ứng, ZION
                hiểu rằng bạn thiết lập một thỏa thuận với ZION và đảm bảo việc tuân thủ các quy
                định liên quan.
                <br />
              </p>
              <h4 style={{ textAlign: 'left' }}>THU THẬP THÔNG TIN</h4>
              <ul style={{ textAlign: 'left' }}>
                <li>
                  Việc thu thập thông tin của bạn bao gồm những thông tin cần thiết cho việc cung
                  cấp dịch vụ và nhằm bảo vệ tài khoản của bạn.
                </li>
                <li>
                  Các thông tin thu thập bao gồm nhưng không giới hạn các thông tin: Họ tên, ngày
                  tháng năm sinh, địa chỉ, số điện thoại, email (Sau đây gọi tắt là “Thông tin đăng
                  ký”) có thể được thu thập trong bất kỳ thời điểm nào bạn đăng ký sử dụng dịch vụ
                  do ZION cung ứng.
                </li>
                <li>
                  Trong quá trình kết nối tới bất kỳ dịch vụ nào do ZION cung ứng, thông tin bao gồm
                  nhưng không giới hạn: Số tài khoản ngân hàng, thông tin thanh toán và giao hàng,
                  số thẻ ngân hàng và ngày đến hạn và các thông tin liên quan đến Séc hoặc lệnh
                  chuyển tiền, và các thông tin liên quan khác (Sau đây gọi chung là “Thông tin tài
                  khoản”) có thể được thu thập để hỗ trợ cho quá trình thực hiện các giao dịch thanh
                  toán hàng hóa, dịch vụ sử dụng Dịch vụ thanh toán do ZION cung ứng.
                </li>
                <li>
                  Hoạt động thu thập thông tin về khách hàng và khách hàng tiềm năng có thể được
                  tiến hành tại nhiều địa điểm, thời gian, sự kiện khác nhau. Các thông tin được
                  ZION thu thập có thể bao gồm nhưng không giới hạn: Tên tài khoản, địa chỉ, số điện
                  thoại, số fax, địa chỉ email,….
                </li>
                <li>
                  Trong phạm vi thỏa Thỏa thuận này, các thông tin thu thập nói trên sẽ tạo thành
                  tệp dữ liệu nhận dạng cá nhân, các thông tin này được gọi chung là “Thông tin cá
                  nhân”.
                </li>
              </ul>
              <h4 style={{ textAlign: 'left' }}>SỬ DỤNG THÔNG TIN CÁ NHÂN</h4>
              <p style={{ textAlign: 'left' }}>
                Khi bạn cung cấp Thông tin cá nhân cho chúng tôi, bạn đảm bảo rằng bạn cung cấp cho
                chúng tôi quyền được thu thập, sắp xếp, lưu trữ và sử dụng Thông tin cá nhân cho các
                mục đích sau đây:
              </p>
              <ul style={{ textAlign: 'left' }}>
                <li>
                  Xác thực thông tin về bạn trong suốt quá trình tạo lập tài khoản và mật khẩu.
                </li>
                <li>Xác thực năng lực của bạn để đăng ký như một khách hàng của ZION;</li>
                <li>
                  Chấp thuận quá trình đăng ký của bạn như một khách hàng của ZION, cung ứng cho bạn
                  một tài khoản đăng nhập vào ZaloPay cũng như các hoạt động sửa đổi, bổ sung, cập
                  nhật, quản lý Thông tin đăng ký.
                </li>
                <li>
                  Cung cấp các dịch vụ Trung Gian Thanh Toán và Dịch vụ Khách hàng có liên quan (bao
                  gồm nhưng không giới hạn các dịch vụ thanh toán trực tuyến sử dụng chức năng của
                  ZaloPay, các thông báo giao dịch và trả lời các truy vấn, phản hồi, khiếu nại hoặc
                  tranh chấp của bạn) thông qua các cuộc gọi thoại, tin nhắn hoặc email hay có thể
                  là bằng văn bản hoặc các hình thức khác.
                </li>
                <li>
                  Tiến hành các hoạt động tra cứu và phân tích người dùng nhằm nâng cao chất lượng
                  dịch vụ, quản lý và bảo vệ các thông tin, hệ thống kỹ thuật dịch vụ, đo lường hiệu
                  suất dịch vụ do ZION cung ứng và cải thiện sản phẩm, dịch vụ của ZION.
                </li>
                <li>
                  Trên cơ sở các thông tin có được, phù hợp với quy định pháp luật hiện hành, bạn
                  đồng ý rằng, chúng tôi có thể sử dụng tên của bạn, số điện thoại, địa chỉ liên hệ,
                  email, số fax để cung cấp các thông báo, khảo sát, thông tin sản phẩm, các hoạt
                  động truyền thông, khuyến mại khác tới bạn liên quan đến dịch vụ được cung cấp bởi
                  ZION.
                </li>
                <li>
                  Quản lý rủi ro, thực hiện kểm tra khả năng tín nhiệm và khả năng thanh toán (và
                  đối chiếu thông tin để xác định tính chính xác, xác thực với bên thứ ba) hoặc đánh
                  giá, phát hiện, và/hoặc khắc phục gian lận, giả mạo hoặc các hành vi có thể bị cấm
                  hoặc vi phạm pháp luật.
                </li>
                <li>
                  Phát hiện, ngăn ngừa hay khắc phục tình trạng vi phạm chính sách hay thỏa thuận
                  người dùng có hiệu lực.
                </li>
                <li>
                  Cung cấp thông tin khi phát sinh yêu cầu theo quy định pháp luật, cơ quan có thẩm
                  quyền hay bên thứ ba căn cứ vào yêu cầu của tòa án hoặc thực thể pháp lý khác,
                  hoặc khi thực thi các quy định pháp luật Bao gồm nhưng không giới hạn các quy định
                  phòng chống rửa tiền, tài trợ khủng bố) hoặc các quy tắc của tổ chức thẻ; hoặc khi
                  chúng tôi tin rằng việc tiết lộ Dữ liệu cá nhân là cần thiết để ngăn ngừa tổn hại
                  về thể chất hoặc mất mát tài chính, để báo cáo hoạt động bất hợp pháp bị nghi ngờ
                  hoặc liên quan đến bất kỳ khiếu nại hoặc khả năng khiếu nại nào có thể đưa ra đối
                  với chúng tôi
                </li>
              </ul>
              <h4 style={{ textAlign: 'left' }}>TIẾT LỘ THÔNG TIN</h4>
              <ul style={{ textAlign: 'left' }}>
                <li>
                  Bạn đồng ý rằng, chúng tôi có thể tiết lộ và sử dụng thông tin cá nhân của bạn cho
                  các dịch vụ được cung cấp bởi chúng tôi hoặc tổ chức tài chính mà chúng tôi có hợp
                  tác với mục đích cung cấp cho bạn các dịch vụ an toàn an ninh thông tin do chúng
                  tôi cung ứng cho bạn (bao gồm nhưng không giới hạn ở việc phòng chống giao dịch
                  gian lận, thu phí, nhập dữ liệu, quản lý cơ sở dữ liệu, khuyến mãi, tiếp thị, dịch
                  vụ khách hàng, dịch vụ công nghệ, sản phẩm và các cảnh báo và các dịch vụ thanh
                  toán)
                </li>
                <li>
                  Bạn đồng ý rằng nhằm phục vụ cho các mục đích tại mục II nói trên, chúng tôi có
                  thể cung cấp, sử dụng thông tin cá nhân của bạn trong việc phối hợp với các doanh
                  nghiệp liên kết (Công ty mẹ, con theo quy định pháp luật) của ZION.
                </li>
                <li>
                  Trong trường hợp cần thiết, chúng tôi có thể tiết lộ và cung cấp các thông tin cá
                  nhân của bạn tới các chuyên gia tư vấn, cơ quan có thẩm quyền, bên bảo hiểm, hoặc
                  các định chế pháp lý cho mục đích tại mục II nêu trên.
                </li>
                <li>
                  Chúng tôi có thể chia sẻ thông tin tin cá nhân của bạn với Tổ chức tín dụng hoặc
                  Bên cung cấp dịch vụ để chấp thuận và hoàn tất giao dịch của bạn trên ZaloPay.
                  Ngoài ra, chúng tôi có thể sử dụng thông tin tài khoản và dữ liệu cá nhân của bạn
                  để đánh giá, ngăn ngừa gian lận, quản lý các rủi ro hoặc đánh giá mức độ đáng tin
                  cậy của bạn và các rủi ro liên quan hoặc bảo vệ tài khoản của bạn khỏi hoạt động
                  gian lận và trong quá trình xác định như vậy, chúng tôi có thể cần cung cấp Thông
                  tin Tài khoản và Dữ liệu Cá nhân cho Nhà Cung cấp Dịch vụ, ngân hàng, đại lý thu
                  nợ hoặc các Tổ chức tín dụng.
                </li>
                <li>
                  Chúng tôi có thể chia sẻ Dữ liệu cá nhân của bạn với các công ty mà chúng tôi dự
                  định hợp nhất hoặc được mua lại, sẽ tuân theo các điều khoản và điều kiện của
                  Chính sách này liên quan đến việc sử dụng Thông tin Cá nhân của Bạn.
                </li>
                <li>
                  Chúng tôi có thể cung cấp thông tin thống kê cho các bên thứ ba, nhưng khi chúng
                  tôi làm như vậy, chúng tôi không cung cấp thông tin nhận dạng cá nhân mà không có
                  sự cho phép của bạn. Chúng tôi cũng có thể chia sẻ Dữ liệu Cá nhân của bạn với các
                  bên thứ ba khác với sự chấp thuận hoặc chỉ dẫn của bạn để làm như vậy.
                </li>
                <li>
                  Mặc dù chúng tôi đã có công nghệ cập nhật và thủ tục nội bộ để giữ cho Thông tin
                  Tài khoản của bạn và các Dữ liệu Cá nhân khác an toàn từ những kẻ xâm nhập, nhưng
                  không có đảm bảo rằng các công nghệ hoặc thủ tục như vậy có thể loại bỏ tất cả các
                  rủi ro về trộm cắp, mất mát hoặc sử dụng sai .
                </li>
                <li>
                  Mọi thông tin cá nhân do bạn cung cấp sẽ được lưu giữ bởi chúng tôi và nhân viên
                  của chúng tôi, bất kỳ Nhà cung cấp Dịch vụ nào mà chúng tôi và các bên thứ ba tham
                  gia nêu trên đều có thể tiếp cận được, hoặc liên quan đến bất kỳ mục đích nào tại
                  mục II trên đây, những người sẽ có trách nhiệm sẽ giữ bí mật và chỉ được phép sử
                  dụng Dữ liệu Cá nhân của bạn liên quan đến các mục đích được chỉ định tại mục II
                  trên đây và không cho mục đích của riêng họ (kể cả tiếp thị trực tiếp) trừ khi bạn
                  đã đồng ý cho các mục đích sử dụng khác.
                </li>
              </ul>
              <h4 style={{ textAlign: 'left' }}>BIỆN PHÁP ĐẢM BẢO AN TOÀN, AN NINH</h4>
              <ul style={{ textAlign: 'left' }}>
                <li>
                  Chúng tôi sử dụng phương pháp hợp lý để ngăn chặn truy cập trái phép vào sản phẩm,
                  dịch vụ của ZION, để duy trì độ chính xác của dữ liệu và để đảm bảo sử dụng đúng
                  thông tin chúng tôi lưu giữ.
                </li>
                <li>
                  Đối với người dùng đã đăng ký sử dụng dịch vụ, thông tin đăng ký và thông tin tài
                  khoản của bạn (nếu có) có thể được xem và trong phạm vi nhất định bạn có thể chỉnh
                  sửa thông qua tài khoản của bạn, được bảo vệ bằng mật khẩu theo các cách thức được
                  ZION hỗ trợ trên các website, ứng dụng cung ứng dịch vụ. Chúng tôi đề nghị bạn
                  không tiết lộ mật khẩu của mình cho bất cứ ai. Nhân viên của chúng tôi sẽ không
                  bao giờ yêu cầu bạn cho mật khẩu của bạn trong một cuộc gọi điện thoại hoặc email
                  hay bất kỳ kênh kết nối nào với bạn. Nếu bạn chia sẻ máy tính, thiết bị di động
                  với người khác, bạn không nên chọn lưu thông tin đăng nhập (ví dụ: ID người dùng
                  và mật khẩu) trên thiết bị dùng chung đó. Hãy nhớ đăng xuất khỏi tài khoản của bạn
                  và đóng cửa sổ trình duyệt của bạn khi bạn kết thúc phiên làm việc.
                </li>
              </ul>
              <h4 style={{ textAlign: 'left' }}>THAY ĐỔI VỚI CHÍNH SÁCH RIÊNG TƯ</h4>
              <p style={{ textAlign: 'left' }}>
                Bất kỳ thay đổi nào đối với Chính sách này sẽ được thông báo bởi chúng tôi trên
                website hoặc ứng dụng cung ứng dịch vụ. Một khi được đăng tải công khai, Chính sách
                mới sẽ có hiệu lực ngay lập tức. Bạn đồng ý rằng bất kỳ thông tin nào chúng tôi giữ
                về bạn (như được mô tả trong Chính sách này và có hoặc không thu thập trước hoặc sau
                khi Chính sách mới có hiệu lực) sẽ được điều chỉnh bởi phiên bản mới nhất của Chính
                sách Quyền riêng tư.
              </p>
              <h4 style={{ textAlign: 'left' }}>THẨM QUYỀN VÀ LUẬT ĐIỀU CHỈNH</h4>
              <p style={{ textAlign: 'left' }}>
                Quy định này được giải thích, và thi hành theo Pháp Luật Việt Nam. Bạn hoàn toàn
                đồng ý với thẩm quyền duy nhất của Toà án, liên quan đến bất kỳ hành động nào để thi
                hành các điều khoản của Thỏa thuận này.
              </p>
              <h4 style={{ textAlign: 'left' }}>THÔNG TIN THÊM</h4>
              <p style={{ textAlign: 'left' }}>
                Nếu bạn có thắc mắc hoặc quan ngại về chính sách này, hãy liên hệ với chúng tôi theo
                thông tin sau:
              </p>
              <p style={{ textAlign: 'left' }}>- Công ty Cổ phần ZION</p>
              <p style={{ textAlign: 'left' }}>
                - Địa chỉ số 52 Nguyễn Ngọc Lộc, Phường 14, Quận 10, thành phố Hồ Chí Minh, Việt Nam
              </p>
              <p style={{ textAlign: 'left' }}>
                - Email: hotro@zalopay.vn - Hotline: 1900 54 54 36./.
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions className="action">
          {leftButtonTitle && (
            <Button className="left" fullWidth onClick={onLeftConfirm} color="primary" autoFocus>
              {leftButtonTitle}
            </Button>
          )}
          {rightButtonTitle && (
            <Button
              className="right"
              fullWidth
              onClick={onRightConfirm}
              color="primary"
              autoFocus
              disabled={rightbuttondisabled}
            >
              {rightButtonTitle}
            </Button>
          )}
        </DialogActions>
      </ZPDialogStyle>
    </MuiThemeProvider>
  )
}

export default DialogAlert
