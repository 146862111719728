import styled from 'styled-components'

export const RechargeCounterWrapper = styled.div`
  padding: 10px 10px 0;
  height: 40vh;
  width: 100%;
`

export const CounterImage = styled.img`
  width: 100%;
`
