import { Button } from '@material-ui/core'
import React from 'react'
import { color } from '../utils/color'

const styles = {
  image: { width: 96, height: 96, marginTop: 30 },
  title: { color: color.black[400], fontSize: 16, fontWeight: 600 },
  button: {
    padding: 10,
    borderRadius: 30,
    fontSize: 16,
    fontWeight: 700,
    width: '90%',
    boxShadow: 'none',
    backgroundColor: color.blue[500],
    color: 'white'
  },
  content: { color: '#7A7C8B', fontSize: 14, marginTop: 5 }
}
export default function NoticePage({ img, title, content, action }) {
  return (
    <div className="container">
      <div style={{ height: 40 }} />
      <img alt="" src={img} />
      <div style={styles.title}>{title}</div>
      {content && <div style={styles.content}>{content}</div>}
      {action && (
        <div
          className="bottom-fixed-button-wrapper"
          style={{ bottom: 30, backgroundColor: '#fff', zIndex: 1 }}
        >
          <Button variant="contained" fullWidth style={styles.button} onClick={action}>
            Quay lại trang chủ
          </Button>
        </div>
      )}
    </div>
  )
}
