import styled from 'styled-components'

export const Row = styled.div`
  color: #919191;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`

export const Block = styled.div`
  background: #ffffff;
  border-radius: 3px;
  margin-top: 10px;
`

export const Header = styled.div`
  background: #ffffff;
  border-radius: 3px;
  margin-top: 30px;
  padding: 15px 0 1px;
`

export const Value = styled.span`
  color: #152941;
`
