import CryptoJS from 'crypto-js'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REACT_APP_APP_CLIENT_KEY_SS0 } from '../../constants/Env'

export default () => {
  const [triggerFlag, setTriggerFlag] = useState(null)
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const triggerTransactionFetcher = () => {
    setTriggerFlag((triggerFlag) => (triggerFlag === null ? true : !triggerFlag))
  }

  useEffect(() => {
    const fetchTransations = async () => {
      const ssoKey = REACT_APP_APP_CLIENT_KEY_SS0
      const reqTime = Date.now()
      const cuId = auth.customerId
      const clientSignature = CryptoJS.HmacSHA256(`${cuId}|${reqTime}`, ssoKey).toString()
      // const res = await postApi({
      //   endpoint: "api/customer/getInvoices",
      //   body: {
      //     cm: "gibu",
      //     dt: {
      //       cu_id: cuId,
      //       sso_sig: clientSignature,
      //       rq_t: reqTime,
      //     }
      //   }
      // });

      // if (res.dt) {
      //   dispatch(addTransactions(res.dt))
      // }
    }
    if (auth.customerId && triggerFlag !== null) {
      fetchTransations()
    }
  }, [triggerFlag])

  return {
    triggerTransactionFetcher
  }
}
