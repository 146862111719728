import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SkeletonCustom from '../../../../../components/Skeleton'
import RequestAndUpload from './RequestAndUpdate'
import { loadImage } from './utils'

const styles = {
  image: { borderRadius: 8 }
}

export default function StudentImage({ studentCardImage }) {
  const user = useSelector((state) => state.auth.user)
  const [urlData, setUrlData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingImage, setLoadingImage] = useState(true)

  useEffect(() => {
    setLoading(false)
    if (user.student_card_image) {
      loadImage(user.student_card_image, setLoading, setUrlData)
    }
  }, [user.student_card_image])

  return (
    <div>
      {studentCardImage && user?.registration_confirm_status === 1 && !user?.reject_description ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: 600 }}
        >
          {loading ||
            (loadingImage && <SkeletonCustom height={580} width={window.innerWidth - 50} />)}
          <img
            alt="student-image"
            src={urlData}
            onLoad={() => setLoadingImage(false)}
            onLoadedData={() => setLoadingImage(false)}
            onError={() => setLoadingImage(false)}
            style={{
              ...styles.image,
              height: 580,
              width: window.innerWidth - 50,
              objectFit: 'fill',
              display: loading || loadingImage ? 'none' : 'flex',
              borderRadius: 8
            }}
          />
        </div>
      ) : (
        <RequestAndUpload user={user} />
      )}
    </div>
  )
}
