function getEnv(name) {
  return window.env[name] === undefined ? process.env[name] : window.env[name]
}

export const REACT_APP_ZALO_ID = getEnv('REACT_APP_ZALO_ID')

export const REACT_APP_ZALO_SECRET_KEY = getEnv('REACT_APP_ZALO_SECRET_KEY')
export const REACT_APP_ZALO_REDIRECT_URL = getEnv('REACT_APP_ZALO_REDIRECT_URL')

export const REACT_APP_ADMIN_DOMAIN = getEnv('REACT_APP_ADMIN_DOMAIN')
export const REACT_APP_API_DOMAIN = getEnv('REACT_APP_API_DOMAIN')
export const REACT_APP_API_URL = getEnv('REACT_APP_API_URL')
export const REACT_APP_APP_CLIENT_KEY_SS0 = getEnv('REACT_APP_APP_CLIENT_KEY_SS0')
export const REACT_APP_BUS_APP_ID = getEnv('REACT_APP_BUS_APP_ID')
export const REACT_APP_DEBUG_ACCOUNTS = getEnv('REACT_APP_DEBUG_ACCOUNTS')
export const REACT_APP_GOOGLE_MAPS_API_KEY = getEnv('REACT_APP_GOOGLE_MAPS_API_KEY')
export const REACT_APP_LOCATION_SERVICE_URL = getEnv('REACT_APP_LOCATION_SERVICE_URL')
export const REACT_APP_ON_ZALOPAY = getEnv('REACT_APP_ON_ZALOPAY')
export const REACT_APP_RSA_KEY = getEnv('REACT_APP_RSA_KEY')
export const REACT_APP_TRANSACTION_BROKER_LINK = getEnv('REACT_APP_TRANSACTION_BROKER_LINK')
export const REACT_APP_TRANSACTION_BROKER_PASSWORD = getEnv('REACT_APP_TRANSACTION_BROKER_PASSWORD')
export const REACT_APP_TRANSACTION_BROKER_USER = getEnv('REACT_APP_TRANSACTION_BROKER_USER')
export const REACT_APP_PHONE_WHITELIST = getEnv('REACT_APP_PHONE_WHITELIST')
export const REACT_APP_SENTRY_DSN = 'https://5365015aadc8445d9fe78e017ff56623@sentry.prism.vn/5'
