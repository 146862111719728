import React, { Component, Fragment } from 'react'

import { setTitle, removeBodyBg } from '../utils/helper'
import { COLOR_BLUE } from '../constants/Styles'
import NoticePage from '../components/NoticePage'
import { useHistory, useLocation } from 'react-router-dom'
import * as api from '../utils/apiCallerV2'
import ZPSubTitle from '../components/ZPSubTitle'
import { color } from '../utils/color'
import { Button } from '@material-ui/core'
import './styles/animation.css'

const styles = {
  img: { width: 148 },

  button: {
    position: 'fixed',
    bottom: 40,
    padding: 10,
    fontSize: 15,
    fontWeight: 500,
    width: '95%',
    boxShadow: 'none',
    marginTop: 42,
    color: 'white',
    backgroundColor: color.blue[500]
  }
}

export default function ConfirmDegree13Page() {
  setTitle('Chính sách quyền riêng tư')
  removeBodyBg()
  const history = useHistory()
  const degree13 = () => {
    history.replace('/degree13')
  }
  const onConfirm = () => {
    api.post('v2/customer/update-privacy-policy?accepted=true').then(() => {
      history.go(0)
    })
  }
  return (
    <Fragment>
      <ZPSubTitle fullWidth style={{ margin: '0px 0px 20px 0px', height: '60px', borderRadius: '0' }}>
        <div style={{ position: 'relative', textAlign: 'center' }}>
          <a
            onClick={degree13}
            className="text-14-20"
            style={{ position: 'absolute', bottom: '-30px', right: '20px' }}
          >
            Tìm Hiểu Thêm
          </a>
        </div>
      </ZPSubTitle>
      <div className="d-flex flex-column align-items-center px-16 pt-24">
        <div className="mt-16">
          <img alt="unipass speaker temp close refund" src="/img/security.png" style={styles.img} />
        </div>
        <div className='d-flex flex-column'>
          <span className="text-20-24-700 mt-32 text-bold text-black320">
            UniPass tôn trọng
            <br /> quyền riêng tư của bạn
          </span>
          <span className="mt-24 mx-auto text-16-20 text-black250">
            UniPass sẽ thu thập các dữ liệu chẩn đoán để ứng dụng luôn bảo mật và cập nhật.
            <span className="text-bold"></span>
          </span>
          <span className="mt-24 mx-auto text-16-20 text-black250 mt-16">
            UniPass cũng sẽ thu thập một số dữ liệu trong quá trình sử dụng để cải thiện trải nghiệm
            của bạn.<br/> Bạn có thể từ chối chia sẻ dữ liệu này bất cứ lúc nào bạn muốn.
          </span>
        </div>
        <Button variant="contained" fullWidth style={styles.button} onClick={onConfirm}>
          Đồng ý
        </Button>
      </div>
    </Fragment>
  )
}
