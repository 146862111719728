import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircle from '@material-ui/icons/CheckCircle'
import FormControl from '@material-ui/core/FormControl'

const styles = {
  container: { padding: '30px 0 0 15px' },
  checkBoxStyle: {
    textAlign: 'left',
    color: '#7A7C8B'
  }
}
export default function RechargeTerms() {
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          checked={true}
          value="end"
          style={styles.checkBoxStyle}
          label="Tiền được cộng vào tài khoản UniPass sau khi nạp"
          control={
            <Checkbox icon={<CircleUnchecked />} checkedIcon={<CheckCircle />} color="primary" />
          }
        />
        <FormControlLabel
          checked={true}
          value="end"
          style={styles.checkBoxStyle}
          label="Tự động thanh toán từ số dư tài khoản UniPass"
          control={
            <Checkbox icon={<CircleUnchecked />} checkedIcon={<CheckCircle />} color="primary" />
          }
        />
        <FormControlLabel
          checked={true}
          value="end"
          style={styles.checkBoxStyle}
          label="Tiền chỉ có thể sử dụng cho dịch vụ UniPass, không thể rút ra"
          control={
            <Checkbox icon={<CircleUnchecked />} checkedIcon={<CheckCircle />} color="primary" />
          }
        />
      </FormGroup>
    </FormControl>
  )
}
