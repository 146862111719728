import React, { Component } from 'react'
import CustomerFeedBackUI from '../components/Pages/CustomerFeedBack'
import axios from 'axios'
import UnipassAnnouncement from '../components/GoogleMap/UnipassAnnouncement'
import { REACT_APP_LOCATION_SERVICE_URL as API_URL } from '../constants/Env'

class CustomerFeedBack extends Component {
  state = {
    rating: 1,
    comment: '',
    isAnonymous: false,
    feedBackInfo: {
      routeNumber: '',
      carId: '',
      routeId: '',
      routeName: '',
      carNumber: '',
      userId: ''
    },
    isDialogOpen: false
  }

  handleCommentChange = (e) => {
    const { value } = e.target
    this.setState({
      comment: value
    })
  }

  handleStarRatingsChange = (rating) => {
    this.setState({
      rating
    })
  }

  handleCheckboxChange = (e) => {
    const checked = e.target.checked
    this.setState({
      isAnonymous: checked
    })
  }

  proceedSendFeedBack = async () => {
    const isValid = this.validateBeforeSending()
    console.log('this.state.feedBackInfo; ', this.state.feedBackInfo)
    if (isValid) {
      const feedBackInfo = this.state.feedBackInfo

      const response = await this.sendFeedBack({
        car_id: feedBackInfo.carId,
        car_number: feedBackInfo.carNumber,
        route_number: feedBackInfo.routeNumber,
        user_id_encoded: feedBackInfo.userId,
        rating: this.state.rating,
        comment: this.state.comment,
        is_anonymous: this.state.isAnonymous ? 1 : 0
      })

      this.handlePostSendFeedBack(response)
    }
  }

  handlePostSendFeedBack = (response) => {
    if (response.error !== -1) {
      this.handleSuccededCase()
    }
  }

  handleSuccededCase = () => {
    this.setState({
      isDialogOpen: true,
      comment: '',
      rating: 1,
      isAnonymous: false
    })

    setTimeout(() => {
      this.closeDialog()
    }, 2000)
  }

  closeDialog = () => {
    this.setState({
      isDialogOpen: false
    })
  }

  validateBeforeSending = () => {
    let isValid = true
    const feedBackInfo = this.state.feedBackInfo
    Object.keys(feedBackInfo).forEach((key) => {
      if (feedBackInfo[key] === '' || this.state.comment === '') {
        isValid = false
      }
    })

    return isValid
  }

  sendFeedBack = (data) => {
    return axios({
      method: 'POST',
      url: `${API_URL}/feedbacks/create`,
      headers: {
        'content-type': 'application/json'
      },
      data: {
        ...data
      }
    })
      .then((res) => {
        return res.data
      })
      .then((data) => {
        return data
      })
      .catch((err) => {
        console.error('failed to send feedback')
      })
  }

  requestInformation = async () => {
    const url_string = window.location.href
    const url = new URL(url_string)
    const carId = url.searchParams.get('carId')
    const routeId = url.searchParams.get('routeId')
    const userId = url.searchParams.get('userId')

    return axios({
      method: 'GET',
      url: `${API_URL}/feedbacks/get_info?route_id=${routeId}&car_id=${carId}`
    })
      .then((res) => {
        if (res) {
          return res.data
        }
      })
      .then((data) => {
        if (data) {
          return {
            carId,
            routeId,
            routeNumber: data.data.route_number,
            userId,
            routeName: `${data.data.route_from} - ${data.data.route_to}`,
            carNumber: data.data.car_number
          }
        }
      })
      .catch((err) => {
        console.error('failed to get info')
      })
  }

  setInformation = async () => {
    const info = await this.requestInformation()
    if (info) {
      this.setState({
        feedBackInfo: {
          ...this.state.feedBackInfo,
          ...info
        }
      })
    }
  }

  render() {
    const { rating, comment, isAnonymous } = this.state

    return (
      <>
        <CustomerFeedBackUI
          handleCheckboxChange={this.handleCheckboxChange}
          handleCommentChange={this.handleCommentChange}
          handleStarRatingsChange={this.handleStarRatingsChange}
          rating={rating}
          comment={comment}
          isAnnonymous={isAnonymous}
          sendFeedBack={this.proceedSendFeedBack}
          feedBackInfo={this.state.feedBackInfo}
        />
        <UnipassAnnouncement
          open={this.state.isDialogOpen}
          handleClose={this.closeDialog}
          buttonImage={'/img/ok_button.svg'}
          title={'Thông báo'}
          content={
            'Cảm ơn bạn đã đánh giá dịch vụ của UniPass. Nếu gặp trở ngại khi sử dụng dịch vụ, vui lòng nhắn tin trực tiếp trên Zalo/Fanpage để được hỗ trợ.'
          }
        />
      </>
    )
  }

  componentDidMount() {
    this.setInformation()
  }
}

export default CustomerFeedBack
