import axios from 'axios'
import { removeStorageLogout } from './helper'
import { REACT_APP_API_DOMAIN as API_DOMAIN, REACT_APP_API_URL as API_URL } from '../constants/Env'

let headers = {
  'content-type': 'application/json'
}

export const postApi = ({ endpoint, body = null }) => {
  const { cm } = body
  if (
    ![
      'login_zalopay',
      'check_phone',
      'gen_sms_code',
      'validate_sms_code',
      'update_pw',
      'create_customer_app'
    ].includes(cm)
  ) {
    headers = {
      ...headers,
      Authorization: localStorage.getItem('tk') || ''
    }
  }

  return axios({
    method: 'POST',
    url: `${API_URL}/${endpoint}`,
    data: body,
    headers,
    withCredentials: true
  })
    .then((res) => {
      if (res.data.err && res.data.err === -93) {
        postApi({
          endpoint: 'authentication/logout',
          body: {
            cm: 'logout'
          }
        })

        removeStorageLogout()
        window.location.href = `${window.location.protocol}//${window.location.hostname}`
      } else {
        return res.data
      }
    })
    .catch((err) => {
      // window.location.href = `${window.location.protocol}//${window.location.hostname}`;
      const cp = localStorage.getItem('cp')
      postApi({
        endpoint: 'authentication/logout',
        body: {
          cm: 'logout'
          // dt: {
          // 	phone: cp
          // }
        }
      })

      removeStorageLogout()
      window.location.href = `${window.location.protocol}//${window.location.hostname}`
      // this.props.logout();
      // this.onChangeRoute("/");
      console.log(err)
    })
}

export const sendGET = (url, options) => {
  const config = {
    headers: {
      'content-type': 'application/json',
      Authorization: localStorage.getItem('tk') || ''
    },
    withCredentials: true,
    ...options
  }

  const uri = `${API_URL}/${url}`

  return axios
    .get(uri, config)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      console.error('an error occured while sending GET request to ', uri)
    })
}

export const upload = ({ body = null }) => {
  const data = new FormData()
  data.append('file', body.file)
  data.append(
    'dt',
    JSON.stringify({
      sub_path: body.sub_path,
      merchant_code: 'smart-ticket'
    })
  )

  return axios({
    method: 'POST',
    url: `${API_DOMAIN}/vpos/upload/controller`,
    data,
    withCredentials: true
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

export const callApiWithProgressBar = async (options, reference) => {
  reference.setState({
    isInProgress: true
  })
  const response = await axios({
    method: options.method,
    url: options.url,
    headers: options.headers,
    withCredentials: true
  })
    .then((res) => {
      if (res.data) {
        return res.data
      }
    })
    .catch((error) => {
      console.error(error.message + ", can't get route info")
    })

  reference.setState({
    isInProgress: false
  })

  return response
}
