import React from 'react'
import {
  ReminderAction,
  ReminderBody,
  ReminderDescription,
  ReminderIcon,
  ReminderItemWrapper
} from './style'

function ReminderItem(props) {
  const { actionText, action, description, iconLink } = props

  return (
    <ReminderItemWrapper>
      <ReminderBody>
        <ReminderIcon>
          <img src={iconLink} />
        </ReminderIcon>
        <div>
          <ReminderDescription>{description}</ReminderDescription>
          <ReminderAction onClick={action}>{actionText}</ReminderAction>
        </div>
      </ReminderBody>
    </ReminderItemWrapper>
  )
}

export default ReminderItem
