import * as Types from '../constants/ActionType'

export const setTabId = (selectedTab) => {
  return {
    type: Types.SET_TAB_ID,
    selectedTab
  }
}

export const setQuantity = (quantity) => {
  return {
    type: Types.SET_QUANTITY,
    quantity
  }
}
export const checkDisableButton = () => {
  return {
    type: Types.CHECK_BUTTON_BUY_TICKET
  }
}
export const clearData = () => {
  return {
    type: Types.CLEAR_DATA
  }
}
