import * as Types from '../constants/ActionType'

export const setInstructionDialogVisibility = (isOpenInstructionDialog) => {
  return {
    type: Types.SET_INSTRUCTION_DIALOG,
    isOpenInstructionDialog
  }
}

export const setReminderDialogVisibility = (isOpenReminderDialog) => {
  return {
    type: Types.SET_REMINDER_DIALOG,
    isOpenReminderDialog
  }
}

export const setSupportDialogVisibility = (isOpenSupportDialog) => {
  return {
    type: Types.SET_SUPPORT_DIALOG,
    isOpenSupportDialog
  }
}

export const setPayableInfo = (payable) => {
  return {
    type: Types.SET_PAYABLE_INFO,
    payable
  }
}
export const setMonthlyTicketInfo = (monthlyTicket) => {
  return {
    type: Types.SET_MONTHLY_TICKET,
    monthlyTicket
  }
}
export const setBalance = (newBalance) => {
  return {
    type: Types.SET_BALANCE,
    newBalance
  }
}

export const checkShowPopup = () => {
  return {
    type: Types.HOME.CHECK_SHOW_POPUP
  }
}
export const checkShowPopupSuccess = (data) => {
  return {
    type: Types.HOME.CHECK_SHOW_POPUP_SUCCESS,
    data
  }
}
export const setStatusPopup = (status) => {
  return {
    type: Types.HOME.SET_STATUS_POPUP,
    status
  }
}
