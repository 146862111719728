import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@material-ui/core/Button'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import FormBuilder from '../../common/form/FormBuilder'
import NoticePage from '../../components/NoticePage'
import { validatePhone } from '../../constants/validation'
import { post } from '../../utils/apiCallerV2'
import { color } from '../../utils/color'
import { setTitle } from '../../utils/helper'
import Fields from './fields'
import RechargeTerms from './terms'

const styles = {
  formWrapper: {
    paddingTop: 20
  },
  button: {
    borderRadius: 30,
    fontSize: 16,
    fontWeight: 700,
    width: '90%',
    boxShadow: 'none',
    padding: 10
  }
}

const requiredMsg = 'Không được để trống'
const requireUserName = 'Vui lòng kiểm tra lại số điện thoại'
const schema = yup.object().shape({
  phone: yup.string().required(requiredMsg).matches(validatePhone.reg, validatePhone.message),
  customer_name: yup.string().required(requireUserName)
})
export default function RechargePage({ isMaintenance = false }) {
  setTitle('Nạp hộ vào tài khoản UniPass')
  const useInfo = useSelector((state) => state.auth.user)
  const history = useHistory()

  const form = useForm({ resolver: yupResolver(schema) })
  const inititalData = {}

  const [phone] = form.watch(['phone'])

  const onSubmit = (data) => {
    let isHelper = true
    if (useInfo && data.phone === useInfo.phone) isHelper = false
    history.push({ pathname: '/payment', state: { phone, isHelper } })
  }

  useEffect(() => {
    form.setValue('customer_name', '')
    if (validatePhone.reg.test(phone)) {
      const checkUserExist = async (phone) => {
        const res = await post(`v2/customer/verify`, { phone })
        if (res && res.error && res.error.code === 1) {
          form.clearErrors(['customer_name', 'phone'])
          form.setValue('customer_name', res.data.customer_name)
        } else if (res && res.error && res.error.code === 1701) {
          form.setError('phone', {
            type: 'custom',
            message: 'Tài khoản không tồn tại.'
          })
        } else {
          form.setError('phone', {
            type: 'custom',
            message: 'Đã có lỗi xảy ra.'
          })
        }
      }
      checkUserExist(phone)
    } else {
      form.clearErrors(['customer_name', 'phone'])
    }
  }, [phone])

  const maintenanceAction = () => {
    history.replace('/')
  }
  if (isMaintenance) {
    return (
      <NoticePage
        img={'/img/promotion/maintenance.svg'}
        title={'Hệ thống đang bảo trì'}
        content={'Vui lòng quay lại sau, cám ơn bạn!'}
        action={maintenanceAction}
      />
    )
  }

  return (
    <div className="container">
      <div style={styles.formWrapper}>
        <FormBuilder
          form={form}
          fields={[Fields.owner, Fields.note].filter(Boolean)}
          data={inititalData}
        />
        <RechargeTerms />
        <div
          className="bottom-fixed-button-wrapper"
          style={{ bottom: 30, backgroundColor: '#fff', zIndex: 1 }}
        >
          <Button
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
            fullWidth
            disabled={!!(!validatePhone.reg.test(phone) || form.formState.errors['phone'])}
            style={{
              ...styles.button,
              backgroundColor:
                validatePhone.reg.test(phone) && !form.formState.errors['phone']
                  ? color.blue[500]
                  : '#EEEEEE',
              color:
                validatePhone.reg.test(phone) && !form.formState.errors['phone']
                  ? 'white'
                  : color.blue[300]
            }}
          >
            Tiếp tục
          </Button>
        </div>
      </div>
    </div>
  )
}
