import React from 'react'

import { ZPButtonStyle } from './style'

const ZPButton = (props) => {
  const { onClick, ...restProps } = props

  return (
    <ZPButtonStyle onClick={(e) => onClick()} variant="contained" color="primary" {...restProps} />
  )
}

export default ZPButton
