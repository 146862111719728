import React, { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { paymentApi } from '../../api/payment'
import NoticePage from '../../components/NoticePage'
import DialogAlert from '../../components/ZPDialog'
import { LIMIT_PRICE_RECHARGE } from '../../constants/recharge'
import useFetch from '../../hooks/useFetch'
import LoadingModal from '../../new-components/LoadingModal'
import { currencyFormat, setTitle } from '../../utils/helper'
import PaymentMethod from './PaymentMethod'
import InputAmount from './inputAmount'

export default function PaymentPage({ isMaintenance = false }) {
  const history = useHistory()

  const balance = useSelector((state) => state.home.balance)

  const [value, setValue] = useState('')
  const [error, setError] = useState(null)
  const [isOpenPopup, setIsOpenPopup] = useState(false)

  const location = useLocation()
  const state = location.state

  const [handleRecharge, { loading }] = useFetch(paymentApi.recharge)

  useLayoutEffect(() => {
    setTitle(state?.isHelper ? 'Nạp hộ vào tài khoản UniPass' : 'Nạp tiền vào tài khoản UniPass')
  }, [state])

  const onChange = (value) => {
    setError(null)
    if (value === '') {
      setValue('')
    } else if (value > LIMIT_PRICE_RECHARGE.max) {
      setError(`Số tiền tối đa ${currencyFormat(LIMIT_PRICE_RECHARGE.max)}đ`)
    } else if (LIMIT_PRICE_RECHARGE.min > value) {
      setValue(`${currencyFormat(value)}`)
      setError(`Số tiền tối thiểu ${currencyFormat(LIMIT_PRICE_RECHARGE.min)}đ`)
    } else {
      setValue(`${currencyFormat(Number(value))}`)
    }
  }

  if (isMaintenance) {
    return (
      <NoticePage
        img={'/img/promotion/maintenance.svg'}
        title={'Hệ thống đang bảo trì'}
        content={'Vui lòng quay lại sau, cám ơn bạn!'}
        action={() => history.replace('/')}
      />
    )
  }

  const onPay = (v) => {
    if (!Number(value.split('.').join(''))) {
      setError('Vui lòng nhập số tiền cần nạp')
      return
    }
    if (!state?.phone) return setError('Đã có lỗi xảy ra')
    handleRecharge({
      data: {
        sourceOfFund: v,
        receiver: state.phone,
        amount: Number(value.split('.').join(''))
      },
      onSuccess: (dt) => {
        window.location.href = dt?.orderUrl
      },
      onError: (code, message) => {
        setIsOpenPopup(true)
      }
    })
  }

  return (
    <div className="p-16 bg-white min-h-screen">
      <LoadingModal loading={loading} />
      {!state?.isHelper && (
        <div className="flex justify-between">
          <span className="text-16-20">Số dư tài khoản Unipass:</span>
          <span className="text-16-20 ff-b">{`${currencyFormat(balance)}đ`}</span>
        </div>
      )}
      <InputAmount className={'mt-36'} onChange={onChange} value={value} error={error} />
      <PaymentMethod className={'mt-36'} onPay={onPay} />
      <DialogAlert
        open={isOpenPopup}
        description={
          <div className="flex-column-center gap-6">
            <span className="text-black450 text-15-20 ff-sb">
              Có lỗi xảy ra trong quá trình thanh toán!
            </span>
            <span className="text-14-20">
              Vui lòng thử lại sau hoặc liên hệ: Tổng đài hỗ trợ{' '}
              <a href="tel:1900633314" className="ff-b">
                {' '}
                1900 633 314{' '}
              </a>
            </span>
          </div>
        }
        rightButtonTitle="Đóng"
        onRightConfirm={() => setIsOpenPopup(false)}
      />
    </div>
  )
}
