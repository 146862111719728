import styled from 'styled-components'

export const Container = styled.div`
  opacity: ${(props) => (props.expire ? '0.5' : '1')};
`

export const MonthLyTicketLinkPageWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 80px;
  background: #ffffff;
  height: 100vh;
  overflow: auto;
`
export const ItemWrapper = styled.div`
  margin: 10px 20px 0px 20px;
`

export const Text = styled.div`
  && {
    font-style: normal;
    &.name {
      font-size: 20px;
      color: #31333f;
      font-weight: 600;
      text-align: left;
    }
    &.price {
      font-size: 16px;
      color: #31333f;
      font-weight: normal;
    }
    &.route {
      font-size: 14px;
      color: #99a2ad;
      font-weight: normal;
      text-align: left;
      overflow-wrap: anywhere;
    }
    &.number {
      font-size: 16px;
      color: #31333f;
      font-weight: normal;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.warning {
      font-size: 14px;
      font-weight: normal;
      color: #e64646;
      margin-top: 10px;
    }
    &.totalPrice {
      font-size: 20px;
      font-weight: 600;
      color: #e64646;
    }
    &.pricePerTicket {
      font-size: 16px;
      color: #31333f;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`
export const ContentText = styled.span`
  color: #31333f;
  font-size: 16px;
`
export const LinkImage = styled.img`
  width: auto;
  height: 100%;
`
