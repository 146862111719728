import * as Types from './../constants/ActionType'
import { postApi } from '../utils/apiCaller'
import CryptoJS from 'crypto-js'
import { REACT_APP_APP_CLIENT_KEY_SS0 } from '../constants/Env'

export const jumpToInstuctionStep = (step) => {
  return (dispatch) => {
    dispatch({
      type: Types.JUMP_TO_STEP,
      step
    })
  }
}
export const setIfTheClientShouldSeeInstuction = (shouldSeeInstruction) => {
  return (dispatch) => {
    dispatch({
      type: Types.SET_IF_THE_CLIENT_SHOULD_SEE_INSTRUCTION,
      shouldSeeInstruction
    })
  }
}

export const shouldTheClientSeeInstuction = (phoneNumber) => {
  const ssoKey = REACT_APP_APP_CLIENT_KEY_SS0
  const reqTime = Date.now()
  const clientSignature = CryptoJS.HmacSHA256(`${phoneNumber}|${reqTime}`, ssoKey).toString()
  return (dispatch) => {
    postApi({
      endpoint: 'api/customer/checkIFShouldShowDialog',
      body: {
        cm: 'check_show_dialog',
        dt: {
          p: phoneNumber,
          sso_sig: clientSignature,
          rq_t: reqTime
        }
      }
    }).then((res) => {
      if (res) {
        dispatch(setIfTheClientShouldSeeInstuction(res.err === 0))
      }
    })
  }
}
