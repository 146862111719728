import React from 'react'
import { AvatarStyle } from './style'
import { REACT_APP_ADMIN_DOMAIN } from '../../constants/Env'

const Avatar = (props) => {
  let { src } = props
  if (src.indexOf('/resource/smart-ticket/') === 0) {
    src = REACT_APP_ADMIN_DOMAIN + src
  }

  if (!src) {
    return (
      <AvatarStyle {...props}>
        <span className="icon-no_avatar" style={{ fontSize: 68 }}>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </span>
      </AvatarStyle>
    )
  }

  return <AvatarStyle {...props} src={src} />
}

export default Avatar
