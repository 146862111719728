import React from 'react'
// import asyncComponent from "./components/AsyncComponent";
import PaymentFriendPage from './pages/PaymentFriendPage'
import RegisterPage from './pages/RegisterPage'
import TheStudent from './pages/TheStudent'
import ConfirmInfo from './pages/ConfirmInfo'
import ConfirmInfoPriority from './pages/ConfirmInfoPriority'
import Student from './pages/Student'
import DisabledPeople from './pages/DisabledPeople'
import OldPeople from './pages/OldPeople'
import QrPage from './pages/QrPage'
import NotFoundPage from './pages/NotFoundPage'
// import ReceiveCardPage from './pages/ReceiveCardPage'
import TransactionHistoryPage from './pages/history/TransactionHistoryPage'
import MonthlyTicketOptionPage from './pages/MonthlyTicketOptionPage'
import ManagementMonthlyTicketPage from './pages/ManagementMonthlyTicketPage'
import MonthlyTickekCreatePage from './pages/MonthlyTickekCreatePage'
import MonthlyTickekOrderPage from './pages/MonthlyTickekOrderPage'
import MonthlyTicketPaymentDonePage from './pages/MonthlyTicketPaymentDonePage'
import ManagementCardPage from './pages/ManagementCardPage'
import TransactionDetailPage from './pages/history/TransactionDetail'
import ZPPolicyPage from './pages/ZPPolicyPage'
import HomePage from './pages/home/HomePage'
import CreateCardPage from './pages/CreateCardPage'
import UserLocationInfoPage from './pages/UserLocationInfoPage'
import InactiveAccountPage from './pages/InactiveAccountPage'
import PaymentOptionPage from './pages/binding'
import RechargeOptionPage from './pages/RechargeOptionPage'
import AutodebitDetailPage from './pages/AutodebitDetailPage'
import SupportedRoutes from './pages/route'
import CustomerFeedBack from './pages/CustomerFeedBack'
import OauthCallback from './pages/OauthCallback'
import RechargePage from './pages/recharge'
import PaymentPage from './pages/payment'
import GiftPage from './pages/promotion'
import GiftDetail from './pages/promotion/giftDetail'
import CampaignDetail from './pages/promotion/campaignDetail'
import GiftList from './pages/promotion/giftList'
import CampaignListPage from './pages/promotion/campaignList'
import InviteFollowing from './pages/invite-following'
import Refund from './pages/refund'
import OTPVerification from './pages/phone-otp-verification'
import ReceiveCardPage from './pages/receive-card'
import ConfirmDegree13Page from './pages/ConfirmDegree13'
import Degree13Page from './pages/Degree13'

const routes = [
  {
    path: '/',
    exact: true,
    main: () => <HomePage />
    // main: asyncComponent(() => import("./pages/HomePage").then(module => module.default)),
  },
  {
    path: '/otp_verification',
    exact: true,
    main: () => <OTPVerification />
    // main: asyncComponent(() => import("./pages/HomePage").then(module => module.default)),
  },
  {
    path: '/user/info',
    exact: true,
    main: () => <RegisterPage />
    // main: asyncComponent(() => import("./pages/UserInfoPage").then(module => module.default)),
  },
  {
    path: '/register/the-student',
    exact: true,
    main: () => <TheStudent />
    // main: asyncComponent(() => import("./pages/TheStudent").then(module => module.default)),
  },
  {
    path: '/register/confirm-info',
    exact: true,
    main: () => <ConfirmInfo />
    // main: asyncComponent(() => import("./pages/ConfirmInfo").then(module => module.default)),
  },
  {
    path: '/register/confirm-info-priority',
    exact: true,
    main: () => <ConfirmInfoPriority />
    // main: asyncComponent(() => import("./pages/ConfirmInfoPriority").then(module => module.default)),
  },
  {
    path: '/register/student',
    exact: true,
    main: () => <Student />
    // main: asyncComponent(() => import("./pages/Student").then(module => module.default)),
  },
  {
    path: '/register/disabled_people',
    exact: true,
    main: () => <DisabledPeople />
    // main: asyncComponent(() => import("./pages/DisabledPeople").then(module => module.default)),
  },

  {
    path: '/bus/feedback',
    exact: true,
    main: () => <CustomerFeedBack />
  },

  {
    path: '/register/old_people',
    exact: true,
    main: () => <OldPeople />
    // main: asyncComponent(() => import("./pages/OldPeople").then(module => module.default)),
  },
  {
    path: '/supported_routes',
    exact: true,
    main: () => <SupportedRoutes />
    // main: asyncComponent(() => import("./pages/UserLocationInfoPage").then(module => module.default)),
  },
  {
    path: '/user/location',
    exact: true,
    main: () => <UserLocationInfoPage />
    // main: asyncComponent(() => import("./pages/UserLocationInfoPage").then(module => module.default)),
  },
  {
    path: '/qr',
    exact: true,
    main: () => <QrPage />
    // main: asyncComponent(() => import("./pages/QrPage").then(module => module.default)),
  },
  {
    path: '/payment/friend',
    exact: true,
    main: () => <PaymentFriendPage />
    // main: asyncComponent(() => import("./pages/PaymentFriendPage").then(module => module.default)),
  },
  {
    path: '/payment/option',
    exact: false,
    main: () => <PaymentOptionPage />
  },
  {
    path: '/recharge/option',
    exact: true,
    main: () => <RechargeOptionPage />
    // main: asyncComponent(() => import("./pages/PaymentFriendPage").then(module => module.default)),
  },
  {
    path: '/transaction/history',
    exact: true,
    main: () => <TransactionHistoryPage />
    // main: asyncComponent(() => import("./pages/TransactionHistoryPage").then(module => module.default)),
  },
  {
    path: '/transaction/detail',
    exact: true,
    main: () => <TransactionDetailPage />
    // main: asyncComponent(() => import("./pages/TransactionDetailPage").then(module => module.default)),
  },
  {
    path: '/autodebit/detail',
    exact: true,
    main: () => <AutodebitDetailPage />
  },
  {
    path: '/card/management',
    exact: true,
    main: () => <ManagementCardPage />
    // main: asyncComponent(() => import("./pages/ManagementCardPage").then(module => module.default)),
  },
  {
    path: '/card/receive',
    exact: true,
    main: () => <ReceiveCardPage />
  },
  {
    path: '/card/create',
    exact: true,
    main: () => <CreateCardPage />
    // main: asyncComponent(() => import("./pages/CreateCardPage").then(module => module.default)),
  },
  {
    path: '/inactive-account',
    exact: true,
    main: () => <InactiveAccountPage />
    // main: asyncComponent(() => import("./pages/InactiveAccountPage").then(module => module.default)),
  },
  {
    path: '/zppolicy',
    exact: true,
    main: () => <ZPPolicyPage />
    // main: asyncComponent(() => import("./pages/ZPPolicyPage").then(module => module.default)),
  },
  {
    path: '/monthly_ticket/option',
    exact: true,
    main: () => <MonthlyTicketOptionPage />
  },
  {
    path: '/monthly_ticket/payment-done',
    exact: true,
    main: () => <MonthlyTicketPaymentDonePage />
  },
  {
    path: '/monthly_ticket/management',
    exact: true,
    main: () => <ManagementMonthlyTicketPage />
  },
  {
    path: '/monthly_ticket/create',
    exact: true,
    main: () => <MonthlyTickekCreatePage />
  },
  // {
  //   path: '/callback',
  //   exact: true,
  //   main: () => <OauthCallback />
  // },
  {
    path: '/monthly_ticket/order',
    exact: true,
    main: () => <MonthlyTickekOrderPage />
  },
  {
    path: '/error-page',
    exact: true,
    main: () => <NotFoundPage />
  },
  //recharge
  {
    path: '/recharge',
    exact: true,
    main: () => <RechargePage />
  },
  {
    path: '/payment',
    exact: true,
    main: () => <PaymentPage />
  },
  {
    path: '/gift',
    exact: true,
    main: () => <GiftPage />
  },
  {
    path: '/gift-list',
    exact: true,
    main: () => <GiftList />
  },
  {
    path: '/campaign-list',
    exact: true,
    main: () => <CampaignListPage />
  },
  {
    path: '/gift-detail',
    exact: true,
    main: () => <GiftDetail />
  },
  {
    path: '/campaign-detail',
    exact: true,
    main: () => <CampaignDetail />
  },
  {
    path: '/following-invite',
    exact: true,
    main: () => <InviteFollowing />
  },
  {
    path: '/refund',
    exact: true,
    main: () => <Refund />
  },
  {
    path: '/confirm-degree13',
    exact: true,
    main: () => <ConfirmDegree13Page/>
  },
  {
    path: '/degree13',
    exact: true,
    main: () => <Degree13Page/>
  }
]

export default routes
