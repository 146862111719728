import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { AnnouncementDialogMessage, IAgreeButton } from './style'

const StyledDialogTitle = withStyles({
  root: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    padding: '31px 22px 0px 21px'
  }
})(DialogTitle)

const StyledDialog = withStyles({
  paper: {
    borderRadius: '30px'
  }
})(Dialog)

export default (props) => {
  const { handleClose, open, buttonImage, title, content } = props

  return (
    <StyledDialog onClose={handleClose} open={open}>
      <StyledDialogTitle disableTypography>{title}</StyledDialogTitle>
      <AnnouncementDialogMessage>{content}</AnnouncementDialogMessage>
      <IAgreeButton src={buttonImage} onClick={handleClose} />
    </StyledDialog>
  )
}
