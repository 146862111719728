import React, { useMemo } from 'react'

import { Row, RowContent, RowIcon } from './style'
import { formatMoney } from '../../../utils/helper'
import styled from 'styled-components'
import { TRANSACTION_TYPE } from '../../../constants/history'
const TRANSACTION_TYPE_DEFAULT = 3

const TransactionRow = (props) => {
  const {
    is_cash_out = false,
    onClick,
    type,
    description,
    created_date,
    status = 1,
    unit,
    final_amount,
    transaction_type
  } = props

  const imageType = useMemo(() => {
    if (!transaction_type || transaction_type === TRANSACTION_TYPE_DEFAULT) {
      return TRANSACTION_TYPE[TRANSACTION_TYPE_DEFAULT]
    } else {
      return TRANSACTION_TYPE[transaction_type]
    }
  }, [transaction_type])

  return (
    <div>
      <Row onClick={onClick} isCashOut={is_cash_out}>
        <RowIcon style={{ padding: '10px 0' }}>
          <img src={imageType.srcIcon} alt="" />
        </RowIcon>
        <RowContent>
          <div style={{ width: '60%' }}>
            <p style={{ margin: 0 }}>{description}</p>
            <span className="date">{created_date}</span>
          </div>

          <div style={{ width: '40%', textAlign: 'right' }}>
            <span className="amount medium">
              {type === 1 ? '+ ' : type === -1 ? '- ' : ''}
              {formatMoney(final_amount) + unit}
            </span>
            <Status status={status}>{status === 2 ? 'Thành công' : status === 1 ? 'Thất bại' : 'Đang xử lý'}</Status>
          </div>
        </RowContent>
      </Row>
    </div>
  )
}

const Status = styled.p`
  color: ${(props) => {
    return props.status === 2 ? '#19B258' : props.status === 1 ? '#EB2227' :  '#ebd61c'
  }};
  font-size: 13px;
`
export default TransactionRow
