import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import StarRating from '../shared/StarRating'
import TextField from '@material-ui/core/TextField'
import CircleCheckbox from '../shared/CircleCheckbox'
import { color } from '../../utils/color'

const RouteName = withStyles({
  item: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    marginTop: '26px'
  }
})(Grid)

const RouteDescription = withStyles({
  item: {
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center'
  }
})(Grid)

const BusPlateNumber = withStyles({
  item: {
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center'
  }
})(Grid)

const StarRatingsContainer = withStyles({
  item: {
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    marginTop: '28px'
  }
})(Grid)

const CommentTextField = withStyles({
  root: {
    border: '2px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    marginTop: '28px',
    '& textarea::placeholder': {
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '30px'
    },
    '& textarea': {
      fontSize: '14px',
      lineHeight: '16px',
      color: color.black[550]
    },
    '& fieldset': {
      borderColor: 'transparent!important'
    }
  }
})(TextField)

const CommitmentContainer = withStyles({
  container: {
    marginTop: '17px',
    textAlign: 'left'
  }
})(Grid)

const Commitment = withStyles({
  item: {
    fontSize: '14px',
    lineHeight: '16px'
  }
})(Grid)

const Note = withStyles({
  item: {
    marginTop: '16px',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center'
  }
})(Grid)

const SendFeecBackButtonContainer = withStyles({
  item: {
    marginTop: '30px',
    marginBottom: '60px'
  }
})(Grid)

export default (props) => {
  const {
    rating,
    comment,
    isAnnonymous,
    handleCheckboxChange,
    handleCommentChange,
    handleStarRatingsChange,
    sendFeedBack,
    feedBackInfo
  } = props

  return (
    <div
      style={{
        position: 'absolute',
        height: '100vh',
        width: '100%',
        padding: '0 24px',
        overflowY: 'scroll'
      }}
    >
      <Grid item xs={12} md={12} style={{ marginTop: '26px' }}>
        <img src="/img/6wheels_bus.svg" alt="a 6 wheels bus" />
      </Grid>
      <RouteName item xs={12} md={12}>
        Tuyến {feedBackInfo.routeNumber}
      </RouteName>
      <RouteDescription item xs={12} md={12}>
        {feedBackInfo.routeName}
      </RouteDescription>
      <BusPlateNumber item xs={12} md={12}>
        Biển số xe: {feedBackInfo.carNumber}
      </BusPlateNumber>
      <StarRatingsContainer item xs={12} md={12}>
        <StarRating rating={rating} onRate={handleStarRatingsChange} />
      </StarRatingsContainer>
      <CommentTextField
        fullWidth
        placeholder="Chia sẻ đánh giá của bạn tại đây"
        multiline
        rows={12}
        variant="outlined"
        value={comment}
        onChange={handleCommentChange}
      />
      <CommitmentContainer container>
        <Grid item xs={1} md={1} style={{ display: 'flex' }}>
          <CircleCheckbox checked={isAnnonymous} onChange={handleCheckboxChange} />
        </Grid>
        <Commitment item xs={11} md={11}>
          Tôi muốn đánh giá này ẩn danh, UniPass sẽ không chia sẻ thông tin của bạn.
        </Commitment>
      </CommitmentContainer>
      <Note item xs={12} md={12}>
        Lưu ý: Nếu sử dụng câu từ khiếm nhã, không phù hợp, đánh giá sẽ không được hiển thị.
      </Note>
      <SendFeecBackButtonContainer item xs={12} md={12}>
        <img
          src="/img/send_feedback_button.svg"
          alt="send feedback button"
          onClick={sendFeedBack}
        />
      </SendFeecBackButtonContainer>
    </div>
  )
}
