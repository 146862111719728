import { get, post, postWithZalo } from '../utils/apiCallerV2'
import axios from 'axios'
import { REACT_APP_ZALO_ID, REACT_APP_ZALO_SECRET_KEY } from '../constants/Env'
import querystring from 'querystring'

export const getZaloAccessCode = (authCode) => {
  return axios({
    method: 'post',
    url: `https://oauth.zaloapp.com/v4/access_token`,
    data: querystring.stringify({
      code: authCode,
      app_id: REACT_APP_ZALO_ID,
      grant_type: 'authorization_code'
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      secret_key: REACT_APP_ZALO_SECRET_KEY
    }
  })
}

export const loginWithZaloAccessToken = (accessToken) => {
  return post('v2/authentication/login-with-zalo', {
    access_token: accessToken
  })
}
export const loginWithZaloAccessTokenCus = (accessToken) => {
  return postWithZalo('v2/authentication/login-with-zalo', {
    access_token: accessToken
  })
}
export const pingServer = () => {
  return get('')
}

export const sendOTPRequest = (data) => {
  return post('v2/authentication/verify-phone', data)
}

export const verifyOTP = (data) => {
  return post('v2/authentication/verify-otp', data)
}

export const loginWithZalopay = (data) => {
  return post('v2/authentication/login-with-zalopay', {
    ...data
  })
}
