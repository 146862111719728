import React, { Component, Fragment } from 'react'
import FormControl from '@material-ui/core/FormControl'

import ZPTextField from '../components/ZPTextField'
import ZPSelect from '../components/ZPSelect'
import ZPButton from '../components/ZPButton'
import ClearButton from '../components/ClearButton'
import { cities } from '../utils/location/cities'

class UserLocationInfoPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updateData: {},
      listDistricts: []
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // if(Object.keys(prevState.updateData).length <= 0 && nextProps.user && nextProps.user.updateData && Object.keys(nextProps.user.updateData).length > 0) {
    // 	const { updateData } = nextProps;
    // 	return {
    // 		...prevState,
    // 		updateData: updateData,
    // 		listDistricts: districts[updateData.city]
    // 	}
    // }

    return {
      ...prevState
    }
  }

  render() {
    const { updateData, listDistricts } = this.state

    const { city, district, ward, address } = updateData

    const cityOptions =
      cities && cities.length > 0 ? (
        cities.map((city) => {
          const id = Number(Object.keys(city)[0])
          return (
            <option key={id} value={id}>
              {city[id]}
            </option>
          )
        })
      ) : (
        <option value="0">Không tìm thấy tỉnh/thành phố</option>
      )

    const districtOptions =
      listDistricts.length > 0 ? (
        listDistricts.map((d) => {
          const id = Number(Object.keys(d)[0])
          return (
            <option key={id} value={id}>
              {d[id]}
            </option>
          )
        })
      ) : (
        <option value="0">Không tìm thấy quận/huyện</option>
      )

    return (
      <Fragment>
        <FormControl fullWidth>
          <ZPSelect label="Tỉnh/Thành phố" name="city" onChange={this.onChangeCity} value={city}>
            {cityOptions}
          </ZPSelect>
        </FormControl>
        <FormControl fullWidth>
          <ZPSelect label="Quận/Huyện" name="district" onChange={this.onChange} value={district}>
            {districtOptions}
          </ZPSelect>
        </FormControl>
        <FormControl fullWidth>
          <ZPTextField
            label="Phường/Xã"
            name="ward"
            value={ward}
            maxLength={50}
            myRef={(ref) => (this.ward_input = ref)}
            onChange={this.onChange}
          />
          <ClearButton isShow={ward.length > 0} name="ward" onClick={this.onClear} />
        </FormControl>
        <FormControl fullWidth className="last-block">
          <ZPTextField
            // labelTop={true}
            label="Địa chỉ"
            // placeholder="Số nhà, tên đường, toà nhà, khu vực"
            name="address"
            value={address}
            isLast={true}
            maxLength={100}
            myRef={(ref) => (this.address_input = ref)}
            onChange={this.onChange}
          />
          <ClearButton isShow={address.length > 0} name="address" onClick={this.onClear} />
        </FormControl>
        <ZPButton
          // disabled={isDisabledDoneLocationButton}
          onClick={this.onDoneChangeLocation}
          fullWidth
          style={{ marginTop: 15 }}
        >
          Xác nhận
        </ZPButton>
      </Fragment>
    )
  }
}

export default UserLocationInfoPage
