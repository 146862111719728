import React, { useLayoutEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setTitle } from '../../utils/helper'
import { getListGift, getListCampaign } from '../../actions/promotion.action'
import Loading from '../../components/loading'
import Campaign from './components/compaign'
import Gift from './components/gift'
import { useHistory } from 'react-router-dom'
import { PROMOTION } from '../../constants/Config'
import NoticePage from '../../components/NoticePage'
import { color } from '../../utils/color'

const styles = {
  container: {
    backgroundColor: '#F8F8F8',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    overflowY: 'auto'
  }
}
const Title = ({ name, action, count }) => {
  return (
    <div className="d-flex justify-content-between mb-2">
      <span style={{ fontWeight: 'bold', fontSize: 16, color: color.black[500] }}>
        {`${name} ${count > PROMOTION.limitShowListCampaign ? `(${count})` : ''}`}
      </span>
      <span style={{ fontSize: 13, color: color.blue[500] }} onClick={action}>
        {count > PROMOTION.limitShowListCampaign ? 'Xem tất cả' : ''}
      </span>
    </div>
  )
}

export default function GiftPage() {
  setTitle('Ưu đãi dành cho bạn')
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading, listGift, listCampaign } = useSelector((state) => state.promotion)
  useLayoutEffect(() => {
    dispatch(getListGift())
    dispatch(getListCampaign())
  }, [])

  const listGiftNeedShow = useMemo(() => {
    if (listGift.length > PROMOTION.limitShowListGift) {
      return listGift.slice(0, PROMOTION.limitShowListGift)
    } else {
      return listGift
    }
  }, [listGift])

  const listCampaignNeedShow = useMemo(() => {
    if (listCampaign.length > PROMOTION.limitShowListCampaign) {
      return listCampaign.slice(0, PROMOTION.limitShowListCampaign)
    } else {
      return listCampaign
    }
  }, [listCampaign])

  const goGiftDetail = (item) => {
    history.push({ pathname: '/gift-detail', state: { gift: item } })
  }
  const goGiftListPage = () => {
    history.push({ pathname: '/gift-list', state: { listGift: listGift } })
  }

  const goCampaignDetailPage = (item) => {
    history.push({
      pathname: '/campaign-detail',
      state: { campaign: item }
    })
  }
  const goCampaignListPage = () => {
    history.push({
      pathname: '/campaign-list',
      state: { listCampaign: listCampaign }
    })
  }
  return (
    <div style={styles.container}>
      {!listCampaignNeedShow.length && !listGiftNeedShow.length ? (
        <NoticePage
          img={'/img/promotion/initial-page-gift.svg'}
          title={'Chưa có chương trình ưu đãi'}
          content={'Bạn sẽ nhận được khuyến mãi khi có chương trình'}
        />
      ) : (
        <>
          <Loading loading={loading} />
          <div className="m-3">
            {!!listGiftNeedShow.length && (
              <Title name="Quà tặng của bạn" action={goGiftListPage} count={listGift.length} />
            )}
            {listGiftNeedShow.map((item, key) => (
              <Gift item={item} key={key} onGoViewDetail={goGiftDetail} />
            ))}
            {!!listCampaignNeedShow.length && (
              <Title
                name="Ưu đãi dành cho bạn"
                action={goCampaignListPage}
                count={listCampaign.length}
              />
            )}
            {listCampaignNeedShow.map((item, key) => (
              <Campaign key={key} item={item} goDetail={goCampaignDetailPage} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}
