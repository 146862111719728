import styled from 'styled-components'

export const BorderWrapper = styled.div`
  display: flex;
`

export const BorderDotted = styled.span`
  border-bottom: 1px dashed #919191;
  width: 100%;
  margin: auto;
`

export const Circle = styled.span`
  height: 18px;
  width: 18px;
  border-radius: 50%;
`

export const rightStyle = {
  marginRight: '-10px',
  background: '#00ABF9'
}

export const leftStyle = {
  marginLeft: '-10px',
  background: '#006DFE'
}
