import styled from 'styled-components'

export const RechargeOptionPageWrapper = styled.div`
  background: #ffffff;

  > div {
    //padding-top: 10px !important;
  }
`

export const TabWrapper = styled.div`
  padding: 25px 10px 0 !important;
`

export const checkBoxStyle = {
  textAlign: 'left',
  color: '#000000'
}

export const selectedTab = {
  color: 'white',
  fontWeight: 'bold',
  background: '#006FFF',
  borderRadius: '5px 0px 0px 5px'
}

export const initialTab = {
  color: 'black',
  fontWeight: 'bold',
  borderRadius: '5px 0px 0px 5px'
  // border: "2px solid black"
}

export const rechargeTab = {
  // paddingLeft: "10px",
  // paddingRight: "10px"
}

export const TabContentWrapper = styled.div`
  > div {
    //padding: 10px 0px 0px;
    padding: 0;
  }
`
