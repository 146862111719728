import styled from 'styled-components'

export const Line = styled.div`
  && {
    background-image: url(/images/line-dashed${(props) =>
      props.size && props.size > 1 ? '-2x' : ''}.svg);
    background-size: cover;
    width: 100%;
    height: 2px;
    background-repeat-y: no-repeat;
  }
`
