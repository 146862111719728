import React, { Component } from 'react'
import { Divider, Grid } from '@material-ui/core'
import {
  Container,
  ItemWrapper,
  MonthLyTicketLinkPageWrapper,
  Text
} from './styles/monthly-ticket-create.style'
import InputSpinner from '../components/InputSpinner'
import ButtonMonthlyTicket from '../components/ButtonMonthlyTicket'
import { withRouter } from 'react-router-dom'
import { formatMoney, sumValueInArray } from '../utils/helper'
import { get } from '../utils/apiCallerV2'
import { connect } from 'react-redux'
import MonthlyTicketManagementContainer from '../containers/MonthlyTicketManagementContainer/MonthlyTicketManagementContainer'
import LostConnectionContainer from '../containers/LostConnectionContainer/LostConnectionContainer'
import { checkDisableButton, setQuantity } from '../actions/monthlyTicket.action'
import Loading from '../components/loading'
class MonthlyTickekCreatePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      warning: {},
      data: [],
      showLoading: true,
      isLostConnection: false
    }
  }

  componentDidMount() {
    this.onFetchListSku()
  }

  onFetchListSku = async () => {
    const res = await get('v2/monthly-ticket')
    if (!res || res.error.code !== 1) {
      this.setState({
        showLoading: false,
        isLostConnection: true
      })
      return
    }
    this.setState({
      data: res.data,
      showLoading: false
    })
  }
  onChangeQuatity = (quantity, skuId, warning) => {
    this.setState({
      warning: {
        ...this.state.warning,
        [skuId]: warning
      }
    })
    this.props.setQuantity({
      ...this.props.quantity,
      [skuId]: quantity
    })
    this.props.checkDisableButton()
  }
  onSubmit = async () => {
    const amountList = this.state.data.map((item) => {
      return item.price * (this.props.quantity[item.monthly_ticket_id] || 0)
    })
    const amount = await sumValueInArray(amountList)

    const dataFilter = this.state.data.filter(
      (item) =>
        this.props.quantity[item.monthly_ticket_id] !== 0 &&
        this.props.quantity[item.monthly_ticket_id]
    )

    this.props.history.push({
      pathname: '/monthly_ticket/order',
      state: {
        data: dataFilter,
        quantity: this.props.quantity,
        amount: amount
      }
    })
  }
  renderItem = (item, key) => {
    const { warning } = this.state
    const pricePerTicket = item.price / item.tickets_in_batch || 0
    const route = () => {
      return item.all ? 'Tất cả' : item.routeNumbers.join(', ')
    }

    //limit 180 ticket per typy sku 30 ticket per book
    let maxChoise = 6
    if (item.total_remaining_ticket < 6 * item.tickets_in_batch) {
      maxChoise = Math.floor(item.total_remaining_ticket / item.tickets_in_batch)
    }
    const { quantity } = this.props
    const numberChoise = quantity[item.monthly_ticket_id] || 0
    return (
      <Container key={key} expire={maxChoise === 0}>
        <ItemWrapper>
          <Grid item xs={12} md={12} style={{ width: 'auto' }} container direction={'row'}>
            <Grid item xs={6} md={6} style={{ width: 'auto' }}>
              <Text className="name">{item.monthly_ticket_name}</Text>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              style={{ width: 'auto', justifyContent: 'flex-end' }}
              container
            >
              <Text className="name">{`${formatMoney(item.price)}đ`}</Text>
            </Grid>
          </Grid>
        </ItemWrapper>
        <ItemWrapper>
          <Grid item xs={12} md={12} style={{ width: 'auto' }} container direction={'row'}>
            <Grid item xs={6} md={6} style={{ width: 'auto' }}>
              <Text className="route">{`Tuyến: ${route()}`}</Text>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              style={{ width: 'auto', justifyContent: 'flex-end' }}
              container
            >
              <Text className="number">{`${item.tickets_in_batch} vé - ${formatMoney(
                pricePerTicket
              )}đ/Lượt`}</Text>
            </Grid>
          </Grid>
        </ItemWrapper>
        <ItemWrapper>
          <Grid
            item
            xs={12}
            md={12}
            style={{ width: 'auto' }}
            container
            direction={'row'}
            justifyContent={'space-between'}
          >
            {maxChoise > 0 ? (
              <>
                <Text className="number">Số lượng</Text>
                <InputSpinner
                  minValue={0}
                  maxValue={maxChoise}
                  valueDefault={numberChoise}
                  skuId={item.monthly_ticket_id}
                  onChangeQuatity={this.onChangeQuatity}
                />
                {warning[item.monthly_ticket_id] ? (
                  <Text className="warning">Đã đạt tới số lượng mua tối đa của gói này</Text>
                ) : null}
              </>
            ) : (
              <>
                <Text style={{ textAlign: 'left' }} className="warning">
                  Tạm hết! Vui lòng chọn loại vé khác.
                </Text>
              </>
            )}
          </Grid>
        </ItemWrapper>
        <Divider style={{ marginTop: 20 }} />
      </Container>
    )
  }
  onReload = () => {
    this.onFetchListSku()
  }

  render() {
    const { data, showLoading, isLostConnection } = this.state
    const { isDisbleButton } = this.props

    const sortData = data.sort((a, b) => b.total_remaining_ticket - a.total_remaining_ticket)

    return (
      <MonthLyTicketLinkPageWrapper>
        <Loading loading={showLoading} />

        {isLostConnection ? (
          <LostConnectionContainer onReload={this.onReload} />
        ) : (
          <>
            {data.length > 0 ? (
              <>
                {sortData.map(this.renderItem)}
                <div
                  className="bottom-fixed-button-wrapper"
                  style={{
                    padding: '12px 0',
                    backgroundColor: '#fff',
                    zIndex: 1
                  }}
                >
                  <ButtonMonthlyTicket
                    disabled={isDisbleButton}
                    onClick={() => this.onSubmit()}
                    fullWidth
                    style={{ margin: 0 }}
                  >
                    Tiếp tục
                  </ButtonMonthlyTicket>
                </div>
              </>
            ) : (
              <MonthlyTicketManagementContainer isBuyTicket={true} />
            )}
          </>
        )}
      </MonthLyTicketLinkPageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quantity: state.monthlyTicketReducer.quantity,
    isDisbleButton: state.monthlyTicketReducer.isDisbleButton
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    setQuantity: (quantity) => {
      dispatch(setQuantity(quantity))
    },
    checkDisableButton: () => {
      dispatch(checkDisableButton())
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MonthlyTickekCreatePage))
