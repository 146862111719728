import * as Types from './../constants/ActionType'

export const setTabId = (tabId) => {
  return {
    type: Types.INVITE.SET_TAB_ID,
    tabId
  }
}
export const checkShowReferral = () => {
  return {
    type: Types.INVITE.CHECK_SHOW_REFERRAL
  }
}

export const checkShowReferralDone = (data) => {
  return {
    type: Types.INVITE.CHECK_SHOW_REFERRAL_DONE,
    data
  }
}
export const checkPhone = (phone) => {
  return {
    type: Types.INVITE.CHECK_PHONE,
    phone
  }
}
export const checkPhoneDone = (data) => {
  return {
    type: Types.INVITE.CHECK_PHONE_DONE,
    data
  }
}

export const getInviteList = () => {
  return {
    type: Types.INVITE.GET_INVITE_LIST
  }
}
export const getInviteListDone = (data) => {
  return {
    type: Types.INVITE.GET_INVITE_LIST_DONE,
    data
  }
}

export const inviteFriend = (data) => {
  return {
    type: Types.INVITE.INVITE_FRIEND,
    data
  }
}

export const inviteFriendSuccess = () => {
  return {
    type: Types.INVITE.INVITE_FRIEND_SUCCESS
  }
}
