import React, { useEffect, useState } from 'react'
import TransactionRow from './TransactionRow'
import { useHistory } from 'react-router-dom'
import * as api from '../../utils/apiCallerV2'
import Loading from '../../components/loading'
export default function TransactionHistoryPage() {
  const history = useHistory()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    document.title = 'Lịch sử giao dịch'
    api
      .post('v2/history/list', {
        offset: 0,
        limit: 1000
      })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
  }, [])

  const toDetailPage = (row) => {
    history.push({
      pathname: '/transaction/detail',
      state: {
        row
      }
    })
  }
  return (
    <>
      <Loading loading={loading} />
      <div style={{ height: '100vh', background: 'white' }}>
        {data.length === 0 ? (
          <>
            <img
              style={{ width: '50%', marginTop: 100 }}
              alt="unipass-empty-transaction"
              src="images/history_empty_2.jpg"
            />
            <p
              style={{
                textAlign: 'center',
                marginTop: 16,
                fontSize: 16,
                color: '#acb3ba'
              }}
            >
              Bạn chưa có giao dịch nào
            </p>
          </>
        ) : (
          data.map((month, monthIndex) => {
            return (
              <React.Fragment key={month.month}>
                <div key={month.month}>
                  <div style={{ padding: '10px 12px', background: '#F8F8F8' }}>
                    <p
                      style={{
                        textAlign: 'left',
                        fontSize: 13,
                        color: '#727f8c',
                        margin: 1
                      }}
                    >
                      Tháng {month.month}
                    </p>
                  </div>
                  {month.items.length === 0 ? (
                    <>
                      <img
                        style={{ width: '50%', marginTop: 100 }}
                        alt="unipass-empty-transaction"
                        src="images/history_empty_2.jpg"
                      />
                      <p
                        style={{
                          textAlign: 'center',
                          marginTop: 16,
                          fontSize: 16,
                          color: '#acb3ba'
                        }}
                      >
                        Bạn chưa có giao dịch nào
                      </p>
                    </>
                  ) : (
                    month.items.map((row, index) => {
                      return (
                        <TransactionRow onClick={(e) => toDetailPage(row)} key={index} {...row} />
                      )
                    })
                  )}
                </div>
              </React.Fragment>
            )
          })
        )}
      </div>
    </>
  )
}
