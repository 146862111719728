import React, { Component } from 'react'
import QRCode from 'qrcode.react'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import sha1 from 'crypto-js/sha1'

import {
  getCardName,
  setTitle,
  isRealCard,
  getValidCardDate,
  friendlyDate,
  formatPhone
} from '../utils/helper'
import LineDashed from '../components/LineDashed'
import Avatar from '../components/Avatar'
import {
  RemainTime,
  LinkReceiveCard,
  ReceiveCardWrapper,
  QRCodeWrapper,
  QRWrapper,
  QRHeader,
  TimingWrapper
} from './styles/qr-page'
import { CARD_TYPE_ID } from '../constants/Variables'
import CommonFeild from '../components/CommonFeild'

class QrPage extends Component {
  constructor(props) {
    super(props)
    setTitle('Vé điện tử')
    this.state = {
      time: Date.now(),
      // remainTime: defaultTimeout,
      isShowUpdated: false
    }
    // this.secondRemaining = defaultTimeout;
    this.intervalHandle = () => {}
    this.countdown()
  }

  tick = () => {
    this.setState({
      // remainTime: --this.secondRemaining
    })

    if (parseInt(61 - (Date.now() - this.state.time) / 1000) < 0) {
      this.onRefresh()
    }
  }

  onRefresh = () => {
    clearInterval(this.intervalHandle)
    this.setState({
      isShowUpdated: true,
      time: Date.now()
      // remainTime: defaultTimeout,
    })
    setTimeout(() => {
      this.setState({
        isShowUpdated: false
      })
      // this.secondRemaining = defaultTimeout;
      this.countdown()
    }, 1000)
  }

  countdown = () => {
    this.intervalHandle = setInterval(this.tick, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle)
  }

  onChangeRoute = (to) => {
    this.props.history.push(to)
  }
  render() {
    const { auth } = this.props
    if (!auth.user) {
      return <Redirect to="/" />
    }

    const { time, isShowUpdated } = this.state
    const { user } = auth
    const {
      card_no,
      qr_tk,
      card_type,
      account_name,
      avatar,
      customer_name,
      registration_confirm_status,
      registration_type,
      promo_object_type
    } = user
    const balance = this.props.balance
    const tk = `${card_no}|${time}?${qr_tk}`
    console.log('card_no', card_no)
    const qrData = JSON.stringify({
      u: card_no,
      t: time,
      tk: sha1(tk) + ''
    })
    const remainTime = parseInt(61 - (Date.now() - this.state.time) / 1000)
    return (
      <QRWrapper>
        {auth.user.registration_confirm_status === 0 && auth.user.customer_type !== 4 && (
          <CommonFeild onChangeRoute={() => this.onChangeRoute('/card/management')} />
        )}
        <div className="qr-code">
          <div style={{ padding: '0 10px 0 12px' }}>
            <Table>
              <TableBody>
                <TableRow>
                  {card_type !== CARD_TYPE_ID.PT && (
                    <QRHeader className="avatar">
                      <Avatar hasBorder={false} src={avatar} />
                    </QRHeader>
                  )}
                  <QRHeader className="name">
                    <p>{customer_name || formatPhone(account_name)}</p>
                    <span className="card-type">{getCardName(card_type)}</span>
                    <span className="validation-time">
                      Thời hạn: {friendlyDate(getValidCardDate(user))}
                    </span>
                  </QRHeader>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <LineDashed />
          {registration_confirm_status === 2 &&
            registration_type == 2 &&
            promo_object_type != 1 && (
              <div style={{ padding: '35px' }}>
                <p>Thông tin đăng ký không hợp lệ, vui lòng thực hiện lại. Xin cảm ơn</p>
              </div>
            )}
          {(registration_confirm_status !== 2 || registration_type != 2) &&
            balance == 0 &&
            promo_object_type != 1 && (
              <div style={{ padding: '35px' }}>
                <p>Hãy nạp tiền trước khi sử dụng tính năng này</p>
              </div>
            )}
          {(registration_confirm_status !== 2 || registration_type != 2) &&
            balance != 0 &&
            promo_object_type != 1 && (
              <div>
                <QRCodeWrapper>
                  <QRCode value={qrData} />
                </QRCodeWrapper>
                <TimingWrapper>
                  {isShowUpdated ? (
                    <span className="grey completed">
                      <span className="icon-checked icon-zp-20 grey"></span>Đã cập nhật
                    </span>
                  ) : (
                    <div className="running">
                      <span
                        onClick={this.onRefresh}
                        className="icon-refresh icon-zp-20 refresh"
                      ></span>
                      Tự động cập nhật sau{' '}
                      <RemainTime>
                        {remainTime.toString().length == 2 ? remainTime : `0${remainTime}`}
                      </RemainTime>{' '}
                      giây
                    </div>
                  )}
                </TimingWrapper>
              </div>
            )}
          {promo_object_type == 1 && (
            <div>
              <QRCodeWrapper>
                <QRCode value={qrData} />
              </QRCodeWrapper>
              <TimingWrapper>
                {isShowUpdated ? (
                  <span className="grey completed">
                    <span className="icon-checked icon-zp-20 grey"></span>Đã cập nhật
                  </span>
                ) : (
                  <div className="running">
                    <span
                      onClick={this.onRefresh}
                      className="icon-refresh icon-zp-20 refresh"
                    ></span>
                    Tự động cập nhật sau{' '}
                    <RemainTime>
                      {remainTime.toString().length == 2 ? remainTime : `0${remainTime}`}
                    </RemainTime>{' '}
                    giây
                  </div>
                )}
              </TimingWrapper>
            </div>
          )}
        </div>
        {!isRealCard(card_no) && (
          <ReceiveCardWrapper>
            <LinkReceiveCard to="/card/receive">Nhận thẻ từ</LinkReceiveCard>
            <p className="description">Để thanh toán nhanh hơn</p>
          </ReceiveCardWrapper>
        )}
      </QRWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    balance: state.home.balance
  }
}

export default connect(mapStateToProps, null)(withRouter(QrPage))
