import styled from 'styled-components'

export const SpinnerWrapper = styled.div``
export const Button = styled.button`
  width: 50px;
  height: 40px;
  border: 1px solid ${(props) => props.color};
  background: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Value = styled.div`
  width: 50px;
  height: 40px;
  font-weight: bold;
  border: 1px solid #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
`
