import styled from 'styled-components'

import { LINE, COLOR_BLUE, COLOR_RED } from '../../constants/Styles'

export const TextFieldWrapper = styled.div`
  && {
    padding: 0 0 0 12px;
    position: relative;
    height: 60px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: ${(props) => (props.isLast ? '0' : '12px')};
      right: 0;
      border-bottom: ${(props) => (props.isFocus ? `0.8px solid ${COLOR_BLUE}` : LINE)};
      ${(props) => (props.isError ? `border-bottom-color: ${COLOR_RED}` : '')};
    }
    .label {
      color: ${(props) => (props.isFocus ? COLOR_BLUE : '#b6b8b8')};
      ${(props) => (props.isError ? `color: ${COLOR_RED}` : '')};
      position: absolute;
      font-size: ${(props) =>
        props.isFocus || props.isHaveValue || props.labelTop ? '13px' : '16px'};
      left: 12px;
      top: ${(props) => (props.isFocus || props.isHaveValue || props.labelTop ? '30%' : '50%')};
      transform: translateY(-50%);
      transition: all 0.2s;
      pointer-events: none;
    }
    input {
      margin-top: 12px;
      height: 36px;
      font-size: 16px;
      padding: 10px 0px 0px 0px;
      border: none;
      background-color: transparent;
      -moz-appearance: none;
      -webkit-appearance: none;
      ${(props) =>
        props.isTooLong
          ? `
					width: calc(100% - 12px);
					float: left;
					text-overflow: ellipsis;
    			overflow: hidden;
				`
          : null};
      &::-ms-expand {
        display: none;
      }
      pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};
      &:focus {
        outline: none;
      }
    }
  }
`
