import React from 'react'
import { setTitle } from '../../utils/helper'
import NoticePage from '../../components/NoticePage'
import { useHistory, useLocation } from 'react-router-dom'
import Gift from './components/gift'

const styles = {
  container: {
    backgroundColor: '#F8F8F8',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    overflowY: 'auto'
  }
}

export default function GiftPage() {
  setTitle('Quà tặng của bạn')
  const history = useHistory()
  const location = useLocation()
  const listGift = location.state.listGift
  const onGoViewDetail = (item) => {
    history.push({ pathname: '/gift-detail', state: { gift: item } })
  }
  return (
    <div style={styles.container}>
      <div className="m-3">
        {listGift.length ? (
          <>
            {listGift.map((item, key) => (
              <Gift item={item} key={key} onGoViewDetail={onGoViewDetail} />
            ))}
          </>
        ) : (
          <NoticePage
            img={'/img/promotion/initial-page-gift.svg'}
            title={'Bạn chưa có quà tặng nào'}
            content={'Bạn sẽ nhận được khuyến mãi khi có chương trình'}
          />
        )}
      </div>
    </div>
  )
}
