import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkBinding, initializeBindingData } from '../../actions/autodebit.action'
import ZPBindingContainer from '../../containers/ZPBinding/ZPBindingContainer'
import { setTitle } from '../../utils/helper'
import { LinkTitle } from '../styles/payment-options.style'
import TermBinding from './term'

const styles = {
  button: {
    height: 50,
    display: 'flex',
    background: '#e8f2ff',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    padding: '0 30px',
    width: 300
  }
}
export default function PaymentOptionPage() {
  setTitle('Liên kết ví ZaloPay')
  const dispatch = useDispatch()
  const { bindingLoading, isBinding } = useSelector((state) => state.autodebit)

  useEffect(() => {
    const timerCheckBinding = setInterval(() => {
      dispatch(checkBinding())
    }, 1000)
    return () => {
      clearInterval(timerCheckBinding)
    }
  }, [])

  const linkZaloPay = () => {
    dispatch(initializeBindingData())
  }
  return (
    <div className="p-16 bg-white">
      {isBinding ? (
        <ZPBindingContainer />
      ) : (
        <div className="d-flex flex-column justify-content-between h-100">
          <TermBinding />
          <div className="d-flex justify-content-center mb-4">
            {bindingLoading ? (
              <div>
                <img src="/img/loading.svg" style={{ width: 30, height: 30 }} />
              </div>
            ) : (
              <div onClick={linkZaloPay} style={styles.button}>
                <LinkTitle>Liên kết ngay</LinkTitle>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
