export const LIMIT_PRICE_RECHARGE = { max: 500000, min: 10000 }

export const PAYMENT_METHOD = {
  zpWallet: {
    value: 'ZALOPAY_PG_ZALOPAY_WALLET',
    label: 'ZaloPay',
    iconSrc: '/img/logo/zalopay-logo.png'
  },
  vietQR: {
    value: 'ZALOPAY_PG_VIETQR',
    label: 'Ứng dụng ngân hàng - VietQR',
    iconSrc: '/img/logo/vietqr-logo.png'
  },
  internaltionalCard: {
    value: 'ZALOPAY_PG_DOMESTIC_CARD_ACCOUNT',
    label: 'Thẻ ATM nội địa - Napas',
    iconSrc: '/img/logo/napas-logo.png'
  },
  domesticCardAccount: {
    value: 'ZALOPAY_PG_INTERNATIONAL_CARD',
    label: 'Thẻ tín dụng/ghi nợ quốc tế',
    iconSrc: '/img/logo/credit-logo.png'
  },

  applePay: {
    value: 'ZALOPAY_PG_APPLE_PAY',
    label: 'Thanh toán bằng',
    otherMethod: true,
    iconSrc: '/img/logo/applepay-white-text.png'
  }
}
