import * as Types from './../constants/ActionType'

const initialState = {
  isOpenInstructionDialog: false,
  isOpenReminderDialog: false,
  isOpenSupportDialog: false,
  payable: null,
  balance: null,
  monthlyTicket: null,
  popup: { data: [], isShow: false }
}

const home = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_INSTRUCTION_DIALOG:
      state.isOpenInstructionDialog = action.isOpenInstructionDialog
      return {
        ...state
      }
    case Types.SET_REMINDER_DIALOG:
      state.isOpenReminderDialog = action.isOpenReminderDialog
      return {
        ...state
      }
    case Types.SET_SUPPORT_DIALOG:
      state.isOpenSupportDialog = action.isOpenSupportDialog
      return {
        ...state
      }
    case Types.SET_PAYABLE_INFO:
      state.payable = action.payable
      return {
        ...state
      }
    case Types.SET_MONTHLY_TICKET:
      state.monthlyTicket = action.monthlyTicket
      return {
        ...state
      }

    case Types.SET_BALANCE:
      return {
        ...state,
        balance: action.newBalance
      }
    case Types.HOME.CHECK_SHOW_POPUP_SUCCESS:
      return {
        ...state,
        popup: action.data
      }
    case Types.HOME.SET_STATUS_POPUP:
      return {
        ...state,
        popup: { ...state.popup, isShow: action.status }
      }
    default:
      return state
  }
}

export default home
