import styled from 'styled-components'

export const InputCertificationStyle = styled.div`
  text-align: left;
  padding-left: 12px;
  .p-margin-0 {
    margin: 0px;
    padding-right: 10px;
  }
`
