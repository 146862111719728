import * as Types from './../constants/ActionType'

export const getListGift = () => {
  return {
    type: Types.PROMOTION.GET_LIST_GIFT
  }
}

export const getListGiftSuccess = (data) => {
  return {
    type: Types.PROMOTION.GET_LIST_GIFT_SUCCESS,
    data
  }
}
export const getListGiftFail = () => {
  return {
    type: Types.PROMOTION.GET_LIST_GIFT_FAIL
  }
}

export const getGiftDetail = (id) => {
  return {
    type: Types.PROMOTION.GET_GIFT_DETAIL,
    id
  }
}

export const getGiftDetailSuccess = (data) => {
  return {
    type: Types.PROMOTION.GET_GIFT_DETAIL_SUCCESS,
    data
  }
}
export const getGiftDetailFail = () => {
  return {
    type: Types.PROMOTION.GET_GIFT_DETAIL_FAIL
  }
}

export const getCampaignDetail = (id) => {
  return {
    type: Types.PROMOTION.GET_CAMPAIGN_DETAIL,
    id
  }
}

export const getCampaignDetailSuccess = (data) => {
  return {
    type: Types.PROMOTION.GET_CAMPAIGN_DETAIL_SUCCESS,
    data
  }
}
export const getCampaignDetailFail = () => {
  return {
    type: Types.PROMOTION.GET_CAMPAIGN_DETAIL_FAIL
  }
}

export const getListCampaign = () => {
  return {
    type: Types.PROMOTION.GET_LIST_CAMPAIGN
  }
}

export const getListCampaignSuccess = (data) => {
  return {
    type: Types.PROMOTION.GET_LIST_CAMPAIGN_SUCCESS,
    data
  }
}
export const getListCampaignFail = () => {
  return {
    type: Types.PROMOTION.GET_LIST_CAMPAIGN_FAIL
  }
}
