import { call, put, takeLatest } from '@redux-saga/core/effects'
import { refundProcessingDone } from '../actions/refund.action'
import * as actionTypes from '../constants/ActionType'
import { getStausRefund, requestRefundApi } from '../services/refund.services'

function* handleRefundProcessing() {
  try {
    const res = yield call(getStausRefund)
    if (res && res.error && res.error.code === 1) {
      yield put(refundProcessingDone(res.data.items))
    } else {
      yield put(refundProcessingDone([]))
    }
  } catch (e) {
    console.log('error: ', e)
  }
}

function* handleRequestRefund({ payload }) {
  const { data, setStep, setLoading, failed } = payload
  console.log('data', data)
  try {
    setLoading(true)
    const res = yield call(requestRefundApi, data)
    if (res && res.error && res.error.code === 1) {
    } else {
      failed()
    }
  } catch (e) {
    console.log('error: ', e)
    failed()
  } finally {
    setLoading(false)
    setStep('requested')
  }
}
export default function* refundSaga() {
  yield takeLatest(actionTypes.REFUND.PROCESSING, handleRefundProcessing)
  yield takeLatest(actionTypes.REFUND.REQUEST_REFUND, handleRequestRefund)
}
