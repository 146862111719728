import React from 'react'

import { Wrapper } from './style'
import { Table, TableRow, TableCell, TableBody } from '@material-ui/core'
import Avatar from '../Avatar'

import { getCardName, friendlyDate } from '../../utils/helper'
import { CARD_TYPE_ID } from '../../constants/Variables'

const UserCard = (props) => {
  const { customerName, cardType, avatar, cardStatus, cardValidationDate } = props
  const isNotStandard = cardType !== CARD_TYPE_ID.PT

  return (
    <Wrapper cardStatus={cardStatus}>
      <div id="circle-left" />
      <div id="circle-right" />
      <div id="pointer">{cardStatus ? 'Đang hoạt động' : 'Đã khoá'}</div>
      <Table>
        <TableBody>
          <TableRow>
            {isNotStandard && (
              <TableCell className="avatar">
                <Avatar hasBorder={true} src={avatar} />
              </TableCell>
            )}
            <TableCell className="info">
              <p className="name">{customerName}</p>
              <p className="ticket-type">{getCardName(cardType)}</p>
            </TableCell>
          </TableRow>
          <TableRow>
            {isNotStandard && <TableCell></TableCell>}
            <TableCell className="validate-to">
              <p className="title">Thời hạn</p>
              <p className="time">{friendlyDate(cardValidationDate)}</p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Wrapper>
  )
}

export default UserCard
