import { Button } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { color } from '../../utils/color'

const styles = {
  img: { width: 106 },

  button: {
    padding: 10,
    borderRadius: 30,
    fontSize: 16,
    fontWeight: 700,
    width: '95%',
    boxShadow: 'none',
    marginTop: 42,
    color: 'white',
    backgroundColor: color.blue[500]
  }
}
export default function TempCloseRefund() {
  const history = useHistory()
  const backHome = () => {
    history.replace('/')
  }

  return (
    <div className=" d-flex flex-column align-items-center pt-24">
      <img
        alt="unipass speaker temp close refund"
        src="/img/refund/speaker.svg"
        style={styles.img}
      />
      <span className="text-16-20 text-bold text-black320">Tạm đóng tính năng Hoàn tiền</span>
      <span className="mt-6 text-15-18 text-black250" style={{ width: '85%' }}>
        Kể từ <span className="text-bold "> 00:00 </span>
        ngày <span className="text-bold "> 01/03/2024 </span> tính năng Hoàn tiền từ tài khoản
        UniPass về ví ZaloPay sẽ tạm thời đóng lại. Rất mong Quý khách hàng thông cảm, xin cảm ơn!
        <span className="text-bold"></span>
      </span>
      <span className="mt-6 text-15-18 text-black250 mt-16" style={{ width: '85%' }}>
        Mọi thắc mắc vui lòng liên hệ
        <a href="tel:1900633314"> 1900 633 314 </a>
        để được hỗ trợ.
      </span>
      <Button variant="contained" fullWidth style={styles.button} onClick={backHome}>
        Quay lại trang chủ
      </Button>
    </div>
  )
}
