import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'

import { ZP_GRADIENT } from '../../constants/Styles'

export const ReceiveCardDialogStyle = styled(Dialog)`
  && {
    > div {
      background:transparent !important > div {
        width: calc(100% - 60px);
        background-color: #717773;
        margin: 0;
        box-shadow: none;
        > div {
          &.content {
            width: unset !important;
            padding: 30px 15px;
            text-align: center;
            p {
              color: #fff;
              margin: 0;
            }
            img {
              width: 50px;
              margin-bottom: 30px;
            }
          }
          &.action {
            margin: 0;
            > button {
              margin: 0;
              width: ${(props) => props.buttonwidth};
              padding: 15px 16px;
              font-size: 15px;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              font-family: 'SFDisplay' !important;
              &.left {
                background-color: #f0f4f6;
                color: #727f8c;
                border-bottom-right-radius: 0;
              }
              &.right {
                background-image: linear-gradient(${ZP_GRADIENT});
                background-image: -moz-linear-gradient(${ZP_GRADIENT});
                background-image: -webkit-linear-gradient(${ZP_GRADIENT});
                background-image: -o-linear-gradient(${ZP_GRADIENT});
                color: #fff;
                border-bottom-left-radius: 0;
              }
            }
          }
        }
      }
    }
    .description {
      font-size: 15px;
    }
    span[class^='icon-'],
    span[class*=' icon-'] {
      margin-bottom: 20px;
      display: block;
    }
  }
`
