import React, { PureComponent } from 'react'
import omit from 'lodash/omit'

import { TextFieldWrapper } from './style'

class ZPTextField extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isFocus: false,
      value: this.props.value || ''
    }
  }

  componentDidMount() {
    const { autoFocus } = this.props

    if (autoFocus) {
      this.setState({
        isFocus: true
      })
    }
  }

  onChange = (e) => {
    const { value } = e.target
    this.setState({
      value
    })
    this.props.onChange(e)
  }

  onFocus = (name) => {
    this.setState({
      isFocus: true
    })
    if (typeof this.props.onMyFocus === 'function') {
      this.props.onMyFocus()
    }
  }

  onBlur = () => {
    this.setState({
      isFocus: false
    })
  }

  render() {
    const { isFocus } = this.state
    const {
      type = 'text',
      name,
      value,
      disabled,
      isLast,
      isTooLong = false,
      autoFocus = false,
      labelTop = false,
      onChange,
      error,
      myRef = null,
      ...restProps
    } = this.props
    let { label } = this.props

    let isError = false
    if (error && error.isError) {
      label = error.message
      isError = true
    }

    const formattedRestProps = omit(restProps, 'onMyFocus')

    return (
      <TextFieldWrapper
        isFocus={isFocus}
        isError={isError}
        isHaveValue={value}
        isDisabled={disabled}
        isLast={isLast}
        labelTop={labelTop}
        isTooLong={isTooLong}
      >
        <label className="label">{label}</label>
        <input
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          type={type}
          name={name}
          value={value}
          onChange={this.onChange}
          autoFocus={autoFocus}
          ref={myRef}
          {...formattedRestProps}
        />
      </TextFieldWrapper>
    )
  }
}

export default ZPTextField
