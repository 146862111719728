import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppBar, Tab, Tabs, Typography, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { setTabId } from '../../actions/invite.action'
import Introduce from './introduce'
import Following from './following'
import './styles.css'
import { color } from '../../utils/color'
const styles = {
  container: {
    backgroundColor: '#F8F8F8',
    width: '100vw',
    height: '100vh'
  },
  selectedTab: {
    color: color.blue[500],
    backgroundColor: 'white',
    fontFamily: 'SFDisplay',
    fontSize: 16,
    borderBottom: '0.5px solid rgb(230, 230, 230)'
  },
  initialTab: {
    backgroundColor: 'white',
    fontFamily: 'SFDisplay',
    color: color.black[500],
    fontSize: 16,
    borderBottom: '0.5px solid rgb(230, 230, 230)'
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ background: 'white', padding: 0, height: '100vh' }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function InviteFriend() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const { selectedTab } = useSelector((state) => state.invite)

  const handleChangeTab = (event, tabId) => {
    dispatch(setTabId(tabId))
  }

  return (
    <div style={styles.container} className={'custome-tab'}>
      <AppBar color={'default'} position={'static'}>
        <Tabs
          value={selectedTab}
          TabIndicatorProps={{ style: { color: 'red' } }}
          variant="fullWidth"
          indicatorColor={'primary'}
          onChange={handleChangeTab}
        >
          <Tab
            style={selectedTab === 0 ? styles.selectedTab : styles.initialTab}
            className="initial-tab"
            label="Giới thiệu"
            {...a11yProps(0)}
          />
          <Tab
            style={selectedTab === 1 ? styles.selectedTab : styles.initialTab}
            className="initial-tab"
            label="Theo dõi"
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel index={0} value={selectedTab}>
          <Introduce />
        </TabPanel>
        <TabPanel index={1} value={selectedTab}>
          <Following user={user} />
        </TabPanel>
      </AppBar>
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}
