import styled from 'styled-components'

export const ClearWrapper = styled.span`
  && {
    font-size: 18px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    padding: 8px;
    display: ${(props) => (props.isShow ? 'block' : 'none')};
  }
`
