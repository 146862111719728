import { TICKET_TYPE, CARD_TYPE } from '../constants/Variables'
import moment from 'moment'
import { postApi } from './apiCaller'
import React from 'react'
import CryptoJS from 'crypto-js'
import { get } from '../utils/apiCallerV2'
import {
  REACT_APP_ADMIN_DOMAIN,
  REACT_APP_APP_CLIENT_KEY_SS0,
  REACT_APP_DEBUG_ACCOUNTS
} from '../constants/Env'
import { refund } from '../constants/AppConstants'
const ERROR_ZALOID_NOT_FOUND = -99

export const b64EncodeUnicode = (str) => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(parseInt(p1, 16))
    })
  )
}

export const b64DecodeUnicode = (str) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), (c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}
export const sumValueInArray = (arr) => {
  return arr.reduce((partial_sum, a) => partial_sum + a, 0)
}
export const getTicketName = (ticketTypeId) => {
  return TICKET_TYPE.find((tt) => tt.id === ticketTypeId).name
}

export const getCardName = (cardTypeId) => {
  if (!cardTypeId && cardTypeId !== 0) return ''
  return CARD_TYPE.find((ct) => ct.id === cardTypeId).name
}

export const getCardNameByRegisterStatus = (card_type, registration_confirm_status) => {
  if (!card_type && card_type !== 0) return ''

  if (!registration_confirm_status && registration_confirm_status === 0) {
    return 'Vé phổ thông'
  }

  return CARD_TYPE.find((ct) => ct.id === card_type).name
}

// export const getValidCardDate = user => {
// 	const { card_type, graduation_year, birthday } = user;
// 	const fullYear = moment().format("YYYY");
// 	if(card_type === 2) { // sinh vien
// 		return "31/12/" + graduation_year;
// 	} else if(card_type === 7) { // hoc sinh
// 		return "31/12/" + (fullYear + (12 - Number(graduation_year)));
// 	} else if(card_type === 4) { // tre em
// 		const year = moment(birthday).format("YYYY");
// 		return "31/12/" + (Number(year) + 7);
// 	} else {
// 		return "31/12/" + (Number(fullYear) + 5);
// 	}
// }

export const getValidCardDate = ({ ...params }) => {
  const { ca_typ, gra_yr, brth, d_add, poor_certificate_expire_date } = params
  if (ca_typ === 2) {
    // sinh vien
    const lastDayOfMonth = moment(gra_yr).endOf('month').format('DD')
    return `${moment(gra_yr).format('YYYY-MM')}-${lastDayOfMonth}`
  }
  if ([4, 7].includes(ca_typ)) {
    // 4: tre em, 7: hoc sinh
    const birthdayYear = moment(brth).format('YYYY')
    const eightteenYear = Number(birthdayYear) + (ca_typ === 4 ? 7 : 18)
    const lastDayOfBirthdayMonth = moment(brth).endOf('month').format('MM-DD')
    return `${eightteenYear}-${lastDayOfBirthdayMonth}`
  }
  if (ca_typ === 6) {
    // nguoi ngheo
    return poor_certificate_expire_date
  }
  const fullYear = moment().format('YYYY')
  const nowDayMonth = moment(d_add).format('MM-DD')
  return Number(fullYear) + 5 + '-' + nowDayMonth
}

export const formatMoney = (n, c = 0, d = '.', t = '.') => {
  c = isNaN((c = Math.abs(c))) ? 0 : c
  d = d === undefined ? '.' : d
  t = t === undefined ? '.' : t
  const s = n < 0 ? '-' : '',
    i = String(parseFloat((n = Math.abs(Number(n) || 0).toFixed(c))))
  let j
  j = (j = i.length) > 3 ? j % 3 : 0
  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  )
}

export const hidePhoneNumber = (phone) => {
  const phoneLength = phone.length
  const lastNumber = `${phone.charAt(phoneLength - 2)}${phone.charAt(phoneLength - 1)}`
  return `********${lastNumber}`
}

export const setTitle = (title) => {
  document.title = title
}

export const friendlyDate = (datetime) => {
  if (!datetime) return datetime
  return moment(datetime).format('DD/MM/YYYY')
}

export const countDay = (startDate, isHour = false) => {
  const start = moment(startDate)
  const end = moment()
  if (isHour) {
    return start.diff(end, 'hours')
  }
  return start.diff(end, 'days')
}

export const friendlyTime = (datetime) => {
  if (!datetime) return datetime
  return moment(datetime).format('DD/MM/YYYY HH:mm')
}

export const formatDateVN = (datetime) => {
  if (!datetime) return datetime
  return moment(datetime).format('HH:mm DD/MM/YYYY')
}

export const friendlyPhone = (phone) => {
  if (!phone) return ''
  const arr = phone.split('')
  arr.splice(3, 0, ' ')
  return arr.join('')
}

export const getCookie = (name) => {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export const setCookie = (name, value, days = 7) => {
  const expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const convertPhone84 = (phone) => {
  const formattedPhone = phone.toString()
  if (formattedPhone.indexOf('84') === 0) {
    const tmpArr = formattedPhone.split('')
    tmpArr.splice(0, 2, '0')
    formattedPhone = tmpArr.join('')
  }
  return formattedPhone
}

export const isRealCard = (cardNo) => {
  return cardNo && cardNo.toString().indexOf('tmp_') !== 0
}

export const setBodyBg = (color = 'grey') => {
  document.body.classList.add(color)
}

export const removeBodyBg = (color = 'grey') => {
  document.body.classList.remove(color)
}

export const getMonthAndYear = (datetime) => {
  return moment(datetime).format('MM/YYYY')
}

export const removeSpace = (str) => {
  return str.replace(/\s+/g, '')
}

export const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipod|ipad/.test(userAgent)
}

export const formatPhone = (phone) => {
  if (phone.length < 4) return phone

  const arr = phone.split('')
  if (phone.length === 4 && arr[3] === ' ') {
    arr.splice(-1, 1)
    return arr.join('')
  }

  if (arr[3] === ' ') return phone

  arr.splice(3, 0, ' ')
  return arr.join('')
}

//todo remove
export const systemDate = (datetime) => {
  if (!datetime) return ''
  return moment(datetime).format('YYYY-MM-DD')
}
export const formatDateWithoutTime = (date) => {
  if (!date) return ''
  return moment(date).format('DD/MM/YYYY')
}
export const dd = (msg, phone = null) => {
  // return;
  const account = phone || localStorage.getItem('cp')
  const debugAccounts = REACT_APP_DEBUG_ACCOUNTS
  if (!debugAccounts) return
  const accounts = debugAccounts.split(',')
  if (!accounts || accounts.length === 0) return
  if (!accounts.includes(account)) return
  if (typeof msg === 'string') {
    alert(msg)
  } else {
    alert(JSON.stringify(msg))
  }
}

export const removeStorageLogout = () => {
  localStorage.removeItem('cp')
  localStorage.removeItem('tk')
  localStorage.removeItem('user')
}

export const existOrNull = (url) => {
  const avatarUrl = url ? (url.includes('https:') ? url : `${REACT_APP_ADMIN_DOMAIN}${url}`) : null
  return avatarUrl
}

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }

  return 'unknown'
}

export function getUserInfoUnipass(customerPhone) {
  if (this.props.common.notification) {
    this.setState({
      toast: {
        isOpen: true,
        message: this.props.common.notification,
        type: 1
      }
    })
    setTimeout(() => {
      this.props.setNotification(null)
      this.setState({
        toast: {
          isOpen: false,
          message: null
        }
      })
    }, 1500)
  }

  // const ZaloPay = window.ZaloPay;
  const phone = customerPhone
  const reqTime = Date.now()
  const clientSignature = CryptoJS.HmacSHA256(
    `${phone}|${reqTime}`,
    REACT_APP_APP_CLIENT_KEY_SS0
  ).toString()
  postApi({
    endpoint: 'api/customer/fetchBalance',
    body: {
      cm: 'get_balance',
      dt: {
        p: customerPhone,
        m_uid: '',
        m_access_token: '',
        sso_sig: clientSignature,
        rq_t: reqTime
      }
    }
  }).then((serverRes) => {
    let balance = null
    if (!serverRes.err) {
      balance = serverRes.dt.bl
    }
    this.props.setBalance(balance)
  })
}

function getPropertyValue(fieldName, res) {
  return res.data[fieldName]
}

export const formatAuthData = (res) => {
  const formattedData = {
    customer_id: getPropertyValue('customer_id', res),
    wallet_id: getPropertyValue('wallet_id', res),
    account_name: getPropertyValue('account_name', res),
    customer_name: getPropertyValue('customer_name', res),
    customer_type: getPropertyValue('customer_type', res),
    phone: getPropertyValue('phone', res),
    email: getPropertyValue('email', res),
    address: getPropertyValue('address', res),
    student_code: getPropertyValue('student_code', res),
    idpp: getPropertyValue('idpp', res),
    avatar: getPropertyValue('avatar', res),
    graduation_year: getPropertyValue('graduation_year', res),
    school_name: getPropertyValue('school_name', res),
    birth_certificate: getPropertyValue('birth_certificate', res),
    incident_certificate: getPropertyValue('incident_certificate', res),
    date_add: getPropertyValue('date_add', res),
    date_update: getPropertyValue('date_update', res),
    is_active: getPropertyValue('status', res),
    is_used: getPropertyValue('status', res),
    card_no: getPropertyValue('card_no', res),
    // qr_tk: getPropertyValue('qr_tk', res),
    card_status: getPropertyValue('card_status', res),
    student_card_image: getPropertyValue('student_card_image', res),
    idpp_front_image: getPropertyValue('idpp_front_image', res),
    idpp_after_image: getPropertyValue('idpp_after_image', res),
    card_type: getPropertyValue('card_type', res),
    card_seri: getPropertyValue('card_seri', res),
    expire_date: getPropertyValue('expire_date', res),
    description: getPropertyValue('description', res),
    type: getPropertyValue('type', res),
    balance: getPropertyValue('balance', res),
    registration_type: getPropertyValue('registration_type', res),
    registration_confirm_status: getPropertyValue('registration_confirm_status', res),
    certification_file: getPropertyValue('certification_file', res),
    received_address: getPropertyValue('received_address', res),
    gender: res.data.gender.toString(),
    card_validation_date: getValidCardDate(res?.data),
    birthday: systemDate(res.data.birthday)
  }
  return formattedData
}

export function handlePostLogin(loginResponse, hasLoggedInByZaloOa) {
  if (loginResponse.err) {
    if (loginResponse.err === ERROR_ZALOID_NOT_FOUND) {
      const url = loginResponse.dt
      this.props.setZaloIdNotFound(true)
      this.props.history.push(url, {
        from: this.props.location.pathname
      })
    } else {
      this.setState({
        isShowingAlert: true,
        ZPResponse: (
          <>
            <h4>Đăng ký / Đăng nhập thất bại</h4>
            <p>{loginResponse.msg}</p>
          </>
        )
      })
    }
  } else {
    this.setState({
      ...this.state,
      isShowingSpashPage: false
    })
    this.props.setHasLoggedInByZaloOa(hasLoggedInByZaloOa)
    const data = {
      customerPhone: loginResponse.dt.cu.p,
      token: loginResponse.dt.tk,
      customerId: loginResponse.dt.cu.cu_id
    }
    const dataAuth = {
      account_name: loginResponse.dt.cu.a_n,
      registration_confirm_status: loginResponse.dt.cu.reg_con_stt,
      customer_type: loginResponse.dt.cu.cu_typ,
      avatar: loginResponse.dt.cu.avt,
      phone: loginResponse.dt.cu.p,
      card_no: loginResponse.dt.cu.ca_no,
      qr_tk: loginResponse.dt.cu.qr_tk,
      card_type: loginResponse.dt.cu.ca_typ,
      customer_name: loginResponse.dt.cu.cu_na,
      card_status: loginResponse.dt.cu.ca_stt,
      promo_object_type: loginResponse.dt.cu.pot,
      promo_remaining_number: loginResponse.dt.cu.promo_remaining_number,
      expire_date: loginResponse.dt.cu.exp_d,
      is_linked: loginResponse.dt.cu.is_linked,
      student_card_image: loginResponse.student_card_image
    }
    // console.log("data",data)
    this.props.setCustomerInfo(data)
    this.props.setAuth(dataAuth)
    const customerPhone = loginResponse.dt.cu.p
    this.getUserInfoUnipass(customerPhone)
    this.checkPayable()
    this.getCountMonthlyTicket(loginResponse.dt.cu.a_n)
    setTimeout(() => {
      this.onOpenDialog(customerPhone)
    }, 1000)
  }
}

export async function getCountMonthlyTicket(customerPhone) {
  const res = await get(`v2/monthly-ticket/count-by-user`)
  if (res.error.code !== 1 || !res.data) {
    return
  }
  const totalTicket = null
  if (res.data.totalTicket > 0) {
    totalTicket = res.data.totalTicket
  }
  this.props.setMonthlyTicketInfo(totalTicket)
}

export async function checkPayable() {
  const response = await get('v2/customer/check-efficient-fund')
  let totalTicket = null
  let balance = null
  if (response && response.error.code !== 1) {
    console.log('checkEfficientFund err: ', response.error)
    this.props.setMonthlyTicketInfo(totalTicket)
    this.props.setPayableInfo(false)
    this.props.setBalance(balance)
    return
  }
  const data = response ? response.data : null
  if (!data) {
    this.props.setMonthlyTicketInfo(totalTicket)
    this.props.setPayableInfo(false)
    this.props.setBalance(balance)
    return
  }
  if (data?.monthly_ticket && data?.monthly_ticket > 0) {
    totalTicket = data?.monthly_ticket
  }
  if (data?.balance) {
    balance = data.balance
  }
  this.props.setPayableInfo(data.payable)
  this.props.setMonthlyTicketInfo(totalTicket)
  this.props.setBalance(balance)
}
// export async function checkPayable() {
//   const response = await postApi({
//     endpoint: 'api/zalopay/checkEfficientFund',
//     body: {
//       cm: 'checkEfficientFund'
//     }
//   })
//   console.log('Check response: ', response)
//   if (response.err !== 0) {
//     console.log('checkEfficientFund err: ', response.err)
//     this.props.setPayableInfo(false)
//     return
//   }

//   const data = response.dt
//   if (!data) {
//     this.props.setPayableInfo(false)
//     return
//   }

//   this.props.setPayableInfo(data.payable)
// }

export function showLoginErrorDialog(content) {
  this.setState({
    isShowingAlert: true,
    // ZPResponse: content ? content : <>
    // 	<h4>Đăng ký / Đăng nhập thất bại</h4>
    // 	<p>Hệ thống xảy ra lỗi</p>
    // </>
    ZPResponse: (
      <>
        <h4>Đăng ký / Đăng nhập thất bại</h4>
        <p>{content}</p>
      </>
    )
  })
}

export async function loginByZaloOA(phoneNumber, ssoKey, zaloId) {
  const res = {
    data: {
      phonenumber: phoneNumber,
      displayname: '',
      gender: 1
    }
  }
  const phone = res.data.phonenumber
  const customer_name = res.data.displayname ? res.data.displayname : ''
  const gender = res.data.gender ? res.data.gender - 1 : 0
  const reqTime = Date.now()
  const clientSignature = CryptoJS.HmacSHA256(
    `${zaloId}|${phone}|${gender}|${customer_name}|${reqTime}`,
    ssoKey
  ).toString()
  const loginResponse = await postApi({
    endpoint: 'authentication/login/zaloOA',
    body: {
      cm: 'login_zalooa',
      dt: {
        p: phone,
        g: gender,
        cu_na: customer_name,
        sso_sig: clientSignature,
        rq_t: reqTime,
        z_id: zaloId
      }
    }
  })

  this.props.setLoginToken(phoneNumber)

  this.handlePostLogin(loginResponse, true)

  return loginResponse
}

export async function loginByZaloOAId(ssoKey, code) {
  const reqTime = Date.now()
  const clientSignature = CryptoJS.HmacSHA256(`${code}|${reqTime}`, ssoKey).toString()
  const loginResponse = await postApi({
    endpoint: 'authentication/login/zaloOAId',
    body: {
      cm: 'login_zalooa_id',
      dt: {
        sso_sig: clientSignature,
        rq_t: reqTime,
        ac: code
      }
    }
  })

  this.handlePostLogin(loginResponse, true)

  return loginResponse
}

export async function getUserInforInRegisterPage() {
  // const reqTime = Date.now()
  // const clientSignature = CryptoJS.HmacSHA256(
  //   `${phone}|${reqTime}`,
  //   ssoKey
  // ).toString()
  // const getUserInfoResponse = await postApi({
  //   endpoint: 'api/customer/getUserInfo',
  //   body: {
  //     cm: 'get_user_info',
  //     dt: {
  //       p: phone,
  //       sso_sig: clientSignature,
  //       rq_t: reqTime
  //     }
  //   }
  // })
  const getUserInfoResponse = await get('v2/customer/profile')

  if (getUserInfoResponse.error.code !== 1) {
    this.setState({
      dialog: {
        open: true,
        id: 1,
        leftButtonTitle: '',
        rightButtonTitle: 'Đã hiểu',
        description: 'Truy xuất thông tin tài khoản thất bại.'
      }
    })
  } else {
    this.props.setAuth(formatAuthData(getUserInfoResponse))
    // this.props.setAuth(getUserInfoResponse.data)
  }
}

export async function loginByZaloPay(ZaloPay, zaloAppId, ssoKey) {
  ZaloPay.showLoading()
  ZaloPay.getUserInfo(zaloAppId, async (res) => {
    console.log(res, 'ZALO PAY RES')
    ZaloPay.hideLoading()
    if (res.err) {
      this.showLoginErrorDialog(res.err)
      return
    }
    // const res = {
    // 	data: {
    // 		phonenumber: "0974670679",
    // 		displayname: "man hn",
    // 		gender: 1
    // 	}
    // };
    const phone = res.data.phonenumber
    const customer_name = res.data.displayname ? res.data.displayname : ''
    const gender = res.data.gender ? res.data.gender - 1 : 0
    const reqTime = Date.now()
    const clientSignature = CryptoJS.HmacSHA256(
      `${phone}|${gender}|${customer_name}|${reqTime}`,
      ssoKey
    ).toString()
    const loginResponse = await postApi({
      endpoint: 'authentication/login/zalopay',
      body: {
        cm: 'login_zalopay',
        dt: {
          p: phone,
          g: gender,
          cu_na: customer_name,
          sso_sig: clientSignature,
          rq_t: reqTime
        }
      }
    })
    console.log(loginResponse)
    this.handlePostLogin(loginResponse, false)
  })
}

export function setInputFilter(textbox, inputFilter) {
  ;['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(
    function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value
          this.oldSelectionStart = this.selectionStart
          this.oldSelectionEnd = this.selectionEnd
        } else if (this.hasOwnProperty('oldValue')) {
          this.value = this.oldValue
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd)
        } else {
          this.value = ''
        }
      })
    }
  )
}

export const addPropertyTo = (field, fieldName, object) => {
  if (field) {
    object[fieldName] = field
  }
}

export const simplifyFieldsName = (customerData) => {
  const convertedObject = {}

  addPropertyTo(customerData.phone, 'phone', convertedObject)
  addPropertyTo(customerData.customer_id, 'customer_id', convertedObject)
  addPropertyTo(customerData.registration_type, 'registration_type', convertedObject)
  addPropertyTo(customerData.customer_name, 'customer_name', convertedObject)
  addPropertyTo(customerData.gender, 'gender', convertedObject)
  addPropertyTo(customerData.birthday, 'birthday', convertedObject)
  addPropertyTo(customerData.email, 'email', convertedObject)
  addPropertyTo(customerData.address, 'address', convertedObject)
  addPropertyTo(customerData.userType, 'customer_type', convertedObject)
  // addPropertyTo(customerData.userPriorityType, 'u_prtype', convertedObject)
  addPropertyTo(customerData.school_name, 'school_name', convertedObject)
  // addPropertyTo(customerData.student_code, 'stu_c', convertedObject)
  addPropertyTo(customerData.graduation_time, 'graduation_year', convertedObject)
  addPropertyTo(customerData.student_card_image, 'student_card_image', convertedObject)
  // addPropertyTo(customerData.card_file, 'ca_f', convertedObject)
  addPropertyTo(customerData.certification_file, 'cer_f', convertedObject)
  addPropertyTo(customerData.idpp, 'idpp', convertedObject)
  addPropertyTo(customerData.idpp_front_image, 'idpp_front_image', convertedObject)
  addPropertyTo(customerData.idpp_after_image, 'idpp_after_image', convertedObject)
  addPropertyTo(customerData.birth_certificate, 'birth_certificate', convertedObject)
  addPropertyTo(customerData.incident_certificate, 'incident_certificate', convertedObject)

  return convertedObject
}

export const checkNullOrUndefine = (value) => {
  if (!value || typeof value == 'undefined' || value == null) {
    value = ''
  }
  return value
}

export const hiddenKeyBoard = () => {
  if (isIOS()) {
    const listInput = document.getElementsByTagName('input')
    for (let i = 0; i < listInput.length; i++) {
      listInput[i].addEventListener('focus', function () {
        if (this.className !== 'is-input-birthday' && this.type !== 'checkbox') {
          document.getElementsByTagName('body')[0].style.position = 'fixed'
        }
      })
    }

    for (let i = 0; i < listInput.length; i++) {
      listInput[i].addEventListener('blur', function () {
        document.getElementsByTagName('body')[0].style.position = 'static'
      })
    }

    if (window.location.href.includes('/otp_verification')) {
      document.body.addEventListener('click', function (e) {
        // console.log("body click",e.target.className.includes("scroll-to-top"));
        if (!e.target.className.includes('scroll-to-top')) {
          document.body.scrollTop = 0 // For Safari
          document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and O
        }
      })
    }
  }
}

export const removeAccentInString = (aString) => {
  return aString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const miliToSecond = (mili) => {
  return mili / 1000
}

export const getKeyForAllStations = () => {
  return 'all_stations'
}

export const getKeyForRouteInfo = (routeId) => {
  return `route_${routeId}_info`
}

export const getKeyForAllRoutes = () => {
  return 'all_routes'
}

export const getKeyForStationsOfRoute = (routeId) => {
  return `route_${routeId}_stations`
}

export const getKeyForWaypointsOfRoute = (routeId) => {
  return `route_${routeId}_waypoints`
}

export function setWithExpiry(key, value, ttl) {
  const now = new Date()

  const item = {
    value: value,
    expiry: now.getTime() + ttl
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export function findNearestPointOnRoutePath(point, coordinates) {
  const pXy = point
  const aXys = coordinates
  let minDist
  let fTo
  let fFrom
  let x
  let y
  let i
  let dist

  if (aXys.length > 1) {
    for (let n = 1; n < aXys.length; n++) {
      if (aXys[n].lat !== aXys[n - 1].lat) {
        const a = (aXys[n].lng - aXys[n - 1].lng) / (aXys[n].lat - aXys[n - 1].lat)
        const b = aXys[n].lng - a * aXys[n].lat
        dist = Math.abs(a * pXy.lat + b - pXy.lng) / Math.sqrt(a * a + 1)
      } else dist = Math.abs(pXy.lat - aXys[n].lat)

      // length^2 of line segment
      const rl2 =
        Math.pow(aXys[n].lng - aXys[n - 1].lng, 2) + Math.pow(aXys[n].lat - aXys[n - 1].lat, 2)

      // distance^2 of pt to end line segment
      const ln2 = Math.pow(aXys[n].lng - pXy.lng, 2) + Math.pow(aXys[n].lat - pXy.lat, 2)

      // distance^2 of pt to begin line segment
      const lnm12 = Math.pow(aXys[n - 1].lng - pXy.lng, 2) + Math.pow(aXys[n - 1].lat - pXy.lat, 2)

      // minimum distance^2 of pt to infinite line
      const dist2 = Math.pow(dist, 2)

      // calculated length^2 of line segment
      const calcrl2 = ln2 - dist2 + lnm12 - dist2

      // redefine minimum distance to line segment (not infinite line) if necessary
      if (calcrl2 > rl2) dist = Math.sqrt(Math.min(ln2, lnm12))

      if (minDist == null || minDist > dist) {
        if (calcrl2 > rl2) {
          if (lnm12 < ln2) {
            fTo = 0 //nearer to previous point
            fFrom = 1
          } else {
            fFrom = 0 //nearer to current point
            fTo = 1
          }
        } else {
          // perpendicular from point intersects line segment
          fTo = Math.sqrt(lnm12 - dist2) / Math.sqrt(rl2)
          fFrom = Math.sqrt(ln2 - dist2) / Math.sqrt(rl2)
        }
        minDist = dist
        i = n
      }
    }

    const dx = aXys[i - 1].lat - aXys[i].lat
    const dy = aXys[i - 1].lng - aXys[i].lng

    x = aXys[i - 1].lat - dx * fTo
    y = aXys[i - 1].lng - dy * fTo
  }

  return { lat: x, lng: y, i: i, fTo: fTo, fFrom: fFrom }
}

export const logWithStyle = (content) => {
  const spacing = '10px'
  const styles = `padding: ${spacing}; background-color: white; color: green; font-style: italic; border: 1px solid black; font-size: 2em;`
  console.log(`%c${content}`, styles)
}

// ** format currency
export const currencyFormat = (val) => {
  return new Intl.NumberFormat('vi-VN', {}).format(val)
}

export const getCardTypeName = (cardType) => {
  let typeName
  if (cardType === 1) {
    typeName = 'phổ thông'
  } else if (cardType === 2) {
    typeName = 'sinh viên'
  } else if (cardType === 3) {
    typeName = 'cao tuổi'
  } else if (cardType === 4) {
    typeName = 'trẻ em'
  } else if (cardType === 5) {
    typeName = 'thương binh/khuyết tật'
  } else if (cardType === 6) {
    typeName = 'người nghèo'
  } else if (cardType === 7) {
    typeName = 'học sinh'
  } else if (cardType === 9) {
    typeName = 'vietBank'
  } else {
    typeName = 'học sinh'
  }
  return typeName
}

export const addLeadingZeros = (num, totalLength) => {
  return String(num).padStart(totalLength, '0')
}

export const refundDateFormat = (date) => {
  const currentDay = Number(moment(date).format('D'))
  const refundDate = moment(date).add(currentDay > refund.day ? 2 : 1, 'M')
  const refundMonth = Number(moment(refundDate).format('M'))
  return `01-05/${addLeadingZeros(refundMonth, 2)}/${moment(refundDate).format('YYYY')}`
}
