import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import imageCompression from 'browser-image-compression'
import isEqual from 'lodash/isEqual'
import MobileDetect from 'mobile-detect'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import DatePicker from 'react-mobile-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setAuth } from '../actions/auth.action'
import { setNotification } from '../actions/common.action'
import {
  jumpToInstuctionStep,
  setIfTheClientShouldSeeInstuction
} from '../actions/instructionStep.action'
import ClearButton from '../components/ClearButton'
import InputImage from '../components/InputImage'
import UserInfoHeader from '../components/UserInfoHeader'
import ZPButton from '../components/ZPButton'
import DialogAlert from '../components/ZPDialog'
import ZPDialogUpdate from '../components/ZPDialogUpdate'
import ZPTextField from '../components/ZPTextField'
import CheckboxCircle from '../components/shared/CheckboxCircle'
import { upload } from '../utils/apiCallerV2'
import {
  existOrNull,
  friendlyDate,
  getUserInforInRegisterPage,
  hiddenKeyBoard,
  removeBodyBg,
  setTitle
} from '../utils/helper'
import Validator from '../utils/validator'
import { FormGroupSelection } from './styles/material-ui'

const genders = [
  { value: 0, label: 'Nam' },
  { value: 1, label: 'Nữ' }
]
const userTypes = [
  { value: 0, label: 'Phổ thông' },
  { value: 1, label: 'Ưu tiên' }
]

// const userTypesNormal = [
// 	{ value: 0, label: "Phổ thông"},
// ]
const userPriorityTypes = [
  { value: 7, label: 'Học sinh' },
  { value: 2, label: 'Sinh viên' },
  {
    value: 3,
    label: 'Người cao tuổi (Bảo trì)',
    isDisabledPersonOrElderlyPerson: true
  },
  {
    value: 5,
    label: 'Người khuyết tật/Thương binh (Bảo trì)',
    isDisabledPersonOrElderlyPerson: true
  }
]

const errorStyle = {
  margin: '0px',
  textAlign: 'left',
  paddingLeft: '10px',
  color: 'red'
}

const checkBoxStyleUncheck = {
  border: '1px solid rgba(0, 0, 0, 0.38)',
  paddingRight: '10px',
  margin: '5px'
}
const checkBoxStyleChecked = {
  border: '1px solid #006dff',
  paddingRight: '10px',
  margin: '5px',
  backgroundColor: '#E4F5FC'
}
class RegisterPage extends Component {
  constructor(props) {
    super(props)

    // const notSupport = false;
    // const md = new MobileDetect(window.navigator.userAgent);
    // if(md.is("AndroidOS")) {
    // 		notSupport = true;
    // }
    setTitle('Thông tin tài khoản')
    removeBodyBg()

    this.state = {
      isDisabledContinuteButton: false,
      updateData: {},
      originalData: {},
      isOpenDatePicker: false,
      datePickerTime: new Date(),
      isUserTypeCheckPriority: false,
      errors: {},
      dialog: {
        open: false,
        id: 0,
        leftButtonTitle: '',
        rightButtonTitle: '',
        description: ''
      },
      registrationPhone: '',
      isDisableFeild: false,
      // notSupport
      informingMessage: (
        <p>
          Cập nhật đúng thông tin cá nhân <br /> để được hỗ trợ nhanh nhất
        </p>
      )
    }
    this.email_input = React.createRef()
    this.customer_name_input = React.createRef()
    this.account_name_input = React.createRef()
    this.password_input = React.createRef()
    this.confirmed_password_input = React.createRef()
    this.address_input = React.createRef()

    this.getUserInforInRegisterPage = getUserInforInRegisterPage.bind(this)
    // if (this.props.location.state.registrationPhone !== null) {
    // 	this.state.registrationPhone = this.props.location.state.registrationPhone;
    // }

    this.state.registrationPhone = '0933890921'
    const isCheckPropTypes = () => {
      if (this.state.updateData.userType === '1') {
        if (
          typeof this.state.updateData.userPriorityType !== 'undefined' &&
          this.state.updateData.userPriorityType !== '1'
        ) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
    const isOldMan = () => {
      const { birthday, userPriorityType } = this.state.updateData
      const currentYear = new Date().getFullYear()
      if (userPriorityType === '3') {
        const birthdayYear = parseInt(birthday.substring(0, 4), 10)
        if (currentYear - birthdayYear >= 70) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }

    const isDoubleSpace = (value) => {
      if (value.includes('  ')) {
        return true
      } else {
        return false
      }
    }

    const isFirstOrLastSpace = (value) => {
      if (value.charAt(0) === ' ' || value.charAt(value.length - 1) === ' ') {
        return true
      } else {
        return false
      }
    }

    const idCardFile = () => {
      const { card_file, userType } = this.state.updateData
      if (card_file || userType == 0) {
        return true
      } else {
        return false
      }
    }
    const idLoadCardFile = () => {
      const { card_file, card_file_show } = this.state.updateData
      if (card_file_show && !card_file) {
        return false
      } else {
        return true
      }
    }
    const rules = [
      //   {
      // 	field: 'password',
      // 	method: isValidPassword,
      // 	validWhen: true,
      // 	message: 'Mật khẩu phải bao gồm: chữ hoa, chữ thường, số, lớn hơn 8 ký tự',
      //   },
      //   {
      // 	field: 'confirmed_password',
      // 	method: isMatchedPassword,
      // 	validWhen: true,
      // 	message: 'Mật khẩu không trùng khớp',
      //   },
      {
        field: 'customer_name',
        method: isFirstOrLastSpace,
        args: ['customer_name'],
        validWhen: false,
        message: 'Dữ liệu không được bắt đầu hoặc kết thúc bằng khoảng trắng.'
      },
      {
        field: 'customer_name',
        method: isDoubleSpace,
        args: ['customer_name'],
        validWhen: false,
        message: 'Dữ liệu không được có khoảng trắng liền kề.'
      },
      {
        field: 'customer_name',
        method: 'isLength',
        args: [{ min: 5 }],
        validWhen: true,
        message: 'Vui lòng nhập ít nhất 5 ký tự.'
      },
      {
        field: 'gender',
        method: 'isEmpty',
        validWhen: false,
        message: 'Dữ liệu không được trống.'
      },
      {
        field: 'birthday',
        method: 'isEmpty',
        validWhen: false,
        message: 'Dữ liệu không được trống.'
      },
      {
        field: 'birthday',
        method: isOldMan,
        validWhen: true,
        message: 'Đối tượng người cao tuổi phải từ 70 tuổi trở lên.'
      },
      {
        field: 'account_name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Dữ liệu không được trống.'
      },
      {
        field: 'account_name',
        method: 'isLength',
        args: [{ min: 10, max: 10 }],
        validWhen: true,
        message: 'Vui lòng nhập đúng số điện thoại.'
      },
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Dữ liệu không được trống.'
      },
      {
        field: 'email',
        method: isFirstOrLastSpace,
        args: ['email'],
        validWhen: false,
        message: 'Dữ liệu không được bắt đầu hoặc kết thúc bằng khoảng trắng.'
      },
      {
        field: 'email',
        method: isDoubleSpace,
        args: ['email'],
        validWhen: false,
        message: 'Dữ liệu không được có khoảng trắng liền kề.'
      },
      {
        field: 'email',
        method: 'isEmail',
        args: [{ allow_utf8_local_part: false }],
        validWhen: true,
        message: 'Email bị sai định dạng.'
      },
      {
        field: 'card_file',
        method: idLoadCardFile,
        validWhen: true,
        message: 'Ảnh đang được tải lên vui lòng chờ.'
      },
      {
        field: 'card_file',
        method: idCardFile,
        validWhen: true,
        message: 'Dữ liệu không được trống.'
      },
      {
        field: 'userType',
        method: 'isEmpty',
        validWhen: false,
        message: 'Dữ liệu không được trống.'
      },
      {
        field: 'userPriorityType',
        method: isCheckPropTypes,
        validWhen: true,
        message: 'Vui lòng chọn đối tượng ưu tiên.'
      }
    ]
    this.validator = new Validator(rules)
  }

  componentDidMount() {
    if (this.props.shouldSeeInstruction) {
      const customerNameInput = document.getElementsByName('customer_name')
      if (customerNameInput.length > 0) {
        const floatedElement = customerNameInput[0].parentNode.parentNode
        floatedElement.style.zIndex = '1000'
        floatedElement.style.backgroundColor = 'white'
      }
    }
    hiddenKeyBoard()
  }

  onChange = (e) => {
    let { updateData } = this.state
    const { name, value } = e.target
    updateData = {
      ...updateData,
      [name]: value
    }

    this.setState(
      {
        updateData
      },
      () => {
        const isEqual = this.checkEqual()
        this.setState({
          isDisabledContinuteButton: isEqual
        })
      }
    )
    if (name === 'gender' && (value === '0' || value === '1')) {
      this.onHandleFocusSelection(name)
    }
    if (
      name === 'userPriorityType' &&
      (value === '2' || value === '3' || value === '5' || value === '7')
    ) {
      this.onHandleFocusSelection(name)
    }
  }

  onChangeUserType = (e) => {
    let { updateData } = this.state
    const { name, value } = e.target
    this.userTypeCheck(value)
    if (value === '0') {
      updateData = {
        ...updateData,
        userPriorityType: '1'
      }
    }

    updateData = {
      ...updateData,
      [name]: value
    }
    this.setState(
      {
        updateData
      },
      () => {
        const isEqual = this.checkEqual()
        this.setState({
          isDisabledContinuteButton: isEqual
        })
      }
    )
    if (value === '0' || value === '1') {
      this.onHandleFocusSelection(name)
    }
  }

  onClear = (name) => {
    let { updateData } = this.state
    updateData = {
      ...updateData,
      [name]: ''
    }
    this.setState(
      {
        updateData
      },
      () => {
        const isEqual = this.checkEqual()
        this.setState({
          isDisabledContinuteButton: isEqual
        })
      }
    )
    this[`${name}_input`].focus()
  }

  toggleVisibility = (name) => {
    const grandParentNode = this[`${name}_input`].parentNode.parentNode
    const icon = grandParentNode.querySelector('span')
    if (this[`${name}_input`].type === 'password') {
      this[`${name}_input`].type = 'text'
      icon.classList.remove('icon-hidden')
      icon.classList.add('icon-show')
    } else {
      this[`${name}_input`].type = 'password'
      icon.classList.remove('icon-show')
      icon.classList.add('icon-hidden')
    }
  }

  onOpenDatePicker = () => {
    this.setState({
      isOpenDatePicker: true
    })
  }

  onCancelDatePicker = () => {
    this.setState({
      isOpenDatePicker: false
    })
  }

  onSelectDatePicker = (time) => {
    const birthday = moment(time).format('YYYY-MM-DD')
    let { updateData } = this.state
    updateData = {
      ...updateData,
      birthday
    }

    this.setState(
      {
        updateData,
        datePickerTime: time,
        isOpenDatePicker: false
      },
      () => {
        const isEqual = this.checkEqual()
        this.setState({
          isDisabledContinuteButton: isEqual
        })
      }
    )
  }

  userTypeCheck = (value) => {
    let valueCheck = false
    if (value === '1') {
      valueCheck = true
    }
    this.setState({
      isUserTypeCheckPriority: valueCheck
    })
  }

  onHandleFocusSelection = (name) => {
    const { errors } = this.state
    this.setState({
      errors: {
        ...errors,
        [name]: ''
      }
    })
  }
  onHandleFocus = (e) => {
    const { errors } = this.state
    const { name } = e.target
    this.setState({
      errors: {
        ...errors,
        [name]: ''
      }
    })
  }

  test = (e) => {
    const class_tooltip = e.target.className
    const tooltip0 = class_tooltip.indexOf('tooltip0')
    const tooltip1 = class_tooltip.indexOf('tooltip1')
    if (tooltip0 !== -1) {
      this.onOpenDialogTolltip0()
    } else if (tooltip1 !== -1) {
      this.onOpenDialogTolltip1()
    }
  }

  onOpenDialogTolltip1 = () => {
    this.setState({
      dialog: {
        open: true,
        id: 1,
        leftButtonTitle: '',
        rightButtonTitle: 'Đã hiểu',
        description: 'Đối tượng ưu tiên.'
      }
    })
  }
  onOpenDialogTolltip0 = () => {
    this.setState({
      dialog: {
        open: true,
        id: 1,
        leftButtonTitle: '',
        rightButtonTitle: 'Đã hiểu',
        description: 'Đối tượng phổ thông.'
      }
    })
  }

  onOpenDialogErr = () => {
    this.setState({
      dialog: {
        open: true,
        id: 1,
        leftButtonTitle: '',
        rightButtonTitle: 'Đã hiểu',
        description: 'Thông tin chưa chính xác, vui lòng kiểm tra lại.'
      }
    })
  }

  onDialogRightConfirm = () => {
    this.setState({
      dialog: {
        open: false
      }
    })
  }

  classifyUserType = (customer_type) => {
    return customer_type == 4 ? 0 : 1
  }

  checkEqual = () => {
    if (this.state.originalData.userType === '0') {
      return isEqual(this.state.originalData, this.state.updateData)
    }
    return false
  }

  getOrientation = (file, callback) => {
    const reader = new FileReader()

    reader.onload = function (event) {
      const view = new DataView(event.target.result)

      if (view.getUint16(0, false) != 0xffd8) return callback(-2)

      const length = view.byteLength
      let offset = 2

      while (offset < length) {
        const marker = view.getUint16(offset, false)
        offset += 2

        if (marker == 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) {
            return callback(-1)
          }
          const little = view.getUint16((offset += 6), false) == 0x4949
          offset += view.getUint32(offset + 4, little)
          const tags = view.getUint16(offset, little)
          offset += 2

          for (let i = 0; i < tags; i++)
            if (view.getUint16(offset + i * 12, little) == 0x0112)
              return callback(view.getUint16(offset + i * 12 + 8, little))
        } else if ((marker & 0xff00) != 0xff00) break
        else offset += view.getUint16(offset, false)
      }
      return callback(-1)
    }

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
  }

  resetOrientation = (srcBase64, srcOrientation, callback) => {
    const img = new Image()

    img.onload = function () {
      const width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d')

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height
        canvas.height = width
      } else {
        canvas.width = width
        canvas.height = height
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0)
          break
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height)
          break
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height)
          break
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0)
          break
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0)
          break
        case 7:
          ctx.transform(0, -1, -1, 0, height, width)
          break
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width)
          break
        default:
          break
      }

      // draw image
      ctx.drawImage(img, 0, 0)

      // export base64
      callback(canvas.toDataURL())
    }

    img.src = srcBase64
  }

  base64toBlob = (base64Data, contentType) => {
    contentType = contentType || ''
    const sliceSize = 1024
    const byteCharacters = atob(base64Data)
    const bytesLength = byteCharacters.length
    const slicesCount = Math.ceil(bytesLength / sliceSize)
    const byteArrays = new Array(slicesCount)

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize
      const end = Math.min(begin + sliceSize, bytesLength)

      const bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }
    return new Blob(byteArrays, { type: contentType })
  }

  onChangeFileAvatar = async (file, name) => {
    let { updateData } = this.state
    if (file === null) {
      updateData = {
        ...updateData,
        [name]: '',
        [name + '_show']: '',
        avatar: ''
      }

      this.setState(
        {
          updateData
          // errors: {...this.error,[name]: ""},
        },
        () => {
          const isEqual = this.checkEqual()
          this.setState({
            isDisabledContinuteButton: isEqual
          })
        }
      )
      return
    }
    updateData = {
      ...updateData,
      [name + '_show']: file
    }

    this.setState({
      showLoading: true,
      updateData
    })
    this.getOrientation(file, async (data) => {
      const reader = new FileReader()
      const maxSizeMB = 0.1
      const maxWidthOrHeight = 500
      try {
        file = await imageCompression(file, maxSizeMB, maxWidthOrHeight)
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          const base64data = reader.result
          this.resetOrientation(base64data, data, async (result) => {
            fetch(result)
              .then((res) => res.blob())
              .then(async (file1) => {
                file1.name = file.name
                file1 = await imageCompression(file1, maxSizeMB, maxWidthOrHeight)
                updateData = {
                  ...updateData,
                  [name + '_show']: file
                }

                this.setState(
                  {
                    updateData
                  },
                  () => () => {
                    const isEqual = this.checkEqual()
                    this.setState({
                      isDisabledContinuteButton: isEqual
                    })
                  }
                )
                upload({
                  body: {
                    file: file1,
                    sub_path: name
                  }
                }).then((res) => {
                  this.setState({
                    showLoading: false
                  })
                  if (res.err) return
                  const img = res.data[0].image_thumbnail
                  updateData = {
                    ...updateData,
                    [name]: img
                  }

                  this.setState(
                    {
                      updateData,
                      errors: { ...this.error, [name]: '' }
                    },
                    () => {
                      const isEqual = this.checkEqual()
                      this.setState({
                        isDisabledContinuteButton: isEqual
                      })
                    }
                  )
                })
              })
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  componentWillMount = async () => {
    await this.getUserInforInRegisterPage()
  }
  componentWillReceiveProps(nextProps) {
    //Perform some operation
    const { auth } = this.props
    if (!auth.customerPhone) return
    //update disable when user is priority type (8/6/2022)
    if (auth.user.registration_confirm_status === 1 && auth.user.customer_type !== 4) {
      this.setState({
        isDisableFeild: true
      })
    }

    let card_type = auth.user.card_type
    if (auth.user.registration_confirm_status !== 1 && auth.user.card_type !== 1) {
      card_type = auth.user.user_card_type
      this.setState({
        isDisableFeild: true
      })
    }
    this.setState(
      {
        card_image: existOrNull(this.props.auth.user.avatar),
        updateData: {
          ...auth.user,
          card_file: auth.user.avatar,
          userType: `${this.classifyUserType(auth.user.customer_type)}`,
          userPriorityType: card_type,
          graduation_time: auth.user.graduation_year
        },
        originalData: {
          ...auth.user,
          card_file: auth.user.avatar,
          userType: `${this.classifyUserType(auth.user.customer_type)}`,
          userPriorityType: `${card_type}`,
          graduation_time: auth.user.graduation_year
        }
      },
      () => {
        const isEqual = this.checkEqual()
        this.setState({
          isDisabledContinuteButton: isEqual
        })
        this.onChangeUserType({
          target: {
            name: 'userType',
            value: this.state.updateData.userType
          }
        })
        this.onChange({
          target: {
            name: 'userPriorityType',
            value: `${this.state.updateData.userPriorityType}`
          }
        })
      }
    )
  }

  handleSubmit = (e) => {
    this.setState({
      errors: this.validator.validate(this.state.updateData)
    })
    if (Object.keys(this.validator.validate(this.state.updateData)).length === 0) {
      const { userPriorityType, userType } = this.state.updateData
      if (userType === '0') {
        this.props.history.push({
          pathname: '/register/confirm-info',
          state: { customerData: this.state.updateData }
        })
      } else if (userType === '1') {
        if (userPriorityType === '7') {
          this.props.history.push({
            pathname: '/register/the-student',
            state: { customerData: this.state.updateData }
          })
        }
        if (userPriorityType === '2') {
          this.props.history.push({
            pathname: '/register/student',
            state: { customerData: this.state.updateData }
          })
        }
        if (userPriorityType === '3') {
          this.props.history.push({
            pathname: '/register/old_people',
            state: { customerData: this.state.updateData }
          })
        }
        if (userPriorityType === '5') {
          this.props.history.push({
            pathname: '/register/disabled_people',
            state: { customerData: this.state.updateData }
          })
        }
      }
    } else {
      this.onOpenDialogErr()
    }
  }

  handleBackDropOnClick = () => {
    if (this.props.instructionStep === 2) {
      const informationForm = document.getElementById('information-form')
      informationForm.scrollTop = 800
      const customerNameInput = document.getElementsByName('customer_name')
      if (customerNameInput.length > 0) {
        const floatedElement = customerNameInput[0].parentNode.parentNode
        floatedElement.style.zIndex = '0'
        floatedElement.style.backgroundColor = ''
      }

      // go to the next step
      const userTypeElements = document.getElementsByName('user-type')
      userTypeElements.forEach((element) => {
        element.style.zIndex = '1000'
        element.style.backgroundColor = 'white'
      })

      this.props.jumpToInstuctionStep(3)
      this.setState({
        informingMessage: (
          <p>
            Chọn đúng loại đối tượng <br /> để tận hưởng ưu đãi tốt nhất
          </p>
        )
      })
    }

    if (this.props.instructionStep === 3) {
      const userTypeElements = document.getElementsByName('user-type')
      userTypeElements.forEach((element) => {
        element.style.zIndex = '0'
        element.style.backgroundColor = ''
      })
      localStorage.setItem('hasWalkedthroughInstuction', true)
      this.props.jumpToInstuctionStep(4)
      this.props.setIfTheClientShouldSeeInstuction(false)
    }
  }

  render() {
    const {
      updateData,
      isDisabledContinuteButton,
      isOpenDatePicker,
      datePickerTime,
      isUserTypeCheckPriority,
      errors,
      dialog,
      isDisableFeild
      // notSupport
    } = this.state

    const {
      customer_name,
      gender,
      birthday,
      email,
      address,
      userType,
      phone,
      userPriorityType,
      account_name,
      referral_phone
    } = updateData

    const { open, rightButtonTitle, description } = dialog

    const md = new MobileDetect(window.navigator.userAgent)
    let formattedBirthdayForPlatform
    if (birthday && typeof birthday !== 'undefined') {
      formattedBirthdayForPlatform = friendlyDate(birthday)
      // if(!md.is("AndroidOS")) {
      // 	const tmp = birthday.split(" ")[0];
      // 	const date = tmp.split("-");
      // 	formattedBirthdayForPlatform = date[2] + "/" + date[1] + "/" + date[0]
      // }
    }
    // giới tính
    const genderSelection = genders?.map((g) => {
      return (
        <FormControlLabel
          key={g.value}
          control={
            <Checkbox
              icon={<CheckboxCircle />}
              checkedIcon={<CheckboxCircle checked />}
              checked={gender == g.value}
              onChange={this.onChange}
              value={`${g.value}`}
              color="primary"
              name="gender"
              disabled={isDisableFeild}
            />
          }
          label={g.label}
        />
      )
    })

    // Đối tượng
    const userTypeSelection = userTypes.map((u) => {
      return (
        <div style={{ marginRight: '10px' }} key={u.value}>
          <FormControlLabel
            key={u.value}
            control={
              <Checkbox
                icon={<CheckboxCircle />}
                checkedIcon={<CheckboxCircle checked />}
                checked={userType == u.value}
                onChange={this.onChangeUserType}
                value={`${u.value}`}
                color="primary"
                name="userType"
                disabled={isDisableFeild}
              />
            }
            label={u.label}
            style={{ marginRight: '5px', fontSize: '0.8rem' }}
          />
          <span
            className={`icon-dialoge_warning icon-zp-15 tooltip${u.value}`}
            onClick={this.test}
          ></span>
        </div>
      )
    })

    // Loại Đối tượng
    const userPriorityTypeSelection = userPriorityTypes.map((up) => {
      return (
        <FormControlLabel
          key={up.value}
          control={
            <Checkbox
              icon={<CheckboxCircle style={{ width: '15px', height: '15px' }} />}
              checkedIcon={<CheckboxCircle checked small />}
              checked={userPriorityType == up.value}
              onChange={this.onChange}
              value={`${up.value}`}
              color="primary"
              name="userPriorityType"
              // disabled={!isUserTypeCheckPriority}
              style={{ padding: '10px 5px' }}
              disabled={
                isDisableFeild || !isUserTypeCheckPriority || up.isDisabledPersonOrElderlyPerson
              }
            />
          }
          label={up.label}
          style={userPriorityType == up.value ? checkBoxStyleChecked : checkBoxStyleUncheck}
        />
      )
    })
    // const {
    //   birthday
    // } = updateData;
    return (
      <Fragment>
        {/*{ this.props.shouldSeeInstruction && (this.props.instructionStep <= 3 && this.props.instructionStep >= 2) ? <div onClick={this.handleBackDropOnClick}>*/}
        {/*		<div style={{width: '100%', height: '100vh', position: 'fixed', zIndex: '1000', backgroundColor: 'black', opacity: '0.7'}}>*/}
        {/*		</div>*/}
        {/*		{ this.props.instructionStep === 3 ? <div style={{width: '100%', height: '100vh', position: 'fixed', zIndex: '1001', marginTop: 'calc(100vh - 435px)', color: 'white'}}>*/}
        {/*			{this.state.informingMessage}*/}
        {/*			<div><img alt="click_here_icon_upsidedown.png" style={{width: '50px'}} src="/images/click_here_icon_upsidedown.png"/></div>*/}
        {/*		</div> : null }*/}
        {/*	</div> : null }*/}
        <UserInfoHeader user={updateData} />
        <ZPDialogUpdate />
        <div
          style={{
            paddingBottom: '81px',
            overflow: 'scroll',
            paddingTop: '140px'
          }}
          id="information-form"
        >
          <DatePicker
            value={birthday ? new Date(birthday) : new Date()}
            isOpen={isOpenDatePicker}
            headerFormat="DD/MM/YYYY"
            min={new Date('01/01/1900')}
            max={new Date()}
            confirmText="Xác nhận"
            cancelText="Huỷ"
            showCaption={true}
            onSelect={this.onSelectDatePicker}
            onCancel={this.onCancelDatePicker}
            theme="ios"
            dateConfig={{
              date: {
                format: 'DD',
                caption: 'Ngày',
                step: 1
              },
              month: {
                format: 'MM',
                caption: 'Tháng',
                step: 1
              },
              year: {
                format: 'YYYY',
                caption: 'Năm',
                step: 1
              }
            }}
          />
          <DialogAlert
            open={open}
            rightButtonTitle={rightButtonTitle}
            description={description}
            onRightConfirm={this.onDialogRightConfirm}
          />
          <FormControl fullWidth>
            <p
              style={{
                fontSize: '13px',
                textAlign: 'left',
                paddingLeft: '12px'
              }}
            >
              Mục có dấu * là bắt buộc phải nhập đầy đủ thông tin
            </p>
          </FormControl>
          {/* <FormControl fullWidth>
            <ZPTextField
              label="SĐT giới thiệu"
              name="referral_phone"
              type="tel"
              value={referral_phone}
              maxLength={10}
              onChange={this.onChange}
              myRef={(ref) => (this.referral_phone = ref)}
              onFocus={this.onHandleFocus}
              disabled={true}
            />
            {errors.referral_phone && (
              <p className="error" style={errorStyle}>
                {this.state.errors['referral_phone']}
              </p>
            )}
          </FormControl> */}
          <FormControl fullWidth>
            <ZPTextField
              label="Họ và tên *"
              name="customer_name"
              value={customer_name}
              maxLength={45}
              onChange={this.onChange}
              myRef={(ref) => (this.customer_name_input = ref)}
              // onBlur={this.handleSubmitTest}
              onFocus={this.onHandleFocus}
              disabled={isDisableFeild}
            />
            {!isDisableFeild && (
              <ClearButton isShow={customer_name} name="customer_name" onClick={this.onClear} />
            )}
            {errors.customer_name && (
              <div className="validation" style={{ ...errorStyle, display: 'block' }}>
                {errors.customer_name}
              </div>
            )}
          </FormControl>
          <FormControl fullWidth>
            <FormGroupSelection row>
              <label className="name">Giới tính *</label>
              {genderSelection}
            </FormGroupSelection>
            <p className="error" style={errorStyle}>
              {this.state.errors['gender']}
            </p>
          </FormControl>
          <FormControl fullWidth>
            <ZPTextField
              label="Ngày sinh *"
              name="birthday"
              onClick={this.onOpenDatePicker}
              value={formattedBirthdayForPlatform}
              readOnly={true}
              onFocus={this.onHandleFocus}
              disabled={isDisableFeild}
              className="is-input-birthday"
            />
            {errors.birthday && (
              <p className="error" style={errorStyle}>
                {this.state.errors['birthday']}
              </p>
            )}
          </FormControl>
          <FormControl fullWidth>
            <ZPTextField
              label="Số điện thoại *"
              name="account_name"
              type="tel"
              value={account_name}
              maxLength={10}
              onChange={this.onChange}
              myRef={(ref) => (this.account_name_input = ref)}
              onFocus={this.onHandleFocus}
              disabled={true}
            />
            {/* <ClearButton 
							isShow={phone} 
							name="phone" 
							onClick={this.onClear} 
						/> */}
            {errors.account_name && (
              <p className="error" style={errorStyle}>
                {this.state.errors['account_name']}
              </p>
            )}
          </FormControl>
          <FormControl fullWidth>
            <ZPTextField
              label="Email *"
              name="email"
              value={email}
              maxLength={45}
              onChange={this.onChange}
              myRef={(ref) => (this.email_input = ref)}
              onFocus={this.onHandleFocus}
              disabled={isDisableFeild}
            />
            {!isDisableFeild && <ClearButton isShow={email} name="email" onClick={this.onClear} />}
            {errors.email && (
              <p className="error" style={errorStyle}>
                {this.state.errors['email']}
              </p>
            )}
          </FormControl>
          <FormControl fullWidth>
            <ZPTextField
              label="Địa chỉ"
              name="address"
              value={address}
              maxLength={100}
              myRef={(ref) => (this.address_input = ref)}
              onChange={this.onChange}
              disabled={isDisableFeild}
            />

            {!isDisableFeild && (
              <ClearButton isShow={address} name="address" onClick={this.onClear} />
            )}
            {errors.address && (
              <p className="error" style={errorStyle}>
                {this.state.errors['address']}
              </p>
            )}
          </FormControl>
          <InputImage
            title="Ảnh làm thẻ"
            description="Ảnh làm thẻ"
            id="card_id"
            preparedFile={this.state.card_image}
            onChooseImage={(file) => this.onChangeFileAvatar(file, 'card_file')}
            isDisableFeild={isDisableFeild}
          />
          {!updateData.card_file && (
            <p className="error" style={errorStyle}>
              {this.state.errors['card_file']}
            </p>
          )}
          <FormControl fullWidth name="user-type">
            <label
              className="name"
              style={{
                marginRight: '20px',
                textAlign: 'left',
                padding: '12px'
              }}
            >
              Đối tượng *{' '}
            </label>
            <FormGroupSelection>{userTypeSelection}</FormGroupSelection>
            <p className="error" style={errorStyle}>
              {this.state.errors['userType']}
            </p>
          </FormControl>
          <FormControl fullWidth name="user-type">
            <div style={{ paddingLeft: '12px', textAlign: 'left' }}>
              {userPriorityTypeSelection}
            </div>
            <p className="error" style={errorStyle}>
              {this.state.errors['userPriorityType']}
            </p>
          </FormControl>
        </div>
        <div
          className="bottom-fixed-button-wrapper"
          style={{ padding: '12px 0', backgroundColor: '#fff', zIndex: 1 }}
        >
          <ZPButton
            disabled={isDisabledContinuteButton || isDisableFeild}
            onClick={this.handleSubmit.bind(this)}
            fullWidth
            style={{ margin: 0 }}
          >
            Tiếp tục
          </ZPButton>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    common: state.common,
    instructionStep: state.instructionStep,
    shouldSeeInstruction: state.shouldTheClientSeeInstuction
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setAuth: (user) => {
      dispatch(setAuth(user))
    },
    setNotification: (notification) => {
      dispatch(setNotification(notification))
    },
    jumpToInstuctionStep: (step) => {
      dispatch(jumpToInstuctionStep(step))
    },
    setIfTheClientShouldSeeInstuction: (isVisible) => {
      dispatch(setIfTheClientShouldSeeInstuction(isVisible))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterPage))
