import { Drawer } from 'antd'
import './styles.scss'

export default function BasicDrawer({
  placement = 'bottom',
  className,
  children,
  title,
  height = 'auto',
  ...props
}) {
  return (
    <Drawer
      className={`swipeable-drawer-custom ${className}`}
      {...props}
      placement={placement}
      extra={title}
      height={height}
      push={190}
    >
      <div style={{ height: 'auto', overflow: 'scroll' }}>{children}</div>
    </Drawer>
  )
}
