import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import axios from 'axios'
import MobileDetect from 'mobile-detect'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import {
  loginWithZalo,
  loginWithZalopay,
  logout,
  setAuth,
  setCustomerInfo,
  setHasLoggedInByZaloOa,
  setLoginToken,
  setZaloIdNotFound
} from '../../actions/auth.action'
import { checkBinding } from '../../actions/autodebit.action'
import { setNotification } from '../../actions/common.action'
import { getCustomerInfo } from '../../actions/customer.action'
import { setBalance, setMonthlyTicketInfo, setPayableInfo } from '../../actions/home.action'
import {
  jumpToInstuctionStep,
  shouldTheClientSeeInstuction
} from '../../actions/instructionStep.action'
import { setOriginalData, setUpdateData } from '../../actions/user.action'
import { ZALOPAY_APP_ID } from '../../constants/Config'
import { REACT_APP_APP_CLIENT_KEY_SS0, REACT_APP_ON_ZALOPAY } from '../../constants/Env'
import { HOMEPAGE } from '../../constants/Page'
import {
  checkPayable,
  formatMoney,
  getMobileOperatingSystem,
  setBodyBg,
  setTitle
} from '../../utils/helper'
import { UserInfoHeader, UserInfoHeaderWrapper } from '../styles/material-ui'
import '../styles/userinfo.css'
import NewHomePageContainer from './NewHomePage'
import PopupBroadCast from './popupBroadCast'
import ConfirmDegree13Page from '../ConfirmDegree13'

class HomePage extends Component {
  constructor(props) {
    super(props)
    let notSupport = false
    const md = new MobileDetect(window.navigator.userAgent)
    if (md.is('AndroidOS')) {
      const version = md.version('Android')
      if (version && Number(version) < 5) {
        notSupport = true
      }
    }
    setTitle(HOMEPAGE)
    setBodyBg()
    this.state = {
      notSupport,
      toast: {
        isOpen: false,
        message: '',
        type: 1
      },
      isShowingSpashPage: false,
      isShowingAlert: false,
      ZPResponse: null,
      dialog: {
        open: false,
        id: 0,
        leftButtonTitle: '',
        rightButtonTitle: '',
        description: ''
      },
      zaloId: '',
      backFormOTP: false
    }

    // this.getUserInfoUnipass = getUserInfoUnipass.bind(this)
    // this.handlePostLogin = handlePostLogin.bind(this)
    // this.showLoginErrorDialog = showLoginErrorDialog.bind(this)
    // this.getCountMonthlyTicket = getCountMonthlyTicket.bind(this)
    // if (REACT_APP_ON_ZALOPAY) {
    //   this.loginByZaloPay = loginByZaloPay.bind(this)
    // } else {
    //   this.loginByZaloOA = loginByZaloOA.bind(this)
    //   this.loginByZaloOAId = loginByZaloOAId.bind(this)
    // }
    this.checkPayable = checkPayable.bind(this)
    // if (
    //   typeof this.props.location.state !== 'undefined' &&
    //   this.props.location.state.zaloId !== null &&
    //   typeof this.props.location.state.zaloId !== 'undefined'
    // ) {
    //   this.state.zaloId = this.props.location.state.zaloId
    // }
    document.getElementsByTagName('body')[0].style.position = 'static'
  }

  onDialogRightConfirm = () => {
    window.history.back()
    this.setState({
      isShowingAlert: false
    })
  }

  // componentWillMount() {
  //   setTimeout(() => {
  //     //call api to check if the client should see the instuction
  //     if (
  //       this.props.auth.customerPhone &&
  //       !localStorage.getItem('hasWalkedthroughInstuction')
  //     ) {
  //       this.props.shouldTheClientSeeInstuction(this.props.auth.user.phone)
  //     }
  //   }, 1000)
  // }

  async componentDidMount() {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const state = params.get('state')
    const code = params.get('code')
    if (state == 'isCallback') {
      this.props.loginWithZalo(code)
      return
    }
    this.props.getCustomerInfo()
    // this.props.checkBinding();
    this.checkPayable()
    window.addEventListener(
      'message',
      function (event) {
        const { cmd } = event.data
        if (cmd === 'zsdk_redirect') {
          this.onCloseDialog()
        }
      },
      false
    )
  }

  async handleZaloPay() {
    if (REACT_APP_ON_ZALOPAY) {
      const ZaloPay = window.ZaloPay
      ZaloPay.isZaloPay = true

      this.loginByZaloPay(ZaloPay, ZALOPAY_APP_ID, REACT_APP_APP_CLIENT_KEY_SS0)
    }
  }
  onOpenDialog = () => {
    if (!REACT_APP_ON_ZALOPAY) return

    if (typeof this.props.auth.user === 'undefined' || this.props.auth.user === null) {
      return
    }
    const customerPhone = this.props.auth.user.account_name
    window.onCloseDialog = () => {
      this.setState({
        dialog: {
          open: false,
          leftButtonTitle: '',
          rightButtonTitle: '',
          description: ''
        }
      })
    }

    const followURL = `https://openapi.zalo.me/v2.0/oa/getprofile?access_token=9DslVR0_jX8XtgnviZom4oBPfN-n9SXiA9JM2h0WeZHacSaXim-H3qle-oI8OvzCJlJcLRX0-cbUtvbEocJGMGR1h5x5PTXr2_YbMF8UltG_-Tv_yrF6L1xRfNo7MEGHHzBzUR9Ej7v9gQ0JdGFP8sgtp0QgBhGqVhdNBRuEenzRlhzod1tNUKczpHwoDRKHUfR0398YbJjqnj8peKIO1sEWnIon5hiKKONvFeCvaZ1cdTW9yJkZ4pcVudhPUAP2AF_7LDb_eZm1nEqGvNU9Vw7IB5-s8R0R&data={"user_id":"${customerPhone}"}`
    axios({
      method: 'GET',
      url: `${followURL}`
    }).then((res) => {
      if (res.data.error !== -213) {
        this.setState({
          dialog: {
            open: false,
            leftButtonTitle: '',
            rightButtonTitle: '',
            description: ''
          }
        })
      } else {
        this.setState({
          dialog: {
            open: true,
            leftButtonTitle: '',
            rightButtonTitle: 'Đóng',
            description: (
              <Fragment>
                <div style={{ textAlign: 'center' }}>
                  <img
                    alt="banner follow"
                    src="/images/Poster_UniPass.png"
                    style={{ width: '300px' }}
                  />
                  {getMobileOperatingSystem() === 'iOS' ? (
                    <a href="http://zalo.me/3820907403981754700">
                      <button onClick={() => window.onCloseDialog()}>Quan Tâm</button>
                    </a>
                  ) : (
                    <button onClick={() => this.callAPIToOpenZaloApp()}>Quan Tâm</button>
                  )}
                </div>
              </Fragment>
            )
          }
        })
      }
    })
  }

  onChangeRoute = (to) => {
    this.props.history.push(to)
  }

  render() {
    const { notSupport } = this.state
    if (notSupport) {
      return <p>Ứng dụng chỉ hỗ trợ cho thiết bị Android từ 5.0 trở lên</p>
    }

    const { auth, payable } = this.props
    if (auth.user && auth.user.status === 0) {
      return <Redirect to="/inactive-account" />
    }
    const { dialog } = this.state
    const { balance, countMonthlyTicket, showSplashing } = this.props

    const { open, rightButtonTitle, description } = dialog
    return (
      <Fragment>
        <PopupBroadCast data={this.props.popup.data} isShow={this.props.popup.isShow} />
        {/* {this.props.shouldSeeInstruction ? <div>
					<div style={{ width: '100%', height: '100vh', position: 'fixed', zIndex: '1000', backgroundColor: 'black', opacity: '0.7' }}>
					</div>
				</div> : null} */}
        <Fragment>
          {/* <DialogAlert
            open={this.state.isShowingAlert}
            description={this.state.ZPResponse}
            onRightConfirm={this.onDialogRightConfirm}
            rightButtonTitle={'Close'}
          /> */}
          {showSplashing && (
            <div className="diaglog-loading">
              <div className="container-loading">
                <div />
                <div />
                <div />
                {this.state.backFormOTP ? (
                  <p>Vui lòng tải lại trang để tiếp tục</p>
                ) : (
                  <p>Vui lòng chờ</p>
                )}
              </div>
            </div>
          )}
        </Fragment>
        {!showSplashing &&
          auth.user &&
          (auth.user.privacy_policy_accepted ? (
            <Fragment>
              {/* <FollowDialog
              open={open}
              rightButtonTitle={rightButtonTitle}
              description={description}
              onCloseClickOut={window.onCloseDialog}
            /> */}

              <div
                style={{
                  overflow: 'scroll',
                  height: '100vh',
                  background: 'var(--main-bg-color)'
                }}
              >
                <UserInfoHeaderWrapper cellSpacing="0" cellPadding="0">
                  <TableBody>
                    <UserInfoHeader>
                      {auth.user.promo_remaining_number > 0 && (
                        <TableCell style={{ width: 'calc(50vw - 12px)' }}>
                          <p style={{ fontSize: 14, textAlign: 'center' }}>Lượt vé tặng</p>
                          <p style={{ textAlign: 'center' }}>
                            {formatMoney(auth.user.promo_remaining_number)}
                          </p>
                        </TableCell>
                      )}
                      {auth.user.promo_remaining_number > 0 && (
                        <TableCell>
                          <div
                            style={{
                              borderLeft: '1px solid #f0f4f6',
                              height: '40px',
                              width: '1px'
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell style={{ width: 'calc(50vw - 12px)' }}>
                        <p style={{ fontSize: 14, textAlign: 'center' }}>
                          Số dư tài khoản Unipass (đ)
                        </p>
                        <p style={{ textAlign: 'center' }}>{formatMoney(balance)}</p>
                      </TableCell>

                      {countMonthlyTicket > 0 && (
                        <TableCell>
                          <div
                            style={{
                              borderLeft: '1px solid #f0f4f6',
                              height: '40px'
                            }}
                          />
                        </TableCell>
                      )}
                      {countMonthlyTicket > 0 && (
                        <TableCell>
                          <p style={{ fontSize: 14, textAlign: 'center' }}>Lượt vé tập</p>
                          <p style={{ textAlign: 'center' }}>{formatMoney(countMonthlyTicket)}</p>
                        </TableCell>
                      )}
                    </UserInfoHeader>
                  </TableBody>
                </UserInfoHeaderWrapper>

                <NewHomePageContainer
                  user={auth.user}
                  onChangeRoute={this.onChangeRoute}
                  // payable={payable}
                />
              </div>
            </Fragment>
          ) : (
            <ConfirmDegree13Page onChangeRoute={this.onChangeRoute} />
          ))}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    logMessages: state.auth.message || [],
    common: state.common,
    balance: state.home.balance,
    countMonthlyTicket: state.home.monthlyTicket,
    instructionStep: state.instructionStep,
    shouldSeeInstruction: state.shouldTheClientSeeInstuction,
    payable: state.home.payable,
    showSplashing: state.auth.showSplashing,
    popup: state.home.popup
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setAuth: (user) => {
      dispatch(setAuth(user))
    },
    logout: () => {
      dispatch(logout())
    },
    setUpdateData: (user) => {
      dispatch(setUpdateData(user))
    },
    setOriginalData: (user) => {
      dispatch(setOriginalData(user))
    },
    setNotification: (notification) => {
      dispatch(setNotification(notification))
    },
    setCustomerInfo: (data) => {
      dispatch(setCustomerInfo(data))
    },
    jumpToInstuctionStep: (step) => {
      dispatch(jumpToInstuctionStep(step))
    },
    shouldTheClientSeeInstuction: (phoneNumber) => {
      dispatch(shouldTheClientSeeInstuction(phoneNumber))
    },
    setHasLoggedInByZaloOa: (hasLoggedInByZaloOa) => {
      dispatch(setHasLoggedInByZaloOa(hasLoggedInByZaloOa))
    },
    setLoginToken: (loginToken) => {
      dispatch(setLoginToken(loginToken))
    },
    setZaloIdNotFound: (zaloIdNotFound) => {
      dispatch(setZaloIdNotFound(zaloIdNotFound))
    },
    setPayableInfo: (payable) => {
      dispatch(setPayableInfo(payable))
    },
    setMonthlyTicketInfo: (monthlyTicket) => {
      dispatch(setMonthlyTicketInfo(monthlyTicket))
    },
    setBalance: (newBalance) => {
      dispatch(setBalance(newBalance))
    },
    loginWithZalo: (authCode) => {
      dispatch(loginWithZalo(authCode))
    },
    loginWithZalopay: (data) => {
      dispatch(loginWithZalopay(data))
    },
    getCustomerInfo: () => {
      dispatch(getCustomerInfo())
    },
    checkBinding: () => {
      dispatch(checkBinding())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePage))
