import styled from 'styled-components'

import { LINE } from '../../../constants/Styles'

export const Row = styled.div`
  display: flex;
  && {
    background-color: #fff;
    padding: 12px;
    //padding-right: 90px;
    //position: relative;
    text-align: left;
    //border-bottom: ${(props) => (props.isLast ? LINE : 'none')};
    // border-top: ${(props) => (props.isFirst ? LINE : 'none')};
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 12px;
      border-bottom: ${(props) => (props.isLast ? 'none' : LINE)};
      ${(props) => (props.isMonthSecond ? 'border-bottom-width: 1px' : '')};
    }
    .name {
      font-size: 15px;
      margin: 0;
      margin-bottom: 3px;
      color: #4a4a4a;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .date {
      font-size: 13px;
      color: #727f8c;
    }
    .amount {
      //position: absolute;
      //top: 75%;
      transform: translateY(-50%);
      right: 12px;
      // color: ${(props) => (props.isCashOut ? '#24272b' : '#129d5a')} !important;
      font-size: 16px;
    }
    .status {
      font-size: 15px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 3px;
      margin-top: 5px;
      color: #fc572d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const RowIcon = styled.div`
  padding: 10px 0;
`

export const RowContent = styled.div`
  display: flex;
  padding: 0 10px;
  width: 100%;
`
