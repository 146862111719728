import styled from 'styled-components'

export const Wrapper = styled.div`
	&& {
		color: #fff;
		border-radius: 8px;
		background-image: linear-gradient(to right, #0f6ef2, #0298f2);
		padding: 50px 12px 15px 12px;
		position: relative;
		.avatar {
			width: 64px;
			padding: 0 !important;
			padding-right: 12px !important;
			> div {
				width: 64px;
				height: 64px;
				margin: auto;
			}
		}
		#circle-left {
			position: absolute;
			top: -50px;
			left: -37px;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			background-color: rgba(255,255,255,0.1);
		}
		#circle-right {
			position: absolute;
			bottom: -55px;
			right: -70px;
			width: 150px;
			height: 150px;
			border-radius: 50%;
			background-color: rgba(255,255,255,0.1);
		}
		#pointer {
      position: absolute;
			right: 0;
			top: 13px;
			font-size: 10px;
			text-align: right;
			padding: 5px 10px 5px 24px;
			text-transform: uppercase;
			color: #fff;
			background-color: ${(props) => (props.cardStatus ? '#129d5a' : '#d0021b')}
			&:after {
				content: "";
				position: absolute;
				left: -1px;
				bottom: -1px;
				width: 0;
				height: 0;
				border-left: 14px solid #018BF1;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
			}
    }
		.info {
			text-align: left;
			padding-left: 0 !important;
			padding-right: 0 !important;
			p {
				margin: 0;
				color: #fff;
			}
			.name {
				text-transform: uppercase;
				font-size: 16px;
			}
			.ticket-type {
				text-transform: uppercase;
				font-size: 12px;
				margin-top: 10px;
			}
		}
		.validate-to {
			color: #fff;
			text-align: left;
			font-size: 11px;
			padding-left: 0 !important;
			p {
				margin: 0;
			}
			.title {
				color: rgba(255, 255, 255, 0.8);
				margin-bottom: 5px;
			}
		}
	}
`
