import styled from 'styled-components'

import { LINE, COLOR_BLUE } from '../../constants/Styles'

export const SelectWrapper = styled.div`
	&& {
		padding: 0 0 0 12px;
		position: relative;
		height: 60px;
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: ${(props) => (props.isLast ? '0' : '12px')};
			right: 0;
			border-bottom: ${(props) => (props.isFocus ? `0.8px solid ${COLOR_BLUE}` : LINE)};
		}
		.label {
			color: ${(props) => (props.isFocus ? COLOR_BLUE : '#b6b8b8')};
			position: absolute;
			font-size: 13px;
			left: 12px;
			top: 30%
			transform: translateY(-50%);
			transition: all .2s;
			pointer-events: none;
		}
		select {
			margin-top: 12px;
			width: 100%;
			height: 47px;
			font-size: 16px;
			padding: 15px 0px 0px 0px;
			border: none;
			background-color: transparent;
			pointer-events: ${(props) => (props.isDisabled ? 'none' : 'unset')};
			-moz-appearance: none;
			-webkit-appearance: none;
			&::-ms-expand {
				display: none;
			}
			&:focus {
				outline: none;
			}
		}
		.icon-arrow_next {
			position: absolute;
			right: 12px;
			top: 50%;
			transform: translateY(-50%) rotate(90deg);
		}
	}
`
