import { get, put } from '../utils/apiCallerV2'

export const initBinding = () => {
  return put('v2/zalopay/initialize-binding', null)
}
export const checkBinding = () => {
  return get('v2/zalopay/check-binding')
}
export const checkPayable = () => {
  return get('v2/customer/check-efficient-fund')
}
