export const TRANSACTION_TYPE = {
  1: {
    value: 'CASH_IN',
    srcIcon: '/img/unipass-logo-with-text.svg'
  },
  2: {
    value: 'CASH_OUT',
    srcIcon: '/img/unipass-logo-with-text.svg'
  },
  3: {
    value: 'PAYMENT_INVOICE',
    srcIcon: '/img/icons/bus_invoice_icon.svg'
  },
  4: {
    value: 'BUY_MONTHLY_TICKET',
    srcIcon: '/img/icons/buy_monthly_ticket.svg'
  }
}
