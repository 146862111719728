import { useEffect, useState } from 'react'
import BasicButton from '../../../../../new-components/Button'
import { color } from '../../../../../utils/color'
import { upload } from '../../../../../utils/apiCallerV2'
import useFetch from '../../../../../hooks/useFetch'
import { profileApi } from '../../../../../api/profile'
import { ReactComponent as CheckBlueCircle } from '../../../../../assets/icons/check-blue-circle-icon.svg'
import Image from '../../../../../new-components/Image'
import { useDispatch } from 'react-redux'
import { getCustomerInfo } from '../../../../../actions/customer.action'
import useToast from '../../../../../hooks/useToast'

export default function ReviewImage({ setStep, steps, imgSrc, setImgSrc, setNewData }) {
  const dispatch = useDispatch()
  const toast = useToast()
  const [preview, setPreview] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const [update, { loading: updating }] = useFetch(profileApi.updateStudentCardImage, {
    onSuccess: () => {
      dispatch(
        getCustomerInfo((userInfo) => {
          setNewData(userInfo)
        })
      )
      toast({ message: 'Cập nhật thành công' })
    },
    onError: () => {
      toast({ message: 'Có lỗi xảy ra trong quá trình tải ảnh lên', type: 'error' })
    }
  })

  const reCapture = () => {
    setImgSrc(undefined)
    setStep(steps.upload)
  }

  useEffect(() => {
    if (!imgSrc) {
      setPreview(undefined)
      return
    }
    if (!(imgSrc instanceof File)) {
      setPreview(imgSrc)
      return
    }
    const objectUrl = URL.createObjectURL(imgSrc)

    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [imgSrc])

  const handleUpload = () => {
    setLoading(true)
    upload({
      body: {
        file: imgSrc,
        sub_path: 'student_card_image'
      }
    })
      .then((res) => {
        if (res?.error?.code === 1) {
          update({
            data: {
              student_card_image: res?.data?.[0]?.image_big
            }
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="d-flex flex-column w-100 justify-content-center align-items-center">
      <div className="text-16-20 text-center" style={{ color: color.black[450] }}>
        Bạn cần kiểm tra ảnh thẻ học sinh/sinh viên sau khi chụp đã thấy rõ mặt và không che khuất
        bất kỳ thông tin trước khi <span className="text-bold">Gửi</span> đi.
      </div>

      <Image
        containerStyle={{ marginTop: 17, marginBottom: 12 }}
        imgSrc={preview}
        style={{
          borderRadius: 8,
          height: window.innerHeight - 300,
          width: window.innerWidth - 50,
          objectFit: 'contain'
        }}
        onRemove={reCapture}
      />
      <div className="d-flex" style={{ gap: 8, marginBottom: 20 }}>
        <CheckBlueCircle />
        <span className="text-16-20" style={{ color: color.black[450] }}>
          Tôi đã đọc và đồng ý với{' '}
          <span style={{ color: color.blue[400] }}>điều khoản điều kiện của UniPass</span>
        </span>
      </div>

      <BasicButton
        onClick={handleUpload}
        style={{ backgroundColor: color.blue[500], color: 'white' }}
        shape="round"
        loading={loading || updating}
      >
        Gửi
      </BasicButton>
    </div>
  )
}
