import { call, put, takeLatest } from '@redux-saga/core/effects'
import { checkBinding, initBinding, checkPayable } from '../../services/autodebit.services'
import * as actionTypes from '../../constants/ActionType'
import {
  checkBindingFail,
  checkBindingSuccess,
  initializeBindingDataDone
} from '../../actions/autodebit.action'
import { setMonthlyTicketInfo, setPayableInfo, setBalance } from '../../actions/home.action'

function* handleBinding() {
  const initResponse = yield call(initBinding)
  yield put(initializeBindingDataDone())
  if (window.ZaloPay.isZaloPay) {
    console.log('binding_qr_link: ', initResponse.data.binding_qr_link)
    window.location.href = initResponse.data.binding_qr_link
  } else {
    console.log(
      'short_link: ',
      initResponse.data.short_link.replace('source=web', 'source=webinzalo')
    )
    window.location.href = initResponse.data.binding_qr_link
  }
}

function* handleCheckBinding() {
  const response = yield call(checkBinding)
  if (response && response.data) {
    yield put(checkBindingSuccess(response.data))
  } else {
    yield put(checkBindingFail())
  }
}
function* handleCheckPayable() {
  const response = yield call(checkPayable)
  let totalTicket = null
  let balance = null
  if (response && response.error.code !== 1) {
    console.log('checkEfficientFund err: ', response.error)
    yield put(setMonthlyTicketInfo(totalTicket))
    yield put(setPayableInfo(false))
    yield put(setBalance(balance))
    return
  }
  const data = response ? response.data : null
  if (!data) {
    yield put(setMonthlyTicketInfo(totalTicket))
    yield put(setPayableInfo(false))
    yield put(setBalance(balance))
    return
  }
  if (data.monthly_ticket && data.monthly_ticket > 0) {
    totalTicket = data.monthly_ticket
  }
  if (data.balance) {
    balance = data.balance
  }
  yield put(setPayableInfo(data.payable))
  yield put(setMonthlyTicketInfo(totalTicket))
  yield put(setBalance(balance))
}

export default function* bindingSaga() {
  yield takeLatest(actionTypes.INIT_AUTODEBIT_BINDING_DATA, handleBinding)
  yield takeLatest(actionTypes.CHECK_BINDING, handleCheckBinding)
  yield takeLatest(actionTypes.CHECK_PAYABLE, handleCheckPayable)
}
