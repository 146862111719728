import React from 'react'
import OtpInput from 'react18-input-otp'
import { color } from '../../utils/color'

export default function OTPInput({ numInputs = 6, containerStyle = {}, ...props }) {
  return (
    <OtpInput
      numInputs={numInputs}
      inputStyle={{
        border: '1px solid #C4C4C4',
        width: 40,
        height: 40,
        borderRadius: 6,
        fontWeight: 600,
        fontSize: 22
      }}
      isInputNum={true}
      separator={<div style={{ width: 16 }} />}
      shouldAutoFocus={true}
      containerStyle={{ justifyContent: 'center', ...containerStyle }}
      errorStyle={{
        border: `1px solid ${color.red[500]}`
      }}
      {...props}
    />
  )
}
