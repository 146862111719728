import React from 'react'

import { ClearWrapper } from './style'

const ClearButton = (props) => {
  const { onClick, name, isShow } = props

  return <ClearWrapper isShow={isShow} onClick={() => onClick(name)} className="icon-delete" />
}

export default ClearButton
