import styled from 'styled-components'
import { Link } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'

import { COLOR_DARK_GREY, ZP_GRADIENT } from '../../constants/Styles'

export const QRCodeWrapper = styled.div`
  padding: 35px;
  padding-bottom: 0;
  > canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 200px;
  }
`

export const QRWrapper = styled.div`
  && {
    background-image: linear-gradient(${ZP_GRADIENT});
    background-image: -moz-linear-gradient(${ZP_GRADIENT});
    background-image: -webkit-linear-gradient(${ZP_GRADIENT});
    background-image: -o-linear-gradient(${ZP_GRADIENT});
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    > .qr-code {
      margin-left: 15px;
      margin-right: 15px;
      background-color: #fff;
      border-radius: 3px;
      margin-top: 15px;
      padding-bottom: 30px;
    }
  }
`

export const QRHeader = styled(TableCell)`
  && {
    &.avatar {
      padding: 20px 8px 20px 0 !important;
      width: 64px;
      > span {
        margin-right: 5px;
      }
    }
    &.name {
      text-align: left;
      padding: 20px 0 !important;
      > p {
        margin: 0;
        font-size: 18px;
        text-transform: capitalize;
      }
      .card-type {
        color: ${COLOR_DARK_GREY};
        font-size: 16px;
        margin: 3px 0;
        display: block;
      }
      .validation-time {
        color: ${COLOR_DARK_GREY};
        font-size: 16px;
        display: block;
      }
    }
  }
`

export const RemainTime = styled.span`
  && {
    font-weight: bold;
    margin: 0;
    display: inline-block;
    width: 23px;
  }
`

export const ReceiveCardWrapper = styled.div`
  margin-top: 40px;
  > p.description {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-style: italic;
  }
`

export const LinkReceiveCard = styled(Link)`
  && {
    font-size: 18px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 25px;
    padding: 12px 50px;
    display: inline-block;
  }
`

export const TimingWrapper = styled.div`
  padding: 20px 0;
  height: 40px;
  .completed {
    > span {
      margin-right: 7px;
      vertical-align: middle;
    }
  }
  .running {
    padding: 0;
    min-width: unset;
    color: ${COLOR_DARK_GREY};
    .refresh {
      margin-right: 4px;
      vertical-align: middle;
    }
  }
`
