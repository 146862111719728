import { Divider } from 'antd'
import { PAYMENT_METHOD } from '../../constants/recharge'
import './styles.scss'
import { color } from '../../utils/color'

const regularMethod = Object.values(PAYMENT_METHOD).filter((m) => !m.otherMethod)

const PaymentMethod = ({ className, onPay }) => {
  return (
    <div className={`w-full ${className}`}>
      <div className="text-start text-16-20 ff-r text-black400">CHỌN PHƯƠNG THỨC THANH TOÁN</div>
      <div className="flex flex-col pt-16 gap-12 pb-32">
        {regularMethod.map(({ value, label, iconSrc }) => {
          return (
            <div
              className="flex justify-between py-8 px-12 bg-black200 rounded-8 items-center"
              key={value}
              onClick={() => onPay(value)}
            >
              <div className="flex flex-row justify-center items-center">
                <img src={iconSrc} className="w-36 h-36 mr-16" />
                <div>{label} </div>
              </div>
              <img src={'/img/direction/arrow-right.png'} className="w-16 h-16" />
            </div>
          )
        })}
      </div>
      <Divider
        dashed={true}
        className="divider-custom text-16-20 ff-b"
        style={{ color: color.black[310] }}
      >
        hoặc phương thức khác
      </Divider>

      <div
        className="flex justify-center items-center py-16 px-12 bg-black500 rounded-8"
        onClick={() => onPay(PAYMENT_METHOD.applePay.value)}
      >
        <div className="text-16-20 text-white text-bold">{PAYMENT_METHOD.applePay.label}</div>
        <img src={PAYMENT_METHOD.applePay.iconSrc} className="h-18 ml-8" />
      </div>
    </div>
  )
}

export default PaymentMethod
