import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../reducers/index'
import thunk from 'redux-thunk'
import rootSaga from '../saga'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()

const middlewares = [thunk, sagaMiddleware]

export default createStore(rootReducer, composeEnhancer(applyMiddleware(...middlewares)))

sagaMiddleware.run(rootSaga)
