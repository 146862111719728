import React, { useRef, useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import './style.css'
import classNames from 'classnames'
import { color } from '../../utils/color'

const priceList = [
  { value: 10000, label: '10.000đ' },
  { value: 100000, label: '100.000đ' },
  { value: 500000, label: '500.000đ' }
]

const styles = {
  filter: {
    padding: '8px 15px',
    backgroundColor: 'white',
    display: 'inline-block',
    borderRadius: 8,
    color: color.black[400],
    border: '1px solid #BFBEBE'
  },
  error: { color: color.red[450], textAlign: 'start', marginLeft: 25, marginTop: 5 }
}

export default function InputAmount({ error, value, onChange, className, ...rest }) {
  const inputRef = useRef()
  const [showClearData, setShowClearData] = useState(false)
  const [choise, setChoise] = useState(0)

  const choisePrice = (i) => {
    if (choise === i.value) {
      setChoise(0)
    } else {
      setChoise(i.value)
      onChange(i.value)
    }
  }

  useEffect(() => {
    if (value) setShowClearData(true)
    else setShowClearData(false)
  }, [value])

  const onChangeInputFiled = (v) => {
    onChange(v)
    setChoise(v)
  }

  return (
    <div {...rest} className={`input-amount ${className}`}>
      <div className="text-black400 text-16-18">Số tiền cần nạp (đ)</div>
      <div className={classNames('position-relative')}>
        <TextField
          error={!!error}
          inputRef={inputRef}
          hiddenLabel
          fullWidth
          size="small"
          placeholder={'0đ'}
          onChange={(e) => {
            onChangeInputFiled(e.target.value.split('.').join(''))
          }}
          value={value}
          type="tel"
          onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
          inputProps={{ autoFocus: true }}
        />
        <span
          className="position-absolute text-muted"
          style={{ right: 15, top: `calc(50% - 10px)` }}
        >
          {showClearData && (
            <img
              alt="clear button"
              src="/img/icons/circle-x-close.svg"
              onClick={() => {
                onChangeInputFiled('')
                setShowClearData(false)
              }}
            />
          )}
        </span>
      </div>
      {error && <div className="text-red450 text-start mt-1 text-14-18">{error}</div>}
      <div className="flex justify-between  mt-24">
        {priceList.map((i) => {
          return (
            <div
              style={{
                ...styles.filter,
                backgroundColor: choise == i.value ? '#E8F2FF' : 'white',
                border: choise == i.value ? '1px solid #006DFF' : '1px solid #BFBEBE'
              }}
              key={i.value}
              onClick={() => {
                choisePrice(i)
              }}
            >
              <span
                style={{
                  color: choise == i.value ? '#006DFF' : color.black[400],
                  fontSize: 16
                }}
              >
                {i.label}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
