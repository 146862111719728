import React from 'react'
import { useHistory } from 'react-router-dom'
import { color } from '../../../utils/color'
const styles = {
  container: {
    backgroundColor: 'white',
    marginTop: 16,
    borderRadius: 8,
    padding: '10px 20px 10px 20px'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'start',
    paddingBottom: 10,
    paddingTop: 10
  },
  iconWrapper: { width: '15%' },
  text: {
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '85%'
  },
  title: { fontSize: 16, fontWeight: 500, color: color.black[500] },
  content: { fontSize: 14, fontWeight: 400 }
}

const RechargeActions = ({ title, content, onClick, icon }) => {
  return (
    <div style={styles.item} onClick={onClick}>
      <div style={styles.iconWrapper}>
        <img src={icon} alt="" />
      </div>
      <div style={styles.text}>
        <div>
          <div style={styles.title}>{title}</div>
          <div style={styles.content}>{content}</div>
        </div>
        <img alt="" src="/img/icons/arrow-right-not-line.svg" />
      </div>
    </div>
  )
}

export default function RechargeOptions({ isBinding = false, item }) {
  const history = useHistory()
  return (
    <div style={styles.container}>
      <RechargeActions
        title={isBinding ? 'Đã liên kết ví ZaloPay' : 'Liên kết ví ZaloPay'}
        content={'Tiện lợi, nhanh chóng và bảo mật'}
        icon={
          isBinding
            ? '/img/icons/zalopay_with_check_green.svg'
            : '/img/icons/zalopay_icon_border.svg'
        }
        onClick={() => {
          history.push('/payment/option')
        }}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <hr style={{ width: '85%', margin: 0 }} />
      </div>
      <RechargeActions
        title={'Nạp tiền vào tài khoản UniPass'}
        content={'Đơn giản và kiểm soát số dư'}
        icon={'/img/icons/unipass_bus.svg'}
        onClick={() => {
          history.push({
            pathname: '/payment',
            state: { isHelper: false, phone: item.phone }
          })
        }}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        <hr style={{ width: '85%', margin: 0 }} />
      </div>
      <RechargeActions
        title={'Nạp hộ vào tài khoản UniPass'}
        content={'Nạp tiền giúp người thân, bạn bè'}
        icon={'/img/icons/hand_money.svg'}
        onClick={() => {
          history.push({
            pathname: '/recharge',
            state: {
              item
            }
          })
        }}
      />
    </div>
  )
}
