import React, { useMemo } from 'react'

const styles = {
  container: {
    borderRadius: '50%',
    width: 46,
    height: 46,
    backgroundColor: '#D5EBC1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: 46,
    height: 46,
    borderRadius: '50%'
  }
}
export default function Avatar({ url, name }) {
  const formatName = useMemo(() => {
    if (name) {
      const formatName = name
        .split(' ')
        .map((i) => i.charAt(0))
        .toString()
        .toUpperCase()
        .replace(',', '')
      if (formatName.length > 2) {
        return `${formatName.charAt(0)}${formatName.charAt(formatName.length - 1)}`
      } else {
        return formatName
      }
    }
    return 'UN'
  }, [name])
  return (
    <div style={styles.container}>
      {url ? <img alt="" src={url} style={styles.image} /> : <span>{formatName}</span>}
    </div>
  )
}
