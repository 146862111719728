import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core'

const StyledCheckbox = withStyles({
  root: {
    padding: 0
  }
})(Checkbox)

export default (props) => {
  return (
    <StyledCheckbox
      {...props}
      icon={
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="11" fill="#7B7B7B" />
          <path
            d="M20.4284 10.9999C20.4284 16.2071 16.2071 20.4284 10.9999 20.4284C5.7926 20.4284 1.57129 16.2071 1.57129 10.9999C1.57129 5.7926 5.7926 1.57129 10.9999 1.57129C16.2071 1.57129 20.4284 5.7926 20.4284 10.9999Z"
            fill="white"
          />
        </svg>
      }
      checkedIcon={
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
            fill="#349BF8"
          />
          <path
            d="M5.40911 11.1842C5.67759 10.9157 6.11289 10.9157 6.38138 11.1842L9.2982 14.101L8.32592 15.0732L5.40911 12.1564C5.14062 11.8879 5.14062 11.4526 5.40911 11.1842ZM15.618 7.78119C15.8865 7.5127 16.3218 7.5127 16.5903 7.78119C16.8588 8.04967 16.8588 8.48498 16.5903 8.75346L10.2705 15.0732C9.73353 15.6102 8.86293 15.6102 8.32596 15.0732L15.618 7.78119Z"
            fill="white"
          />
        </svg>
      }
    />
  )
}
