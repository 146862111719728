import React from 'react'
import { formatDateWithoutTime } from '../../../utils/helper'
import { PROMOTION } from '../../../constants/Config'
import { color } from '../../../utils/color'

const styles = {
  itemContainer: {
    backgroundColor: 'white',
    width: '100%',
    marginBottom: 16,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 0px 12px 12px'
  },
  iconWrapper: {
    width: '15%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    borderRadius: 8,
    width: '-webkit-fill-available'
  },
  title: {
    fontSize: 14,
    fontWeight: 400,
    color: '#18191F',
    paddingBottom: 10,
    textAlign: 'start'
  },

  expireDate: {
    fontSize: 12,
    color: '#4E5966',
    fontWeight: 400,
    borderRadius: 4
  },
  remainDay: {
    backgroundColor: color.red[450],
    textAlign: 'center',
    padding: '3px 9px',
    marginLeft: 10
  },
  action: {
    fontSize: 12,
    marginRight: 10,
    fontFamily: 'SFDisplay-Semibold',
    color: color.blue[800]
  }
}

const RemainingCount = ({ count }) => {
  return (
    <div
      style={{
        backgroundColor: '#19B258',
        width: 32,
        height: 16,
        float: 'right',
        borderRadius: '8px 0px 0px 8px',
        fontSize: 10,
        color: 'white'
      }}
    >
      {`x${count}`}
    </div>
  )
}
// const onGoViewDetail = (item) => {
//   history.push({ pathname: "/gift-detail", state: { gift: item } });
// };
export default function ItemGift({ item, onGoViewDetail }) {
  return (
    <div
      style={styles.itemContainer}
      onClick={() => {
        onGoViewDetail(item)
      }}
    >
      <div style={styles.iconWrapper}>
        <img src={item.campaign_icon} alt="" style={styles.icon} />
      </div>
      <div style={{ width: '80%' }}>
        <div className="d-flex justify-content-between">
          <div style={styles.title}>{item.campaign_name}</div>
          <RemainingCount count={item.remainingVoucher} />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div style={styles.expireDate}>{`HSD: ${formatDateWithoutTime(item.expired)}`}</div>
            {item.remainingTime < PROMOTION.limitDateWarning && (
              <div
                style={{
                  ...styles.remainDay,
                  ...styles.expireDate,
                  color: 'white'
                }}
              >
                {item.remainingTimeMessasge}
              </div>
            )}
          </div>
          <div style={styles.action}>Xem chi tiết</div>
        </div>
      </div>
    </div>
  )
}
