import styled from 'styled-components'

export const CommonStyle = styled.div`
  text-align: center;
  background-color: #ffffc1;
  color: #000;
  padding: 10px;
  p {
    margin: 5px;
  }
  a {
    font-weight: 700;
    text-decoration: underline !important;
  }
`
