import { Button } from '@material-ui/core'
import React from 'react'
import { color } from '../../utils/color'
import { useHistory } from 'react-router-dom'
import { refundDateFormat } from '../../utils/helper'

const styles = {
  container: {
    paddingTop: 24
  },
  img: { width: 106 },
  title: {
    fontSize: 15,
    color: color.black[400],
    marginTop: 16
  },
  content: {
    color: color.black[300],
    width: '80%',
    fontSize: 15
  },
  button: {
    padding: 10,
    borderRadius: 30,
    fontSize: 16,
    fontWeight: 700,
    width: '95%',
    boxShadow: 'none',
    marginTop: 56,
    color: 'white',
    backgroundColor: color.blue[500]
  }
}
export default function RegisteredRefund({ data }) {
  const history = useHistory()
  const backHome = () => {
    history.replace('/')
  }

  return (
    <div style={styles.container} className=" d-flex flex-column align-items-center">
      <img alt="image image" src="/img/refund/refund.svg" style={styles.img} />
      <b style={styles.title}>Bạn đã đăng ký hoàn tiền thành công!</b>
      <span style={{ ...styles.content, marginTop: 6 }}>
        Thời gian nhận hoàn tiền vào ví ZaloPay của bạn dự kiến từ ngày{' '}
        <span className="text-bold">{refundDateFormat(data.createdTime)}</span>
      </span>
      <span style={{ ...styles.content, marginTop: 16 }}>
        Sau thời gian hoàn tiền mà bạn chưa nhận được, vui lòng liên hệ
        <a href="tel:1900545436"> 1900 54 54 36</a>
      </span>
      <Button variant="contained" fullWidth style={styles.button} onClick={backHome}>
        Quay lại trang chủ
      </Button>
    </div>
  )
}
