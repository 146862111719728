import React from 'react'

import { ToastWrapper } from './style'

const Toast = (props) => {
  const { type = 1, message = 'Thành công' } = props
  const logo =
    type === 1 ? (
      <span className={`icon-radio icon`}></span>
    ) : (
      <span style={{ fontSize: 15 }} className={`icon-closed icon`}></span>
    )

  return (
    <ToastWrapper>
      {logo}
      <span className="message">{message}</span>
    </ToastWrapper>
  )
}

export default Toast
