import { Snackbar } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { closeToast } from '../../actions/common.action'
import './styles.scss'

export default function Toast() {
  const dispatch = useDispatch()
  const { openToast, toastContent } = useSelector((state) => state.common)

  return (
    <Snackbar
      open={openToast}
      autoHideDuration={2000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={() => {
        dispatch(closeToast())
      }}
      message={toastContent}
      className="toast-custom"
    />
  )
}
