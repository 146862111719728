import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { getSupportedRoutes } from '../../actions/route.action'
import LoadingIcon from '../../components/loadingIcon'
import { setTitle } from '../../utils/helper'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core'
const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  background: white;
  padding: 14px 17px 0 17px;
  background: var(--main-bg-color);
  overflow-y: auto;
`

const RouteItem = withStyles({
  root: {
    margin: '8px 0 16px 0',
    borderRadius: '6px',
    height: '59px',
    backgroundColor: 'white'
  }
})(Grid)

const Name = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontFamily: 'SFDisplay-Medium',
    fontStyle: 'normal',
    fontSize: '17px',
    lineHeight: '120%',
    color: '#000000'
  }
})(Grid)

const Description = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    color: '#8C939B',
    lineHeight: 'normal',
    paddingRight: '14px',
    fontFamily: 'SFDisplay',
    fontWeight: '400'
  }
})(Grid)

export default function SupportedRoutes(props) {
  setTitle('Danh sách tuyến áp dụng')

  const dispatch = useDispatch()
  const { supportedRoutes, loading } = useSelector((state) => state.routes)
  useEffect(() => {
    if (!supportedRoutes.length) {
      dispatch(getSupportedRoutes())
    }
  }, [])

  return (
    <PageContainer>
      <LoadingIcon loading={loading} />
      {supportedRoutes
        .sort((a, b) => a.route_number - b.route_number)
        .map((route) => (
          <RouteItem container key={route.route_id}>
            <Name item xs={2} md={2}>
              {route.route_number}
            </Name>
            <Description item xs={10} md={10}>
              {route.route_name}
            </Description>
          </RouteItem>
        ))}
    </PageContainer>
  )
}
