import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import { ZP_GRADIENT } from '../../constants/Styles'

export const ZPButtonStyle = styled(Button)`
  && {
    padding: 13px 0;
    font-size: 18px;
    color: #fff;
    background-image: linear-gradient(${ZP_GRADIENT});
    background-image: -moz-linear-gradient(${ZP_GRADIENT});
    background-image: -webkit-linear-gradient(${ZP_GRADIENT});
    background-image: -o-linear-gradient(${ZP_GRADIENT});
    border-radius: 3px;
    box-shadow: none;
    margin: 40px auto 0 auto;
    width: calc(100% - 20px);
    &.default {
      background: #c7c7cc;
    }
    &[disabled] {
      background: #c7c7cc;
      color: #fff;
    }
  }
`
