import { Upload as UploadAnt } from 'antd'
import { ReactComponent as PictureIcon } from '../../../../../assets/icons/picture-icon.svg'
import BasicButton from '../../../../../new-components/Button'
import { color } from '../../../../../utils/color'
import useToast from '../../../../../hooks/useToast'

export default function Upload({ steps, setStep, setImgSrc }) {
  const toast = useToast()

  const onChangeImage = (v) => {
    if (v?.file) {
      if (v.file?.size > 4 * 1024 * 1024) {
        toast({ message: 'Dung lượng file không được vượt quá 4MB', type: 'error' })
        return
      }
      setImgSrc(v.file?.originFileObj || v.file)
      setStep(steps.review)
      return
    }
    toast({ message: 'Có lỗi xảy ra trong quá trình tải ảnh lên', type: 'error' })
  }

  return (
    <div
      className="d-flex flex-column w-100 justify-content-center align-items-center"
      style={{ gap: 10 }}
    >
      <div
        style={{
          padding: 10,
          backgroundColor: color.yellow[400],
          color: color.orange[500],
          width: '100%'
        }}
        className="text-14-20-400"
      >
        Bạn chưa cập nhật thẻ học sinh/sinh viên
      </div>
      <PictureIcon />
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div
          style={{
            color: color.black[350],
            width: '80%'
          }}
          className="text-16-20 text-center"
        >
          Thẻ học sinh/sinh viên có mục đích xuất trình khi được yêu cầu kiểm tra để được hưởng giá
          ưu đãi.
        </div>
      </div>

      <UploadAnt
        showUploadList={false}
        onChange={onChangeImage}
        accept="image/*"
        beforeUpload={false}
      >
        <BasicButton style={{ backgroundColor: color.blue[500], color: 'white' }} shape="round">
          Cập nhật ngay
        </BasicButton>
      </UploadAnt>
    </div>
  )
}
