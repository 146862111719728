import React from 'react'
import { color } from '../../../utils/color'

const styles = {
  container: {
    backgroundColor: '#EFF7FF',
    margin: '16px 16px 0px 16px',
    borderRadius: 10,
    padding: 12
  },
  dot: {
    color: color.blue[500],
    marginRight: 9,
    marginTop: -2
  }
}
export default function Terms() {
  return (
    <div style={styles.container} className="d-flex flex-column text-left justify-content-start">
      <div className="text-bold" style={{ fontSize: 15, fontFamily: 'SFDisplay' }}>
        Điều kiện để nhận thưởng người được mời cần:
      </div>
      <div className="ml-2 d-flex align-items-center">
        <div style={styles.dot}> • </div>
        <div style={{ fontSize: 14 }}>Đăng ký tài khoản UniPass thành công</div>
      </div>
      <div className="ml-2 d-flex align-items-start justify-content-start">
        <div style={styles.dot}> • </div>
        <div style={{ fontSize: 14 }}>
          Thực hiện 1 giao dịch đi xe buýt trả tiền bằng thẻ UniPass hoặc mã QR
        </div>
      </div>
    </div>
  )
}
