import styled from 'styled-components'

import { ZP_GRADIENT } from '../../constants/Styles'

export const ZPSubTitleStyle = styled.div`
  && {
    padding: 10px 0;
    font-size: 18px;
    color: #fff;
    background-image: linear-gradient(${ZP_GRADIENT});
    background-image: -moz-linear-gradient(${ZP_GRADIENT});
    background-image: -webkit-linear-gradient(${ZP_GRADIENT});
    background-image: -o-linear-gradient(${ZP_GRADIENT});
    border-radius: 3px;
    box-shadow: none;
    margin: 40px auto 0 auto;
  }
`
