import axios from 'axios'
import { REACT_APP_API_URL as API_URL } from '../constants/Env'

const post = async (endpoint, body = null) => {
  return axios({
    method: 'POST',
    url: `${API_URL}/${endpoint}`,
    data: body,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

const postWithZalo = async (endpoint, body = null) => {
  return axios({
    method: 'POST',
    url: `${API_URL}/${endpoint}`,
    data: body,
    headers: {
      'Content-Type': 'application/json',
      Cookie: ''
    },
    withCredentials: true
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

const put = async (endpoint, body = null) => {
  return axios({
    method: 'PUT',
    url: `${API_URL}/${endpoint}`,
    data: body,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
const deleteApi = async (endpoint, body = null) => {
  return axios({
    method: 'DELETE',
    url: `${API_URL}/${endpoint}`,
    data: body,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}
const get = async (endpoint) => {
  return axios({
    method: 'GET',
    url: `${API_URL}/${endpoint}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

const upload = async ({ body = null }) => {
  const data = new FormData()
  data.append('images', body.file)
  return axios({
    headers: { 'content-type': 'multipart/form-data' },
    method: 'POST',
    url: `${API_URL}/v2/upload/upload-images`,
    data,
    withCredentials: true
  })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.log(err)
    })
}

const axiosClient = axios.create({
  baseURL: API_URL,
  withCredentials: true
})

axiosClient.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    return Promise.reject(error)
  }
)

const config = ({ headers = {}, method, url, data }) => {
  const _config = { method, url, headers: { 'Content-type': 'application/json', ...headers } }
  if (method?.toLowerCase() === 'get') _config.params = data
  else _config.data = data
  return _config
}

export { post, get, put, deleteApi, upload, postWithZalo, axiosClient, config }
