import styled from 'styled-components'

export const SupportDialogWrapper = styled.div`
  padding: 20px 10px 20px;
`

export const HeaderWrapper = styled.div`
  text-align: center;
  > span {
    color: #333333;
    font-weight: bold;
    font-size: 16px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  padding: 10px 30px;
  align-items: center;
  justify-content: center;
`

export const Icon = styled.img`
  padding: 0 5px;
`

export const CloseButton = styled.div`
  background: #006dfe;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
`
