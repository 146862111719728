import { createSelector } from 'reselect'
import { getMonthAndYear } from '../utils/helper'

export const transactionsSelector = (state) => state.transactions

export const lastTransactionSelector = createSelector(transactionsSelector, (transactions) => {
  const invoices = [...transactions.invoice]
  invoices.sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    if (dateA > dateB) {
      return -1
    }
    if (dateA < dateB) {
      return 1
    }
    return 0
  })

  return invoices[0]
})

const appendData = (newData) => {
  const listHistory = []
  for (const i in newData) {
    const { date } = newData[i]
    const index = listHistory.findIndex((history) => history.date === getMonthAndYear(date))

    if (index === -1) {
      listHistory.push({
        date: getMonthAndYear(date),
        transactions: []
      })
      index = listHistory.length - 1
    }

    listHistory[index].transactions.push(newData[i])
  }

  return listHistory
}

export const formattedTransactionsSelector = createSelector(
  transactionsSelector,
  (transactions) => {
    const { invoice, cash } = transactions
    const formattedInvoice = invoice.map((i) => {
      return {
        ...i,
        date: i.created_date_time,
        trans_id: i.invoice_code,
        is_cash_out: true
      }
    })

    const summary = [...formattedInvoice, ...cash]
    summary.sort((a, b) => {
      const dateA = new Date(a.date)
      const dateB = new Date(b.date)
      if (dateA > dateB) {
        return -1
      }
      if (dateA < dateB) {
        return 1
      }
      return 0
    })

    return appendData(summary)
  }
)
