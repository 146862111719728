import { call, put, takeLatest, select } from '@redux-saga/core/effects'
import * as actionTypes from '../constants/ActionType'
import { checkShowPopup } from '../services/home.services'
import { checkShowPopupSuccess } from '../actions/home.action'
import _isEqual from 'lodash/isEqual'
function* handleCheckShowPopup() {
  try {
    const res = yield call(checkShowPopup)
    if (res && res.data && res.data.length) {
      const data = yield select((state) => state.home.popup.data)
      if (!_isEqual(data, res.data)) {
        yield put(checkShowPopupSuccess({ data: res.data, isShow: true }))
      }
    }
  } catch (e) {
    console.log('error: ', e)
  }
}
export default function* homeSaga() {
  yield takeLatest(actionTypes.HOME.CHECK_SHOW_POPUP, handleCheckShowPopup)
}
