import styled from 'styled-components'

export const ZPBindingContainerPage = styled.div`
  padding: 15px;
  background: #f6f7f9;
  height: 100vh;
`

export const ZPBindingContainerWrapper = styled.div`
  display: flex;
  background: white;
  padding: 10px;
`

export const UniPassIcon = styled.img`
  border: 1px solid #e4e4e4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`

export const UniPassTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: #333333;
  margin-bottom: 0.5rem;
`

export const InfoWrapper = styled.div`
  padding: 0 15px;
  text-align: left;

  @media (max-width: 400px) {
    font-size: 12px;
  }

  @media (max-width: 320px) {
    font-size: 11px;
  }
`

export const InfoText = styled.p`
  color: #8c939b;
  margin-bottom: 0;
  font-size: 14px;

  @media (max-width: 400px) {
    font-size: inherit;
  }
`

export const StatusWrapper = styled.div`
  margin: auto;

  @media (max-width: 400px) {
    font-size: 14px;
  }

  @media (max-width: 358px) {
    font-size: 13px;
  }

  @media (max-width: 320px) {
    font-size: 10px;
  }
`

export const ViewIcon = styled.img`
  padding-left: 15px;
  height: 10px;
`
