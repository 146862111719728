import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { checkBindingFail, setUnbindDialogVisibility } from '../actions/autodebit.action'
import ZPUnbindAlert from '../components/ZPUnbindAlert/ZPUnbindAlert'
import ZPUnbindDialog from '../components/ZPUnbindDialog/ZPUnbindDialog'
import { AUTODEBIT_DETAIL } from '../constants/Page'
import { put } from '../utils/apiCallerV2'
import { setTitle } from '../utils/helper'
import {
  AutodebitDetailPageWrapper,
  Block,
  Header,
  Info,
  Key,
  Row,
  Title,
  Unbind,
  UniPassIcon,
  Value,
  ZPIcon
} from './styles/autodebit-detail-page'
class AutodebitDetailPage extends Component {
  constructor(props) {
    super(props)
    setTitle(AUTODEBIT_DETAIL)

    this.state = {
      isOpenUnbindAlert: false,
      loadingUnBindZaloPay: false
    }
  }

  openUnbindZaloPay = () => {
    this.props.setUnbindDialogVisibility(true)
  }

  doUnbindZaloPay = async () => {
    this.setState({ loadingUnBindZaloPay: true })
    const response = await put('v2/zalopay/un-bind', null)

    if (response && response.error.code !== 1) {
      this.setState({ loadingUnBindZaloPay: false })
      console.log('unBindFromZaloPay err: ', response.error)
      return
    }
    this.props.checkBindingFail()
    this.props.setUnbindDialogVisibility(false)

    // Open dialog
    this.setState({ isOpenUnbindAlert: true, loadingUnBindZaloPay: false })
    setTimeout(() => {
      this.setState({ isOpenUnbindAlert: false })
      this.props.history.replace('/')
    }, 2000)
  }

  onOpenDialog = () => {
    console.log('onOpenUnbindDialog')
  }

  onCloseDialog = () => {
    this.props.setUnbindDialogVisibility(false)
  }

  render() {
    const { phone_number, customer_name, date_created } = this.props.bindingData
    const displayNone = {
      display: 'none'
    }

    let loadingStyle = {
      display: 'block'
    }
    let confirmStyle = {
      display: 'block'
    }

    if (this.state.loadingUnBindZaloPay) {
      confirmStyle = displayNone
    } else {
      loadingStyle = displayNone
    }

    return (
      <AutodebitDetailPageWrapper>
        {this.state.loadingUnBindZaloPay ? (
          <div className="diaglog-loading" style={(loadingStyle, { height: '100vh' })}>
            <div className="container-loading">
              <div></div>
              <div></div>
              <div></div>
              <p>Vui lòng chờ</p>
            </div>
          </div>
        ) : (
          <>
            <Header>
              <UniPassIcon src="/img/icons/unipass.svg" />
            </Header>
            <Info>
              <Title>Thông tin</Title>
              <Block>
                <Row>
                  <Key>Mã khách hàng</Key>
                  <Value>{phone_number}</Value>
                </Row>
                <Row>
                  <Key>Tên khách hàng</Key>
                  <Value>{customer_name}</Value>
                </Row>
              </Block>
            </Info>
            <Info>
              <Title>Thông tin liên kết</Title>
              <Block>
                <Row>
                  <Key>Nguồn tiền thanh toán</Key>
                  <Value>
                    <ZPIcon src="/img/icons/zalopay_icon.svg" />
                    TK ZaloPay
                  </Value>
                </Row>
                <Row>
                  <Key>Thời gian bắt đầu</Key>
                  <Value>{moment(date_created).format('hh:mm:ss DD/MM/YYYY')}</Value>
                </Row>
                <Row>
                  <Key>Thời gian kết thúc</Key>
                  <Value>Vô thời hạn</Value>
                </Row>
                <Row>
                  <Key>Hạn mức thanh toán</Key>
                  <Value>Hạn mức khả dụng</Value>
                </Row>
              </Block>
            </Info>

            <Unbind onClick={this.openUnbindZaloPay}>Huỷ thanh toán tự động</Unbind>
            <SwipeableDrawer
              anchor="bottom"
              onOpen={this.onOpenDialog}
              onClose={this.onCloseDialog}
              open={this.props.isOpenUnbindDialog}
              PaperProps={{
                style: { borderRadius: '30px 30px 0px 0px' }
              }}
            >
              <ZPUnbindDialog
                onCloseDialog={this.props.setUnbindDialogVisibility}
                onUnbindAutodebit={this.doUnbindZaloPay}
                loadingUnBindZaloPay={this.state.loadingUnBindZaloPay}
              />
            </SwipeableDrawer>
            <ZPUnbindAlert visibility={this.state.isOpenUnbindAlert} />
          </>
        )}
      </AutodebitDetailPageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isOpenUnbindDialog: state.autodebit.isOpenUnbindDialog,
    bindingData: state.autodebit.bindingData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUnbindDialogVisibility: (isOpenUnbindDialog) => {
      dispatch(setUnbindDialogVisibility(isOpenUnbindDialog))
    },
    checkBindingFail: () => {
      dispatch(checkBindingFail())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AutodebitDetailPage))
