import React, { useMemo } from 'react'
import { Block, Header, Row, Value } from './style'
import { formatMoney, setTitle } from '../../../utils/helper'
import { TRANSACTION_TYPE } from '../../../constants/history'
import { color } from '../../../utils/color'

const styles = {
  amount: {
    fontFamily: 'SFDisplay-Semibold',
    fontWeight: 'bold',
    fontSize: 20,
    color: color.black[500],
    marginBottom: '0.5rem'
  },
  iconContainer: {
    width: '100%',
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  iconWrapper: {
    marginTop: -38,
    width: 'fit-content',
    width: 46,
    height: 46,
    borderRadius: '50%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #EAEAEA'
  },
  status: {
    fontFamily: 'SFDisplay-Semibold',
    fontWeight: 'bold'
  }
}

const TRANSACTION_TYPE_DEFAULT = 3

const TransactionBodyDetail = (props) => {
  setTitle('Chi tiết giao dịch')

  const {
    type,
    title,
    description,
    created_date,
    status,
    unit,
    code,
    transaction_id,
    money_source,
    metadata,
    receiver,
    transaction_type,
    final_amount,
    origin_amount,
    discount_amount,
    guid
  } = props.row

  const metadataJson = JSON.parse(metadata)
  const keys = Object.keys(metadataJson)
  const itemsData = keys.map((key) => {
    return metadataJson[key]
  })

  const imageType = useMemo(() => {
    if (!transaction_type || transaction_type === TRANSACTION_TYPE_DEFAULT) {
      return TRANSACTION_TYPE[TRANSACTION_TYPE_DEFAULT]
    } else {
      return TRANSACTION_TYPE[transaction_type]
    }
  }, [transaction_type])
  return (
    <div>
      <Header>
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <img src={imageType.srcIcon} alt="" />
          </div>
        </div>
        <p style={{ marginBottom: '0.5rem', fontSize: 13, fontWeight: 400 }}>{title}</p>
        <p style={styles.amount}>
          {type === 1 ? '+ ' : type === -1 ? '- ' : ''} {formatMoney(final_amount) + unit}
        </p>
        <p style={{ fontSize: 14, color: '#7A7C8B' }}>{description}</p>
      </Header>
      <Block>
        <Row>
          <span>Trạng thái</span>
          {status === 2 ? (
            <span style={{ ...styles.status, color: '#15B34E' }}>Thành công</span>
          ) : status === 1 ? (
            <span style={{ ...styles.status, color: '#EB2227' }}>Thất bại</span>
          ) : (
            <span style={{ ...styles.status, color: '#ebd61c' }}>Đang xử lý</span>
          )}
        </Row>
      </Block>
      {origin_amount !== final_amount && (
        <Block>
          <Row>
            <span>Số tiền ban đầu</span>
            <Value>{formatMoney(origin_amount) + unit}</Value>
          </Row>
          <Row>
            <span>Số tiền giảm</span>
            <Value>
              {type === 1 ? '+ ' : type === -1 ? '- ' : ''} {formatMoney(discount_amount) + unit}
            </Value>
          </Row>
        </Block>
      )}
      <Block>
        <Row>
          <span>Mã đơn hàng</span>
          <Value>{code}</Value>
        </Row>
        <Row>
          <span style={{ textAlign: 'left' }}>Tài khoản thụ hưởng</span>
          <Value style={{ textAlign: 'right' }}>{receiver}</Value>
        </Row>
      </Block>
      <Block>
        <Row>
          <span>Mã tra cứu</span>
          <Value>{guid}</Value>
        </Row>
        <Row>
          <span>Mã giao dịch</span>
          <Value>{transaction_id}</Value>
        </Row>
        <Row>
          <span>Thời gian</span>
          <Value>{created_date}</Value>
        </Row>
        <Row>
          <span>Nguồn tiền</span>
          <Value>{money_source}</Value>
        </Row>
        <Row>
          <span>Phí giao dịch</span>
          <Value>Miễn phí</Value>
        </Row>
      </Block>

      {itemsData && itemsData.length > 0 && (
        <Block>
          {itemsData.map((item) => {
            return (
              <Row key={item.description}>
                <span>{item.description}</span>
                <Value>{item.value}</Value>
              </Row>
            )
          })}
        </Block>
      )}
    </div>
  )
}

export default TransactionBodyDetail
