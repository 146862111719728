import styled from 'styled-components'

export const AutodebitDetailPageWrapper = styled.div`
  height: 100vh;
  background: #f6f7f9;
  padding: 15px;
`

export const Title = styled.p`
  text-align: left;
  font-weight: bold;
  margin: 7px 0;
`

export const UniPassIcon = styled.img`
  border: 1px solid #e4e4e4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: white;
`

export const Row = styled.div`
  color: #919191;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`

export const Block = styled.div`
  background: #ffffff;
  border-radius: 3px;
  margin-top: 5px;
`

export const Header = styled.div`
  padding: 21px;
  padding-top: 6px;
`

export const Key = styled.span`
  text-align: left;
`

export const Value = styled.span`
  color: #152941;
  text-align: right;
`

export const Info = styled.div``

export const Unbind = styled.p`
  text-decoration-line: underline;
  color: #026cfe;
  margin-top: 30px;
`

export const ZPIcon = styled.img`
  height: 20px;
  padding-right: 7px;
`
