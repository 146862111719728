import React from 'react'
import Field from './Field'

export default function FormBuilder({ fields, form, data = {}, colProps, ...rest }) {
  return (
    <div {...rest}>
      {fields.map((field) => (
        <Field
          key={field.name}
          colProps={colProps}
          field={field}
          form={form}
          defaultValue={data[field.name]}
        />
      ))}
    </div>
  )
}
