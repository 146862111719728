import React, { Component } from 'react'
import {
  IconWrapper,
  Icon,
  ZPUnbindDialogWrapper,
  InfoWrapper,
  CancelButtonStyle,
  DoUnbindButtonStyle,
  ButtonWrapper
} from './style'
import Button from '@material-ui/core/Button'
import { ZALOPAY_UNBIND_ALERT } from '../../constants/Messages'

class ZPUnbindDialog extends Component {
  constructor(props) {
    super(props)
  }

  closeUnbindDialog = () => {
    this.props.onCloseDialog(false)
  }

  doUnbindAutodebit = () => {
    this.props.onUnbindAutodebit()
  }

  render() {
    return (
      <ZPUnbindDialogWrapper>
        <IconWrapper>
          <Icon src="/img/icons/unbind.svg" />
        </IconWrapper>
        <InfoWrapper>
          <strong>Hủy thanh toán tự động cho đơn hàng này?</strong>
          <p style={{ marginTop: '10px' }}>{ZALOPAY_UNBIND_ALERT}</p>
        </InfoWrapper>
        <ButtonWrapper>
          <Button onClick={this.closeUnbindDialog} style={CancelButtonStyle}>
            Bỏ qua
          </Button>
          <Button
            onClick={this.doUnbindAutodebit}
            disabled={this.props.loadingUnBindZaloPay}
            style={DoUnbindButtonStyle}
          >
            Huỷ
          </Button>
        </ButtonWrapper>
      </ZPUnbindDialogWrapper>
    )
  }
}

export default ZPUnbindDialog
