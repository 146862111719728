import React, { Component, Fragment } from 'react'

class NotFoundPage extends Component {
  render() {
    return (
      <Fragment>
        <h2>Page not found</h2>
      </Fragment>
    )
  }
}

export default NotFoundPage
