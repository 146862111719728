import React from 'react'
export default function LoadingIcon({ loading }) {
  return (
    <div
      className="diaglog-loading"
      style={{ height: '100vh', display: loading ? 'block' : 'none' }}
    >
      <div>
        <img src="/img/loading.svg" style={{ width: 100, height: 100 }} alt="" />
        <div></div>
        <div></div>
        <div></div>
        <h4 style={{ fontWeight: 'bold', color: 'white', marginTop: 20 }}>Vui lòng chờ</h4>
      </div>
    </div>
  )
}
