import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { TransactionDetailWrapper } from './styles'
import TransactionBodyDetail from './RechargeDetail/TransactionBodyDetail'
import { Block, Row } from './RechargeDetail/style'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { setSupportDialogVisibility } from '../../actions/home.action'
import SupportDialog from '../../components/SupportDialog/SupportDialog'

export default function TransactionDetail() {
  const dispatch = useDispatch()
  const location = useLocation()
  const row = location.state.row
  const isOpenSupportDialog = useSelector((state) => {
    return state.home.isOpenSupportDialog
  })
  const showSupportDialog = () => {
    dispatch(setSupportDialogVisibility(true))
  }
  const onOpenSupportDialog = () => {
    console.log('onOpenInstruction')
  }
  const onCloseSupportDialog = () => {
    dispatch(setSupportDialogVisibility(false))
  }

  return (
    <>
      <TransactionDetailWrapper>
        <TransactionBodyDetail row={row} />
        <Block>
          <Row>
            <span>
              <img src="/img/icons/tran_support.svg" style={{ paddingRight: '10px' }} alt="" />
              Yêu cầu hỗ trợ
            </span>
            <img src="/img/icons/tran_support_active.svg" onClick={showSupportDialog} alt="" />
          </Row>
        </Block>
        <SwipeableDrawer
          anchor="bottom"
          onOpen={onOpenSupportDialog}
          onClose={onCloseSupportDialog}
          open={isOpenSupportDialog}
          PaperProps={{ style: { borderRadius: '30px 30px 0px 0px' } }}
        >
          <SupportDialog onChangeStatus={(e) => dispatch(setSupportDialogVisibility(e))} />
        </SwipeableDrawer>
      </TransactionDetailWrapper>
    </>
  )
}
