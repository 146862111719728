import React, { Component, Fragment } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import MobileDetect from 'mobile-detect'
import {
  setTitle,
  removeBodyBg,
  friendlyDate,
  getMonthAndYear,
  simplifyFieldsName,
  checkNullOrUndefine
} from '../utils/helper'
import { setAuth } from '../actions/auth.action'
import { setNotification } from '../actions/common.action'
import ZPTextField from '../components/ZPTextField'
import ZPButton from '../components/ZPButton'
import DialogAlert from '../components/ZPDialog'
import './styles/loading.css'
import SuccessfullyRegisteredPopup from '../components/ZPDialog/SuccessfullyRegisteredPopup'
import CryptoJS from 'crypto-js'
import { REACT_APP_APP_CLIENT_KEY_SS0 } from '../constants/Env'
import { put } from '../utils/apiCallerV2'

const titleImageStyle = {
  textAlign: 'left',
  paddingLeft: '12px',
  color: '#b6b8b8',
  fontSize: '13px'
}

const imageLink = 'https://admin.unipass.vn'

class ConfirmInfoPriority extends Component {
  constructor(props) {
    super(props)
    setTitle('Xác nhận thông tin')
    removeBodyBg()

    this.state = {
      isDisabledContinuteButton: false,
      customerData: {},
      dialog: {
        open: false,
        id: 0,
        leftButtonTitle: '',
        rightButtonTitle: '',
        description: ''
      },
      showLoading: false,
      hasRegisteredSuccessfully: false
    }

    if (this.props.location.state.customerData !== null) {
      this.state.customerData = this.props.location.state.customerData
    }
  }

  componentWillMount = () => {
    let { customerData } = this.state
    const { userPriorityType } = customerData
    if (userPriorityType === '7') {
      customerData = {
        ...customerData,
        userType: '5',
        registration_type: '2',
        card_type: '7'
      }
      this.setState({
        customerData
      })
    }
    if (userPriorityType === '2') {
      customerData = {
        ...customerData,
        userType: '5',
        registration_type: '2'
      }
      this.setState({
        customerData
      })
    }
    if (userPriorityType === '3') {
      customerData = {
        ...customerData,
        userType: '6',
        registration_type: '2'
      }
      this.setState({
        customerData
      })
    }
    if (userPriorityType === '5') {
      customerData = {
        ...customerData,
        userType: '6',
        registration_type: '2'
      }
      this.setState({
        customerData
      })
    }
  }

  componentDidMount = () => {
    const { customerData } = this.state
    delete customerData.ignore_whitespace
    delete customerData.allow_display_name
    delete customerData.require_display_name
    delete customerData.allow_utf8_local_part
    delete customerData.require_tld
  }

  handleSubmit = (e) => {
    this.onOpenDialogTicketInfo()
    this.onCreateCustomer()
  }

  onOpenDialogTicketInfo = () => {
    this.setState({
      hasRegisteredSuccessfully: true
    })
  }

  onOpenDialogInfoFaild = (err) => {
    this.setState({
      dialog: {
        open: true,
        id: 1,
        leftButtonTitle: '',
        rightButtonTitle: 'Đã hiểu',
        description: err
      }
    })
  }

  onDialogRightConfirm = () => {
    this.setState({
      dialog: {
        open: false
      }
    })
    this.props.history.push({
      pathname: '/'
    })
  }

  onCreateCustomer = () => {
    const { customerData } = this.state
    this.setState({
      showLoading: true
    })
    let {
      customer_name,
      gender,
      birthday,
      email,
      address,
      userType,
      phone,
      userPriorityType,
      card_file,
      school_name,
      student_code,
      graduation_time,
      idpp,
      idpp_front_image,
      incident_certificate,
      idpp_after_image,
      certification_file,
      birth_certificate,
      student_card_image,
      registration_type
    } = customerData

    const ssoKey = REACT_APP_APP_CLIENT_KEY_SS0
    let reqTime = Date.now()
    phone = checkNullOrUndefine(phone)
    gender = checkNullOrUndefine(gender)
    customer_name = checkNullOrUndefine(customer_name)
    birthday = checkNullOrUndefine(birthday)
    email = checkNullOrUndefine(email)
    address = checkNullOrUndefine(address)
    userType = checkNullOrUndefine(userType)
    userPriorityType = checkNullOrUndefine(userPriorityType)
    card_file = checkNullOrUndefine(card_file)
    school_name = checkNullOrUndefine(school_name)
    student_code = checkNullOrUndefine(student_code)
    graduation_time = checkNullOrUndefine(graduation_time)
    idpp = checkNullOrUndefine(idpp)
    idpp_front_image = checkNullOrUndefine(idpp_front_image)
    incident_certificate = checkNullOrUndefine(incident_certificate)
    idpp_after_image = checkNullOrUndefine(idpp_after_image)
    certification_file = checkNullOrUndefine(certification_file)
    birth_certificate = checkNullOrUndefine(birth_certificate)
    student_card_image = checkNullOrUndefine(student_card_image)
    userType = checkNullOrUndefine(userType)
    registration_type = checkNullOrUndefine(registration_type)
    reqTime = checkNullOrUndefine(reqTime)
    // console.log("hash String :",`${phone}|${gender}|${customer_name}|${birthday}|${email}|${address}|${userType}|${userPriorityType}|${card_file}|${school_name}|${student_code}|${graduation_time}|${idpp}|${idpp_front_image}|${incident_certificate}|${idpp_after_image}|${certification_file}|${birth_certificate}|${student_card_image}|${userType}|${registration_type}|${reqTime}`)
    const clientSignature = CryptoJS.HmacSHA256(
      `${phone}|${gender}|${customer_name}|${birthday}|${email}|${address}|${userType}|${userPriorityType}|${card_file}|${school_name}|${student_code}|${graduation_time}|${idpp}|${idpp_front_image}|${incident_certificate}|${idpp_after_image}|${certification_file}|${birth_certificate}|${student_card_image}|${userType}|${registration_type}|${reqTime}`,
      ssoKey
    ).toString()
    const data = {
      ...this.props.auth.user,
      ...simplifyFieldsName(customerData),
      sso_sig: clientSignature,
      rq_t: reqTime,
      gender: Number(gender),
      student_code: student_code ? student_code : this.props.auth.user.student_code,
      app: true,
      card_type: Number(userPriorityType),
      avatar: card_file
    }
    put('v2/customer/update', data).then((res) => {
      this.setState({
        showLoading: false
      })
      if (res.error.code !== 1) {
        this.onOpenDialogInfoFaild(res.msg)
      } else {
        this.onOpenDialogTicketInfo()
      }
    })
  }

  render() {
    const { customerData, isDisabledContinuteButton, dialog, showLoading } = this.state

    const {
      customer_name,
      gender,
      birthday,
      email,
      address,
      // userType,
      phone,
      userPriorityType,
      // student
      school_name,
      student_code,
      graduation_time,
      student_card_image,
      // student_card_image_show,
      // priority
      card_file,
      // card_file_show,
      certification_file,
      // certification_file_show,
      //disable + old people
      idpp,
      idpp_front_image,
      // idpp_front_image_show,
      idpp_after_image,
      // idpp_after_image_show,
      incident_certificate,
      // incident_certificate_show,
      birth_certificate,
      // birth_certificate_show,
      account_name
    } = customerData
    const { open, leftButtonTitle, rightButtonTitle, description } = dialog

    let genderString
    if (gender === '0') {
      genderString = 'Nam'
    } else if (gender === '1') {
      genderString = 'Nữ'
    }

    let userTypeString
    let theStudentNumberLable
    let theStudentFileTitle
    if (userTypeString === '1') {
      userTypeString = 'Phổ thông'
    }
    if (userPriorityType === '7') {
      userTypeString = 'Học sinh'
      theStudentNumberLable = 'Mã số học sinh'
      theStudentFileTitle = 'Thẻ học sinh'
    }
    if (userPriorityType === '2') {
      userTypeString = 'Sinh viên'
      theStudentNumberLable = 'Mã số sinh viên'
      theStudentFileTitle = 'Thẻ sinh viên'
    }
    if (userPriorityType === '3') {
      userTypeString = 'Người cao tuổi'
    }
    if (userPriorityType === '5') {
      userTypeString = 'Người khuyết tật/thương binh'
    }

    const md = new MobileDetect(window.navigator.userAgent)
    let formattedBirthdayForPlatform
    if (typeof birthday !== 'undefined') {
      formattedBirthdayForPlatform = friendlyDate(birthday)
      if (!md.is('AndroidOS')) {
        const tmp = birthday.split(' ')[0]
        const date = tmp.split('-')
        formattedBirthdayForPlatform = date[2] + '/' + date[1] + '/' + date[0]
      }
    }

    let formattedGraduationTimeForPlatform
    if (typeof graduation_time !== 'undefined') {
      formattedGraduationTimeForPlatform = getMonthAndYear(graduation_time)
      if (!md.is('AndroidOS')) {
        const tmp = graduation_time.split(' ')[0]
        const date = tmp.split('-')
        formattedGraduationTimeForPlatform = date[1] + '/' + date[0]
      }
    }

    const scrollStyle = {
      overflow: 'scroll',
      height: '100vh'
    }

    const displayNone = {
      display: 'none'
    }

    let loadingStyle = {
      display: 'block'
    }
    let confirmStyle = {
      display: 'block'
    }

    if (showLoading) {
      confirmStyle = displayNone
    } else {
      loadingStyle = displayNone
    }
    return (
      <Fragment>
        {this.state.hasRegisteredSuccessfully && <SuccessfullyRegisteredPopup />}
        <div className="diaglog-loading" style={loadingStyle}>
          <div className="container-loading">
            <div></div>
            <div></div>
            <div></div>
            <p>Vui lòng chờ</p>
          </div>
        </div>
        <div style={{ ...confirmStyle, ...scrollStyle }}>
          <div style={{ paddingBottom: 85 }}>
            <FormControl fullWidth>
              <ZPTextField
                // style={{fontSize:"20px"}}
                label="Họ và tên"
                disabled="true"
                name="customer_name"
                value={customer_name}
              />
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField label="Giới tính" disabled="true" name="gender" value={genderString} />
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField
                label="Ngày sinh"
                name="birthday"
                onClick={this.onOpenDatePicker}
                value={formattedBirthdayForPlatform}
                readOnly={true}
                disabled="true"
              />
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField
                label="Số điện thoại"
                name="phone"
                disabled="true"
                value={account_name}
              />
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField label="Email" name="email" value={email} disabled="true" />
            </FormControl>
            {address && (
              <FormControl fullWidth>
                <ZPTextField label="Địa chỉ" name="address" value={address} disabled="true" />
              </FormControl>
            )}
            {/* className="last-block" */}
            <FormControl fullWidth>
              <ZPTextField
                label="Đối tượng"
                name="userType"
                value={userTypeString}
                disabled="true"
                // isLast={true}
              />
            </FormControl>
            {school_name && (userPriorityType === '7' || userPriorityType === '2') && (
              <FormControl fullWidth>
                <ZPTextField
                  label="Tên trường"
                  name="school_name"
                  value={school_name}
                  disabled="true"
                />
              </FormControl>
            )}
            {student_code && (userPriorityType === '7' || userPriorityType === '2') && (
              <FormControl fullWidth>
                <ZPTextField
                  label={theStudentNumberLable}
                  name="student_code"
                  value={student_code}
                  disabled="true"
                />
              </FormControl>
            )}
            {graduation_time && (userPriorityType === '7' || userPriorityType === '2') && (
              <FormControl fullWidth>
                <ZPTextField
                  label="Thời hạn tốt nghiệp"
                  name="graduation_time"
                  value={formattedGraduationTimeForPlatform}
                  readOnly={true}
                  disabled="true"
                />
              </FormControl>
            )}
            {idpp && (userPriorityType === '3' || userPriorityType === '5') && (
              <FormControl fullWidth>
                <ZPTextField
                  label="Số CMND/ Căn cước/ Hộ chiếu"
                  name="idpp"
                  value={idpp}
                  disabled="true"
                />
              </FormControl>
            )}
            {(idpp_front_image || this.props.auth.user.idpp_front_image) &&
              (userPriorityType === '3' || userPriorityType === '5') && (
                <div>
                  <p style={titleImageStyle}>Ảnh CMND/Căn cước/Hộ chiếu (mặt trước)</p>
                  <img
                    alt="idpp_front_image"
                    // src={`${imageLink +
                    //   (idpp_front_image
                    //     ? idpp_front_image
                    //     : this.props.auth.user.idpp_front_image)}`}
                    src={`${
                      idpp_front_image ? idpp_front_image : this.props.auth.user.idpp_front_image
                    }`}
                    style={{ width: 'calc(100% - 24px)' }}
                  />
                </div>
              )}
            {(idpp_after_image || this.props.auth.user.idpp_after_image) &&
              (userPriorityType === '3' || userPriorityType === '5') && (
                <div>
                  <p style={titleImageStyle}>Ảnh CMND/Căn cước/Hộ chiếu (mặt sau)</p>
                  <img
                    alt="idpp_after_image"
                    src={`${
                      imageLink +
                      (idpp_after_image ? idpp_after_image : this.props.auth.user.idpp_after_image)
                    }`}
                    style={{ width: 'calc(100% - 24px)' }}
                  />
                </div>
              )}
            {(incident_certificate || this.props.auth.user.incident_certificate) &&
              userPriorityType === '5' && (
                <div>
                  <p style={titleImageStyle}>Ảnh thẻ thương binh/khuyết tật</p>
                  <img
                    alt="incident_certificate"
                    // src={`${imageLink +
                    //   (incident_certificate
                    //     ? incident_certificate
                    //     : this.props.auth.user.incident_certificate)}`}
                    src={`${
                      incident_certificate
                        ? incident_certificate
                        : this.props.auth.user.incident_certificate
                    }`}
                    style={{ width: 'calc(100% - 24px)' }}
                  />
                </div>
              )}
            {(birth_certificate || this.props.auth.user.birth_certificate) &&
              userPriorityType === '3' && (
                <div>
                  <p style={titleImageStyle}>Giấy chứng nhận người cao tuổi</p>
                  <img
                    alt="birth_certificate"
                    // src={`${imageLink +
                    //   (birth_certificate
                    //     ? birth_certificate
                    //     : this.props.auth.user.birth_certificate)}`}
                    src={`${
                      birth_certificate ? birth_certificate : this.props.auth.user.birth_certificate
                    }`}
                    style={{ width: 'calc(100% - 24px)' }}
                  />
                </div>
              )}
            {(student_card_image || this.props.auth.user.student_card_image) &&
              (userPriorityType === '7' || userPriorityType === '2') && (
                <div>
                  <p style={titleImageStyle}>{theStudentFileTitle}</p>
                  <img
                    alt="student_card_image"
                    src={`${
                      student_card_image
                        ? student_card_image
                        : this.props.auth.user.student_card_image
                    }`}
                    // src={`${imageLink +
                    //   (student_card_image
                    //     ? student_card_image
                    //     : this.props.auth.user.student_card_image)}`}
                    style={{ width: 'calc(100% - 24px)' }}
                  />
                </div>
              )}
            {certification_file && certification_file !== 'null' && (
              <div>
                <p style={titleImageStyle}>Mẫu đơn đề nghị/chứng nhận </p>
                <img
                  alt="link to certification_file"
                  src={`${certification_file}`}
                  style={{ width: 'calc(100% - 24px)' }}
                />
              </div>
            )}
            {(card_file || this.props.auth.user.avatar) && (
              <div>
                <p style={titleImageStyle}>Ảnh làm thẻ</p>
                <img
                  alt="avatar"
                  src={`${card_file ? card_file : this.props.auth.user.avatar}`}
                  style={{ width: 'calc(100% - 24px)' }}
                />
              </div>
            )}
            <DialogAlert
              open={open}
              rightButtonTitle={rightButtonTitle}
              leftButtonTitle={leftButtonTitle}
              description={description}
              onRightConfirm={this.onDialogRightConfirm}
            />
          </div>
          <div
            className="bottom-fixed-button-wrapper"
            style={{ padding: '12px 0', backgroundColor: '#fff', zIndex: 1 }}
          >
            <ZPButton
              disabled={isDisabledContinuteButton}
              onClick={this.handleSubmit.bind(this)}
              fullWidth
              style={{ margin: 0 }}
            >
              Hoàn Thành
            </ZPButton>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    common: state.common
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setAuth: (user) => {
      dispatch(setAuth(user))
    },
    setNotification: (notification) => {
      dispatch(setNotification(notification))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmInfoPriority))
