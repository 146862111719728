import React from 'react'
export default function Loading({ loading }) {
  return (
    <div
      className="diaglog-loading"
      style={{ height: '100vh', display: loading ? 'block' : 'none' }}
    >
      <div className="container-loading">
        <div></div>
        <div></div>
        <div></div>
        <p>Vui lòng chờ</p>
      </div>
    </div>
  )
}
