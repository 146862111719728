import styled from 'styled-components'

export const ReminderItemWrapper = styled.div`
  background: white;
  padding: 10px 20px 10px;
  //margin-top: 15px;
  border-radius: 5px;
  text-align: left;
  //margin-top: 10px;
`

export const ReminderBody = styled.div`
  display: inline-flex;
`

export const ReminderIcon = styled.div`
  margin-right: 15px;
`

export const ReminderDescription = styled.p`
  color: #3f3f3f;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0.5rem;
`

export const ReminderAction = styled.p`
  margin: 0px;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #0071fe;
`
