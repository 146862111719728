import React from 'react'
import { color } from '../../utils/color'

const styles = {
  container: {
    backgroundColor: color.blue[200],
    borderRadius: 6,
    padding: 12,
    marginBottom: 48
  },
  dot: {
    color: color.blue[700],
    marginRight: 7,
    marginTop: -6,
    fontSize: 20
  },
  content: { fontSize: 15, color: color.black[1000] }
}
export default function Terms() {
  return (
    <div style={styles.container} className="d-flex flex-column text-left justify-content-start">
      <div className="ml-2 d-flex">
        <div style={styles.dot}> • </div>
        <div style={styles.content}>
          Thời gian nhận đăng ký hoàn tiền từ ngày 01 đến 20 hàng tháng.
        </div>
      </div>
      <div className="ml-2 d-flex align-items-start justify-content-start">
        <div style={styles.dot}> • </div>
        <div style={styles.content}>
          Thời gian nhận hoàn tiền vào ví ZaloPay dự kiến từ ngày 01 đến 05 của tháng kế tiếp.
        </div>
      </div>
      <div className="ml-2 d-flex align-items-start justify-content-start">
        <div style={styles.dot}> • </div>
        <div style={styles.content}>
          Đăng ký sau ngày 20 hàng tháng, sẽ nhận hoàn tiền vào ngày 01 đến 05 của tháng kế tiếp
          nữa.
        </div>
      </div>
    </div>
  )
}
