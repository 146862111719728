import axios from 'axios'
import { REACT_APP_LOCATION_SERVICE_URL as API_URL } from '../constants/Env'
import { threeMonthsInMiliSeconds } from '../constants/Variables'
import { getKeyForAllRoutes, getWithExpiry, setWithExpiry } from '../utils/helper'
import * as Types from './../constants/ActionType'

export const setListRouteToStore = (routes) => {
  return {
    type: Types.SET_ROUTES,
    routes
  }
}

export const getListRouteFromAPI = () => {
  const getAllRoutesFromLocalStorage = () => {
    const key = getKeyForAllRoutes()
    const routes = getWithExpiry(key)

    return routes
  }
  const routes = getAllRoutesFromLocalStorage()
  if (routes) {
    return setListRouteToStore(routes)
  }

  return (dispatch) => {
    return axios({
      method: 'GET',
      url: `${API_URL}/routes/`
    }).then((res) => {
      if (res.data.data) {
        const key = getKeyForAllRoutes()
        setWithExpiry(key, res.data.data, threeMonthsInMiliSeconds)
        dispatch(setListRouteToStore(res.data.data))
      }
    })
  }
}

export const setRoutesInStationMode = (routes) => {
  return (dispatch) => {
    dispatch({
      type: Types.SET_ROUTES_IN_STATION_MODE,
      routes
    })
  }
}

export const getSupportedRoutes = () => {
  return {
    type: Types.GET_SUPPORTED_ROUTES
  }
}

export const getSupportedRoutesDone = (routes) => {
  return {
    type: Types.GET_SUPPORTED_ROUTES_DONE,
    routes
  }
}
