import React from 'react'
import { connect } from 'react-redux'
import { HeaderWrapper, ImageWrapper, ReminderDialogWrapper, ReminderItemBorder } from './style'

import ReminderItem from '../ReminderItem'

const ReminderDialog = ({ onClose, notifications }) => {
  const onCloseDialog = () => {
    onClose(false)
  }

  const notificationUIs = () => {
    return notifications.map((notification, index) => (
      <ReminderItemBorder key={notification.id}>
        <ReminderItem
          iconLink={notification.iconLink}
          action={notification.action}
          actionText={notification.actionText}
          description={notification.description}
        />
      </ReminderItemBorder>
    ))
  }

  return (
    <ReminderDialogWrapper>
      <HeaderWrapper>
        <span>Nhắc nhở</span>
      </HeaderWrapper>
      <ImageWrapper background="#FF6660" onClick={onCloseDialog}>
        <img src="/img/icons/close.svg" />
      </ImageWrapper>
      {notificationUIs()}
    </ReminderDialogWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications
  }
}

export default connect(mapStateToProps, null)(ReminderDialog)
