import styled from 'styled-components'
import React from 'react'
import Avatar from '@material-ui/core/Avatar'

export const AvatarStyle = styled((props) => {
  const { hasBorder, ...rest } = props
  return <Avatar {...rest} />
})`
  && {
    margin: auto;
    width: 64px;
    height: 64px;
    background-color: transparent !important;
    border: ${(props) => (props.hasBorder ? '2px solid rgba(255, 255, 255, 0.6)' : 'none')};
  }
`
