import React, { Component, Fragment } from 'react'
import { Header, Phone, Inactive, Body } from './styles/inactive-account.style'
import { friendlyPhone, setBodyBg, removeBodyBg, setTitle } from '../utils/helper'
import { HOMEPAGE } from '../constants/Page'

class InactiveAccountPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: this.state.props.user.account_name
    }
    setBodyBg()
    setTitle(HOMEPAGE)
  }

  componentWillUnmount() {
    removeBodyBg()
  }

  render() {
    return (
      <Fragment>
        <Header>
          <Phone>Tài khoản {friendlyPhone(this.state.phone)}</Phone>
          <Inactive>Đang tạm khoá</Inactive>
        </Header>
        <Body>
          <img src="/images/inactive_account.jpg" alt="unipass-inactive-account" />
          <p>Tài khoản của bạn đang tạm khoá</p>
          <p>
            Vui lòng liên hệ <strong>1900 54 54 36</strong> để được hỗ trợ
          </p>
        </Body>
      </Fragment>
    )
  }
}

export default InactiveAccountPage
