import styled from 'styled-components'
export const Container = styled.div`
  padding: 10px 15px 40px;
  background: #e5e5e5;
  height: 100vh;
  overflow: auto;
`

export const Wrapper = styled.div`
  background: #ffffff;
  margin-top: 5px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 6px;
`
export const WrapperText = styled.div`
  padding: 10px 0 10px;
`

export const Text = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #000000;
`
export const ContentText = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #99a2ad;
  ${(props) => (props.isMargin ? 'margin-top: 20px;' : 'margin-top: 0px')}
`
export const ErrorText = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-top: 20px;
`
export const LinkImage = styled.img`
  width: auto;
  height: 100%;
  ${(props) => (props.isMargin ? 'margin-top: 30px;' : 'margin: 10px')}
`
