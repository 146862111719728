import React, { Component, Fragment } from 'react'
import PaymentFriendPage from './PaymentFriendPage'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { AppBar } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {
  RechargeOptionPageWrapper,
  selectedTab,
  rechargeTab,
  initialTab,
  TabContentWrapper,
  TabWrapper
} from './styles/recharge-option-style'
import RechargeOnlineTermContainer from '../containers/RechargeOnlineTerm/RechargeOnlineTermContainer'
import RechargeCounterContainer from '../containers/RechargeCounter/RechargeCounterContainer'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <TabContentWrapper
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </TabContentWrapper>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class RechargeOptionPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTab: 0
    }
  }

  onSelectedRechargeTab = (event, newValue) => {
    this.setState({
      selectedTab: newValue
    })
  }

  render() {
    return (
      <RechargeOptionPageWrapper>
        <TabWrapper>
          <AppBar color={'default'} position={'static'}>
            <Tabs
              style={rechargeTab}
              value={this.state.selectedTab}
              variant="fullWidth"
              indicatorColor={'primary'}
              onChange={this.onSelectedRechargeTab}
            >
              <Tab
                label="Nạp trực tuyến"
                {...a11yProps(0)}
                style={this.state.selectedTab === 0 ? selectedTab : initialTab}
              />
              <Tab
                label="Nạp tại quầy"
                {...a11yProps(1)}
                style={this.state.selectedTab === 1 ? selectedTab : initialTab}
              />
            </Tabs>
          </AppBar>
        </TabWrapper>

        <TabPanel index={0} value={this.state.selectedTab}>
          <Fragment>
            <PaymentFriendPage />
          </Fragment>
          <Fragment>
            <RechargeOnlineTermContainer />
          </Fragment>
        </TabPanel>
        <TabPanel index={1} value={this.state.selectedTab}>
          <Fragment>
            <RechargeCounterContainer />
          </Fragment>
        </TabPanel>
      </RechargeOptionPageWrapper>
    )
  }
}

export default RechargeOptionPage
