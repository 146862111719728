import * as Types from './../constants/ActionType'

const initialState = 1

const instructionStep = (state = initialState, action) => {
  switch (action.type) {
    case Types.JUMP_TO_STEP:
      return action.step
    default:
      return state
  }
}

export const shouldTheClientSeeInstuction = (state = false, action) => {
  switch (action.type) {
    case Types.SET_IF_THE_CLIENT_SHOULD_SEE_INSTRUCTION:
      return action.shouldSeeInstruction
    default:
      return state
  }
}

export default instructionStep
