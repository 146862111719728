import React from 'react'
import { withRouter } from 'react-router-dom'

const SuccessfullyRegisteredPopup = (props) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: '1000'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          opacity: '0.8',
          position: 'fixed',
          zIndex: '1000'
        }}
      ></div>
      <span
        style={{
          display: 'inline-block',
          height: '100%',
          verticalAlign: 'middle'
        }}
      />
      <img
        alt="SuccessfullyRegistered.png"
        onClick={() => props.history.push('/card/management')}
        style={{ width: '94%', position: 'relative', zIndex: '1000' }}
        src="/images/SuccessfullyRegistered.png"
      />
      <div>above the image</div>
    </div>
  )
}

export default withRouter(SuccessfullyRegisteredPopup)
