import styled from 'styled-components'
import Button from '@material-ui/core/Button'

export const ButtonCus = styled(Button)`
  && {
    padding: 13px 0;
    font-size: 18px;
    color: #006dff;
    font-weight: bold;
    margin: 40px auto 0 auto;
    width: calc(100% - 20px);
    background: #e8f2ff;
    &[disabled] {
      background: #e5e5e5;
      color: #7a7c8b;
    }
  }
`
