import React from 'react'
import Avatar from '../../../components/AvatarCustom'
import Button from '@material-ui/core/Button'
import { RWebShare } from 'react-web-share'
import { color } from '../../../utils/color'
const styles = {
  button: {
    margin: '10px 0px',
    backgroundColor: '#CFE1F4',
    color: color.blue[500],
    fontSize: 14,
    padding: '10px 10px',
    boxShadow: 'none'
  }
}

export default function NoTransactionItem({ item, countItem = 0, index }) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <Avatar name={item.customerName} url={item.customerAvatar} />
      <div
        className="d-flex justify-content-between flex-row align-items-center"
        style={{
          width: '83%',
          borderBottom: countItem === 1 || index === countItem ? 'none' : '0.5px solid #E6E6E6'
        }}
      >
        <div className="d-flex flex-column align-items-start">
          <div style={{ fontSize: 15, color: color.black[600] }}>{item.customerName}</div>
          <div style={{ fontSize: 14, color: '#818C99' }}>{item.customerPhone}</div>
        </div>
        <RWebShare
          data={{
            text: 'HD sử dụng UniPass',
            url: 'https://rd.zapps.vn/detail/3820907403981754700?id=074f1a34aa71432f1a60&pageId=3820907403981754700',
            title: 'UniPass'
          }}
          onClick={() => {
            console.log('success')
            // navigator.clipboard.writeText(
            //   `https://rd.zapps.vn/detail/3820907403981754700?id=074f1a34aa71432f1a60&pageId=3820907403981754700`
            // );
          }}
        >
          <Button variant="contained" style={styles.button} onClick={() => {}}>
            Nhắc nhở
          </Button>
        </RWebShare>
      </div>
    </div>
  )
}
