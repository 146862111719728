import React from 'react'
import { Checkbox, CheckboxChecked, CheckboxSmallChecked } from './style'

const CheckboxCircle = (props) => {
  const { checked, small } = props

  if (checked) {
    return (
      // <span className="icon-radio"></span>
      small ? (
        <CheckboxSmallChecked className="icon-radio white" />
      ) : (
        <CheckboxChecked className="icon-radio white" />
      )
    )
  }

  return <Checkbox {...props} />
}

export default CheckboxCircle
