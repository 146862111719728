import React from 'react'

const RenderItem = ({ name }) => {
  return (
    <div className="d-flex align-items-start justify-content-start" style={{ margin: 28 }}>
      <img alt="" src="/img/promotion/check-blue.svg" className="mr-2" style={{ marginTop: 1.5 }} />
      <span style={{ fontSize: 16, color: '#7A7C8B', textAlign: 'start' }}>{name}</span>
    </div>
  )
}
export default function TermBinding() {
  return (
    <div>
      <img className="w-100" alt="" src="/img/promotion/background-binding.svg" />
      <RenderItem name={'Thêm ZaloPay làm phương thức thanh toán'} />
      <RenderItem name={'Thanh toán tự động bằng số dư ví ZaloPay'} />
      <RenderItem name={'Chỉ cần liên kết 1 lần duy nhất'} />
      <RenderItem name={'Có thể dùng dịch vụ khác sau khi nạp tiền vào ví ZaloPay'} />
    </div>
  )
}
