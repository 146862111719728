export const cities = [
  { 2: 'Hồ Chí Minh' }, // TP
  { 1: 'Hà Nội' }, // TP
  { 3: 'Yên Bái' },
  { 4: 'Vĩnh Phúc' },
  { 5: 'Vĩnh Long' },
  { 6: 'Tuyên Quang' },
  { 7: 'Trà Vinh' },
  { 8: 'Tiền Giang' },
  { 9: 'Thừa Thiên Huế' },
  { 10: 'Thanh Hóa' },
  { 11: 'Thái Nguyên' },
  { 12: 'Thái Bình' },
  { 13: 'Tây Ninh' },
  { 14: 'Sơn La' },
  { 15: 'Sóc Trăng' },
  { 16: 'Quảng Trị' },
  { 17: 'Quảng Ninh' },
  { 18: 'Quảng Ngãi' },
  { 19: 'Quảng Nam' },
  { 20: 'Quảng Bình' },
  { 21: 'Phú Yên' },
  { 22: 'Phú Thọ' },
  { 23: 'Ninh Thuận' },
  { 24: 'Ninh Bình' },
  { 25: 'Nghệ An' },
  { 26: 'Nam Định' },
  { 27: 'Long An' },
  { 28: 'Lâm Đồng' },
  { 29: 'Lào Cai' },
  { 30: 'Lạng Sơn' },
  { 31: 'Lai Châu' },
  { 32: 'Kon Tum' },
  { 33: 'Kiên Giang' },
  { 34: 'Khánh Hòa' },
  { 35: 'Hưng Yên' },
  { 36: 'Hòa Bình' },
  { 37: 'Hậu Giang' },
  { 38: 'Hải Phòng' }, // TP
  { 39: 'Hải Dương' },
  { 40: 'Hà Tĩnh' },
  { 42: 'Hà Nam' },
  { 43: 'Hà Giang' },
  { 44: 'Gia Lai' },
  { 45: 'Đồng Tháp' },
  { 46: 'Đồng Nai' },
  { 47: 'Điện Biên' },
  { 48: 'Đắk Nông' },
  { 49: 'DakLak' },
  { 50: 'Đà Nẵng' }, // TP
  { 51: 'Cần Thơ' }, // TP
  { 52: 'Cà Mau' },
  { 53: 'Cao Bằng' },
  { 54: 'Bến Tre' },
  { 55: 'Bắc Ninh' },
  { 56: 'Bắc Kạn' },
  { 57: 'Bắc Giang' },
  { 58: 'Bạc Liêu' },
  { 59: 'Bình Ðịnh' },
  { 60: 'Bình Thuận' },
  { 61: 'Bình Phước' },
  { 62: 'Bình Dương' },
  { 63: 'Bà Rịa - Vũng Tàu' },
  { 64: 'An Giang' }
]
