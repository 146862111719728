import styled from 'styled-components'

export const ZPUnbindAlertWrapper = styled.div`
  background: red;
`

export const ZPAlertStyle = {
  textAlign: 'center',
  background: '#152B43',
  color: 'white',
  borderRadius: '5px',
  paddingTop: '10px'
}

export const AlertText = styled.p`
  margin-top: 10px;
`

export const AlertIcon = styled.img``
