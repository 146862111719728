import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import { useSelector, useDispatch } from 'react-redux'
import { Copy } from 'react-feather'
import TextField from '../../components/TextField'
import {
  checkPhone,
  checkPhoneDone,
  checkShowReferral,
  inviteFriend
} from '../../actions/invite.action'
import { validatePhone } from '../../constants/validation'
import { setTitle } from '../../utils/helper'
import { color } from '../../utils/color'

const styles = {
  container: {
    backgroundColor: '#F8F8F8',
    width: '100vw',
    height: '100vh',
    overflowY: 'auto'
  },
  itemWrapper: {
    backgroundColor: 'white',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
    margin: 16,
    borderRadius: 10,
    padding: 12
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'SFDisplay-Semibold',
    color: color.black[1000]
  },
  description: {
    textAlign: 'left',
    marginTop: 6,
    color: color.black[400],
    fontSize: 14
  },
  errorMessage: { color: '#FF4646' },
  successMessage: { color: '#19B258' }
}

const InviteCode = ({ copyContent, phone }) => {
  return (
    <div style={styles.itemWrapper} className="d-flex flex-column align-items-start">
      <div style={styles.title}>Mã giới thiệu của bạn</div>
      <div
        style={{
          backgroundColor: '#F2F2F2',
          width: '100%',
          borderRadius: 10
        }}
        className="d-flex justify-content-around p-2 mt-2"
      >
        <div className="d-flex" style={{ width: '70%' }}>
          <Copy size={20} color={color.black[300]} />
          <div className="ml-3" style={{ ...styles.title, color: '#4E5966' }}>
            {phone}
          </div>
        </div>
        <div style={{ color: color.blue[500], fontStyle: 'italic' }} onClick={copyContent}>
          Sao chép
        </div>
      </div>
      <div style={{ ...styles.description, marginTop: 16 }}>
        Hãy giới thiệu cho bạn bè của bạn về UniPass. Để cùng nhau trải nghiệm phương tiện công cộng
        với hình thức thanh toán nhanh - không tiền mặt.
      </div>
      <div style={{ ...styles.title, fontSize: 18, marginTop: 16 }}>Phần thưởng</div>
      <div style={{ ...styles.description, marginTop: 6 }}>
        Bạn được tặng <span className="text-bold"> 10</span> mã quà tặng tương đương mỗi mã{' '}
        <span className="text-bold"> 1.000đ</span> cho 10 chuyến đi xe buýt.
      </div>
    </div>
  )
}

const InputCode = ({ value, onChange, checkPhone, onInvite, entered }) => {
  const { message, error } = checkPhone
  const disabled = useMemo(() => {
    if (message && !error) return false
    else return true
  }, [error, message])
  return (
    <div style={styles.itemWrapper} className="d-flex flex-column align-items-start">
      <div style={styles.title}>Nhập mã giới thiệu</div>
      {entered ? (
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <img alt="" src="/img/promotion/invite-success.svg" className="mt-2" />
          <div style={{ fontSize: 24 }}>Bạn đã nhập mã thành công!</div>
        </div>
      ) : (
        <>
          <div style={styles.description}>
            Nhập mã giới thiệu để nhận thưởng. Chỉ áp dụng cho người dùng mới chưa phát sinh giao
            dịch đi xe buýt.
          </div>
          <div style={{ ...styles.description, marginTop: 6 }}>
            Ngoài ra, bạn vẫn có thể trải nghiệm UniPass bình thường dù có mã giới thiệu hay không.
          </div>
          <div className="d-flex align-items-center justify-content-between w-100 mt-2">
            <div className="w-75">
              <TextField
                error={error}
                placeholder={'Vui lòng nhập mã tại đây'}
                label={'Mã giới thiệu'}
                variant="outlined"
                onChange={onChange}
                value={value}
                fullWidth
                type="number"
                showCheck={message && !error}
                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                isPhone={true}
              />
            </div>
            <Button
              variant="contained"
              disabled={disabled}
              onClick={onInvite}
              style={{
                ...styles.title,
                height: 56,
                backgroundColor: disabled ? '#E6E6E6' : '#006DFE',
                color: disabled ? '#99A2AD' : 'white',
                borderRadius: 4,
                width: '23%'
              }}
            >
              Nhận
            </Button>
          </div>
          {message && (
            <div className="mt-2" style={error ? styles.errorMessage : styles.successMessage}>
              {message}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default function Introduce() {
  setTitle('Giới thiệu')
  const dispatch = useDispatch()
  const {
    referral: { referralCode, entered, newCustomer },
    checkPhone: checkPhoneData
  } = useSelector((state) => state.invite)

  const [openToast, setOpenToast] = useState(false)
  const [value, setValue] = useState('')

  const copyContent = () => {
    navigator.clipboard.writeText(referralCode)
    setOpenToast(true)
  }

  const onChange = (e) => {
    dispatch(checkPhoneDone({ error: false, message: null }))
    setValue(typeof e === 'object' ? e.target.value : e)
  }

  useLayoutEffect(() => {
    dispatch(checkShowReferral())
  }, [])

  useEffect(() => {
    if (value.length === 10 && validatePhone.reg.test(value)) {
      dispatch(checkPhone(value))
    }
  }, [value])

  const onInvite = () => {
    dispatch(inviteFriend({ phone: value }))
  }
  return (
    <div style={styles.container}>
      <InviteCode copyContent={copyContent} phone={referralCode} />
      {newCustomer && (
        <InputCode
          onChange={onChange}
          value={value}
          checkPhone={checkPhoneData}
          onInvite={onInvite}
          entered={entered}
        />
      )}
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        onClose={() => {
          setOpenToast(false)
        }}
        message="Đã sao chép mã giới thiệu"
      />
    </div>
  )
}
