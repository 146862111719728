import { merge } from 'lodash'
import { useCallback, useState } from 'react'
import { axiosClient, config } from '../utils/apiCallerV2'

export default function useFetch(api = {}, opts = {}) {
  const { url, method } = api
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  const fetch = useCallback(
    async (fetchOpts = {}) => {
      setLoading(true)
      setData(null)
      const {
        data,
        headers,
        onSuccess = (dt) => {},
        onError = (code, message) => {}
      } = merge(opts, fetchOpts)
      const fetchConfig = config({ method, url, data, headers })
      try {
        const response = await axiosClient(fetchConfig)
        if (response?.error?.code === 1) {
          onSuccess(response?.data)
          setData(response?.data)
        } else {
          onError(response?.error?.code, response?.error?.messageVN)
        }
      } catch (error) {
        console.log('error', error)
        onError(error?.response?.status, error?.response?.data)
      } finally {
        setLoading(false)
      }
    },
    [url, method, opts]
  )

  return [
    fetch,
    {
      loading,
      data
    }
  ]
}
