import { call, put, takeLatest } from '@redux-saga/core/effects'
import * as actionTypes from '../constants/ActionType'
import {
  getListGift,
  getListGiftDetail,
  getListCampaignDetail,
  getListCampaign
} from '../services/promotion.services'
import {
  getListGiftFail,
  getListGiftSuccess,
  getGiftDetailSuccess,
  getGiftDetailFail,
  getCampaignDetailSuccess,
  getCampaignDetailFail,
  getListCampaignSuccess,
  getListCampaignFail
} from '../actions/promotion.action'
import { countDay, formatDateVN, formatDateWithoutTime } from '../utils/helper'

function* handleGetListGift() {
  try {
    const res = yield call(getListGift)
    if (res && res.error && res.error.code === 1) {
      const formatData = res.data.map((item) => {
        const remainingTime = countDay(item.expired, true)
        item.remainingVoucher = item.quantity - item.used_quantity
        item.remainingTime = remainingTime
        item.remainingTimeMessasge =
          remainingTime && remainingTime > 24
            ? `Còn ${Math.floor(remainingTime / 24)} ngày`
            : remainingTime === 0
            ? `còn ${remainingTime + 1} giờ`
            : `còn ${remainingTime} giờ`
        return item
      })
      yield put(getListGiftSuccess(formatData))
    } else {
      yield put(getListGiftFail())
    }
  } catch (e) {
    console.log('error: ', e)
  }
}

function* handleGetListCampaign() {
  try {
    const res = yield call(getListCampaign)
    if (res && res.error && res.error.code === 1) {
      const formatData = res.data.map((i) => {
        const remainingTime = countDay(i.expired, false)
        i.remainingTime = remainingTime
        i.remainingTimeMessasge =
          remainingTime && remainingTime > 30
            ? `${Math.floor(remainingTime / 30)} tháng nữa sẽ kết thúc`
            : remainingTime === 0
            ? `${remainingTime + 1} ngày nữa sẽ kết thúc`
            : `${remainingTime} ngày nữa sẽ kết thúc`
        return i
      })
      yield put(getListCampaignSuccess(formatData))
    } else {
      yield put(getListCampaignFail())
    }
  } catch (e) {
    console.log('error: ', e)
  }
}

function* handleGetListGiftDetail(payload) {
  const { id } = payload
  try {
    const res = yield call(getListGiftDetail, id)
    if (res && res.error && res.error.code === 1) {
      const formatData = {
        ...res.data,
        eventTime: formatDateWithoutTime(res.data.expired)
      }
      yield put(getGiftDetailSuccess(formatData))
    } else {
      yield put(getGiftDetailFail())
    }
  } catch (e) {
    yield put(getGiftDetailFail())
    console.log('error: ', e)
  }
}

function* handleGetListCampaignDetail(payload) {
  const { id } = payload
  try {
    const res = yield call(getListCampaignDetail, id)
    if (res && res.error && res.error.code === 1) {
      const formatData = {
        ...res.data,
        eventTime: `Từ ${formatDateVN(res.data.started)} - ${formatDateVN(res.data.expired)}`
      }
      yield put(getCampaignDetailSuccess(formatData))
    } else {
      yield put(getCampaignDetailFail())
    }
  } catch (e) {
    yield put(getCampaignDetailFail())
    console.log('error: ', e)
  }
}
export default function* promotionSaga() {
  yield takeLatest(actionTypes.PROMOTION.GET_LIST_GIFT, handleGetListGift)
  yield takeLatest(actionTypes.PROMOTION.GET_GIFT_DETAIL, handleGetListGiftDetail)
  yield takeLatest(actionTypes.PROMOTION.GET_CAMPAIGN_DETAIL, handleGetListCampaignDetail)
  yield takeLatest(actionTypes.PROMOTION.GET_LIST_CAMPAIGN, handleGetListCampaign)
}
