import _isEmpty from 'lodash/isEmpty'

const owner = {
  type: 'input',
  name: 'phone',
  label: 'Tài khoản',
  placeholder: 'Nhập số điện thoại bạn muốn nạp hộ',
  require: true,
  props: {
    type: 'number',
    onKeyDown: (evt) => evt.key === 'e' && evt.preventDefault(),
    isPhone: true,
    inputProps: { autoFocus: true }
  }
}

const note = {
  type: 'input',
  name: 'customer_name',
  label: 'Thuộc về',
  placeholder: 'Họ và tên',
  props: {
    disabled: true
  }
}

export default { owner, note }
