import { createSelector } from 'reselect'
import { userSelector } from './user.selector'

export const zaloPayEfficentFundSelector = (state) => state.home.payable

export const balanceSelector = (state) => state.home.balance

export const monthlyTicketSelector = (state) => state.home.monthlyTicket

export const unipassEfficientFundSelector = createSelector(
  userSelector,
  balanceSelector,
  monthlyTicketSelector,
  (user, balance, monthlyTicket) => {
    if (monthlyTicket) {
      return true
    }
    if (balance == null) {
      return false
    }
    let isUnipassBalanceEfficient = false
    if (user.customer_type === 4 && balance >= 5000) {
      isUnipassBalanceEfficient = true
    } else if (user.customer_type === 5 && balance >= 2000) {
      isUnipassBalanceEfficient = true
    } else if (user.customer_type === 6 && balance >= 0) {
      isUnipassBalanceEfficient = true
    }
    return false
    // return isUnipassBalanceEfficient;
  }
)

export const efficientFundChecker = createSelector(
  zaloPayEfficentFundSelector,
  unipassEfficientFundSelector,
  (isZaloPayBalanceEfficient, isUnipassBalanceEfficient) => {
    return isZaloPayBalanceEfficient || isUnipassBalanceEfficient
  }
)

export const QRVisibilitySelector = createSelector(
  unipassEfficientFundSelector,
  zaloPayEfficentFundSelector,
  (isUnipassBalanceEfficient, isZaloPayBalanceEfficient) => {
    let isQRVisible = true
    if (!isUnipassBalanceEfficient) {
      if (!isZaloPayBalanceEfficient) {
        isQRVisible = false
      }
    }

    return isQRVisible
  }
)
