import { Button } from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'

const styles = {
  container: {
    paddingTop: 30
  },
  title: {
    fontSize: 18,
    marginTop: 30,
    marginBottom: 10,
    color: 'black'
  },
  content: {
    fontSize: 15,
    fontWeight: 400,
    color: 'black'
  }
}

export default function Result({ errorData }) {
  const history = useHistory()
  const onGoHome = () => {
    history.replace('/')
  }
  const { content, title, image, isSuccess } = useMemo(() => {
    if (
      errorData &&
      (errorData.code === 105 ||
        errorData.code === 106 ||
        errorData.code === 113 ||
        errorData.code === 114)
    ) {
      return {
        title: 'ĐĂNG NHẬP KHÔNG THÀNH CÔNG',
        image: 'img/otp-phone-verification/phone-error.svg',
        content: (
          <span>
            {errorData.messageVN}
            <br />
            <br /> Mọi thắc mắc vui lòng liên hệ tổng đài
            <br />
            <a href="tel:1900633314"> 1900 633 314 </a>
            để được hỗ trợ
          </span>
        )
      }
    } else if (errorData && errorData.code === 112) {
      return {
        title: 'ĐĂNG NHẬP KHÔNG THÀNH CÔNG',
        image: 'img/otp-phone-verification/phone-error.svg',
        content: (
          <span>
            Đã hết số lần gửi OTP
            <br />
            <br /> Mọi thắc mắc vui lòng liên hệ tổng đài
            <br />
            <a href="tel:1900633314"> 1900 633 314 </a>
            để được hỗ trợ
          </span>
        )
      }
    }
    return {
      title: 'ĐĂNG NHẬP THÀNH CÔNG',
      image: 'img/otp-phone-verification/phone-error.svg',
      content: <span>Vui lòng chờ trong giây lát.</span>,
      isSuccess: true
    }
  }, [errorData])

  useEffect(() => {
    let timeRemaining = 5
    const timerRemaining = setInterval(() => {
      timeRemaining -= 1
      if (timeRemaining === 0 && isSuccess) {
        history.replace('/')
      }
    }, 1000)
    return () => {
      clearInterval(timerRemaining)
    }
  }, [isSuccess])

  return (
    <div style={styles.container}>
      <img alt="phone-unipass" src={image} />
      <div className="text-bold" style={styles.title}>
        {title}
      </div>
      <span style={styles.content}>{content}</span>

      {!isSuccess && (
        <div className="bottom-div">
          <Button variant="contained" fullWidth onClick={onGoHome} className="button-v2">
            Quay lại màn hình đăng nhập
          </Button>
        </div>
      )}
    </div>
  )
}
