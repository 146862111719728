import styled from 'styled-components'

export const IconWrapper = styled.div`
  flex: 1;
  > img {
    height: 35px;
    padding: 3px 0px;
  }

  > p {
    font-size: 13px;
    color: #3f3f3f;
    margin: 0;
  }
`

export const SideBarWrapper = styled.div`
  margin-top: 15px;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px 10px;
`
