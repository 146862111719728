import React, { Component, Fragment } from 'react'
import { Grid, Divider } from '@material-ui/core'
import {
  MonthLyTicketLinkPageWrapper,
  Text,
  ItemWrapper
} from './styles/monthly-ticket-create.style'
import ButtonMonthlyTicket from '../components/ButtonMonthlyTicket'
import { withRouter } from 'react-router-dom'
import { post } from '../utils/apiCallerV2'
import { formatMoney } from '../utils/helper'
import { connect } from 'react-redux'
import DialogAlert from '../components/ZPDialog'
import { clearData } from '../actions/monthlyTicket.action'
import { initializeBindingData } from '../actions/autodebit.action'
import Loading from '../components/loading'
class MonthlyTickekOrderPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: null,
      amount: 0,
      checkPayable: false,
      dialogNotice: {
        open: false,
        id: 0,
        leftButtonTitle: '',
        rightButtonTitle: '',
        description: null
      },
      isLinked: 0,
      showLoading: false
    }
    if (this.props.location.state.data !== null) {
      this.state.data = this.props.location.state.data
      this.state.amount = this.props.location.state.amount
    }
  }

  onCreateOrder = async () => {
    const { amount } = this.state
    const { quantity } = this.props
    const { account_name } = this.props.auth.user
    const data = {
      phone: account_name,
      amount: amount,
      items: quantity
    }
    this.setState({
      showLoading: true
    })
    let isPaymentSucess = true
    let error = {}
    const res = await post(`v2/monthly-ticket/order`, data)
    if (res.error.code !== 1) {
      error = res.error
      isPaymentSucess = false
    }
    this.setState({
      showLoading: false
    })
    this.props.clearData()
    this.props.history.replace({
      pathname: '/monthly_ticket/payment-done',
      state: {
        isPaymentSucess,
        error,
        amount
      }
    })
  }

  componentDidMount() {
    this.handleCheckBinding()
  }
  async handleCheckBinding() {
    if (this.props.isBinding) {
      this.setState({ isLinked: 1 })
      clearInterval(this.interval)
      this.onCheckPayable()
    } else {
      this.setState({
        dialogNotice: {
          open: true,
          id: 2, //payable
          leftButtonTitle: 'Để sau',
          rightButtonTitle: 'Liên kết',
          description: (
            <>
              <div>Bạn chưa liên kết ví.</div>
              <div>
                Vui lòng <span style={{ fontWeight: 'bold' }}> Liên kết </span> để tiến hành giao
                dịch
              </div>
            </>
          )
        }
      })
    }
  }

  onCheckPayable = async () => {
    const { amount } = this.state
    const data = {
      amount: amount
    }
    const res = await post(`v2/monthly-ticket/pay-able`, data)
    if (res.error.code !== 1 || !res.data) {
      this.setState({
        dialogNotice: {
          open: true,
          id: 3,
          rightButtonTitle: 'Đóng',
          description: res.error.messageVN
        }
      })
      return
    } else if (!res.data.payable) {
      this.setState({
        dialogNotice: {
          open: !res.data.status,
          id: 1, //payable
          leftButtonTitle: 'Để sau',
          rightButtonTitle: 'Nạp tiền',
          description: (
            <>
              <div>Số dư tài khoản của bạn không đủ tiền.</div>
              <div>
                Vui lòng <span style={{ fontWeight: 'bold' }}> Nạp tiền </span> để tiến hành giao
                dịch
              </div>
            </>
          )
        }
      })
    }
    this.setState({
      checkPayable: res.data.payable
    })
  }

  onCancel = () => {
    this.setState({
      dialogNotice: {
        open: false,
        id: 0,
        leftButtonTitle: '',
        rightButtonTitle: '',
        description: ''
      }
    })
  }
  onDialogRightConfirm = () => {
    const { dialogNotice } = this.state
    if (dialogNotice.id === 1) {
      window.location.replace('https://socialdev.zalopay.vn/spa/wallet/topup')
    } else if (dialogNotice.id === 2) {
      this.linkZaloPay()
    }
    this.setState({
      dialogNotice: {
        open: false,
        id: 0,
        leftButtonTitle: '',
        rightButtonTitle: '',
        description: ''
      }
    })
  }

  linkZaloPay = async () => {
    this.props.initializeBindingData()
  }
  intervalCheckBinding = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.interval = setInterval(() => {
      this.handleCheckBinding()
    }, 2000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  renderItem = (item, key) => {
    const route = () => {
      return item.all ? 'Tất cả' : item.routeNumbers.join(', ')
    }
    const { quantity } = this.props
    const pricePerTicket = item.price / item.tickets_in_batch || 0
    const numberChoise = quantity[item.monthly_ticket_id] || 0
    return (
      <Fragment key={key}>
        <ItemWrapper>
          <Grid item xs={12} md={12} style={{ width: 'auto' }} container direction={'row'}>
            <Grid item xs={6} md={6} style={{ width: 'auto' }}>
              <Text className="name">{item.monthly_ticket_name}</Text>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              style={{ width: 'auto', justifyContent: 'flex-end' }}
              container
            >
              <Text className="name">{`${formatMoney(item.price)}đ`}</Text>
            </Grid>
          </Grid>
        </ItemWrapper>
        <ItemWrapper>
          <Grid item xs={12} md={12} style={{ width: 'auto' }} container direction={'row'}>
            <Grid item xs={7} md={7} style={{ width: 'auto' }}>
              <Text className="route">{`Tuyến: ${route()}`}</Text>
            </Grid>
            <Grid
              item
              xs={5}
              md={5}
              style={{ width: 'auto', justifyContent: 'flex-end' }}
              container
            >
              <Text className="number">{`${item.tickets_in_batch} vé - ${formatMoney(
                pricePerTicket
              )}đ/Lượt`}</Text>
            </Grid>
          </Grid>
        </ItemWrapper>
        <ItemWrapper>
          <Grid
            item
            xs={12}
            md={12}
            style={{ width: 'auto' }}
            container
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Text className="number">Số lượng</Text>
            <Text className="number">{`x${numberChoise}`}</Text>
          </Grid>
        </ItemWrapper>
        <Divider style={{ marginTop: 20 }} />
      </Fragment>
    )
  }

  render() {
    const { data, showLoading, amount, checkPayable, dialogNotice } = this.state
    const { open, rightButtonTitle, description, privatePolicy, leftButtonTitle } = dialogNotice

    return (
      <MonthLyTicketLinkPageWrapper>
        <Loading loading={showLoading} />
        {data.map(this.renderItem)}
        <ItemWrapper>
          <Grid
            item
            xs={12}
            md={12}
            style={{ width: 'auto' }}
            container
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Text className="number">Thanh toán</Text>
            <Text className="totalPrice">{`${formatMoney(amount)}đ`}</Text>
          </Grid>
        </ItemWrapper>
        <div
          className="bottom-fixed-button-wrapper"
          style={{ padding: '12px 0', backgroundColor: '#fff', zIndex: 1 }}
        >
          <ButtonMonthlyTicket
            disabled={!checkPayable}
            onClick={() => this.onCreateOrder()}
            fullWidth
            style={{ margin: 0 }}
          >
            Thanh toán
          </ButtonMonthlyTicket>
        </div>
        <DialogAlert
          open={open}
          leftButtonTitle={leftButtonTitle}
          rightButtonTitle={rightButtonTitle}
          description={description}
          onRightConfirm={this.onDialogRightConfirm}
          onLeftConfirm={this.onCancel}
          privatePolicy={privatePolicy}
        />
      </MonthLyTicketLinkPageWrapper>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quantity: state.monthlyTicketReducer.quantity,
    isBinding: state.autodebit.isBinding
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    clearData: () => {
      dispatch(clearData())
    },
    initializeBindingData: () => {
      dispatch(initializeBindingData())
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MonthlyTickekOrderPage))
