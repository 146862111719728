import * as Types from './../constants/ActionType'

const initialState = {}

const weatherReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_WEATHER:
      state = action.weather
      return state
    default:
      return { ...state } // copy new obj and return it
  }
}

export default weatherReducer
