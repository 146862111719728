import * as Types from '../constants/ActionType'

export const setNotification = (notification) => {
  return {
    type: Types.SET_NOTIFICATION,
    notification
  }
}

export const openToast = (data) => {
  return {
    type: Types.TOAST.OPEN_TOAST,
    data
  }
}

export const closeToast = () => {
  return {
    type: Types.TOAST.CLOSE_TOAST
  }
}
