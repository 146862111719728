import styled from 'styled-components'

export const NewHomePageWrapper = styled.div`
  margin: 0px 15px 0px;
`

export const FooterWrapper = styled.div`
    text-align: center;
    padding-top: 15px
    padding-bottom: 15px;
    
    > p {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 13px;
        color: #CCE6FF;
    }
`

export const UserIcon = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`

export const UserName = styled.p`
  color: #333333;
`

export const HeaderWrapper = styled.div`
  display: flex;
  padding: 10px 20px;
  > div {
    text-align: left;
    padding: 0px 15px;
    width: 100%;

    > p {
      margin: 0px;
    }

    > span {
      font-size: 13px;
      color: #919191;
    }
  }
`

export const LinkTitle = styled.p`
  color: ${(props) => (props.isBinding ? '#F3F8FE' : '#006DFF')};
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 100%;
  margin: auto;
`

export const LinkWrapper = styled.div`
  padding: 5px 0px 15px;
  > div {
    display: flex;
    background: #e8f2ff;
    border-radius: 30px;
    height: 50px;
    width: fit-content;
    margin: auto;
    padding: 8px 20px;
  }
`

export const LinkList = styled.div`
  padding: 15px 0px;
  > div {
    justify-content: center;
    align-items: center;
    display: flex;
    background: #e8f2ff;
    border-radius: 30px;
    height: 50px;
    width: 60%;
    margin: auto;
    padding: 8px 20px;

    > span {
      color: #006dff;
      padding: 10px;
      font-size: 18px;
      font-weight: bold;
      line-height: 100%;
    }
  }
`

export const ZaloPayIcon = styled.img`
  width: 25px;
  height: 25px;
  margin: 5px 0px;
`

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  //margin-top: 20px;
  position: relative;
`

export const TitleWrapper = styled.div`
  //border-top: 1px dashed #A4A4A4;
  //border-bottom: 1px dashed #A4A4A4;
  padding: 15px 15px 30px 15px;

  > span {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
`

export const InstructionIcon = styled.img`
  position: absolute;
  top: 115px;
  right: 20px;
`

export const ReminderShowAll = styled.div`
  color: #0071fe;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  margin-bottom: -25px;
`

export const ReminderWrapper = styled.div`
  background: white;
  padding: 10px 0;
  margin-top: 15px;
  border-radius: 8px;
  text-align: left;
`

export const Border = styled.p`
  border-bottom: 1px solid #919191;
  margin: 10px 20px 0;
`
