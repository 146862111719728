import { useCallback } from 'react'
//Sentry
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { REACT_APP_SENTRY_DSN } from '../constants/Env'

const configs = {
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV || 'development'
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

export default function useSentry() {
  return useCallback(() => {
    try {
      if (!isLocalhost) Sentry.init(configs)
    } catch (error) {}
  }, [])
}
