import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  NewQRWrapper,
  QRCodeBody,
  QRTitle,
  QRArrow,
  RemainTime,
  TimingWrapper
} from './style/new-qr.style'
import QRCode from 'qrcode.react'
import sha1 from 'crypto-js/sha1'

class NewQRContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      time: Date.now(),
      isShowUpdated: false
    }

    this.intervalHandle = () => {}
    this.countdown()
  }

  tick = () => {
    this.setState({})

    if (parseInt(61 - (Date.now() - this.state.time) / 1000) < 0) {
      this.onRefresh()
    }
  }

  onRefresh = () => {
    clearInterval(this.intervalHandle)
    this.setState({
      isShowUpdated: true,
      time: Date.now()
    })
    setTimeout(() => {
      this.setState({
        isShowUpdated: false
      })
      this.countdown()
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle)
  }

  countdown = () => {
    this.intervalHandle = setInterval(this.tick, 1000)
  }

  render() {
    const { auth } = this.props
    const { time, isShowUpdated } = this.state
    const { user } = auth
    const { card_no, qr_tk } = user
    const tk = `${card_no}|${time}?${qr_tk}`
    const qrData = JSON.stringify({
      u: card_no,
      t: time,
      tk: sha1(tk) + ''
    })

    const remainTime = parseInt(61 - (Date.now() - this.state.time) / 1000)
    return (
      <NewQRWrapper>
        <div>
          <QRTitle>Đưa mã này vào máy quét</QRTitle>
        </div>
        <QRCodeBody>
          <QRArrow src="/img/icons/qr_arrow.svg" />
          <QRCode value={qrData} style={{ marginRight: '37px' }} />
        </QRCodeBody>
        <TimingWrapper>
          {isShowUpdated ? (
            <span className="grey completed">
              <span className="icon-checked icon-zp-20 grey" />
              Đã cập nhật
            </span>
          ) : (
            <div className="running">
              <span style={{ fontSize: 14 }}>Tự động tạo mới sau </span>
              <RemainTime>
                {remainTime.toString().length === 2 ? remainTime : `0${remainTime}`}
              </RemainTime>
              <span style={{ fontSize: 14 }}>giây</span>
            </div>
          )}
        </TimingWrapper>
      </NewQRWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(NewQRContainer)
