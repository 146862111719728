import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { TableRow, FormGroup, Table } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'

import { COLOR_MAIN, LINE, ZP_GRADIENT } from '../../constants/Styles'

export const ZPTextFieldStyle = styled(TextField)`
  && {
    > label {
      color: #b6b8b8;
    }
    > div {
      background-color: transparent;
      &:hover {
        &:before {
          border-bottom: 1px solid #d7d8d3;
        }
      }
      &:before,
      &:after {
        border-bottom: 1px solid #d7d8d3;
        left: 12px;
      }
    }
    &.focused {
      > label {
        color: #008fe5;
      }
      > div {
        &:before,
        &:after {
          border-color: #008fe5;
        }
      }
    }
  }
  .last-block & {
    > div:before {
      left: 0;
    }
  }
`

export const ZPButtonStyle = styled(Button)`
  && {
    padding: 13px 0;
    font-size: 18px;
    color: #fff;
    background: #008fe5;
    border-radius: 3px;
    box-shadow: none;
    &.default {
      background: #c7c7cc;
    }
    &[disabled] {
      background: #c7c7cc;
      color: #fff;
    }
  }
`

export const ZPHeader = styled.p`
  padding: 20px 15px;
  text-align: left;
  margin: 0;
  border-bottom: ${LINE};
`

export const UserInfoHeaderWrapper = styled(Table)`
  && {
    height: 20%;
    width: 100%;
    background-image: linear-gradient(${ZP_GRADIENT});
    background-image: -moz-linear-gradient(${ZP_GRADIENT});
    background-image: -webkit-linear-gradient(${ZP_GRADIENT});
    background-image: -o-linear-gradient(${ZP_GRADIENT});
  }
`

export const UserInfoHeader = styled(TableRow)`
  && {
    > td {
      border: none;
      padding: 35px 12px 45px !important;
      vertical-align: bottom;
      &:first-child {
        padding-right: 0 !important;
      }
      &:last-child {
        padding-left: 5px !important;
      }
      p {
        margin: 0;
        &:first-child {
          color: #f0f4f6;
          opacity: 0.7;
          white-space: nowrap;
        }
        &:last-child {
          font-size: 30px;
          color: #fff;
          margin-top: 8px;
        }
      }
    }
  }
`

export const UserInfoChargeButton = styled(Button)`
  && {
    border: 1px solid #fff;
    color: #fff;
    border-radius: 25px;
    padding: 8px 16px;
    background-color: #00a5fb;
  }
`

export const UserInfoBody = styled(TableRow)`
  && {
    > td {
      padding: 20px 15px !important;
      border-bottom: 1px solid rgba(215, 216, 211, 0.4) !important;
      font-size: 15px;
      &:first-child {
        color: #727f8c;
        border: none !important;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          border-bottom: 1px solid rgba(215, 216, 211, 0.4) !important;
          left: 15px;
          bottom: -1px;
          right: 0;
        }
      }
    }
    &.last-block {
      > td {
        &:first-child {
          &:before {
            left: 0;
          }
        }
      }
    }
  }
`

export const UserInfoHistoryHeader = styled(TableRow)`
  && {
    > td {
      background-color: #f0f4f6;
      color: #7e8a95;
      padding-top: 26px !important;
      padding-bottom: 9px !important;
      border-bottom: 1px solid rgba(215, 216, 211, 0.4) !important;
    }
  }
`

export const UserInfoHistoryBody = styled(TableRow)`
  && {
    > td {
      border-bottom: 1px solid rgba(215, 216, 211, 0.4) !important;
      padding: 12px !important;
      background-color: #fff;
      &.info {
        position: relative;
        border: none !important;
        p {
          margin: 0 0 6px 0;
        }
        &:before {
          content: '';
          position: absolute;
          border-bottom: 1px solid rgba(215, 216, 211, 0.4) !important;
          left: 12px;
          bottom: -1px;
          right: 0;
        }
      }
      &.money {
        font-size: 20px;
        max-width: 100px;
        width: 87px;
      }
    }
    &.last-block {
      > td {
        &:first-child {
          &:before {
            left: 0;
          }
        }
      }
    }
  }
`

export const ZPDrawer = styled(Drawer)`
  && {
    div:nth-child(2) {
      padding: 15px;
      background-color: transparent;
      ul {
        background-color: #fff;
        border-radius: 10px;
        > div {
          > div {
            padding: 0;
          }
        }
        &:last-child {
          margin-top: 10px;
          span {
            color: #727f8c !important;
          }
        }
      }
    }
  }
`

export const FormGroupSelection = styled(FormGroup)`
  && {
    padding-left: 12px;
    height: 56px;
    position: relative;
    &:before {
      left: 12px;
      right: 0;
      bottom: 0;
      content: '';
      position: absolute;
      border-bottom: 1px solid rgba(215, 216, 211, 0.5);
    }
    .name {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
    }
    label {
      &:nth-child(2) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:last-child {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`

export const UpdateUserInfoHeader = styled(Table)`
  && {
    background-color: ${COLOR_MAIN};
    position: fixed;
    z-index: 2;
    td {
      padding: 15px !important;
    }
    .avatar {
      width: 64px;
      padding-right: 0px !important;
      > div {
        width: 64px;
        height: 64px;
      }
    }
    .info {
      text-align: left;
      color: #fff;
      > p {
        margin: 0;
        &.name {
          font-size: 16px;
          text-transform: capitalize;
        }
        &.ticket-type {
          font-size: 14px;
          padding-top: 8px;
          color: rgba(255, 255, 255, 0.8);
          .ticket-info {
            margin-left: 4px;
          }
        }
      }
    }
  }
`

export const TransactionDetailTable = styled(Table)`
  && {
    width: 100%;
    tr {
      height: auto !important;
    }
    td {
      color: #77818b;
      font-size: 16px;
      padding: 8px 15px;
    }
  }
`

export const RegistrationBlock = styled.div`
  && {
    margin-top: 64px;
    background-color: #fff;
    padding-bottom: 10px;
    padding-top: 15px;
    p {
      color: #24272b;
      font-size: 15px;
      margin-top: 0;
    }
    button {
      color: #008fe5;
      border: 1px solid #008fe5;
      border-radius: 25px;
      padding: 13px;
      width: calc(100% - 100px);
      min-width: 200px;
    }
  }
`

export const InputHeaderTitle = styled.p`
  && {
    padding: 20px 15px;
    text-align: left;
    margin: 0;
    border-bottom: 1px solid #d7d8d3;
  }
`
