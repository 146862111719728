import { post, put } from '../utils/apiCallerV2'

export const checkShowReferralApi = () => {
  return put(`v2/promotion/referral/app/check-show-referral`)
}
export const checkPhoneApi = (body) => {
  return post(`v2/customer/verify`, body)
}

export const getInviteListApi = () => {
  return post(`v2/promotion/referral/app/all-invite`)
}
export const inviteFriendApi = (body) => {
  return post(`v2/promotion/referral/app/invite`, body)
}
