import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import { ZP_GRADIENT } from '../../constants/Styles'

export const UserInfoHeaderWrapper = styled(Table)`
  && {
    background-image: linear-gradient(${ZP_GRADIENT});
    background-image: -moz-linear-gradient(${ZP_GRADIENT});
    background-image: -webkit-linear-gradient(${ZP_GRADIENT});
    background-image: -o-linear-gradient(${ZP_GRADIENT});
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    td {
      padding: 15px !important;
    }
    .avatar {
      width: 64px;
      padding-right: 0px !important;
      > div {
        width: 64px;
        height: 64px;
      }
    }
    .info {
      text-align: left;
      color: #fff;
      > p {
        margin: 0;
        &.name {
          font-size: 18px;
          text-transform: capitalize;
        }
        &.ticket-type {
          font-size: 14px;
          padding-top: 8px;
          color: rgba(255, 255, 255, 0.8);
          .ticket-info {
            margin-left: 4px;
            vertical-align: middle;
          }
        }
      }
    }
    .more-options {
      position: absolute;
      top: 0;
      right: 0;
      padding: 12px 12px 20px 20px;
      font-size: 16px;
    }
  }
`

export const ZPDrawer = styled(Drawer)`
  && {
    > div:nth-child(2) {
      background: transparent !important;
      #option-drawer {
        padding-bottom: 12px !important;
      }
    }
  }
`

export const ZPDrawerItem = styled(ListItem)`
  && {
    padding: 0 12px;
    > div {
      background: #fff;
      padding: 15px 12px !important;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 0.3px solid #d7d8d3;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
      ${(props) =>
        props.isFirst
          ? `
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
				`
          : null}
      ${(props) =>
        props.isLast
          ? `
					border-bottom-left-radius: 8px;
					border-bottom-right-radius: 8px;
				`
          : null}
    }
  }
`

export const ZPDrawerLastItem = styled(ListItem)`
  && {
    padding: 8px 12px 0 12px;
    > div {
      background: #fff;
      padding: 15px 12px !important;
      border-radius: 8px;
    }
  }
`
