import React from 'react'
import { color } from '../../../utils/color'

export default function Campaign({ goDetail, item }) {
  return (
    <div
      className="w-100 pb-3 mb-3"
      style={{
        backgroundColor: 'white',
        borderRadius: 10,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
      }}
      onClick={() => {
        goDetail(item)
      }}
    >
      <div style={{ width: '100%', height: 200 }} className="mb-3">
        <img
          src={item.image}
          alt=""
          style={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            height: '-webkit-fill-available',
            width: '-webkit-fill-available',
            objectFit: 'cover',
            objectPosition: 'top left'
          }}
        />
      </div>
      <div className="text-left ml-3">
        <div style={{ fontWeight: 'bold', fontSize: 18, color: color.black[600] }}>{item.name}</div>
        <div style={{ fontSize: 14, fontWeight: 400, color: '#4E5966' }}>
          {item.remainingTimeMessasge}
        </div>
      </div>
    </div>
  )
}
