import React, { Component } from 'react'
import { MonthLyTicketLinkContainerWrapper, LinkTitle, LinkImage, LinkWrapper } from './style'
import lost_connect from './assets/lost_connect.png'
import { color } from '../../utils/color'
class LostConnectionContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <MonthLyTicketLinkContainerWrapper>
        <LinkImage src={lost_connect} />
        <div style={{ color: color.black[400], fontSize: 16, fontWeight: 600 }}>Mất kết nối</div>
        <div style={{ color: color.black[400], fontSize: 14 }}>
          Vui lòng kiểm tra kết nối mạng và thử lại.
        </div>
        <LinkWrapper>
          <div onClick={this.props.onReload}>
            <LinkTitle>Tải lại</LinkTitle>
          </div>
        </LinkWrapper>
      </MonthLyTicketLinkContainerWrapper>
    )
  }
}

export default LostConnectionContainer
