import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import MobileDetect from 'mobile-detect'
import React, { Component, Fragment } from 'react'
import DatePicker from 'react-mobile-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { existOrNull, hiddenKeyBoard, friendlyDate, removeBodyBg, setTitle } from '../utils/helper'

import imageCompression from 'browser-image-compression'
import isEqual from 'lodash/isEqual'
import { setAuth } from '../actions/auth.action'
import { setNotification } from '../actions/common.action'
import ClearButton from '../components/ClearButton'
import InputCertification from '../components/InputCertification'
import InputImage from '../components/InputImage'
import UserInfoHeaderRegister from '../components/UserInfoHeaderRegister'
import ZPButton from '../components/ZPButton'
import DialogAlert from '../components/ZPDialog'
import ZPDialogUpdate from '../components/ZPDialogUpdate'
import ZPTextField from '../components/ZPTextField'
import { upload } from '../utils/apiCallerV2'
import Validator from '../utils/validator'
import './styles/loading.css'

const errorStyle = {
  margin: '0px',
  textAlign: 'left',
  paddingLeft: '10px',
  color: 'red'
}

class TheStudent extends Component {
  constructor(props) {
    super(props)
    setTitle('Thông tin tài khoản')
    removeBodyBg()

    this.state = {
      isDisabledContinuteButton: true,
      customerData: {},
      isOpenDatePicker: false,
      datePickerTime: new Date(),
      errors: {},
      dialog: {
        open: false,
        id: 0,
        leftButtonTitle: '',
        rightButtonTitle: '',
        description: ''
      },
      originalData: {},
      showLoading: false,
      isDisableFeild: false
    }
    this.school_name_input = React.createRef()
    this.student_code_input = React.createRef()

    if (this.props.location.state.customerData !== null) {
      this.state.customerData = this.props.location.state.customerData
    }

    const idStudentCardImage = () => {
      const { student_card_image } = this.state.customerData
      if (student_card_image) {
        return true
      } else {
        return false
      }
    }

    const idLoadStudentCardImage = () => {
      const { student_card_image, student_card_image_show } = this.state.customerData
      if (student_card_image_show && !student_card_image) {
        return false
      } else {
        return true
      }
    }
    const isDoubleSpace = (value) => {
      if (value.includes('  ')) {
        return true
      } else {
        return false
      }
    }

    const isFirstOrLastSpace = (value) => {
      if (value.charAt(0) === ' ' || value.charAt(value.length - 1) === ' ') {
        return true
      } else {
        return false
      }
    }

    const rules = [
      {
        field: 'school_name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Dữ liệu không được trống.'
      },
      {
        field: 'school_name',
        method: isFirstOrLastSpace,
        args: ['school_name'],
        validWhen: false,
        message: 'Dữ liệu không được bắt đầu hoặc kết thúc bằng khoảng trắng.'
      },
      {
        field: 'school_name',
        method: isDoubleSpace,
        args: ['school_name'],
        validWhen: false,
        message: 'Dữ liệu không được có khoảng trắng liền kề.'
      },
      {
        field: 'student_code',
        method: 'isEmpty',
        validWhen: false,
        message: 'Dữ liệu không được trống.'
      },
      {
        field: 'student_code',
        method: isFirstOrLastSpace,
        args: ['student_code'],
        validWhen: false,
        message: 'Dữ liệu không được bắt đầu hoặc kết thúc bằng khoảng trắng.'
      },
      {
        field: 'student_code',
        method: isDoubleSpace,
        args: ['student_code'],
        validWhen: false,
        message: 'Dữ liệu không được có khoảng trắng liền kề.'
      },
      {
        field: 'graduation_time',
        method: 'isEmpty',
        validWhen: false,
        message: 'Dữ liệu không được trống.'
      },
      //   {
      // 	field: 'card_file',
      // 	method: idLoadCardFile,
      // 	validWhen: true,
      // 	message: 'Ảnh đang được tải lên vui lòng chờ.',
      //   },
      //   {
      // 	field: 'card_file',
      // 	method: idCardFile,
      // 	validWhen: true,
      // 	message: 'Dữ liệu không được trống.',
      //   },
      {
        field: 'student_card_image',
        method: idLoadStudentCardImage,
        validWhen: true,
        message: 'Ảnh đang được tải lên vui lòng chờ.'
      },
      {
        field: 'student_card_image',
        method: idStudentCardImage,
        validWhen: true,
        message: 'Dữ liệu không được trống.'
      }
    ]
    this.validator = new Validator(rules)
  }

  onChange = (e) => {
    let { customerData } = this.state
    const { name, value } = e.target
    customerData = {
      ...customerData,
      [name]: value
    }

    this.setState(
      {
        customerData
      },
      () => {
        const isEqual = this.checkEqual()
        this.setState({
          isDisabledContinuteButton: isEqual
        })
      }
    )
  }

  componentDidMount() {
    hiddenKeyBoard()
  }

  onClear = (name) => {
    let { customerData } = this.state
    customerData = {
      ...customerData,
      [name]: ''
    }
    this.setState(
      {
        customerData
      },
      () => {
        const isEqual = this.checkEqual()
        this.setState({
          isDisabledContinuteButton: isEqual
        })
      }
    )
    this[`${name}_input`].focus()
  }

  onOpenDatePicker = () => {
    this.setState({
      isOpenDatePicker: true
    })
  }

  onCancelDatePicker = () => {
    this.setState({
      isOpenDatePicker: false
    })
  }

  onSelectDatePicker = (time) => {
    const graduation_time = `${time.getFullYear()}-${time.getMonth() + 1}`

    let { customerData } = this.state
    customerData = {
      ...customerData,
      graduation_time
    }

    this.setState(
      {
        customerData,
        datePickerTime: time,
        isOpenDatePicker: false
      },
      () => {
        const isEqual = this.checkEqual()
        this.setState({
          isDisabledContinuteButton: isEqual
        })
      }
    )
  }

  onHandleFocus = (e) => {
    const { errors } = this.state
    const { name } = e.target
    this.setState({
      errors: {
        ...errors,
        [name]: ''
      }
    })
  }

  onOpenDialog = () => {
    this.setState({
      dialog: {
        open: true,
        id: 1,
        leftButtonTitle: '',
        rightButtonTitle: 'Đã hiểu',
        description: 'Thông tin chưa chính xác, vui lòng kiểm tra lại.'
      }
    })
  }

  onOpenDialogPrivatepolicy = () => {
    this.setState({
      dialog: {
        open: true,
        id: 1,
        leftButtonTitle: '',
        rightButtonTitle: 'Đã hiểu',
        description: '',
        privatePolicy: 'true'
      }
    })
  }

  onDialogRightConfirm = () => {
    this.setState({
      dialog: {
        open: false
      }
    })
  }

  onChangeRoute = (to) => {
    this.props.history.push(to)
  }

  handleSubmit = (e) => {
    this.setState({
      errors: this.validator.validate(this.state.customerData)
    })
    if (Object.keys(this.validator.validate(this.state.customerData)).length === 0) {
      this.props.history.push({
        pathname: '/register/confirm-info-priority',
        state: { customerData: this.state.customerData }
      })
    } else {
      this.onOpenDialog()
    }
  }

  getOrientation = (file, callback) => {
    const reader = new FileReader()

    reader.onload = function (event) {
      const view = new DataView(event.target.result)

      if (view.getUint16(0, false) != 0xffd8) return callback(-2)

      const length = view.byteLength,
        offset = 2

      while (offset < length) {
        const marker = view.getUint16(offset, false)
        offset += 2

        if (marker == 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) {
            return callback(-1)
          }
          const little = view.getUint16((offset += 6), false) == 0x4949
          offset += view.getUint32(offset + 4, little)
          const tags = view.getUint16(offset, little)
          offset += 2

          for (let i = 0; i < tags; i++)
            if (view.getUint16(offset + i * 12, little) == 0x0112)
              return callback(view.getUint16(offset + i * 12 + 8, little))
        } else if ((marker & 0xff00) != 0xff00) break
        else offset += view.getUint16(offset, false)
      }
      return callback(-1)
    }

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024))
  }

  resetOrientation = (srcBase64, srcOrientation, callback) => {
    const img = new Image()

    img.onload = function () {
      const width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d')

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height
        canvas.height = width
      } else {
        canvas.width = width
        canvas.height = height
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0)
          break
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height)
          break
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height)
          break
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0)
          break
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0)
          break
        case 7:
          ctx.transform(0, -1, -1, 0, height, width)
          break
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width)
          break
        default:
          break
      }

      // draw image
      ctx.drawImage(img, 0, 0)

      // export base64
      callback(canvas.toDataURL())
    }

    img.src = srcBase64
  }

  base64toBlob = (base64Data, contentType) => {
    contentType = contentType || ''
    const sliceSize = 1024
    const byteCharacters = atob(base64Data)
    const bytesLength = byteCharacters.length
    const slicesCount = Math.ceil(bytesLength / sliceSize)
    const byteArrays = new Array(slicesCount)

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize
      const end = Math.min(begin + sliceSize, bytesLength)

      const bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }
    return new Blob(byteArrays, { type: contentType })
  }

  onChangeFileAvatar = async (file, name) => {
    let { customerData } = this.state
    if (file === null) return
    this.setState({
      showLoading: true
    })
    this.getOrientation(file, async (data) => {
      const reader = new FileReader()
      const maxSizeMB = 0.1
      const maxWidthOrHeight = 500
      try {
        file = await imageCompression(file, maxSizeMB, maxWidthOrHeight)
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          const base64data = reader.result
          this.resetOrientation(base64data, data, async (result) => {
            fetch(result)
              .then((res) => res.blob())
              .then(async (file1) => {
                file1.name = file.name
                file1 = await imageCompression(file1, maxSizeMB, maxWidthOrHeight)
                customerData = {
                  ...customerData,
                  [name + '_show']: file
                }

                this.setState(
                  {
                    customerData
                  },
                  () => {
                    const isEqual = this.checkEqual()
                    this.setState({
                      isDisabledContinuteButton: isEqual
                    })
                  }
                )
                upload({
                  body: {
                    file: file1,
                    sub_path: name
                  }
                }).then((res) => {
                  this.setState({
                    showLoading: false
                  })
                  if (res.err) return
                  const img = res.dt[0].relative_path

                  customerData = {
                    ...customerData,
                    [name]: img
                  }

                  this.setState(
                    {
                      customerData,
                      errors: { ...this.error, [name]: '' }
                    },
                    () => {
                      const isEqual = this.checkEqual()
                      this.setState({
                        isDisabledContinuteButton: isEqual
                      })
                    }
                  )
                })
              })
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  onChangeFile = async (file, name) => {
    let { customerData } = this.state
    if (file === null) return
    this.setState({
      showLoading: true
    })
    const maxSizeMB = 0.1
    const maxWidthOrHeight = 500
    try {
      file = await imageCompression(file, maxSizeMB, maxWidthOrHeight)
      customerData = {
        ...customerData,
        [name + '_show']: file
      }

      this.setState(
        {
          customerData
        },
        () => {
          const isEqual = this.checkEqual()
          this.setState({
            isDisabledContinuteButton: isEqual
          })
        }
      )
      upload({
        body: {
          file: file,
          sub_path: name
        }
      }).then((res) => {
        this.setState({
          showLoading: false
        })
        if (res && res.error.code !== 1) return
        const img = res.data[0].image_big

        customerData = {
          ...customerData,
          [name]: img
        }

        this.setState(
          {
            customerData,
            errors: { ...this.error, [name]: '' }
          },
          () => {
            const isEqual = this.checkEqual()
            this.setState({
              isDisabledContinuteButton: isEqual
            })
          }
        )
      })
    } catch (error) {
      console.log(error)
    }
  }
  componentWillMount() {
    const { auth } = this.props
    if (auth.user.registration_confirm_status === 1 && auth.user.customer_type !== 4) {
      this.setState({
        isDisableFeild: true
      })
    }
    this.setState(
      {
        student_card_image: existOrNull(this.props.auth.user.student_card_image),
        card_image: existOrNull(this.props.auth.user.avatar),
        originalData: {
          ...auth.user,
          card_file: auth.user.avatar,
          userType: `${this.classifyUserType(auth.user.customer_type)}`,
          userPriorityType: `${auth.user.card_type}`,
          graduation_time: auth.user.graduation_year
        }
      },
      () => {
        delete this.state.customerData.ignore_whitespace
        const isEqual = this.checkEqual()
        this.setState({
          isDisabledContinuteButton: isEqual
        })
      }
    )
  }

  checkEqual = (customerData) => {
    return isEqual(this.state.originalData, this.state.customerData)
  }
  classifyUserType = (customer_type) => {
    return customer_type == 4 ? 0 : 1
  }

  render() {
    const {
      customerData,
      isDisabledContinuteButton,
      isOpenDatePicker,
      datePickerTime,
      errors,
      dialog,
      showLoading,
      isDisableFeild
    } = this.state

    const { school_name, student_code, graduation_time } = customerData

    const { open, rightButtonTitle, description, privatePolicy } = dialog

    const md = new MobileDetect(window.navigator.userAgent)
    let formattedGraduationTimeForPlatform
    if (typeof graduation_time !== 'undefined' && graduation_time) {
      formattedGraduationTimeForPlatform = friendlyDate(graduation_time)
      if (!md.is('AndroidOS')) {
        const tmp = graduation_time.split(' ')[0]
        const date = tmp.split('-')
        formattedGraduationTimeForPlatform = date[1] + '/' + date[0]
      }
    }

    const now1 = new Date()
    now1.setDate(now1.getDate() - 1)

    const displayNone = {
      display: 'none'
    }

    let loadingStyle = {
      display: 'block'
    }
    let confirmStyle = {
      display: 'block'
    }

    if (showLoading) {
      confirmStyle = displayNone
    } else {
      loadingStyle = displayNone
    }

    return (
      <Fragment>
        <div className="diaglog-loading" style={loadingStyle}>
          <div className="container-loading">
            <div></div>
            <div></div>
            <div></div>
            <p>Vui lòng chờ</p>
          </div>
        </div>
        <div style={confirmStyle}>
          <UserInfoHeaderRegister user={customerData} />
          <ZPDialogUpdate />
          <div
            style={{
              paddingBottom: 81,
              paddingTop: '140px',
              overflow: 'scroll',
              height: '100vh'
            }}
          >
            <DatePicker
              value={datePickerTime}
              isOpen={isOpenDatePicker}
              headerFormat="MM/YYYY"
              min={now1}
              // max={now100}
              confirmText="Xác nhận"
              cancelText="Huỷ"
              showCaption={true}
              onSelect={this.onSelectDatePicker}
              onCancel={this.onCancelDatePicker}
              theme="ios"
              dateConfig={{
                // "date": {
                // 	format: "DD",
                // 	caption: "Ngày",
                // 	step: 1,
                // },
                month: {
                  format: 'MM',
                  caption: 'Tháng',
                  step: 1
                },
                year: {
                  format: 'YYYY',
                  caption: 'Năm',
                  step: 1
                }
              }}
            />
            <DialogAlert
              open={open}
              rightButtonTitle={rightButtonTitle}
              description={description}
              onRightConfirm={this.onDialogRightConfirm}
              privatePolicy={privatePolicy}
            />
            <FormControl fullWidth>
              <b
                style={{
                  fontSize: 18,
                  textDecoration: '',
                  margin: 0,
                  textAlign: 'left',
                  paddingLeft: '12px'
                }}
              >
                Đối tượng : Học Sinh
              </b>
              <p
                style={{
                  fontSize: '13px',
                  textAlign: 'left',
                  paddingLeft: '12px',
                  margin: '0px'
                }}
              >
                Mục có dấu * là bắt buộc phải nhập đầy đủ thông tin
              </p>
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField
                label="Tên trường *"
                name="school_name"
                value={school_name}
                maxLength={100}
                onChange={this.onChange}
                myRef={(ref) => (this.school_name_input = ref)}
                onFocus={this.onHandleFocus}
                disabled={isDisableFeild}
              />
              {!isDisableFeild && (
                <ClearButton isShow={school_name} name="school_name" onClick={this.onClear} />
              )}
              {errors.school_name && (
                <div className="validation" style={{ ...errorStyle, display: 'block' }}>
                  {errors.school_name}
                </div>
              )}
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField
                label="Mã số học sinh *"
                name="student_code"
                value={student_code}
                // type="tel"
                maxLength={15}
                onChange={this.onChange}
                myRef={(ref) => (this.student_code_input = ref)}
                onFocus={this.onHandleFocus}
                disabled={isDisableFeild}
              />
              {!isDisableFeild && (
                <ClearButton isShow={student_code} name="student_code" onClick={this.onClear} />
              )}
              {errors.student_code && (
                <div className="validation" style={{ ...errorStyle, display: 'block' }}>
                  {errors.student_code}
                </div>
              )}
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField
                label="Thời hạn tốt nghiệp *"
                name="graduation_time"
                onClick={this.onOpenDatePicker}
                value={formattedGraduationTimeForPlatform}
                readOnly={true}
                onFocus={this.onHandleFocus}
                disabled={isDisableFeild}
              />
              <p className="error" style={errorStyle}>
                {this.state.errors['graduation_time']}
              </p>
            </FormControl>
            <InputImage
              title="Thẻ học sinh"
              description="Mặt trước"
              id="student_id"
              preparedFile={this.state.student_card_image}
              onChooseImage={(file) => this.onChangeFile(file, 'student_card_image')}
              isDisableFeild={isDisableFeild}
            />
            {!customerData.student_card_image && (
              <p className="error" style={errorStyle}>
                {this.state.errors['student_card_image']}
              </p>
            )}
            <InputCertification
              typeCetification="học sinh, vui lòng thực hiện"
              cetificationForm="Đơn chứng nhận học sinh"
              onChooseCertification={(file) => this.onChangeFile(file, 'certification_file')}
            />
            <div
              className="d-flex justify-content-center align-items-start"
              style={{ gap: 4, padding: 12 }}
            >
              <Checkbox
                checked={true}
                style={{ pointerEvents: 'none', color: '#008fe5', padding: 0 }}
                color="primary"
              />
              <span className="text-left">
                Tôi đã đọc và đồng ý với
                <span
                  style={{ color: '#008fe5', cursor: 'pointer' }}
                  onClick={() => this.onOpenDialogPrivatepolicy()}
                >
                  {' '}
                  điều khoản điều kiện của UniPass
                </span>
              </span>
            </div>
          </div>

          <div
            className="bottom-fixed-button-wrapper"
            style={{ padding: '12px 0', backgroundColor: '#fff', zIndex: 1 }}
          >
            <ZPButton
              disabled={isDisabledContinuteButton}
              onClick={this.handleSubmit.bind(this)}
              fullWidth
              style={{ margin: 0 }}
            >
              Hoàn thành
            </ZPButton>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    common: state.common
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setAuth: (user) => {
      dispatch(setAuth(user))
    },
    setNotification: (notification) => {
      dispatch(setNotification(notification))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TheStudent))
