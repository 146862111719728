export const TICKET_TYPE = [
  {
    id: 1,
    name: 'Vé phổ thông'
  },
  {
    id: 2,
    name: 'Vé giảm'
  },
  {
    id: 3,
    name: 'Vé miễn'
  },
  {
    id: 4,
    name: 'Vé phổ thông'
  },
  {
    id: 5,
    name: 'Vé giảm'
  },
  {
    id: 6,
    name: 'Vé miễn'
  },
  {
    id: 8,
    name: 'Vé kiểm soát'
  }
]

export const CARD_TYPE = [
  {
    id: 0,
    name: 'Vé phổ thông'
  },
  {
    id: 1,
    name: 'Vé phổ thông'
  },
  {
    id: 2,
    name: 'Vé sinh viên'
  },
  {
    id: 7,
    name: 'Vé học sinh'
  },
  {
    id: 3,
    name: 'Vé người cao tuổi'
  },
  {
    id: 4,
    name: 'Vé trẻ em'
  },
  {
    id: 5,
    name: 'Vé khuyết tật/thương binh'
  },
  {
    id: 6,
    name: 'Vé người nghèo'
  },
  {
    id: 8,
    name: 'Vé kiểm soát'
  }
]

export const PAYMENT_STATUS = {
  0: 'Không xác định',
  2: 'Thành công',
  4: 'Thất bại',
  6: 'Hoàn tiền'
}

export const CASH_IN_PAYMENT_STATUS = {
  0: 'Thất bại',
  1: 'Thành công'
}

export const CARD_TYPE_ID = {
  PT: 1,
  SV: 2,
  HS: 7,
  NN: 6,
  NCT: 3,
  TE: 4,
  KTTB: 5,
  KSV: 8
}

export const TURN = {
  AWAY_TURN: 0,
  RETURN_TURN: 1
}

export const CarMarkerWidth = 30
export const CarMarkerHeight = 30

export const MODE = {
  NORMAL: 0,
  FULL_SCREEN: 1
}

export const CONTROL_PANEL_HEIGHT = '300px'

export const SWIPE_THRESHOLD = 130

export const SCHOOL_LIST = [
  { label: 'Đại học Bách khoa- Đại học Quốc gia TPHCM', value: '1' },
  { label: 'Đại học Khoa học tự nhiên- Đại học Quốc gia TPHCM', value: '2' },
  {
    label: 'Đại học Khoa học xã hội và nhân văn- Đại học Quốc gia TPHCM',
    value: '3'
  },
  { label: 'Đại học Quốc tế- Đại học Quốc gia TPHCM', value: '4' },
  { label: 'Đại học Công nghệ thông tin - Đại học Quốc gia TPHCM', value: '5' },
  { label: 'Đại học Kinh tế- Luật- Đại học Quốc gia TPHCM', value: '6' },
  { label: 'Khoa Y- Đại học Quốc gia TPHCM', value: '7' },
  { label: 'Học viện Cán bộ TPHCM', value: '8' },
  { label: 'Học viện Công nghệ Bưu chính Viễn thông TPHCM', value: '9' },
  { label: 'Học viện Hàng không Việt Nam', value: '10' },
  { label: 'Đại học Công nghiệp TPHCM', value: '11' },
  { label: 'Đại học Công nghiệp thực phẩm TPHCM', value: '12' },
  { label: 'Đại học Giao thông Vận tải cơ sở 2', value: '13' },
  { label: 'Đại học Giao thông vận tải TPHCM', value: '14' },
  { label: 'Đại học Kiến trúc TPHCM', value: '15' },
  { label: 'Đại học Kinh tế TPHCM', value: '16' },
  { label: 'Đai học Lao động xã hội TPHCM', value: '17' },
  { label: 'Đại học Luật TPHCM', value: '18' },
  { label: 'Đại học Mở TPHCM', value: '19' },
  { label: 'Đại học Mỹ thuật TPHCM', value: '20' },
  { label: 'Đại học Ngân hàng TPHCM', value: '21' },
  { label: 'Đại học Ngoại thương- cơ sở phía Nam', value: '22' },
  { label: 'Đại học Nội vụ Hà Nội - cơ sở phía Nam', value: '23' },
  { label: 'Nhạc viện TPHCM', value: '24' },
  { label: 'Đại học Nông Lâm TPHCM', value: '25' },
  { label: 'Đại học Sài Gòn', value: '26' },
  { label: 'Đại học Sân khấu điện ảnh TPHCM', value: '27' },
  { label: 'Đại học Sư phạm TPHCM', value: '28' },
  { label: 'Đại học Sư phạm Kỹ thuật TPHCM', value: '29' },
  { label: 'Đại học Tài chính- Marketing', value: '30' },
  { label: 'Đại học Tài nguyên và Môi TPHCM', value: '31' },
  { label: 'Đại học Thể dục thể thao TPHCM', value: '32' },
  { label: 'Đại học Thủy lợi TPHCM', value: '33' },
  { label: 'Đại học Tôn Đức Thắng', value: '34' },
  { label: 'Đại học Văn hóa TPHCM', value: '35' },
  { label: 'Đại học Y Dược TPHCM', value: '36' },
  { label: 'Đại học Y khoa Phạm Ngọc Thạch', value: '37' },
  { label: 'Đại học Công nghệ Sài Gòn', value: '38' },
  { label: 'Đại học Công nghệ TPHCM', value: '39' },
  { label: 'Đại học Gia Định', value: '40' },
  { label: 'Đại học Hoa Sen', value: '41' },
  { label: 'Đại học Hùng Vương', value: '42' },
  { label: 'Đại học Kinh tế - Tài chính TPHCM', value: '43' },
  { label: 'Đại học Ngoại ngữ - Tin học TPHCM', value: '44' },
  { label: 'Đại học Nguyễn Tất Thành', value: '45' },
  { label: 'Đại học Quốc tế Hồng Bàng', value: '46' },
  { label: 'Đại học Văn Hiến', value: '47' },
  { label: 'Đại học Quốc tế Sài Gòn', value: '48' },
  { label: 'Đại học Văn Lang', value: '49' },
  { label: 'Cao đẳng An ninh mạng iSPACE', value: '50' },
  { label: 'Cao đẳng Bách khoa Nam Sài Gòn', value: '51' },
  { label: 'Cao đẳng Bách Việt', value: '52' },
  {
    label: 'Cao đẳng Bán công Công nghệ và Quản trị doanh nghiệp',
    value: '53'
  },
  { label: 'Cao đẳng Công nghệ Sài Gòn', value: '54' },
  { label: 'Cao đẳng Công nghệ Thủ Đức', value: '55' },
  { label: 'Cao đẳng Công thương TP.HCM', value: '56' },
  { label: 'Cao đẳng Đại Việt Sài Gòn', value: '57' },
  { label: 'Cao đẳng Điện lực TP.HCM', value: '58' },
  { label: 'Cao đẳng Giao thông vận tải Đường thủy II', value: '59' },
  { label: 'Cao đẳng Giao thông Vận tải Trung ương VI', value: '60' },
  { label: 'Cao đẳng Giao thông Vận tải TP.HCM', value: '61' },
  { label: 'Cao đẳng Kinh tế – Công nghệ TP.HCM', value: '62' },
  { label: 'Cao đẳng Kinh tế Đối ngoại', value: '63' },
  { label: 'Cao đẳng Kinh tế Kỹ thuật TP. Hồ Chí Minh', value: '64' },
  { label: 'Cao đẳng Kinh tế – Kỹ thuật Thủ Đức', value: '65' },
  { label: 'Cao đẳng Kinh tế – Kỹ thuật Vinatex TP.HCM', value: '66' },
  { label: 'Cao đẳng Kinh tế TP.HCM', value: '67' },
  { label: 'Cao đẳng Kỹ nghệ II', value: '68' },
  { label: 'Cao đẳng Kỹ thuật Cao Thắng', value: '69' },
  { label: 'Cao đẳng Kỹ thuật Công nghệ Vạn Xuân', value: '70' },
  { label: 'Cao đẳng Kỹ thuật Nguyễn Tộ', value: '71' },
  { label: 'Cao đẳng Kỹ thuật Lý Tự Trọng TP. Hồ Chí Minh', value: '72' },
  { label: 'Cao đẳng Miền Nam', value: '73' },
  { label: 'Cao đẳng nghề Du lịch Sài Gòn', value: '74' },
  { label: 'Cao đẳng nghề Hàng hải TP.HCM', value: '75' },
  { label: 'Cao đẳng nghề Thành phố Hồ Chí Minh', value: '76' },
  { label: 'Cao đẳng nghề Thủ Thiêm – Tp. Hồ Chí Minh', value: '77' },
  { label: 'Cao đẳng Phát thanh Truyền hình 2', value: '78' },
  { label: 'Cao đẳng Quốc tế TP.HCM', value: '79' },
  { label: 'Cao đẳng Quốc tế KENT', value: '80' },
  { label: 'Cao đẳng Sài Gòn', value: '81' },
  { label: 'Cao đẳng Sài Gòn Gia Định', value: '82' },
  { label: 'Cao đẳng Văn hóa Nghệ thuật TP.HCM', value: '83' },
  { label: 'Cao đẳng Văn hóa Nghệ thuật và Du lịch Sài Gòn', value: '84' },
  { label: 'Cao đẳng Viễn Đông', value: '85' },
  { label: 'Cao đẳng Việt Mỹ', value: '86' },
  { label: 'Cao đẳng Xây dựng Tp. Hồ Chí Minh', value: '87' },
  { label: 'Cao đẳng Y Dược Hồng Đức', value: '88' },
  { label: 'Cao đẳng Y Dược Sài Gòn', value: '89' },
  { label: 'Trường Đại học FPT', value: '90' },
  { label: 'Trường Đại Học RMIT', value: '91' },
  { label: 'Trường khác', value: '92' }
]

export const threeMonthsInMiliSeconds = 7776000000
