import React from 'react'

import { BorderDotted, BorderWrapper, Circle, leftStyle, rightStyle } from './style'

const BorderDot = (props) => {
  return (
    <BorderWrapper>
      <Circle style={leftStyle} />
      <BorderDotted />
      <Circle style={rightStyle} />
    </BorderWrapper>
  )
}

export default BorderDot
