import styled from 'styled-components'

export const AnnouncementDialogMessage = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 15px 16px 11px 16px;
`

export const IAgreeButton = styled.img`
  margin: 11px;
  margin-top: 0;
`

export const RouteItemContainer = styled.div`
  text-align: left;
  margin: 0 16px;
  align-items: center;
  display: flex;
`

export const SelectedRouteContainer = styled.div`
  text-align: left;
  align-items: center;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  border-radius: 8px 8px 0 0;

  img.turn-indicator {
    height: 32px;
  }
`

export const RouteNameArea = styled.span`
  height: 32px;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;

  img {
    margin-left: 12px;
  }
`

export const RouteInfo = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #a6a6a6;
  text-align: right;
  height: 32px;
  display: inline-flex;
  align-items: center;
`

export const OperatingHours = styled.span`
  margin-left: 10px;
`

export const TicketPriceArea = styled.span`
  margin-left: 16px;
`

export const TicketPrice = styled.span`
  margin-left: 8px;
`

export const RouteName = styled.span`
  margin: 0 12px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #3f3f3f;
`

export const StopByStations = styled.p`
  padding-top: 12px;
  padding-bottom: 14px;
  margin-bottom: 0;
`

export const SeperatorBetweenRouteItems = styled.div`
  height: 0;
  border-top: 1px solid #f3f5f7;
  margin: 0 16px;
`

export const SeperatorBetweenComments = styled.div`
  height: 0;
  border-top: 1px solid #f3f5f7;
`

export const ResultsContainer = styled.div`
  position: absolute;
  top: 0;
  background-color: white;
  width: 100%;
  height: 100vh;
  padding-top: 75px;
  text-align: left;
  overflow-y: scroll;
  padding-bottom: ${(props) => props.selectedRoute && '103px'};
`

export const TabsContentContainer = styled.div`
  padding: 0 16px;
  padding-top: 16px;
  max-height: calc(50vh - 153px);
  overflow-y: scroll;
`

export const StepIcon = styled.div`
  margin-left: 3px;
`

export const RoutesContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 100vh;
  text-align: left;
  overflow: hidden;
`

export const ResultsContainerByClickOnStation = styled.div`
  position: absolute;
  top: 100px;
  bottom: 0;
  overflow-y: scroll;
`

export const StationName = styled.p`
  margin-top: 16px;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
  text-align: center;
  margin-bottom: 0;
`

export const StationAddress = styled.p`
  text-align: center;
  margin-bottom: 15px;
  margin-top: 6px;
`

export const UserAvatar = styled.img`
  float: left;
  max-width: 40px;
  max-height: 40px;
  clip-path: circle(50% at 50% 50%);
`

export const UserName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  overflow: hidden;
  font-size: 14px;
  color: #000000;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
`

export const PersonalRatingContainer = styled.div``

export const Comment = styled.span`
  font-size: 14px;
  line-height: 120%;
  color: #3f3f3f;
`

export const RankingAndRatingText = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  color: #3f3f3f;
  margin-left: 4px;
  margin-bottom: 0;
`

export const AverageRatingNumber = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 100%;
  color: #3f3f3f;
  padding-top: 30px;
  margin: 0;
  margin-bottom: 16px;
`

export const LocateMeControl = styled.div`
  position: absolute;
  right: 15px;
  top: -60px;
`

export const NumberPlateInStationStep = styled.span`
  background: #01a7fb;
  border-radius: 4px;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  padding: 2px 7px;
`

export const ETA = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #3f3f3f;
`

export const NoRatingText = styled.div`
  padding-bottom: 16px;
  text-align: center;
  color: gray;
`
