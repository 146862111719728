import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkShowPopup,
  setInstructionDialogVisibility,
  setReminderDialogVisibility
} from '../../../actions/home.action'
import { refundProcessing } from '../../../actions/refund.action'
import BorderDot from '../../../components/Border'
import NotificationCenter from '../../../components/NotificationCenter'
import ReminderDialog from '../../../components/ReminderDialog/ReminderDialog'
import SideBar from '../../../components/SideBar/SideBar'
import { REACT_APP_ADMIN_DOMAIN } from '../../../constants/Env'
import NewQRContainer from '../../../containers/NewQRContainer/NewQRContainer'
import { QRVisibilitySelector } from '../../../selectors/balance.selector'
import { lastTransactionSelector } from '../../../selectors/transaction.selector'
import InstructionDialog from './Dialog/InstructionDialog'
import StudentImage from './Dialog/image-student'
import RechargeOptions from './RechargeOptions'
import User from './components/user'
import {
  ContentWrapper,
  FooterWrapper,
  InstructionIcon,
  NewHomePageWrapper,
  TitleWrapper
} from './style/new-home-page.style'
import BasicDrawer from '../../../new-components/SwipeableDrawer'

export default function NewHomePageContainer({ user, onChangeRoute }) {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const userInfo = useSelector((state) => state.auth.user)
  const isBinding = useSelector((state) => state.autodebit.isBinding)
  const homeState = useSelector((state) => state.home)
  const [isOpenImage, setIsOpenImage] = useState(false)

  useEffect(() => {
    dispatch(checkShowPopup())
    dispatch(refundProcessing())
  }, [])

  const { customer_name, expire_date, avatar, card_type } = user
  const avatarFullUrl = useMemo(() => {
    if (avatar) {
      if (avatar.includes('https:')) {
        return avatar
      } else {
        return REACT_APP_ADMIN_DOMAIN + avatar
      }
    }
  }, [avatar])
  const isQRVisible = QRVisibilitySelector(state)
  const lastTransaction = lastTransactionSelector(state)

  const showInstructionDialog = () => {
    dispatch(setInstructionDialogVisibility(true))
  }

  const showReminderDialog = () => {
    dispatch(setReminderDialogVisibility(true))
  }

  const onOpenInstruction = () => {
    dispatch(setInstructionDialogVisibility(true))
  }

  const onCloseInstruction = () => {
    dispatch(setInstructionDialogVisibility(false))
  }

  const onOpenReminder = () => {
    dispatch(setReminderDialogVisibility(true))
  }

  const onCloseReminder = () => {
    dispatch(setReminderDialogVisibility(false))
  }

  const expireDataFormat = useMemo(() => {
    if (expire_date) {
      const arr = expire_date.split('-')
      return arr && arr.length ? `${arr[2]}/${arr[1]}/${arr[0]}` : null
    } else return null
  }, [expire_date])

  const studentCardView = () => {
    if (userInfo?.customer_type === 5) setIsOpenImage(true) //student
  }

  return (
    <NewHomePageWrapper>
      <ContentWrapper>
        <User
          avatar={avatarFullUrl}
          name={customer_name}
          expire={expireDataFormat}
          cardType={card_type}
          onClickCardType={studentCardView}
        />
        <div>
          {isQRVisible === true ? (
            <>
              <BorderDot />
              {!lastTransaction || lastTransaction.payment_status === 2 ? (
                <>
                  <InstructionIcon onClick={showInstructionDialog} src="/img/icons/question.svg" />
                  <NewQRContainer />
                </>
              ) : (
                <div>
                  <TitleWrapper>
                    <span>
                      Vui lòng thực hiện thông báo <br /> bên dưới để tiếp tục sử dụng
                    </span>
                  </TitleWrapper>
                </div>
              )}
            </>
          ) : (
            <>
              <BorderDot />
              <div>
                <TitleWrapper>
                  <span style={{ color: '#7A7C8B' }}>
                    Vui lòng liên kết ví ZaloPay để sử dụng dịch vụ.
                  </span>
                </TitleWrapper>
              </div>
            </>
          )}
        </div>
      </ContentWrapper>
      <RechargeOptions isBinding={isBinding} item={user} />
      <NotificationCenter showAllNotifications={showReminderDialog} />
      <SideBar onChangeRoute={onChangeRoute} />
      <FooterWrapper>
        <p onClick={(e) => onChangeRoute('/degree13')} className='text-14-18' style={{color:'white', opacity: '0.8' }}>Chính sách bảo vệ quyền riêng tư</p>
        <p className='mt-6' style={{color:'#195177'}}>Dịch vụ chỉ dành cho xe buýt tại TP.Hồ Chí Minh</p>
      </FooterWrapper>

      <SwipeableDrawer
        anchor="bottom"
        onOpen={onOpenInstruction}
        onClose={onCloseInstruction}
        open={homeState.isOpenInstructionDialog}
        PaperProps={{
          style: { borderRadius: '30px 30px 0px 0px' }
        }}
      >
        <InstructionDialog
          onChangeStatus={(state) => {
            dispatch(setInstructionDialogVisibility(state))
          }}
        />
      </SwipeableDrawer>

      <BasicDrawer
        destroyOnClose={true}
        onClose={() => {
          setIsOpenImage(false)
        }}
        open={isOpenImage}
        title={'Thẻ học sinh/sinh viên'}
      >
        <StudentImage
          onChangeStatus={(state) => {
            setIsOpenImage(state)
          }}
          studentCardImage={userInfo?.student_card_image}
        />
      </BasicDrawer>

      <SwipeableDrawer
        anchor="bottom"
        onOpen={onOpenReminder}
        onClose={onCloseReminder}
        open={homeState.isOpenReminderDialog}
        PaperProps={{
          style: { borderRadius: '30px 30px 0px 0px' }
        }}
      >
        <ReminderDialog
          onClose={(state) => {
            dispatch(setReminderDialogVisibility(state))
          }}
        />
      </SwipeableDrawer>
    </NewHomePageWrapper>
  )
}
