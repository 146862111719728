import React, { Component, Fragment } from 'react'

import { setTitle, removeBodyBg } from '../utils/helper'
import { COLOR_BLUE } from '../constants/Styles'
import ZPSubTitle from '../components/ZPSubTitle'

class Degree13Page extends Component {
  constructor(props) {
    super(props)
    setTitle('Chính sách bảo vệ quyền riêng tư')
    removeBodyBg()
  }

  render() {
    return (
      <Fragment>
        <div style={{ backgroundColor: 'white', height: '100vh' }}>
          <div style={{ margin: '0px 16px auto 16px', padding: '12px', backgroundColor: 'white' }}>
            <p style={{ fontStyle: 'bold' }}>CHÍNH SÁCH BẢO VỆ QUYỀN RIÊNG TƯ<br/> CHO SẢN PHẨM UNIPASS</p>
            <p style={{ textAlign: 'left' }}>
              <br />
              Công ty Cổ phần ZION (sau đây gọi tắt là “ZION”) xây dựng và công bố Chính sách Quyền
              riêng tư để thực thi những cam kết và trách nhiệm Bảo vệ thông tin cá nhân của khách
              hàng, tuân thủ các quy định pháp luật trong việc đảm bảo an toàn, bảo mật thông tin
              khi khách hàng truy cập, sử dụng dịch vụ do ZION cung ứng.
            </p>
            <p style={{ textAlign: 'left' }}>
              Khi tổ chức, cá nhân, doanh nghiệp (sau đây được gọi chung là “Bạn”) cung cấp thông
              tin để thiết lập quan hệ, tạo tài khoản, sử dụng sản phẩm, dịch vụ do ZION cung ứng,
              ZION hiểu rằng Bạn đồng ý và chấp nhận Chính sách Quyền riêng tư này.
            </p>
            <p style={{ textAlign: 'left' }}>
              Chính sách quyền riêng tư này được ZION công bố công khai trên 
              <a href='https://unipass.vn/quy-dinh/chinhsach'> https://unipass.vn/quy-dinh/chinhsach</a>
            </p>
            <p style={{ textAlign: 'left' }}>
              Bằng việc sử dụng ZaloPay, truy cập hoặc sử dụng website/sản phẩm/dịch vụ do ZION cung
              ứng, Bạn xác nhận và đồng ý rằng Bạn chấp nhận các phương pháp, yêu cầu, và/hoặc chính
              sách được mô tả trong Chính sách quyền riêng tư này, và theo đây Bạn đồng ý cho phép
              ZION thu thập, sử dụng và/hoặc xử lý dữ liệu cá nhân của Bạn như mô tả trong Chính
              sách quyền riêng tư này.
            </p>

            <ol style={{ textAlign: 'left', paddingLeft: '12px' }} type="I">
              <li className="text-bold" style={{ textAlign: 'left' }}>
                Đối tượng và phạm vi áp dụng
              </li>
              <ol style={{ textAlign: 'left', paddingLeft: '12px' }}>
                <li
                  className="text-bold"
                  style={{ textAlign: 'left', textDecoration: 'underline' }}
                >
                  Đối tượng áp dụng:
                </li>
                <p style={{ textAlign: 'left' }}>
                  Người sử dụng là cá nhân, tổ chức, doanh nghiệp.
                </p>
                <li
                  className="text-bold"
                  style={{ textAlign: 'left', textDecoration: 'underline' }}
                >
                  Phạm vi áp dụng:
                </li>
                <p style={{ textAlign: 'left' }}>
                  Hoạt động xử lý dữ liệu cá nhân của ZION (là một hoặc nhiều hoạt động tác động tới
                  dữ liệu cá nhân như: Thu thập, ghi, phân tích, xác nhận, lưu trữ, chỉnh sửa, công
                  khai, kết hợp, truy cập, truy xuất, thu hồi, mã hóa, giải mã, sao chép, chia sẻ,
                  truyền đưa, cung cấp, chuyển giao, xóa, hủy dữ liệu cá nhận hoặc các hoạt động
                  liên quan khác) khi Bạn đăng ký, sử dụng sản phẩm, dịch vụ do ZION cung ứng.
                </p>
                <p style={{ textAlign: 'left' }}>
                  Thông tin dữ liệu cá nhân được hiểu là Thông tin về Bạn và tất cả các thông tin
                  khác liên quan đến Bạn, dưới dạng ký hiệu, chữ viết, chữ số, hình ảnh, âm thanh
                  hoặc dạng tương tự trên môi trường điện tử gắn liền với một con người cụ thể hoặc
                  giúp xác định một con người cụ thể. Dữ liệu cá nhân bao gồm dữ liệu cá nhân cơ bản
                  và dữ liệu cá nhân nhạy cảm.
                </p>
              </ol>
              <li className="text-bold" style={{ textAlign: 'left' }}>
                Hoạt động thu thập, xử lý dữ liệu cá nhân của Zion
              </li>
              <ol style={{ paddingLeft: '12px' }}>
                <li className="text-bold">Các dữ liệu cá nhân được thu thập, xử lý:</li>
                <p>1.1. Thông tin thu thập:</p>
                <p>
                  Nhằm bảo vệ quyền lợi của Bạn tại ZION, thực hiện các quy định pháp luật khi cung
                  ứng dịch vụ cho bạn, ZION tiến hành các hoạt động thu thập dữ liệu cá nhân là gồm:
                </p>
                <ol style={{ paddingLeft: '12px' }} type="a">
                  <li>Thông tin do bạn cung cấp</li>
                  <ul>
                    <li>Họ và tên;</li>
                    <li>Ngày, tháng, năm sinh;</li>
                    <li>Giới tính;</li>
                    <li>Hình ảnh của cá nhân;</li>
                    <li>Số điện thoại;</li>
                    <li>Email;</li>
                    <li>Địa chỉ;</li>
                    Đối tượng học sinh, sinh viên cung cấp thêm:
                    <li>Tên trường</li>
                    <li>Mã số HS/SV</li>
                    <li>Thời hạn tốt nghiệp</li>
                    <li>Thẻ SV/HS</li>
                  </ul>
                  <li>
                    Thông tin về các giao dịch và hoạt động của Bạn bao gồm, nhưng không giới hạn:
                  </li>
                  <ul>
                    <li>Thay đổi/cài đặt lại Mật khẩu thanh toán (PIN);</li>
                    <li>
                      Các hoạt động khác do ZION hợp tác với các đối tác để triển khai trên
                      UniPass/ứng dụng/website khác do ZION cung ứng.
                    </li>
                  </ul>
                  <p>
                      Việc thu thập thông tin được thực hiện từ nhiều nguồn khác nhau như: Qua thỏa
                      thuận/hợp đồng được giao kết với Bạn, qua các kênh thông tin của cơ quan chức
                      năng có thẩm quyền, Qua kênh liên lạc của ZION và Bạn, các tương tác của Bạn
                      với nhân viên của ZION, và kể cả qua những tài khoản khác mà ZION có cơ sở
                      khẳng định có liên quan đến Bạn; các thông tin Bạn phản hồi cho các cuộc khảo
                      sát; các thông tin trong quá trình bạn tham gia vào các trao đổi, thảo luận,
                      giải quyết tranh chấp hoặc các thông tin phù hợp khác được Bạn cung cấp liên
                      quan đến việc sử dụng dịch vụ của ZION cung ứng;
                    </p>
                    <p>
                      Ngoài ra, nhằm mục đích cải thiện, nâng cao chất lượng Dịch vụ, ZION có thể
                      giám sát hoặc ghi âm các cuộc đàm thoại với Bạn hoặc bất kỳ ai nhân danh Bạn
                      trên nguyên tắc phù hợp quy định pháp luật hiện hành.
                    </p>
                  <li>Thông tin được thu thập tự động:</li>
                  <p>
                    Thông tin được gửi đến ZION từ máy tính, điện thoại, hoặc các thiết bị khác mà
                    Bạn sử dụng để tải, truy cập như: Dữ liệu các website, ứng dụng Bạn truy cập,
                    địa chỉ IP, vị trí của Bạn, ID nhận dạng thiết bị, loại thiết bị, thông tin máy
                    tính, thiết bị di động, thiết bị mạng, số liệu thống kê hiển thị của website,
                    ứng dụng truy cập và các dữ liệu cơ bản khác; Khi Bạn truy cập sản phẩm, dịch vụ
                    của ZION, hoặc truy cập website, ứng dụng của bên thứ ba có hợp tác dịch vụ trực
                    tuyến mà ZION cung cấp, ZION và các đối tác kinh doanh và nhà cung cấp của ZION
                    có thể sử dụng cookie và các công cụ kiểm tra (gọi chung là Cookies) để nhận
                    diện khách hàng là một Người sử dụng và để tùy chỉnh trải nghiệm trực tuyến của
                    Bạn, Dịch vụ Bạn sử dụng cũng như nội dung và quảng cáo, giới thiệu sản phẩm
                    trực tuyến khác; đo lường hiệu quả của các chương trình khuyến mãi và thực hiện
                    phân tích; và để giảm thiểu rủi ro, ngăn chặn gian lận tiềm ẩn và thúc đẩy niềm
                    tin và sự an toàn đối với các dịch vụ của ZION;
                  </p>
                  <p>
                    Một số khía cạnh và tính năng của Dịch vụ, sản phẩm của ZION chỉ khả dụng khi sử
                    dụng Cookies, vì vậy nếu Bạn chọn tắt hoặc từ chối Cookies, việc sử dụng Dịch vụ
                    của bạn có thể bị hạn chế hoặc không thể thực hiện;
                  </p>
                  <p>
                    Khi Bạn sử dụng thiết bị để truy cập, sử dụng dịch vụ, tùy theo cấu trúc, tính
                    năng, thiết kế từng loại thiết bị, nhà cung cấp, cho phép Bạn thiết lập, cấu
                    hình, lựa chọn chức năng cho phép Dịch vụ, sản phẩm của ZION được truy cập, sử
                    dụng một, một số, hoặc toàn bộ thông tin nhất định theo tùy chọn của bạn.
                  </p>
                  <li>
                    Thông tin được tạo ra bởi hệ thống của ZION, hoặc các kênh hỗ trợ của ZION như:
                  </li>
                  <p>
                    Thông tin ghi nhận Bạn trên hệ thống như Mã định danh, AppID, và bất kỳ thông
                    tin nào khác mà hệ thống của ZION tạo ra để để nhận diện bạn khi truy cập, sử
                    dụng sản phẩm, dịch vụ của ZION.
                  </p>
                  <p>
                    Thông tin Bạn cung cấp tại các phương tiện thông tin công khai như Fanpage của
                    UniPass trên Facebook, Zalo, website, ứng dụng của ZION.
                  </p>
                  <p>
                    Thông tin khác mà ZION cần nhằm thực hiện nghĩa vụ pháp lý theo quy định pháp
                    luật, thông lệ quốc tế về cung cấp dịch vụ thanh toán, dịch vụ ngân hàng và các
                    dịch vụ liên quan.
                  </p>
                </ol>
              </ol>
              <li className="text-bold" style={{ textAlign: 'left' }}>
                Mục đích xử lý dữ liệu
              </li>
              <ol style={{ paddingLeft: '12px' }} type="a">
                <li style={{ paddingLeft: '12px' }}>Cung cấp các dịch vụ và tính năng cho Bạn</li>
                <li style={{ paddingLeft: '12px' }}>
                  Cung cấp, duy trì và cải thiện các sản phẩm và dịch vụ của ZION
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Kiểm tra, xác minh tính chính xác, trung thực, đầy đủ các thông tin cung cấp; đánh
                  giá, phân loại mức độ rủi ro theo quy định pháp luật Việt Nam;
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Bảo vệ quyền lợi của Bạn đối với các hoạt động sử dụng dịch vụ, tính năng do ZION
                  cung ứng. Trong đó bao gồm cả việc xử lý thông tin nhằm tạo thuận lợi cho Bạn
                  trong việc tiếp nhận quyền lợi qua tính năng, dịch vụ ZION cung ứng, cũng như tạo
                  điều kiện cho các Bên có nghĩa vụ liên quan nhận biết Bạn nhằm giảm thiểu các nhầm
                  lẫn khi các quyền lợi của Bạn được thực hiện;
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Xác thực thông tin, quyền truy cập của Bạn trong suốt quá trình tạo lập, sử dụng
                  dịch vụ (Trong đó bao gồm cả việc thực hiện nghĩa vụ theo thỏa thuận, hợp đồng
                  (dưới bất kỳ hình thức nào qua thao tác sử dụng dịch vụ do ZION cung ứng/phối hợp
                  cung ứng hay bằng văn bản cụ thể) giữa bạn với cơ quan, tổ chức cá nhân có liên
                  quan theo quy định pháp luật), hợp tác giữa ZION và Bạn;
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Ghi nhớ thông tin để Bạn không phải nhập lại trong quá trình truy cập hoặc lần
                  tiếp theo khi Bạn truy cập vào dịch vụ;
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Liên lạc với Bạn và đối tác về tài khoản, dịch vụ, nội dung hợp tác thông qua các
                  cuộc gọi thoại, tin nhắn SMS, ZaloOA, ZMS hoặc email hay có thể là bằng văn bản
                  hoặc các hình thức khác;
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Tạo kết nối dịch vụ giữa Tài khoản của Bạn và tài khoản hoặc nền tảng của Bên thứ
                  ba (như các tổ chức tín dụng, tổ chức cung ứng dịch vụ chuyển mạch tài chính và bù
                  trừ điện tử, Bên cung cấp hàng hóa, dịch vụ, bên thứ ba khác theo sự đồng ý của
                  Bạn trong từng trường hợp);
                  <br />
                  Kiểm tra, đánh giá khả năng tín nhiệm và khả năng thanh toán hoặc đánh giá, phát
                  hiện và/hoặc khắc phục gian lận, giả mạo hoặc các hành vi có thể bị cấm hoặc vi
                  phạm pháp luật.
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Để bảo vệ bạn khỏi những hành vi gian lận sử dụng sai mục đích các thông tin của
                  Bạn, ZION có thể thu thập thông tin về bạn và các tương tác của Bạn, đánh giá máy
                  tính, điện thoại và các thiết bị khác mà Bạn dùng để truy cập vào dịch vụ của ZION
                  để nhận dạng bất kỳ phần mềm, hay hoạt động xâm phạm nào
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Các thông tin khác để đánh giá việc đã tuân thủ, đang tuân thủ và có thể tiếp tục
                  tuân thủ tất cả các nghĩa vụ của Bạn theo các Thỏa thuận sử dụng dịch vụ Trung
                  gian thanh toán, Điều Khoản Chung, Hợp Đồng Điện Tử, các quy định, cảnh báo, thông
                  báo được ZION đăng tải công khai trên <a href='https://UniPass.vn/quy-dinh/chinhsach'> https://UniPass.vn/quy-dinh/chinhsach</a> hoặc thông tin
                  đến từng khách hàng qua tin nhắn (SMS), Notify, ZaloOA, điện thoại, hay công cụ
                  khác.
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Để thực hiện các hoạt động tuân thủ quy định pháp luật, yêu cầu của cơ quan quản
                  lý đối với hoạt động kinh doanh của ZION
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Thực hiện cam kết của ZION với các nhà cung cấp hàng hóa dịch vụ mà Bạn mua bán
                  hàng hóa, dịch vụ (không bao gồm các thông tin cá nhân theo quy định pháp luật
                  dùng để định danh Bạn);
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Quản lý truy cập, và thực hiện các biện pháp an toàn, an ninh và tính toàn vẹn cho
                  các dịch vụ mà ZION cung cấp cho Bạn.
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Hỗ trợ Bạn: Thông qua các thông tin thu thập được, ZION tiến hành các hoạt động hỗ
                  trợ một cách tốt nhất các vấn đề phát sinh đối với Bạn trong quá trình sử dụng như
                  giải quyết thắc mắc, hỗ trợ tra soát, khiếu nại, theo dõi, cải thiện dịch vụ hỗ
                  trợ khách hàng, …
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Nghiên cứu và phát triển: ZION có thể sử dụng thông tin thu thập được để phục vụ
                  công tác nghiên cứu, phát triển nhằm nâng cao chất lượng dịch vụ, quản lý và bảo
                  vệ các thông tin, hệ thống kỹ thuật dịch vụ, đo lường hiệu suất dịch vụ và cải
                  thiện sản phẩm, dịch vụ do ZION cung ứng.
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Quảng bá, giới thiệu về các sản phẩm, dịch vụ, chương trình khuyến mại, nghiên
                  cứu, khảo sát, tin tức, thông tin cập nhật, các sự kiện, các quảng cáo và nội dung
                  có liên quan về các dịch vụ của ZION và các dịch vụ của các đối tác có hợp tác với
                  ZION. Cụ thể đối với việc triển khai hoạt động tiếp thị, giới thiệu sản phẩm quảng
                  cáo:
                  <br /> Việc quảng cáo, giới thiệu sản phẩm quảng cáo (nếu có) sẽ được thực hiện
                  bằng phương thức, online/offline dưới hình thức tương ứng cách tiếp cận của bạn;
                  <br /> Nội dung, tần suất quảng cáo/giới thiệu sẽ được ZION cung cấp cụ thể trong
                  các chương trình quảng cáo, tùy theo cách tiếp cận của bạn, nội dung sản phẩm
                  quảng cáo có thể được bạn xem trực tiếp sau khi bấm vào chương trình/banner (hay
                  bất kỳ hình thức nào tương đương) đối với sản phẩm quảng cáo online; hoặc nội dung
                  sản phẩm quảng cáo có thể được bạn tiếp cận bằng cách đến trực tiếp địa điểm
                  Offline để xem/tham gia vào hoạt động quảng cáo/tiếp thị sản phẩm. Bằng các hoạt
                  động/thao tác tiếp cận sản phẩm quảng cáo, Bạn đồng ý rằng bạn cho phép ZION tiếp
                  nhận, xử lý các thông tin mà Bạn đã/đang/sẽ cung cấp cho các mục đích tại Chính
                  sách Quyền Riêng Tư này.
                  <br /> Ngoài ra, Bạn cũng đồng ý rằng, tùy theo mỗi sản phẩm quảng cáo, ZION tự
                  mình hoặc phối hợp đối tác triển khai có thể có những nội dung quy định riêng nhằm
                  hỗ trợ Bạn một cách tốt nhất, cũng như tạo điều kiện triển khai chương trình.
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Các thủ tục và yêu cầu pháp lý ZION có thể sử dụng thông tin thu thập được để điều
                  tra hoặc giải quyết các khiếu nại hoặc tranh chấp liên quan đến việc sử dụng dịch
                  vụ của Bạn, hoặc các hoạt động khác được pháp luật hiện hành cho phép.
                </li>
              </ol>
              <li className="text-bold" style={{ textAlign: 'left', paddingLeft: '12px' }}>
                Xử lý, Cung cấp, thông tin, dữ liệu cá nhân
              </li>
              <ol style={{ paddingLeft: '12px' }}>
                <li style={{ paddingLeft: '12px' }}>
                  ZION cam kết bảo mật dữ liệu cá nhân của Bạn theo đúng quy định pháp luật về bảo
                  vệ dữ liệu cá nhân. Theo đó, Bạn đồng ý rằng ZION được quyền xử lý, cung cấp, sử
                  dụng dữ liệu cá nhân của Bạn cho các mục đích đã được bạn đồng ý, khi Bạn có yêu
                  cầu và trong trường hợp pháp luật quy định (bao gồm nhưng không giới hạn các quy
                  định về hoạt động kinh doanh của ZION, các quy định pháp luật về bảo vệ dữ liệu cá
                  nhân, và các quy định liên quan).
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Trong trường hợp cần phải cung cấp dữ liệu cá nhân của Bạn đến các bên thứ ba,
                  chúng tôi đảm bảo phải được thực hiện phù hợp pháp luật về bảo mật dữ liệu. Khi
                  cần thiết, chúng tôi sẽ rà soát các chính sách bảo mật và an toàn thông tin của
                  bên nhận dữ liệu nhằm đảm bảo rằng họ đang áp dụng hoặc phải cam kết tuân thủ một
                  cách nghiêm ngặt các tiêu chuẩn tương đương với các nguyên tắc của Chính Sách
                  Quyền Riêng Tư này và các quy định của pháp luật có liên quan.
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Trong trường hợp xử lý dữ liệu cá nhân, cung cấp thông tin theo yêu cầu của Bạn,
                  yêu cầu đó có thể bằng văn bản, hoặc thông qua tính năng dịch vụ do ZION cung ứng,
                  email, điện thoại hoặc bất kỳ hình thức nào khác có ghi nhận nội dung yêu cầu hoặc
                  đồng ý của bạn.
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Trừ trường hợp quy định đặc thù của pháp luật, để cung cấp các lợi ích cho bạn và
                  bảo vệ quyền lợi của bạn, thông qua việc thực hiện các thao tác cung cấp thông tin
                  để sử dụng sản phẩm dịch vụ do Zion cung ứng, hoặc hợp tác triển khai, bạn tại đây
                  đồng ý ủy quyền cho Zion được thay mặt bạn cung cấp dữ liệu cá nhân cần thiết của
                  bạn cho tổ chức hoặc cá nhân khác. Theo đó, Zion được thay mặt bạn thực thi đầy đủ
                  các quyền của bạn (Chủ thể dữ liệu) đối với tổ chức/cá nhân được cung cấp dữ liệu.
                  Thời hạn ủy quyền kể từ ngày dữ liệu cá nhân được cung cấp cho Zion cho đến khi
                  Zion nhận được văn bản yêu cầu chấm dứt ủy quyền, mà không bị giới hạn bởi thời
                  hạn 01 năm theo Điều 563 Bộ luật dân sự 2015.
                  <br /> Để làm rõ, và phù hợp với tính chất của ứng dụng UniPass, bạn đồng ý rằng,
                  các dữ liệu Zion thu thập, xử lý được đề cập trong Chính sách bảo vệ Quyền riêng
                  tư là các thông tin mà Zion có thể thu thập, và sẽ áp dụng theo từng loại sản phẩm
                  dịch vụ trên và/hoặc liên kết và/hoặc hiển thị trên ứng dụng UniPass.
                  <br /> Để thuận tiện cho việc hỗ trợ, cung cấp sản phẩm, dịch vụ, tiện ích cho
                  bạn, với điều kiện Zion đã có những thỏa thuận phù hợp quy định pháp luật về bảo
                  vệ dữ liệu cá nhân, bạn đồng ý cho Zion cung cấp dữ liệu cá nhân của bạn cho cho
                  các tổ chức, cá nhân, là các bên cung cấp sản phẩm, dịch vụ cho bạn; cơ quan nhà
                  nước có thẩm quyền theo quy định pháp luật, hoặc nhằm thực hiện các yêu cầu phục
                  vụ cho việc xác minh, nhận diện bạn khi sử dụng dịch vụ và đồng cho các tổ chức
                  này cung cung cấp dữ liệu cá nhân của bản cho Zion.
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Bên cạnh các nội dung đã được Zion ghi nhận tại chính sách bảo vệ quyền riêng tư,
                  và Thỏa thuận này, tùy từng trường hợp cụ thể, việc xử lý dữ liệu cá nhân trong
                  các trường hợp cụ thể có thể được thực hiện theo các thỏa thuận riêng giữa bạn và
                  Zion hoặc trong các trường hợp pháp luật có quy định khác.
                </li>
                <li style={{ paddingLeft: '12px' }}>
                  Trong trường hợp bạn là trẻ em, trừ khi pháp luật có quy định khác, bạn đồng ý
                  rằng: ZION có quyền thu thập sự đồng ý của Bạn và sự đồng ý của cha, mẹ hoặc người
                  giám hộ của bạn theo quy định.
                  <br /> ZION sẽ ngừng xử lý dữ liệu cá nhân của bạn, xóa không thể khôi phục hoặc
                  hủy dữ liệu cá nhân của bạn trong trường hợp: (i) ZION đã hoàn thành mục đích xử
                  lý dữ liệu cá nhân được Bạn đồng ý; (ii) Cha mẹ, hoặc người giám hộ của Bạn rút
                  lại sự đồng ý cho phép xử lý dữ liệu cá nhân của Bạn; (iii) theo yêu cầu của cơ
                  quan có thẩm quyền khi có đủ căn cứ chứng minh việc xử lý dữ liệu cá nhân gây ảnh
                  hưởng tới quyền và lợi ích của Bạn.
                </li>
              </ol>
              <li className="text-bold" style={{ textAlign: 'left' }}>
                Thời hạn lưu trữ
              </li>
              <p style={{ textAlign: 'left' }}>
                ZION lưu trữ thông tin để thực hiện các nghĩa vụ pháp lý theo quy định pháp luật
                Việt Nam và thông lệ quốc tế lĩnh vực tài chính, và/hoặc các mục đích kinh doanh hợp
                pháp của ZION theo quy định pháp luật hiện hành.
              </p>
              <p style={{ textAlign: 'left' }}>
                Việc lưu trữ được thực hiện trên nguyên tắc đảm bảo tuân thủ các quy định pháp luật
                hiện hành, và tiêu chuẩn, thông lệ quốc tế về lưu trữ, xử lý thông tin trong lĩnh
                vực tài chính, tiền tệ mà ZION có tham gia.
              </p>
              <p style={{ textAlign: 'left' }}>
                Thời gian lưu trữ có thể được ZION thực hiện tối thiểu theo quy định pháp luật với
                từng loại thông tin thu thập, và các dịch vụ, tính năng mà Bạn sử dụng.
              </p>
              <li className="text-bold" style={{ textAlign: 'left' }}>
                Quyền của bạn đối với việc truy cập hoặc điều chỉnh, rút lại sự đồng ý, yêu cầu xóa,
                hủy các thông tin mà bạn đã cung cấp cho ZION bằng các phương thức sau:
              </li>
              <ol style={{ textAlign: 'left', paddingLeft: '12px' }}>
                <li style={{ textAlign: 'left', paddingLeft: '12px' }}>
                  Việc thực hiện quyền truy cập, chỉnh sửa dữ liệu cá nhân.
                  <br /> Nếu Bạn là chủ thể dữ liệu, tùy theo loại dịch vụ do ZION cung ứng mà bạn
                  sử dụng, bạn được quyền xem, yêu cầu chỉnh sửa dữ liệu cá nhân của mình.
                  <br /> Tùy theo khả năng hỗ trợ của ZION tại từng thời điểm, việc xem, chỉnh sửa
                  dữ liệu cá nhân của Bạn có thể thực hiện trực tiếp qua kênh mà ZION thông báo.
                  <br /> Trường hợp, web/app của ZION chưa hỗ trợ xem, chỉnh sửa trực tuyến, bạn có
                  thể thực hiện quyền truy cập, chỉnh sửa thông qua các yêu cầu tới các kênh tiếp
                  nhận hỗ được liệt kê bên dưới.
                  <br /> Bạn cam kết và đảm bảo rằng, việc truy cập vào web/app (có hỗ trợ xem,
                  chỉnh sửa dữ liệu cá nhân) cũng như việc gửi yêu cầu đến các kênh tiếp nhận hỗ trợ
                  được liệt kê bên dưới để yêu cầu xem/yêu cầu chỉnh sửa dữ liệu cá nhân là do chính
                  bạn thực hiện, hoặc do người được ủy quyền hợp pháp thực hiện, theo các thủ tục do
                  ZION quy định. ZION có quyền từ chối hỗ trợ nếu các đảm bảo, cam kết trên không
                  được thực hiện hoặc trong các trường hợp pháp luật liên quan có quy định. Việc từ
                  chối hỗ trợ (nếu có) sẽ được ZION thông báo cho bạn kèm các lý do cụ thể.
                </li>
                <li style={{ textAlign: 'left', paddingLeft: '12px' }}>
                  Rút lại sự đồng ý<br /> Việc bạn rút lại sự đồng ý không ảnh hưởng đến tính hợp
                  pháp của việc ZION xử lý dữ liệu đã được Bạn đồng ý trước khi rút lại sự đồng ý.
                  <br /> Để rút lại sự đồng ý bạn có thể thực hiện bằng cách gửi yêu cầu tới các
                  kênh tiếp nhận hỗ trợ được liệt kê bên dưới.
                </li>
                <li style={{ textAlign: 'left', paddingLeft: '12px' }}>
                  Yêu cầu xóa, hủy dữ liệu cá nhân
                  <br />
                  Bạn có thể vào bất cứ thời điểm nào gửi yêu cầu xóa, hủy dữ liệu cá nhân của Bạn
                  bằng cách gửi yêu cầu đến các kênh tiếp nhận hỗ trợ được liệt kê bên dưới.
                  <br /> Việc thực hiện yêu cầu xóa, hủy dữ liệu cá nhân của bạn sẽ được ZION thực
                  hiện trên nguyên tắc đảm bảo các quy định pháp luật về bảo vệ dữ liệu cá nhân,
                  Nghị định 13/2023/NĐ-CP, Thỏa thuận sử dụng UniPass, và các quy định pháp luật
                  hiện hành liên quan đến dịch vụ mà bạn sử dụng.
                  <br /> Bạn đồng ý rằng, tùy theo từng yêu cầu, nội dung rút lại sự đồng ý, xóa,
                  hủy dữ liệu cá nhận và vai trò sự đồng ý của bạn đối với việc sử dụng dịch vụ. Khi
                  yêu cầu rút lại sự đồng ý của bạn được xử lý, điều đó có thể có nghĩa là ZION có
                  quyền hạn chế và/hoặc không thể tiếp tục cung cấp các sản phẩm/dịch vụ cho Bạn,
                  hoặc/và cần phải chấm dứt việc hợp tác với Bạn mà ZION không phải bồi thường cho
                  bất kỳ tổn thất nào của Bạn.
                </li>
              </ol>

              <li className="text-bold" style={{ textAlign: 'left' }}>
                Các kênh tiếp nhận hỗ trợ
              </li>
              <p style={{ textAlign: 'left' }}>Bộ phận chăm sóc khách hàng:</p>
              <p style={{ textAlign: 'left' }}>Tổng đài: 1900 633 314</p>
              <p style={{ textAlign: 'left' }}>Email: <a href='mailto:unipass@vng.com.vn'> unipass@vng.com.vn</a></p>
              <p style={{ textAlign: 'left' }}>
                Gửi yêu cầu qua <a href='https://www.facebook.com/Unipass.vn'> https://www.facebook.com/Unipass.vn</a>
              </p>
              <p style={{ textAlign: 'left' }}>
                Trực tiếp tại Công ty Cổ phần ZION: Z06 Đường số 13, Phường Tân Thuận Đông, Quận 7,
                Tp. Hồ Chí Minh, Việt Nam.
              </p>

              <li className="text-bold" style={{ textAlign: 'left' }}>
                Sửa đổi, bổ sung, thay đổi chính sách quyền riêng tư.
              </li>
              <p style={{ textAlign: 'left' }}>
                Bất kỳ sửa đổi, bổ sung, thay thế nào đối với Chính sách Quyền riêng tư này sẽ được
                thông báo bởi ZION trên website chính thức <a href='https://unipass.vn'> https://unipass.vn</a>.
              </p>

              <li className="text-bold" style={{ textAlign: 'left' }}>
                Luật điều chỉnh, hiệu lực
              </li>
              <p style={{ textAlign: 'left' }}>
                Luật điều chỉnh: Chính sách quyền riêng tư này được giải thích và thi hành theo Pháp
                luật Việt Nam./.
                <br /> Các nội dung tại Chính sách Quyền Riêng Tư này được ZION thông báo đến Bạn,
                và công bố công khai trên <a href='https://unipass.vn/quy-dinh/chinhsach'> https://unipass.vn/quy-dinh/chinhsach</a>
              </p>
            </ol>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Degree13Page
