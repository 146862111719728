import React, { useEffect } from 'react'
import { setTitle } from '../../utils/helper'
import { useLocation, useHistory } from 'react-router-dom'
import { getGiftDetail } from '../../actions/promotion.action'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../components/loading'
import Button from '@material-ui/core/Button'
import { color } from '../../utils/color'

const styles = {
  container: {
    backgroundColor: '#F8F8F8',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    zIndex: 1000,
    overflowY: 'auto'
  },
  background: {
    background: 'linear-gradient(90deg, #006DFE 0%, #00ABF9 98.91%)',
    width: '100%',
    height: 120,
    borderBottomRightRadius: '40%',
    borderBottomLeftRadius: '40%',
    position: 'absolute'
  },
  contentWrapper: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '90%',
    height: 'auto',
    marginTop: 17,
    borderRadius: 8,
    paddingBottom: 40
  },
  img: { width: 50, height: 50, marginTop: 20, borderRadius: 8 },
  name: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 20,
    color: color.black[600]
  },
  eventTime: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: 12,
    backgroundColor: '#E6E6E6',
    width: 'fit-content',
    padding: '4px 10px',
    borderRadius: 8,
    color: color.black[600]
  },
  line: {
    border: '1px dashed #E6E6E6',
    width: '95%',
    margin: '20px 0px 22px 0px'
  },
  content: { color: color.black[600], textAlign: 'left' },
  button: {
    width: '90%',
    backgroundColor: color.blue[350],
    color: 'white',
    fontSize: 15,
    height: 48
  }
}
export default function GiftDetail() {
  setTitle('Quà tặng của bạn')
  const dispatch = useDispatch()
  const history = useHistory()
  const { giftDetail, loading } = useSelector((state) => state.promotion)
  const location = useLocation()
  const gift = location.state.gift
  useEffect(() => {
    if (gift && gift.id) dispatch(getGiftDetail(gift.id))
  }, [gift.id])
  const backHome = () => {
    history.replace('/')
  }
  return (
    <div className="d-flex justify-content-center" style={styles.container}>
      <Loading loading={loading} />
      <div style={styles.background} />
      <div
        style={styles.contentWrapper}
        className="d-flex justify-content-center flex-column align-items-center"
      >
        <img style={styles.img} src={giftDetail.campaign_icon} alt="" />
        <div style={styles.name}>{giftDetail.campaign_name}</div>
        <div style={styles.eventTime}>{`Hạn sử dụng: ${giftDetail.eventTime}`}</div>
        <hr style={styles.line} />
        <div className="mx-3 mb-4">
          <div style={styles.content} dangerouslySetInnerHTML={{ __html: giftDetail.gift_term }} />
          <div
            style={styles.content}
            dangerouslySetInnerHTML={{ __html: giftDetail.gift_guildline }}
          />
        </div>
      </div>
      <div
        className="bottom-fixed-button-wrapper"
        style={{ padding: '12px 0', zIndex: 1, backgroundColor: '#F8F8F8' }}
      >
        <Button
          variant="contained"
          onClick={backHome}
          fullWidth
          style={styles.button}
          className="text-bold"
        >
          Quay lại trang chủ
        </Button>
      </div>
    </div>
  )
}
