import styled from 'styled-components'
export const MonthLyTicketLinkPageWrapper = styled.div`
  padding: 10px 15px 40px;
  background: #e5e5e5;
  height: 100vh;
  overflow: auto;
`

export const ItemWrapper = styled.div`
  background: #ffffff;
  margin-top: 10px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 6px;
  opacity: ${(props) => (props.expire ? '0.5' : '1')};
`

export const TitleText = styled.span`
  color: #7a7c8b;
  font-size: 14px;
`
export const ContentText = styled.span`
  color: #31333f;
  font-size: 14px;
`
export const LinkImage = styled.div`
    border-left: 6px dotted #F2F2F2;
    height:auto;
    width:fit-content;
  }
`
