import styled from 'styled-components'

export const ToastWrapper = styled.div`
  && {
    position: fixed;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    white-space: nowrap;
    text-align: center;
    .icon {
      font-size: 30px;
      vertical-align: middle;
      margin-right: 5px;
    }
    .message {
      vertical-align: middle;
    }
  }
`
