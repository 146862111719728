import styled from 'styled-components'
import { COLOR_DARK_GREY } from '../../../constants/Styles'

export const NewQRWrapper = styled.div`
  //border-top: 1px dashed #A4A4A4;
  //border-bottom: 1px dashed #A4A4A4;
  padding: 10px;
`

export const QRCodeBody = styled.div`
  position: relative;

  > canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 190px;
  }
`

export const RemainTime = styled.span`
  && {
    font-weight: bold;
    margin: 0;
    display: inline-block;
    width: 23px;
    color: #e85858 !important;
    font-size: 14px;
  }
`

export const TimingWrapper = styled.div`
  //padding-top: 10px;
  height: 20px;
  padding-bottom: 30px;
  .completed {
    > span {
      margin-right: 7px;
      vertical-align: middle;
    }
  }
  .running {
    padding: 0;
    min-width: unset;
    color: ${COLOR_DARK_GREY};
    .refresh {
      margin-right: 4px;
      vertical-align: middle;
    }
  }
`

export const QRTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: #333333;
`

export const QRArrow = styled.img`
  vertical-align: top;
  margin-top: -21px;
`

export const QRWrapper = styled.span`
  margin-right: 37px;
`
