import React, { Component } from 'react'
import { AlertIcon, AlertText, ZPAlertStyle, ZPUnbindAlertWrapper } from './style'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

class ZPUnbindAlert extends Component {
  constructor(props) {
    super(props)
  }

  handleClose = () => {}

  render() {
    const { visibility } = this.props
    return (
      <ZPUnbindAlertWrapper>
        <Dialog
          open={visibility}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={ZPAlertStyle}>
            <AlertIcon src="/img/icons/unbind_ok.svg" />
            <AlertText>Đã huỷ Thanh toán tự động thành công</AlertText>
          </DialogContent>
        </Dialog>
      </ZPUnbindAlertWrapper>
    )
  }
}

export default ZPUnbindAlert
