import React from 'react'
import { setTitle } from '../../utils/helper'
import { useHistory, useLocation } from 'react-router-dom'
import Campaign from './components/compaign'
import NoticePage from '../../components/NoticePage'

const styles = {
  container: {
    backgroundColor: '#F8F8F8',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    overflowY: 'auto'
  }
}

export default function CampaignListPage() {
  setTitle('Ưu đãi dành cho bạn')
  const history = useHistory()
  const location = useLocation()
  const listCampaign = location.state.listCampaign

  const onGoViewDetail = (item) => {
    history.push({ pathname: '/campaign-detail', state: { campaign: item } })
  }
  return (
    <div style={styles.container}>
      <div className="m-3">
        {listCampaign && listCampaign.length ? (
          <>
            {listCampaign.map((item, key) => (
              <Campaign item={item} key={key} goDetail={onGoViewDetail} />
            ))}
          </>
        ) : (
          <NoticePage
            img={'/img/promotion/initial-page-campaign-list.svg'}
            title={'Chưa có chương trình ưu đãi'}
          />
        )}
      </div>
    </div>
  )
}
