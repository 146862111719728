import React, { Component, Fragment } from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import MobileDetect from 'mobile-detect'

import { UserInfoHeaderWrapper } from './style'
import { getCardName, friendlyPhone, removeStorageLogout } from '../../utils/helper'
import { COLOR_BLUE, COLOR_BLACK } from '../../constants/Styles'
import { CARD_TYPE_ID } from '../../constants/Variables'
import { logout } from '../../actions/auth.action'
import DialogAlert from '../ZPDialog'
import Avatar from '../Avatar'
import { postApi } from '../../utils/apiCaller'

const description = (
  <ul style={{ paddingLeft: 20, textAlign: 'left', margin: 0 }}>
    <li style={{ color: COLOR_BLUE }}>
      <span style={{ color: COLOR_BLACK }}>
        Phổ thông: <span className="medium">Vé phổ thông</span>
      </span>
    </li>
    <li style={{ color: COLOR_BLUE }}>
      <span style={{ color: COLOR_BLACK }}>
        Học sinh, sinh viên: <span className="medium">Vé giảm</span>
      </span>
    </li>
    <li style={{ color: COLOR_BLUE }}>
      <span style={{ color: COLOR_BLACK }}>
        Người già, người khuyết tật, thương binh: <span className="medium">Vé miễn</span>
      </span>
    </li>
  </ul>
)

class UserInfoHeader extends Component {
  constructor(props) {
    super(props)

    const md = new MobileDetect(window.navigator.userAgent)
    if (md.is('AndroidOS')) {
      if (typeof window.setIcon === 'function') {
        // window.setIcon();
      }
    }

    this.state = {
      isOpenBottomDrawer: false,
      isIOS: !md.is('AndroidOS'),
      dialog: {
        open: false,
        id: 0,
        leftButtonTitle: '',
        rightButtonTitle: '',
        description: ''
      }
    }

    window.UserInfoHeaderComponent = this
  }

  componentWillUnmount() {
    if (typeof window.removeIcon === 'function') {
      window.removeIcon()
    }
  }

  onOpenDialogTicketInfo = () => {
    this.setState({
      dialog: {
        open: true,
        id: 1,
        leftButtonTitle: '',
        rightButtonTitle: 'Đăng ký vé giảm, vé miễn',
        description
      }
    })
  }

  onDialogRightConfirm = () => {
    const { id } = this.state.dialog
    if (id === 1) {
      this.setState((prevState) => ({
        dialog: {
          ...prevState.dialog,
          open: false,
          id: 0
        }
      }))
      this.props.history.push('/card/receive')
      return
    }

    if (id === 2) {
      this.setState((prevState) => ({
        dialog: {
          ...prevState.dialog,
          open: false,
          id: 0
        }
      }))
    }
  }

  onDialogLeftConfirm = () => {
    const { id } = this.state.dialog
    if (id === 2) {
      this.logout()
    }
  }

  onOpenDrawer() {
    this.setState({
      isOpenBottomDrawer: true
    })
  }

  onToggleDrawer = (open) => () => {
    this.setState({
      isOpenBottomDrawer: open
    })
  }

  onOpenLogoutDialog = () => {
    this.setState({
      dialog: {
        open: true,
        id: 2,
        description: 'Bạn muốn đăng xuất tài khoản UniPass này?',
        rightButtonTitle: 'Ở lại',
        leftButtonTitle: 'Đăng xuất'
      }
    })
  }

  onCloseClickOut = () => {
    const { id } = this.state.dialog
    if (id === 1) {
      this.setState((prevState) => ({
        dialog: {
          ...prevState.dialog,
          open: false,
          id: 0
        }
      }))
    }
  }

  logout = async () => {
    // localStorage.removeItem("user");
    await postApi({
      endpoint: 'authentication/logout',
      body: {
        cm: 'logout'
        // dt: {""
        // 	phone: this.props.auth.user.phone
        // }
      }
    })

    removeStorageLogout()
    this.props.logout()
    this.onChangeRoute('/login')
  }

  onChangeRoute = (to) => {
    this.props.history.push(to)
  }

  render() {
    const { user } = this.props
    const { dialog } = this.state

    const { open, leftButtonTitle, rightButtonTitle, description } = dialog

    const { avatar = '', phone, card_type = 1, account_name } = user

    return (
      <Fragment>
        <UserInfoHeaderWrapper>
          <TableBody>
            <TableRow>
              {card_type !== CARD_TYPE_ID.PT && (
                <TableCell className="avatar">
                  <Avatar hasBorder={true} src={avatar} />
                </TableCell>
              )}
              <TableCell className="info">
                <p className="name">{friendlyPhone(account_name)}</p>
                <p className="ticket-type" onClick={this.onOpenDialogTicketInfo}>
                  <span className="ticket-name">{getCardName(card_type)} </span>
                  <span className="icon-dialoge_infor_white ticket-info"></span>
                </p>
              </TableCell>
            </TableRow>
          </TableBody>
        </UserInfoHeaderWrapper>
        <DialogAlert
          open={open}
          rightButtonTitle={rightButtonTitle}
          leftButtonTitle={leftButtonTitle}
          description={description}
          onRightConfirm={this.onDialogRightConfirm}
          onLeftConfirm={this.onDialogLeftConfirm}
          onCloseClickOut={this.onCloseClickOut}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    logout: () => {
      dispatch(logout())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserInfoHeader))
