import { Button } from 'antd'
import './styles.scss'

export default function BasicButton({ className, children, ...props }) {
  return (
    <Button className={`basic-button-custom ${className}`} {...props}>
      {children}
    </Button>
  )
}
