import * as Types from './../constants/ActionType'

const initialState = {
  cars: [],
  carsIncludingPosition: []
}

const currentRoute = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_CARS:
      const carsWithouPosition = action.cars.map((car) => ({
        carNumber: car.carNumber,
        carId: car.carId,
        epCode: car.epCode
      }))
      state = { ...state, cars: carsWithouPosition, carsIncludingPosition: action.cars }

      return state
    default:
      return state // copy new array and return it
  }
}

export default currentRoute
