import React from 'react'
import { getMobileOperatingSystem } from '../../../utils/helper'

const ZaloPay = window.ZaloPay

const callAPIToOpenFacebookApp = () => {
  if (typeof ZaloPay !== 'undefined' && ZaloPay.isZaloPay) {
    ZaloPay.launchExternalApp({
      url: 'fb://page/640990189682280',
      packageId: 'com.facebook.katana'
    })
  }
}

export default (props) => {
  return (
    <>
      {getMobileOperatingSystem() === 'iOS' ? (
        <a href="fb://profile/640990189682280">{props.children}</a>
      ) : (
        <a href="" onClick={callAPIToOpenFacebookApp}>
          {props.children}
        </a>
      )}
    </>
  )
}
