import { REACT_APP_BUS_APP_ID, REACT_APP_ZALO_ID, REACT_APP_ZALO_REDIRECT_URL } from './Env'

export const API_URL = ''
export const ZALOPAY_APP_ID = Number(REACT_APP_BUS_APP_ID)
export const APP_VERSION = '1.39'

export const ZALO_REDIRECT = `https://oauth.zaloapp.com/v4/permission?app_id=${REACT_APP_ZALO_ID}&redirect_uri=${REACT_APP_ZALO_REDIRECT_URL}&state=isCallback`

export const CARD_NO_HASH_KEY = 'PsQOcz9F9ar1zImrnP2cqlWOl7L8T3'

export const LASTEST_SPEEDS_THRESHOLD = 10
export const FIFTEEN_SECOND = 15
export const PERIOD_TO_CALL_WEATHERAPI = 60 * 1000
export const SMALLEST_METERS_TO_UPDATE_HEADING = 4
export const MAINMENU_KEY = 'MAIN_MENU'
export const RENDERED_MAINMENU_KEY = 'RENDERED_MAIN_MENU'
export const INITIAL_ZOOM_LEVEL = 10

export const MAP_FEATURES_STYLES = [
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
]

export const RESTRICTED_AREA = {
  east: 107.142716,
  north: 11.167899,
  south: 10.359779,
  west: 106.133347
}

export const centerPoint = {
  lat: 10.758107,
  lng: 106.745642
}

export const MAP_OPTIONS = {
  zoom: INITIAL_ZOOM_LEVEL,
  center: centerPoint,
  zoomControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  restriction: {
    latLngBounds: RESTRICTED_AREA,
    strictBounds: true
  },
  styles: MAP_FEATURES_STYLES
}

export const PROMOTION = {
  limitDateWarning: 72, //hour
  limitShowListGift: 2,
  limitShowListCampaign: 2,
  limitShowNoTransacion: 3,
  limitShowReceiveGift: 3
}
