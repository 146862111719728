export const loadImage = (imageUrl, setLoading = (v) => {}, setUrlData) => {
  setLoading(true)
  const img = new Image()
  img.src = imageUrl
  img.crossOrigin = 'anonymous'
  try {
    img.onload = () => {
      const { width, height } = img
      const canvas = document.createElement('canvas')
      if (width > height) {
        canvas.width = height
        canvas.height = width
      } else {
        canvas.width = width
        canvas.height = height
        setLoading(false)
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        setUrlData(canvas.toDataURL())
        return
      }
      const context = canvas.getContext('2d')
      const centerX = canvas.width / 2
      const centerY = canvas.height / 2
      context.translate(centerX, centerY)
      context.rotate((90 * Math.PI) / 180)
      context.drawImage(img, -width / 2, -height / 2, width, height)

      setUrlData(canvas.toDataURL())
      setLoading(false)
    }
    img.onerror = (err) => {
      setLoading(false)
    }
  } finally {
    setLoading(false)
  }
}
