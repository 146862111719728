import FormControl from '@material-ui/core/FormControl'
import CryptoJS from 'crypto-js'
import MobileDetect from 'mobile-detect'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setAuth } from '../actions/auth.action'
import { setNotification } from '../actions/common.action'
import ZPButton from '../components/ZPButton'
import DialogAlert from '../components/ZPDialog'
import SuccessfullyRegisteredPopup from '../components/ZPDialog/SuccessfullyRegisteredPopup'
import ZPTextField from '../components/ZPTextField'
import { REACT_APP_APP_CLIENT_KEY_SS0 } from '../constants/Env'
import { put } from '../utils/apiCallerV2'
import {
  checkNullOrUndefine,
  friendlyDate,
  removeBodyBg,
  setTitle,
  simplifyFieldsName
} from '../utils/helper'
import './styles/loading.css'

const titleImageStyle = {
  textAlign: 'left',
  paddingLeft: '12px',
  color: '#b6b8b8',
  fontSize: '13px'
}

const imageLink = 'https://admin.unipass.vn'

class ConfirmInfo extends Component {
  constructor(props) {
    super(props)
    setTitle('Xác nhận thông tin')
    removeBodyBg()

    this.state = {
      isDisabledContinuteButton: false,
      customerData: {},
      dialog: {
        open: false,
        id: 0,
        leftButtonTitle: '',
        rightButtonTitle: '',
        description: ''
      },
      showLoading: false,
      hasRegisteredSuccessfully: false
    }

    if (this.props.location.state.customerData !== null) {
      this.state.customerData = this.props.location.state.customerData
    }
  }

  componentWillMount = () => {
    let { customerData } = this.state
    customerData = {
      ...customerData,
      userType: '4',
      registration_type: '2',
      userPriorityType: '1'
    }
    this.setState({
      customerData
    })
  }

  componentDidMount = () => {
    const { customerData } = this.state
    delete customerData.ignore_whitespace
    delete customerData.allow_display_name
    delete customerData.require_display_name
    delete customerData.allow_utf8_local_part
    delete customerData.require_tld
  }

  handleSubmit = (e) => {
    this.onCreateCustomer()
  }

  onOpenDialogInfo = () => {
    // this.setState({
    // 	dialog: {
    // 		open: true,
    // 		id: 1,
    // 		leftButtonTitle: "",
    // 		rightButtonTitle: "Trải nghiệm ngay",
    // 		// description:"Bạn đã tạo tài khoản thành công."
    // 		description: (
    // 			<Fragment>
    // 				<p style={{margin:0}}>Cập nhật thành công! Tài khoản đã được kích hoạt.  </p>
    // 				{/* <p style={{margin:0, textAlign:"left", marginTop:"10px"}}>Tài khoản đã được kích hoạt. </p> */}
    // 				<p style={{margin:0, textAlign:"left",  marginTop:"10px"}}>Mức giá phổ thông được áp dụng.</p>
    // 				<p style={{margin:0, textAlign:"left", marginTop:"10px"}}>Liên hệ nhận thẻ UniPass:</p>
    // 				<ul style={{paddingLeft:"15px", marginTop:"0px"}}>
    // 					<li style={{textAlign:"left"}}>Địa điểm: <b>Trạm xe buýt Sài Gòn</b> - 01 Phạm Ngũ Lão, P. Nguyễn Thái Bình, Q. 1, TPHCM. </li>
    // 					<li style={{textAlign:"left"}}>Thời gian: 8h - 16h, thứ 6 hàng tuần. </li>
    // 				</ul>
    // 				<p style={{margin:0, textAlign:"left"}}>Thông tin liên hệ:</p>
    // 				<ul style={{paddingLeft:"15px", marginTop:"0px"}}>
    // 					<li style={{textAlign:"left"}}>Fanpage <a href="https://www.facebook.com/Unipass.vn/">https://www.facebook.com/Unipass.vn/</a></li>
    // 					<li style={{textAlign:"left"}}>Hotline 1900545436</li>
    // 				</ul>
    // 			</Fragment>
    // 		)
    // 	}
    // });

    this.setState({
      hasRegisteredSuccessfully: true
    })
  }

  onOpenDialogInfoFaild = (err) => {
    this.setState({
      dialog: {
        open: true,
        id: 1,
        leftButtonTitle: '',
        rightButtonTitle: 'Đã hiểu',
        description: err
      }
    })
  }

  onDialogRightConfirm = () => {
    this.setState({
      dialog: {
        open: false
      }
    })
    this.props.history.push({
      pathname: '/'
    })
  }

  onCreateCustomer = () => {
    const { customerData } = this.state
    let {
      customer_name,
      gender,
      birthday,
      email,
      address,
      userType,
      phone,
      userPriorityType,
      card_file,
      // school_name,
      // student_code,
      // graduation_time,
      // idpp,
      // idpp_front_image,
      // incident_certificate,
      // idpp_after_image,
      // certification_file,
      // birth_certificate,
      // student_card_image,
      // customer_type,
      registration_type
    } = customerData
    const ssoKey = REACT_APP_APP_CLIENT_KEY_SS0
    let reqTime = Date.now()
    phone = checkNullOrUndefine(phone)
    gender = checkNullOrUndefine(gender)
    customer_name = checkNullOrUndefine(customer_name)
    birthday = checkNullOrUndefine(birthday)
    email = checkNullOrUndefine(email)
    address = checkNullOrUndefine(address)
    userType = checkNullOrUndefine(userType)
    userPriorityType = checkNullOrUndefine(userPriorityType)
    card_file = checkNullOrUndefine(card_file)
    // school_name = checkNullOrUndefine(school_name);
    // student_code =  checkNullOrUndefine(student_code);
    // graduation_time =  checkNullOrUndefine(graduation_time);
    // idpp =  checkNullOrUndefine(idpp);
    // idpp_front_image =  checkNullOrUndefine(idpp_front_image);
    // incident_certificate =  checkNullOrUndefine(incident_certificate);
    // idpp_after_image =  checkNullOrUndefine(idpp_after_image);
    // certification_file =  checkNullOrUndefine(certification_file);
    // birth_certificate =  checkNullOrUndefine(birth_certificate);
    // student_card_image =  checkNullOrUndefine(student_card_image);
    userType = checkNullOrUndefine(userType)
    registration_type = checkNullOrUndefine(registration_type)
    reqTime = checkNullOrUndefine(reqTime)
    // console.log(`${phone}|${gender}|${customer_name}|${birthday}|${email}|${address}|${userType}|${userPriorityType}|${card_file}|${school_name}|${student_code}|${graduation_time}|${idpp}|${idpp_front_image}|${incident_certificate}|${idpp_after_image}|${certification_file}|${birth_certificate}|${student_card_image}|${userType}|${registration_type}|${reqTime}`)
    // const clientSignature = CryptoJS.HmacSHA256(`${phone}|${gender}|${customer_name}|${birthday}|${email}|${address}|${userType}|${userPriorityType}|${card_file}|${school_name}|${student_code}|${graduation_time}|${idpp}|${idpp_front_image}|${incident_certificate}|${idpp_after_image}|${certification_file}|${birth_certificate}|${student_card_image}|${userType}|${registration_type}|${reqTime}`, ssoKey).toString();
    const clientSignature = CryptoJS.HmacSHA256(
      `${phone}|${gender}|${customer_name}|${birthday}|${email}|${address}|${userType}|${userPriorityType}|${card_file}|||||||||||${userType}|${registration_type}|${reqTime}`,
      ssoKey
    ).toString()
    this.setState({
      showLoading: true
    })
    const data = {
      ...this.props.auth.user,
      ...simplifyFieldsName(customerData),
      sso_sig: clientSignature,
      rq_t: reqTime,
      gender: Number(gender),
      avatar: card_file ? card_file : this.props.auth.user.avatar,
      app: true
    }
    put('v2/customer/update', data).then((res) => {
      this.setState({
        showLoading: false
      })
      if (res.error.code !== 1) {
        this.onOpenDialogInfoFaild(res.msg)
      } else {
        this.onOpenDialogInfo()
      }
    })
    // postApi({
    // 	endpoint: "api/customer/update",
    // 	body: {
    // 		cm: "update",
    // 		dt: {
    // 			...simplifyFieldsName(customerData),
    // 				sso_sig: clientSignature,
    // 				rq_t: reqTime,
    // 				cer_f: "",
    // 				idpp: "",
    // 				idpp_fi: "",
    // 				idpp_ai: "",
    // 				b_cer: "",
    // 				in_cer: "",
    // 				sch_na: "",
    // 				stu_c: "",
    // 				gra_yr: "",
    // 				stu_ci: "",

    // 			}
    // 	}
    // }).then(res => {
    // 	this.setState({
    // 		showLoading: false
    // 	})
    // 	if(res.err){
    // 		this.onOpenDialogInfoFaild(res.msg)
    // 	}else{
    // 		this.onOpenDialogInfo();
    // 	}

    // })
  }

  render() {
    const { customerData, isDisabledContinuteButton, dialog, showLoading } = this.state

    const {
      customer_name,
      gender,
      birthday,
      email,
      address,
      // userType,
      phone,
      // userPriorityType,
      card_file,
      account_name
    } = customerData

    const { open, leftButtonTitle, rightButtonTitle, description } = dialog

    let genderString
    if (gender === '0') {
      genderString = 'Nam'
    } else if (gender === '1') {
      genderString = 'Nữ'
    }

    const userTypeString = 'Phổ thông'

    const md = new MobileDetect(window.navigator.userAgent)
    let formattedBirthdayForPlatform
    if (typeof birthday !== 'undefined') {
      formattedBirthdayForPlatform = friendlyDate(birthday)
      // if (!md.is("AndroidOS")) {
      //   const tmp = birthday.split(" ")[0];
      //   const date = tmp.split("-");
      //   formattedBirthdayForPlatform = date[2] + "/" + date[1] + "/" + date[0];
      // }
    }

    const scrollStyle = {
      overflow: 'scroll',
      height: '100vh'
    }
    const displayNone = {
      display: 'none'
    }

    let loadingStyle = {
      display: 'block'
    }
    let confirmStyle = {
      display: 'block'
    }

    if (showLoading) {
      confirmStyle = displayNone
    } else {
      loadingStyle = displayNone
    }

    return (
      <Fragment>
        {this.state.hasRegisteredSuccessfully ? <SuccessfullyRegisteredPopup /> : null}
        <div className="diaglog-loading" style={loadingStyle}>
          <div className="container-loading">
            <div></div>
            <div></div>
            <div></div>
            <p>Vui lòng chờ</p>
          </div>
        </div>
        <div style={{ ...confirmStyle, ...scrollStyle }}>
          <div style={{ paddingBottom: 85 }}>
            <FormControl fullWidth>
              <ZPTextField
                // style={{fontSize:"20px"}}
                label="Họ và tên"
                disabled="true"
                name="customer_name"
                value={customer_name}
              />
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField label="Giới tính" disabled="true" name="gender" value={genderString} />
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField
                label="Ngày sinh"
                name="birthday"
                onClick={this.onOpenDatePicker}
                value={formattedBirthdayForPlatform}
                readOnly={true}
                disabled="true"
              />
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField
                label="Số điện thoại"
                name="phone"
                disabled="true"
                value={account_name}
              />
            </FormControl>
            <FormControl fullWidth>
              <ZPTextField label="Email" name="email" value={email} disabled="true" />
            </FormControl>
            {address && (
              <FormControl fullWidth>
                <ZPTextField label="Địa chỉ" name="address" value={address} disabled="true" />
              </FormControl>
            )}
            <FormControl fullWidth className="last-block">
              <ZPTextField
                label="Đối tượng"
                name="userType"
                value={userTypeString}
                disabled="true"
                isLast={true}
              />
            </FormControl>
            {/* {card_file && (
              <div>
                <p style={titleImageStyle}>Ảnh làm thẻ</p>
                <img
                  alt="avatar"
                  src={`${imageLink +
                    (card_file ? card_file : this.props.auth.user.avatar)}`}
                  style={{ width: "calc(100% - 24px)" }}
                />
              </div>
            )} */}
            {card_file && (
              <div>
                <p style={titleImageStyle}>Ảnh làm thẻ</p>
                <img
                  alt="avatar"
                  src={`${card_file ? card_file : this.props.auth.user.avatar}`}
                  style={{ width: 'calc(100% - 24px)' }}
                />
              </div>
            )}
            <DialogAlert
              open={open}
              rightButtonTitle={rightButtonTitle}
              leftButtonTitle={leftButtonTitle}
              description={description}
              onRightConfirm={this.onDialogRightConfirm}
              onLeftConfirm={this.onDialogLeftConfirm}
              onCloseClickOut={this.onCloseClickOut}
            />
          </div>
          <div
            className="bottom-fixed-button-wrapper"
            style={{ padding: '12px 0', backgroundColor: '#fff', zIndex: 1 }}
          >
            <ZPButton
              disabled={isDisabledContinuteButton}
              onClick={this.handleSubmit.bind(this)}
              fullWidth
              style={{ margin: 0 }}
            >
              Hoàn Thành
            </ZPButton>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    common: state.common
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setAuth: (user) => {
      dispatch(setAuth(user))
    },
    setNotification: (notification) => {
      dispatch(setNotification(notification))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmInfo))
