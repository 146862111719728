import React, { PureComponent } from 'react'

import { SelectWrapper } from './style'

class ZPSelect extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isFocus: false,
      value: this.props.value || ''
    }
  }

  componentDidMount() {
    const { autoFocus } = this.props

    if (autoFocus) {
      this.setState({
        isFocus: true
      })
    }
  }

  onChange = (e) => {
    const { value } = e.target
    this.setState({
      value
    })
    this.props.onChange(e)
  }

  onFocus = (name) => {
    this.setState({
      isFocus: true
    })
  }

  onBlur = () => {
    this.setState({
      isFocus: false
    })
  }

  render() {
    const { isFocus } = this.state
    const { onChange, label, name, value, disabled, isLast } = this.props

    return (
      <SelectWrapper isFocus={isFocus} isHaveValue={value} isDisabled={disabled} isLast={isLast}>
        <label className="label">{label}</label>
        <select
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          name={name}
          value={value}
          onChange={onChange}
        >
          {this.props.children}
        </select>
        <span className="icon-arrow_next"></span>
      </SelectWrapper>
    )
  }
}

export default ZPSelect
