// Page Title
export const PAYMENT_OPTION = 'Phương thức thanh toán'
export const MONTHLY_TICKET_OPTION = 'Vé tập'
export const AUTODEBIT_DETAIL = 'Chi tiết thanh toán tự động'
export const TRANSACTION_DETAIL = 'Chi tiết giao dịch'
export const TRANSACTION_HISTORY = 'Lịch sử giao dịch'
export const HOMEPAGE = 'UniPass - Vé xe buýt điện tử'

// Page Url
export const PAYMENT_OPTION_URL = '/payment/option'
export const AUTODEBIT_DETAIL_URL = '/autodebit/detail'
