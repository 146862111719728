import styled from 'styled-components'

export const MonthLyTicketLinkContainerWrapper = styled.div`
  padding: 10px 20px 0;
`

export const WrapperText = styled.div`
  padding: 20px 0 0;
`

export const LinkImage = styled.img`
  width: auto;
  height: 100%;
  margin-top: 30px;
`

export const checkBoxStyle = {
  textAlign: 'left',
  color: '#000000'
}
export const LinkWrapper = styled.div`
  > div {
    height: 50px;
    display: flex;
    background: #006dff;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 0 30px;
  }

  margin: 50px auto auto;
  width: fit-content;
`
export const LinkTitle = styled.span`
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
`
