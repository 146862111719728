import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { CounterImage, RechargeCounterWrapper } from './style'
import { REACT_APP_GOOGLE_MAPS_API_KEY as GOOGLE_MAPS_API_KEY } from '../../constants/Env'

const COUNTER_LOCATION = {
  lat: 10.7678984,
  lng: 106.6900624,
  name: 'Tru nap'
}

class RechargeCounterContainer extends Component {
  handleApiLoaded = (map, maps) => {
    const infowindow = new maps.InfoWindow({
      content: 'Trụ nạp tiền Unipass'
    })
    const boothMarker = new maps.Marker({
      position: COUNTER_LOCATION,
      map,
      title: 'Hello World!'
    })

    boothMarker.addListener('click', () => {
      infowindow.open(map, boothMarker)
    })

    infowindow.open(map, boothMarker)
  }

  render() {
    return (
      <RechargeCounterWrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          defaultCenter={COUNTER_LOCATION}
          defaultZoom={16}
          options={{
            gestureHandling: 'greedy'
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        ></GoogleMapReact>
        <p>Chọn hình bên trên để mở bản đồ chỉ đường</p>
        <div>
          <CounterImage src="/img/icons/counter.png" />
          <p>Tìm trụ nạp tiền mặt tại công viên 23 tháng 09</p>
        </div>
      </RechargeCounterWrapper>
    )
  }
}

export default RechargeCounterContainer
