import FormGroup from '@material-ui/core/FormGroup'
import _ from 'lodash'
import React from 'react'
import { Controller } from 'react-hook-form'
import TextField from '../../../components/TextField'
import { color } from '../../../utils/color'

const styles = {
  label: { textAlign: 'start', fontSize: 14 },
  error: { color: color.red[450], textAlign: 'start' }
}

export default function Field({ defaultValue, form, field, colProps = {} }) {
  const {
    type,
    name,
    label,
    options: origOptions = [],
    required,
    getOptions,
    placeholder,
    props,
    deps: _deps,
    rules,
    getOptionValue: _getOptionValue,
    getOptionLabel: _getOptionLabel
  } = field
  const {
    control,
    setValue,
    formState: { errors },
    watch
  } = form
  const error = _.get(errors, name)
  const renderController = ({ field }) => {
    switch (type) {
      case 'input':
        return (
          <div style={{ width: '100%' }}>
            <TextField
              {...props}
              {...field}
              error={!!error}
              id={name}
              placeholder={placeholder}
              label={label}
              variant="outlined"
              fullWidth
            />
          </div>
        )

      default:
        return null
    }
  }

  return (
    <FormGroup style={{ marginBottom: 16, width: '100%' }} className="d-flex align-items-center">
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        render={renderController}
      />
      {error && (
        <div className="w-100">
          <div style={styles.error}>{error.message}</div>
        </div>
      )}
    </FormGroup>
  )
}
