import React, { Component } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { checkBoxStyle } from '../../pages/styles/recharge-option-style'
import Checkbox from '@material-ui/core/Checkbox'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircle from '@material-ui/icons/CheckCircle'
import FormControl from '@material-ui/core/FormControl'
import { ZPLinkTermContainerWrapper } from './style'

class RechargeOnlineTermContainer extends Component {
  //   constructor(props) {
  //     super(props);
  //   }

  render() {
    return (
      <ZPLinkTermContainerWrapper>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              checked={true}
              value="end"
              style={checkBoxStyle}
              label="Tiền được cộng vào ví UniPass sau khi nạp"
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CheckCircle />}
                  color="primary"
                />
              }
            />
            <FormControlLabel
              checked={true}
              value="end"
              style={checkBoxStyle}
              label="Tự động trả tiền từ ví UniPass"
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CheckCircle />}
                  color="primary"
                />
              }
            />
            <FormControlLabel
              checked={true}
              value="end"
              style={checkBoxStyle}
              label="Tiền chỉ có thể dùng đi buýt, không thể rút ra"
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CheckCircle />}
                  color="primary"
                />
              }
            />
          </FormGroup>
        </FormControl>
      </ZPLinkTermContainerWrapper>
    )
  }
}

export default RechargeOnlineTermContainer
