import * as Types from '../constants/ActionType'

export const setUpdateData = (user) => {
  return {
    type: Types.SET_UPDATE_DATA,
    user
  }
}

export const setOriginalData = (user) => {
  return {
    type: Types.SET_ORIGINAL_DATA,
    user
  }
}
