import * as Types from './../constants/ActionType'

const initialState = {
  routes: [],
  supportedRoutes: [],
  loading: false
}

const route = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_ROUTES:
      return {
        ...state,
        routes: action.routes
      }
    case Types.GET_SUPPORTED_ROUTES:
      return {
        ...state,
        loading: true,
        routes: []
      }

    case Types.GET_SUPPORTED_ROUTES_DONE:
      return {
        ...state,
        supportedRoutes: action.routes,
        loading: false
      }

    default:
      return state
  }
}

export default route
