import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import { setAuth } from '../actions/auth.action'
import NextArrow from '../components/NextArrow'
import Toast from '../components/Toast'
import UserCard from '../components/UserCard'
import ZPDialog from '../components/ZPDialog'
import { DEACTIVE_CARD } from '../constants/Messages'
import { LINE, LINE_COLOR, LINE_WIDTH } from '../constants/Styles'
import { put } from '../utils/apiCallerV2'
import { isIOS, isRealCard, setBodyBg, setTitle } from '../utils/helper'

const commandIds = [0, 1] // 0: deactive, 1: active
const ACTIVE_CARD = (
  <div>
    Bạn đang mở khóa thẻ!
    <br />
    Để đảm bảo tính bảo mật, chỉ mở khóa khi bạn đã nhận được thẻ UniPass.
  </div>
)

const MenuDivider = (props) => {
  const { detectDevice } = props
  const height = detectDevice ? (isIOS() ? LINE_WIDTH : 1.8) : LINE_WIDTH
  return <Divider style={{ backgroundColor: LINE_COLOR, height: height, ...props.style }} />
}

class ManagementCardPage extends Component {
  constructor(props) {
    super(props)
    setTitle('Quản lý thẻ')
    setBodyBg()
    this.state = {
      isOpenDialogAlert: false,
      alertMessage: '',
      leftButtonTitle: 'Huỷ',
      rightButtonTitle: '',
      commandId: -1,
      isActionSuccess: true,
      toast: {
        isOpen: false,
        message: '',
        type: 1
      }
    }
  }

  onDeactiveCard = () => {
    this.setState({
      isOpenDialogAlert: true,
      alertMessage: DEACTIVE_CARD,
      rightButtonTitle: 'Khoá thẻ',
      leftButtonTitle: 'Huỷ',
      commandId: 0
    })
  }

  onActiveCard = () => {
    this.setState({
      isOpenDialogAlert: true,
      alertMessage: ACTIVE_CARD,
      rightButtonTitle: 'Mở khoá thẻ',
      leftButtonTitle: 'Huỷ',
      commandId: 1
    })
  }

  onCloseDialog = () => {
    this.setState({
      isOpenDialogAlert: false
    })
  }

  onChangeRoute = (to) => {
    this.props.history.push(to)
  }

  onRightConfirm = async () => {
    const { commandId, leftButtonTitle } = this.state

    if (!leftButtonTitle) {
      this.onCloseDialog()
      return
    }

    if (!commandIds.includes(commandId)) {
      this.onCloseDialog()
      return
    }

    const { user } = this.props.auth

    // const ssoKey = REACT_APP_APP_CLIENT_KEY_SS0;
    // const reqTime = Date.now();
    // var clientSignature = CryptoJS.HmacSHA256(`${user.card_no}|${commandId}|${reqTime}`, ssoKey).toString();

    // const res = await postApi({
    // 	endpoint: "api/card/updateCardStatus",
    // 	body: {
    // 		cm: "update_card",
    // 		dt: {
    // 			ca_no: user.card_no,
    // 			stt: commandId,
    // 			sso_sig: clientSignature,
    // 			rq_t: reqTime,
    // 		}
    // 	}
    // });
    const res = await put('v2/customer/change-status', {
      is_active: commandId,
      customer_id: user.customer_id
    })
    if (res && res.error.code !== 1) {
      this.setState({
        leftButtonTitle: null,
        rightButtonTitle: 'Đóng',
        isActionSuccess: false,
        alertMessage: res.msg
      })
      return
    }

    const updatedInfo = {
      ...user,
      is_active: commandId
    }
    this.props.setAuth(updatedInfo)
    this.setState({
      isOpenDialogAlert: false,
      toast: {
        isOpen: true,
        message: commandId === 0 ? 'Khoá thẻ thành công' : 'Mở khoá thẻ thành công'
      }
    })

    setTimeout(() => {
      this.setState({
        toast: {
          ...this.state.toast,
          isOpen: false
        }
      })
    }, 1500)
    // this.setState({
    // 	leftButtonTitle: null,
    // 	rightButtonTitle: "Đóng",
    // 	isActionSuccess: true,
    // 	alertMessage: commandId === 0 ? "Khoá thẻ thành công" : "Mở khoá thẻ thành công"
    // })
  }

  render() {
    const { auth } = this.props
    if (!auth || !auth.user || !auth.user.phone) {
      return <Redirect to="/" />
    }

    const {
      card_no,
      card_type,
      card_status,
      is_active,
      customer_name,
      avatar,
      card_validation_date
    } = auth.user
    if (!card_no || !isRealCard(card_no)) {
      return <Redirect to="/card/receive" />
    }

    const { isOpenDialogAlert, leftButtonTitle, rightButtonTitle, alertMessage, toast } = this.state

    const cardActionLayout = is_active ? (
      <List component="nav" style={{ padding: 0, backgroundColor: '#fff' }}>
        <MenuDivider />
        <ListItem style={{ padding: 12 }} button onClick={this.onDeactiveCard}>
          <span className="icon-lock icon-zp-40"></span>
          <ListItemText
            style={{ padding: '0 12px' }}
            primary="Khoá thẻ"
            secondary="Thẻ sẽ bị khoá và không sử dụng được"
          />
          <NextArrow />
        </ListItem>
        <MenuDivider detectDevice={true} />
      </List>
    ) : (
      <List component="nav" style={{ padding: 0, backgroundColor: '#fff' }}>
        <MenuDivider />
        <ListItem style={{ padding: 12 }} button onClick={this.onActiveCard}>
          <span className="icon-open icon-zp-40"></span>
          <ListItemText
            style={{ padding: '0 12px' }}
            primary="Mở khoá thẻ"
            secondary="Thẻ được mở và sử dụng trở lại"
          />
          <NextArrow />
        </ListItem>
        <MenuDivider detectDevice={true} style={{ marginLeft: 64 }} />
        <ListItem
          style={{ padding: 12 }}
          button
          onClick={(e) => this.onChangeRoute('/card/create')}
        >
          <span className="icon-card blue icon-zp-40"></span>
          <ListItemText
            style={{ padding: '0 12px' }}
            primary="Làm thẻ mới"
            secondary="Đến điểm giao dịch để xoá, làm thẻ mới"
          />
          <NextArrow />
        </ListItem>
        <MenuDivider />
      </List>
    )

    return (
      <div style={{ overflow: 'hidden' }}>
        <div
          style={{
            backgroundColor: '#fff',
            padding: 30,
            marginBottom: 24,
            borderBottom: LINE
          }}
        >
          <UserCard
            customerName={customer_name}
            cardType={card_type}
            avatar={avatar}
            cardStatus={is_active}
            cardValidationDate={card_validation_date}
          />
        </div>
        {cardActionLayout}
        <ZPDialog
          open={isOpenDialogAlert}
          leftButtonTitle={leftButtonTitle}
          rightButtonTitle={rightButtonTitle}
          onLeftConfirm={this.onCloseDialog}
          onRightConfirm={this.onRightConfirm}
          description={alertMessage}
        />
        {toast.isOpen && <Toast {...toast} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    setAuth: (user) => {
      dispatch(setAuth(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManagementCardPage))
