import React from 'react'

import { InputCertificationStyle } from './style'
// import ClearButton from "../ClearButton";

const InputCertification = (props) => {
  return (
    <InputCertificationStyle>
      <p>Trường hợp không còn chứng nhận {props.typeCetification}:</p>
      <p className="p-margin-0">
        1. Tải hình ảnh <b>{props.cetificationForm} </b>
        <b
          style={{ color: '#008fe5', textDecorationLine: 'underline', cursor: 'pointer' }}
          onClick={() => document.getElementById('upload_certifi_form').click()}
        >
          {' '}
          TẠI ĐÂY
        </b>
      </p>
      <p className="p-margin-0">2. Điền đầy đủ thông tin và có xác nhận của nơi có thẩm quyền</p>
      <p className="p-margin-0">3. Chụp hình và tải lên hệ thống</p>
      <input
        type="file"
        id="upload_certifi_form"
        style={{ display: 'none' }}
        onChange={(e) => {
          props?.onChooseCertification(e.target.files[0])
        }}
      ></input>
    </InputCertificationStyle>
  )
}

export default InputCertification
