import * as Types from './../constants/ActionType'
import { MODE } from '../constants/Variables'

const initialState = MODE.NORMAL

const mode = (state = initialState, action) => {
  switch (action.type) {
    case Types.CHANGE_MODE:
      return action.mode

    case Types.TOGGLE_FULLSCREEN_MODE:
      return state === MODE.FULL_SCREEN ? MODE.NORMAL : MODE.FULL_SCREEN

    default:
      return state
  }
}

export default mode
