import * as Types from './../constants/ActionType'

const initialState = {
  notification: null,
  isInProgress: false,
  openToast: false,
  toastContent: ''
}

const common = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_NOTIFICATION:
      state.notification = action.notification
      return {
        ...state
      }
    case Types.SHOW_PROGRESS_INDICATOR:
      return {
        ...state,
        isInProgress: true
      }
    case Types.TOAST.OPEN_TOAST:
      return {
        ...state,
        openToast: true,
        toastContent: action?.data
      }
    case Types.TOAST.CLOSE_TOAST:
      return {
        ...state,
        openToast: false,
        toastContent: ''
      }
    default:
      return state
  }
}

export default common
