import styled from 'styled-components'
import { ZP_GRADIENT } from '../../constants/Styles'

export const InputImageStyle = styled.div`
	width:100vw
	> p {
		color:#b6b8b8
		text-align:left
		padding-left:12px;
		margin-bottom: 0px;
	}
	> div {
		display:inline-flex
		width: -webkit-fill-available
		padding: 0px 12px
		>div{
			width:50px
			height:50px
			> img {
				width: inherit;
				height:inherit;
				border: 0.8px solid rgba(215,216,211,0.8)
			}
		}
		> p {
			height:50px
			background-color: #F9F9F9
			margin:0px
			width:-webkit-fill-available
			line-height:50px
			border: 0.8px solid rgba(215,216,211,0.8);
			color:#b6b8b8
		}
		> button {
			background-image: linear-gradient(${ZP_GRADIENT});
			background-image: -moz-linear-gradient(${ZP_GRADIENT});
			background-image: -webkit-linear-gradient(${ZP_GRADIENT});
			background-image: -o-linear-gradient(${ZP_GRADIENT});
			min-width: 120px
		}
	}
	> img {
		width: calc(100% - 24px);
	}	
	
`
