import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'

import { ZP_GRADIENT } from '../../constants/Styles'

export const ZPDialogUpdateStyle = styled(Dialog)`
  && {
    > div {
      align-items: flex-end;
      .close-icon {
        width: 32px;
        margin-left: auto;
        margin-right: 20px;
        margin-top: 20px;
      }
    }
    > div > div {
      width: calc(100%);
      margin: 0;
      box-shadow: none;
      z-index: 20;
      border-radius: 20px 20px 0px 0px;
      > div {
        &.content {
          width: unset !important;
          padding: 0px 40px 30px 40px;
          text-align: center;
          overflow: hidden;
          p {
            color: #4a4a4a;
          }
          .shield {
            // width: 100px;
            margin-bottom: 30px;
          }
          .facebook {
            // width: 50px;
          }
          .zalo {
            // width: 50px;
          }
          .phone {
            // margin-top: -7px;
          }
        }
        &.action {
          margin: 0;
          > button {
            margin: 0;
            width: ${(props) => props.buttonwidth};
            padding: 15px 16px;
            font-size: 15px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-family: 'SFDisplay' !important;
            &.left {
              background-color: #f0f4f6;
              color: #727f8c;
              border-bottom-right-radius: 0;
            }
            &.right {
              background-image: linear-gradient(${ZP_GRADIENT});
              background-image: -moz-linear-gradient(${ZP_GRADIENT});
              background-image: -webkit-linear-gradient(${ZP_GRADIENT});
              background-image: -o-linear-gradient(${ZP_GRADIENT});
              color: #fff;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
    }
    .description {
      font-size: 15px;
      .txt-title {
        font-size: 17px;
      }
    }
    span[class^='icon-'],
    span[class*=' icon-'] {
      margin-bottom: 20px;
      display: block;
    }
  }
`

export const OpenForm = styled.div`
  .open-form {
    position: fixed;
    top: 86px;
    height: 40px;
    display: flex;
    background: #0071fe;
    color: white;
    width: 100vw;
    align-items: center;
    padding: 0 10px;
    z-index: 20;
    p {
      margin: 0 10px;
    }
  }
`
