import React, { useRef, forwardRef } from 'react'
import { TextField } from '@material-ui/core'
import classNames from 'classnames'
import './style.css'
import { X } from 'react-feather'

function TextFieldCustom({ showCheck = false, isPhone, ...props }, _ref) {
  const inputRef = useRef()
  return (
    <div className={classNames('position-relative', 'text-field-custom')}>
      <TextField
        inputRef={inputRef}
        {...props}
        onChange={(e) => {
          if (isPhone) {
            if (e.target.value < 1000000000) {
              props.onChange(e)
            }
          } else {
            props.onChange(e)
          }
        }}
      />
      <span className="position-absolute text-muted" style={{ right: 32, top: `calc(50% - 11px)` }}>
        {showCheck ? (
          <img alt="" src="/img/promotion/check-green.svg" />
        ) : (
          <>
            {inputRef && inputRef.current && inputRef.current.value && !props.disabled && (
              <X
                size={20}
                className="mr-50"
                onClick={() => {
                  if (inputRef && inputRef.current && inputRef.current.value) {
                    inputRef.current.value = ''
                    props.onChange('')
                  }
                }}
              />
            )}
          </>
        )}
      </span>
    </div>
  )
}
export default forwardRef(TextFieldCustom)
