export const TEST = 'TEST'
export const TEST_1 = 'TEST_1'
export const ADD_PRODUCT = 'ADD_PRODUCT'

export const SET_AUTH = 'SET_AUTH'
export const LOG_OUT = 'LOG_OUT'
export const SET_CUSTOMER_PHONE = 'SET_CUSTOMER_PHONE'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SET_UPDATE_DATA = 'SET_UPDATE_DATA'
export const SET_ORIGINAL_DATA = 'SET_ORIGINAL_DATA'
export const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO'

export const SET_CARS = 'SET_CARS'

//ROUTE

export const GET_SUPPORTED_ROUTES = 'GET_SUPPORTED_ROUTES'

export const GET_SUPPORTED_ROUTES_DONE = 'GET_SUPPORTED_ROUTES_DONE'

export const SET_ROUTES = 'SET_ROUTES'
export const SET_SUPPORTED_ROUTES = 'SET_SUPPORTED_ROUTES'
export const SET_WEATHER = 'SET_WEATHER'

export const CHANGE_MODE = 'CHANGE_MODE'
export const SHOW_PROGRESS_INDICATOR = 'SHOW_PROGRESS_INDICATOR'
export const SET_ROUTES_IN_STATION_MODE = 'SET_ROUTES_IN_STATION_MODE'
export const SET_CARS_IN_STATION_MODE = 'SET_CARS_IN_STATION_MODE'
export const TOGGLE_FULLSCREEN_MODE = 'TOGGLE_FULLSCREEN_MODE'

export const JUMP_TO_STEP = 'JUMP_TO_STEP'
export const SET_IF_THE_CLIENT_SHOULD_SEE_INSTRUCTION = 'SET_IF_THE_CLIENT_SHOULD_SEE_INSTRUCTION'
export const SET_HAS_LOGGEDIN_BY_ZALOOA = 'SET_HAS_LOGGEDIN_BY_ZALOOA'
export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN'
export const SET_ERROR_ZALOID_NOT_FOUND = 'SET_ERROR_ZALOID_NOT_FOUND'

export const SET_INSTRUCTION_DIALOG = 'SET_INSTRUCTION_DIALOG'
export const SET_REMINDER_DIALOG = 'SET_REMINDER_DIALOG'
export const SET_SUPPORT_DIALOG = 'SET_SUPPORT_DIALOG'
export const SET_UNBIND_DIALOG = 'SET_UNBIND_DIALOG'

export const SET_AUTODEBIT_BINDING_DATA = 'SET_AUTODEBIT_BINDING_DATA'
export const INIT_AUTODEBIT_BINDING_DATA = 'INIT_AUTODEBIT_BINDING_DATA'
export const INIT_AUTODEBIT_BINDING_DATA_SUCCESS = 'INIT_AUTODEBIT_BINDING_DATA_SUCCESS'
export const CHECK_BINDING = 'CHECK_BINDING'
export const CHECK_PAYABLE = 'CHECK_PAYABLE'
export const CHECK_BINDING_SUCCESS = 'CHECK_BINDING_SUCCESS'
export const CHECK_BINDING_FAIL = 'CHECK_BINDING_FAIL'
export const SET_PAYABLE_INFO = 'SET_PAYABLE_INFO'
export const SET_BALANCE = 'SET_BALANCE'
export const SET_MONTHLY_TICKET = 'SET_MONTHLY_TICKET'

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const ADD_TRANSACTIONS = 'ADD_TRANSACTIONS'
export const CLEAR_NOTIFICAIONS = 'CLEAR_NOTIFICAIONS'

export const SET_TAB_ID = 'SET_TAB_ID'
export const SET_QUANTITY = 'SET_QUANTITY'
export const CHECK_BUTTON_BUY_TICKET = 'CHECK_BUTTON_BUY_TICKET'
export const CLEAR_DATA = 'CLEAR_DATA'

//zalo login flow

export const LOGIN_WITH_ZALO = 'LOGIN_WITH_ZALO'

export const LOGIN_WITH_ZALOPAY = 'LOGIN_WITH_ZALOPAY'

export const GET_CUSTOMER_INFO = 'GET_CUSTOMER_INFO'

//UI

export const SET_SHOW_SPLASHING = 'SET_SHOW_SPLASING'

//RECHARGE
export const RECHARGE = {
  CREATE_ORDER: 'CREATE_ORDER',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAIL: 'CREATE_ORDER_FAIL'
}

//PROMOTION
export const PROMOTION = {
  GET_LIST_GIFT: 'GET_LIST_GIFT',
  GET_LIST_GIFT_SUCCESS: 'GET_LIST_GIFT_SUCCESS',
  GET_LIST_GIFT_FAIL: 'GET_LIST_GIFT_FAIL',

  GET_LIST_CAMPAIGN: 'GET_LIST_CAMPAIGN',
  GET_LIST_CAMPAIGN_SUCCESS: 'GET_LIST_CAMPAIGN_SUCCESS',
  GET_LIST_CAMPAIGN_FAIL: 'GET_LIST_CAMPAIGN_FAIL',

  GET_GIFT_DETAIL: 'GET_GIFT_DETAIL',
  GET_GIFT_DETAIL_SUCCESS: 'GET_GIFT_DETAIL_SUCCESS',
  GET_GIFT_DETAIL_FAIL: 'GET_GIFT_DETAIL_FAIL',

  GET_CAMPAIGN_DETAIL: 'GET_CAMPAIGN_DETAIL',
  GET_CAMPAIGN_DETAIL_SUCCESS: 'GET_CAMPAIGN_DETAIL_SUCCESS',
  GET_CAMPAIGN_DETAIL_FAIL: 'GET_CAMPAIGN_DETAIL_FAIL'
}

//HOME
export const HOME = {
  CHECK_SHOW_POPUP: 'CHECK_SHOW_POPUP',
  CHECK_SHOW_POPUP_SUCCESS: 'CHECK_SHOW_POPUP_SUCCESS',
  SET_STATUS_POPUP: 'SET_STATUS_POPUP'
}

//INVITE
export const INVITE = {
  SET_TAB_ID: 'SET_TAB_ID',
  CHECK_SHOW_REFERRAL: 'CHECK_SHOW_REFERRAL',
  CHECK_SHOW_REFERRAL_DONE: 'CHECK_SHOW_REFERRAL_DONE',
  CHECK_PHONE: 'CHECK_PHONE',
  CHECK_PHONE_DONE: 'CHECK_PHONE_DONE',
  GET_INVITE_LIST: 'GET_INVITE_LIST',
  GET_INVITE_LIST_DONE: 'GET_INVITE_LIST_DONE',
  INVITE_FRIEND: 'INVITE_FRIEND',
  INVITE_FRIEND_SUCCESS: 'INVITE_FRIEND_SUCCESS'
}

//REFUND
export const REFUND = {
  PROCESSING: 'PROCESSING',
  PROCESSING_DONE: 'PROCESSING_DONE',
  REQUEST_REFUND: 'REQUEST_REFUND'
}

//AUTHEN
export const AUTH = {
  REQUEST_OTP: 'REQUEST_OTP',
  VALIDATE_OTP: 'VALIDATE_OTP'
}

//AUTHEN
export const TOAST = {
  OPEN_TOAST: 'OPEN_TOAST',
  CLOSE_TOAST: 'CLOSE_TOAST'
}
