import React from 'react'
import StarRatings from 'react-star-ratings'

export default (props) => {
  const { rating, onRate, starDimension = '41' } = props

  return (
    <StarRatings
      rating={rating}
      starRatedColor="#349BF8"
      starEmptyColor="rgba(52, 155, 248, 0.35)"
      starHoverColor="#349BF8"
      starSpacing="0"
      starDimension={starDimension}
      svgIconViewBox="0 0 38 38"
      changeRating={onRate}
      numberOfStars={5}
      name="rating"
      svgIconPath="M17.6033 0.816986C17.9701 0.0737402 19.0299 0.0737396 19.3967 0.816985L24.2921 10.7361C24.4378 11.0313 24.7194 11.2358 25.0451 11.2832L35.9915 12.8738C36.8117 12.993 37.1392 14.0009 36.5457 14.5795L28.6248 22.3005C28.3891 22.5302 28.2816 22.8612 28.3372 23.1856L30.2071 34.0878C30.3472 34.9047 29.4898 35.5277 28.7561 35.142L18.9653 29.9946C18.674 29.8415 18.326 29.8415 18.0347 29.9946L8.24387 35.142C7.51025 35.5277 6.65281 34.9047 6.79292 34.0878L8.6628 23.1856C8.71844 22.8612 8.61089 22.5302 8.3752 22.3005L0.454291 14.5795C-0.139226 14.0009 0.188284 12.993 1.0085 12.8738L11.9549 11.2832C12.2806 11.2358 12.5622 11.0313 12.7079 10.7361L17.6033 0.816986Z"
    />
  )
}
