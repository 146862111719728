import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import CheckIcon from '@material-ui/icons/Check'

import { ReceiveCardDialogStyle } from './style'

const ReceiveCardDialog = (props) => {
  const { leftButtonTitle, rightButtonTitle, open, onCloseClickOut } = props

  const buttonWidth = leftButtonTitle && rightButtonTitle ? '50%' : '100%'

  return (
    <ReceiveCardDialogStyle
      open={open}
      onClose={onCloseClickOut ? (e) => onCloseClickOut() : null}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      buttonwidth={buttonWidth}
    >
      <DialogContent className="content">
        <CheckIcon style={{ color: '#FFF' }} />
        <p>Đã gửi thông tin thành công</p>
      </DialogContent>
      {/* <DialogActions className="action">
					{leftButtonTitle && (
						<Button className="left" fullWidth onClick={onLeftConfirm} color="primary" autoFocus>
							{leftButtonTitle}
						</Button>
					)}
					{rightButtonTitle && (
						<Button className="right" fullWidth onClick={onRightConfirm} color="primary" autoFocus>
							{rightButtonTitle}
						</Button>
					)}
				</DialogActions> */}
    </ReceiveCardDialogStyle>
  )
}

export default ReceiveCardDialog
