export const color = {
  black: {
    1000: '#424454',
    600: '#18191F',
    500: '#31333F',
    550: '#3F3F3F',
    450: '#4E5966',
    400: '#565869',
    350: '#818C99',
    310: '#bfbebe',
    300: '#6F7985',
    250: '#919191',
    200: '#898A8D'
  },
  blue: {
    800: '#0066B3',
    700: '#0074CC',
    600: '#005AC8',
    500: '#0090FF',
    400: '#006DF0',
    350: '#2C91FA',
    300: '#85B9FF',
    200: '#DCECFF'
  },
  red: {
    500: '#D52F00',
    450: '#E64646',
    400: '#FFD3D3'
  },
  green: {
    500: '#19B258'
  },
  yellow: {
    400: '#FFEFC0'
  },
  orange: {
    500: '#D47920'
  }
}
