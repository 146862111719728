import React, { Component, Fragment } from 'react'
import {
  InfoText,
  InfoWrapper,
  StatusWrapper,
  UniPassIcon,
  UniPassTitle,
  ViewIcon,
  ZPBindingContainerPage,
  ZPBindingContainerWrapper
} from './style'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { AUTODEBIT_DETAIL_URL } from '../../constants/Page'

const ActiveStatusStyle = {
  color: '#16B24D'
}

class ZPBindingContainer extends Component {
  constructor(props) {
    super(props)
  }

  viewAutodebitDetail = () => {
    this.props.history.push(AUTODEBIT_DETAIL_URL)
  }

  render() {
    const { bindingData } = this.props
    return (
      <Fragment>
        {bindingData && (
          <ZPBindingContainerPage>
            <ZPBindingContainerWrapper onClick={this.viewAutodebitDetail}>
              <div>
                <UniPassIcon src="/img/icons/unipass.svg" />
              </div>
              <InfoWrapper>
                <UniPassTitle>UniPass</UniPassTitle>
                <InfoText>{bindingData.phone_number}</InfoText>
                <InfoText>{bindingData.customer_name}</InfoText>
              </InfoWrapper>
              <StatusWrapper>
                {/* <span style={{ActiveStatusStyle}}>Đang hoạt động</span> */}
                <span
                  style={{
                    color: bindingData.status === 1 ? '#16B24D' : '#F59A44'
                  }}
                >
                  {bindingData.status === 1 ? 'Đang hoạt động' : 'Tạm dừng'}
                </span>
                <ViewIcon src="/img/icons/tran_support_active.svg" />
              </StatusWrapper>
            </ZPBindingContainerWrapper>
          </ZPBindingContainerPage>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    bindingData: state.autodebit.bindingData
  }
}

export default connect(mapStateToProps, null)(withRouter(ZPBindingContainer))
