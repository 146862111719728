import { combineReducers } from 'redux'
import auth from './auth.reducer'
import common from './common.reducer'
import currentRoute from './car.reducer'
import routes from './route.reducer'
import weatherReducer from './weather.reducer'
import mode from './mode.reducer'
import instructionStep, { shouldTheClientSeeInstuction } from './instuctionStep.reducer'
import home from './home.reducer'
import autodebit from './autodebit.reducer'
import notificationReducer from './notification.reducer'
import transactionsReducer from './transaction.reducer'
import monthlyTicketReducer from './monthlyTicket.reducer'
import rechargeReducer from './recharge.reducer'
import promotionReducer from './promotion.reducer'
import inviteReducer from './invite.reducer'
import refund from './refund.reducer'
const myReducer = combineReducers({
  auth,
  common,
  currentRoute,
  routes,
  mode,
  weatherReducer,
  instructionStep,
  shouldTheClientSeeInstuction,
  home,
  autodebit,
  monthlyTicketReducer,
  notifications: notificationReducer,
  transactions: transactionsReducer,
  recharge: rechargeReducer,
  promotion: promotionReducer,
  invite: inviteReducer,
  refund
})

export default myReducer
