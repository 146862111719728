import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestOtp, verifyOTP } from '../../actions/auth.action'
import OTPInput from '../../components/OTP'
import { phoneOtpVerification } from '../../constants/AppConstants'
import { color } from '../../utils/color'

const styles = {
  container: {
    paddingTop: 30
  },
  title: {
    fontSize: 18,
    marginTop: 30,
    marginBottom: 10,
    color: '#000000'
  },
  content: {
    fontSize: 15,
    fontWeight: 400,
    color: '#000000'
  }
}

export default function Otp({ phone, setStep, zaloId, loginFlowId, muid, setErrorData }) {
  const dispatch = useDispatch()
  const [otp, setOtp] = useState(null)
  const [error, setError] = useState(null)
  const [time, setTime] = useState(300)
  const [resend, setResend] = useState(false)

  useEffect(() => {
    let timeRemaining = 300
    const timerRemaining = setInterval(() => {
      timeRemaining -= 1
      setTime(timeRemaining)
      if (timeRemaining === 0) {
        setResend(true)
        clearInterval(timerRemaining)
      }
    }, 1000)
    return () => {
      clearInterval(timerRemaining)
    }
  }, [resend])

  const onOtpChange = (data) => {
    setError(null)
    setOtp(data)
  }

  useEffect(() => {
    if (!error && otp && otp.length === phoneOtpVerification.otpLenght) {
      dispatch(
        verifyOTP({
          body: {
            phone,
            otp,
            zalo_id: zaloId,
            login_flow_id: loginFlowId,
            muid
          },
          setError,
          setErrorData,
          setStep
        })
      )
    }
  }, [otp, zaloId, loginFlowId, muid])

  const onResend = () => {
    const body = {
      phone: phone,
      zalo_id: zaloId,
      login_flow_id: loginFlowId,
      muid
    }
    setResend(false)
    setTime(300)
    dispatch(requestOtp({ body }))
  }
  return (
    <div style={styles.container}>
      <img alt="phone-sms" src="img/otp-phone-verification/phone-sms.svg" />
      <div className="text-bold" style={styles.title}>
        XÁC THỰC SỐ ĐIỆN THOẠI
      </div>
      <span style={styles.content}>
        Mã xác thực đã được gửi vào số <b>{phone}</b>
        <br />
        Vui lòng nhập vào ô bên dưới
      </span>
      <OTPInput
        containerStyle={{ marginTop: 15 }}
        value={otp}
        onChange={onOtpChange}
        numInputs={phoneOtpVerification.otpLenght}
        hasErrored={error}
      />
      {resend ? (
        <div
          className="text-bold"
          style={{
            fontSize: 13,
            color: color.blue[500],
            marginTop: 25
          }}
          onClick={onResend}
        >
          Gửi lại
        </div>
      ) : (
        <div style={{ fontSize: 13, color: color.black[200], marginTop: 10 }}>
          Gửi lại mã sau <span className="text-bold">{time}</span> giây
        </div>
      )}
      {error && <div style={{ fontSize: 15, fontWeight: 500, color: color.red[500] }}>{error}</div>}
    </div>
  )
}
