export const COLOR_GREY = '#f0f4f6'
export const COLOR_MAIN = '#008fe5'
export const COLOR_BLACK = '#24272b'
export const COLOR_BLUE = '#008fe5'
export const COLOR_RED = '#d0021b'
export const LINE_COLOR = 'rgba(215, 216, 211, 0.7)'
export const LINE_WIDTH = '0.8px'
export const LINE = '0.8px solid rgba(215, 216, 211, 0.8)'
export const COLOR_DARK_GREY = '#727f8c'
export const ZP_GRADIENT = 'to right, #006dff, #00acfa'
