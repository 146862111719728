import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  ContentText,
  ItemWrapper,
  LinkImage,
  MonthLyTicketLinkPageWrapper,
  TitleText
} from './styles/management-monthly-ticket.style'
import { Grid } from '@material-ui/core'
import { countDay, friendlyDate } from '../utils/helper'
import { color } from '../utils/color'

class ManagementMonthlyTicket extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderItem = (item, key) => {
    const route = () => {
      return item.all ? 'Tất cả' : item.routeNumbers.join(', ')
    }

    const days = countDay(item.expiry_date)
    const hours = countDay(item.expiry_date, true)
    const expire = hours < 0
    const warning = () => {
      if (days < 1 && days >= 0 && !expire) {
        return (
          <div style={{ color: color.red[450], fontSize: 14 }}>
            Lưu ý: Còn <span style={{ fontWeight: 'bold' }}>{hours}</span> giờ là hết hạn
          </div>
        )
      } else if (days <= 30 && !expire) {
        return (
          <div style={{ color: color.red[450], fontSize: 14 }}>
            Lưu ý: Còn <span style={{ fontWeight: 'bold' }}>{days}</span> ngày là hết hạn
          </div>
        )
      } else if (hours < 0) {
        return (
          <div>
            <span style={{ color: 'red' }}>Đã hết hạn</span>
          </div>
        )
      }
    }
    return (
      <ItemWrapper key={key} expire={expire}>
        <Grid item xs={12} md={12} style={{ alignItems: 'center' }} container>
          <Grid
            item
            xs={9}
            md={9}
            style={{ paddingLeft: 20, textAlign: 'start' }}
            direction="row"
            container
            justifyContent="space-between"
          >
            <div style={{ paddingTop: 16, paddingBottom: 16, width: '92%' }}>
              <div
                style={{
                  color: color.black[500],
                  fontSize: 20,
                  fontWeight: 600
                }}
              >
                {item.monthly_ticket_name}
              </div>
              <div>
                <TitleText>
                  Tuyến: <ContentText>{route()} </ContentText>
                </TitleText>
              </div>
              <div>
                <TitleText>
                  Thời hạn: <ContentText>{friendlyDate(item.expiry_date)}</ContentText>
                </TitleText>
              </div>
              {warning()}
            </div>
            <LinkImage />
          </Grid>
          <Grid item xs={3} md={3} style={{ paddingRight: 10 }}>
            <div style={{ color: '#7A7C8B', fontSize: 14 }}>Số lượng vé tập</div>
            <div style={{ color: color.black[500], fontSize: 36, fontWeight: 500 }}>
              {item.total_ticket}
            </div>
          </Grid>
        </Grid>
      </ItemWrapper>
    )
  }

  render() {
    const sortData = this.props.data.sort(
      (a, b) => new Date(b.expiry_date).getTime() - new Date(a.expiry_date).getTime()
    )
    return (
      <MonthLyTicketLinkPageWrapper>{sortData.map(this.renderItem)}</MonthLyTicketLinkPageWrapper>
    )
  }
}

export default withRouter(ManagementMonthlyTicket)
