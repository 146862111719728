import React, { useEffect, useState } from 'react'

import { InputImageStyle } from './style'
import ClearButtonInputImage from '../ClearButtonInputImage'

const InputImage = (props) => {
  const [file, setFile] = useState(props.preparedFile)
  useEffect(() => setFile(props.preparedFile), [props.preparedFile])
  return (
    <InputImageStyle>
      <p>{props.title}</p>
      {!file && (
        <div>
          <div>
            <img alt="to inform there is nothing selected" src="/images/NO_IMG_600x600.png" />
          </div>
          <p>{props.description}</p>
          <button onClick={() => document.getElementById(props.id).click()}> + Chọn hình </button>
          <input
            type="file"
            id={props.id}
            style={{ display: 'none' }}
            onChange={(e) => {
              setFile(URL.createObjectURL(e.target.files[0]))
              props?.onChooseImage(e.target.files[0])
            }}
          ></input>
        </div>
      )}
      <div style={{ position: 'relative' }}>
        {!props.isDisableFeild && (
          <ClearButtonInputImage
            isShow={file}
            onClick={() => {
              setFile(null)
              props?.onChooseImage(null)
            }}
          />
        )}
        {file && <img alt="selected" src={file} style={{ width: '100%', maxHeight: '70vh' }} />}
      </div>
    </InputImageStyle>
  )
}
export default InputImage
