import { useCallback } from 'react'
import { AlertOctagon, CheckCircle } from 'react-feather'
import { useDispatch } from 'react-redux'
import { openToast } from '../actions/common.action'

const ToastContent = ({ type = 'success', message = '', icon = true }) => {
  return (
    <div style={{ padding: 8, gap: 8 }} className="d-flex align-items-center">
      {icon && (
        <>
          {type === 'success' ? (
            <CheckCircle size={24} color="white" />
          ) : (
            <AlertOctagon size={24} color="white" />
          )}
        </>
      )}
      <div className="text-15-18-400" style={{ color: 'white' }}>
        {message}
      </div>
    </div>
  )
}

export default function useToast() {
  const dispatch = useDispatch()

  const toast = useCallback(({ message, type = 'success', icon = true }) => {
    dispatch(openToast(<ToastContent type={type} icon={icon} message={message} />))
  }, [])

  return toast
}
