import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { requestRefund } from '../../actions/refund.action'
import FormBuilder from '../../common/form/FormBuilder'
import DialogAlert from '../../components/ZPDialog'
import { refund } from '../../constants/AppConstants'
import { color } from '../../utils/color'
import { currencyFormat, refundDateFormat } from '../../utils/helper'
import fields from './fields'
import Terms from './Term'

const styles = {
  container: {
    paddingTop: 24
  },
  price: { fontSize: 33, color: color.black[1000], fontWeight: 700 },
  lablePrice: { color: color.black[300], fontSize: 14 },
  form: {
    marginTop: 12,
    width: '100%'
  },
  checkBoxStyle: {
    textAlign: 'left',
    color: color.black[500],
    fontSize: 15,
    fontWeight: 500,
    width: '100%',
    padding: 0,
    marginTop: 16
  },
  button: {
    padding: 10,
    borderRadius: 30,
    fontSize: 16,
    fontWeight: 700,
    boxShadow: 'none',
    marginTop: 56
  },
  notice: {
    backgroundColor: color.blue[200],
    width: '100%',
    borderRadius: 6,
    padding: '12px 16px',
    marginBottom: 12,
    display: 'flex',
    justifyContent: 'start'
  },
  contentNotice: {
    color: color.blue[600],
    fontSize: 15,
    fontWeight: 400,
    textAlign: 'start'
  },
  imgNotice: {
    color: color.blue[600],
    marginRight: 5,
    height: 24
  }
}

const requiredMsg = 'Không được để trống'
const schema = yup.object().shape({
  phone: yup.string().required(requiredMsg)
})

export default function RequestRefund() {
  const dispatch = useDispatch()
  const history = useHistory()
  const form = useForm({ resolver: yupResolver(schema) })
  const [termChecked, setTermChecked] = useState(false)
  const userInfo = useSelector((state) => state.auth.user)
  const balance = useSelector((state) => state.home.balance)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [data, setData] = useState({})
  const [step, setStep] = useState('confirm')
  const [loading, setLoading] = useState(false)

  const [popupDataRequested, setPopupDataRequested] = useState({
    title: 'Bạn đã đăng ký hoàn tiền thành công!',
    content: (
      <span>
        Thời gian nhận hoàn tiền vào ví ZaloPay của bạn dự kiến từ ngày <b>{refundDateFormat()}</b>
      </span>
    )
  })

  const contentPopup = useMemo(() => {
    if (step === 'confirm') {
      return {
        description: 'Bạn có chắc chắn muốn hoàn tiền trong tài khoản UniPass vào ví ZaloPay?',
        rightButtonTitle: loading ? (
          <img src="/img/loading.svg" style={{ width: 20, height: 20 }} alt="" />
        ) : (
          'Đồng ý'
        ),
        leftButtonTitle: 'Hủy',
        onLeftConfirm: () => {
          setIsOpenPopup(false)
        },
        onRightConfirm: () => {
          if (data) {
            const failed = () => {
              setPopupDataRequested({
                title: 'Đăng ký hoàn tiền không thành công!',
                content: (
                  <span>
                    Vui lòng kiểm tra kết nối mạng và thử lại. Tổng đài hỗ trợ:
                    <b> 1900 54 54 36</b>
                  </span>
                )
              })
            }
            dispatch(
              requestRefund({
                setStep,
                data,
                setLoading,
                failed
              })
            )
          }
        }
      }
    } else {
      return {
        description: (
          <div className="d-flex flex-column">
            <b>{popupDataRequested.title}</b>
            {popupDataRequested.content}
          </div>
        ),
        rightButtonTitle: 'Đóng',
        leftButtonTitle: null,
        onRightConfirm: () => {
          setIsOpenPopup(false)
          history.replace('/')
        }
      }
    }
  }, [step, loading, data])

  const onSubmit = (data) => {
    const body = {
      refundType: refund.refundType.user,
      amount: balance,
      ...data
    }
    setData(body)
    setIsOpenPopup(true)
  }

  const inititalData = useMemo(() => {
    if (!userInfo) return {}
    return { phone: userInfo.phone, requester: userInfo.customer_name }
  }, [userInfo])

  return (
    <div style={styles.container} className=" d-flex flex-column align-items-center">
      {!balance && (
        <div style={styles.notice}>
          <img src="/img/icons/info-blue.svg" alt="image notice" style={styles.imgNotice} />
          <span style={styles.contentNotice}>
            Số dư của bạn không đủ điều kiện đăng ký hoàn tiền.
          </span>
        </div>
      )}
      <Terms />

      <DialogAlert open={isOpenPopup} {...contentPopup} />
      <span style={styles.lablePrice}> Số dư tài khoản UniPass (đ)</span>
      <b style={styles.price}>{balance ? currencyFormat(balance) : '0'}</b>
      <div style={styles.form}>
        <FormBuilder
          form={form}
          fields={[fields.phone, fields.requester].filter(Boolean)}
          data={inititalData}
        />
        {balance && (
          <FormControlLabel
            checked={termChecked}
            value="end"
            style={styles.checkBoxStyle}
            label="Tôi đồng ý hoàn tiền, toàn bộ số tiền trong tài khoản UniPass vào ví ZaloPay."
            control={
              <Checkbox icon={<CircleUnchecked />} checkedIcon={<CheckCircle />} color="primary" />
            }
            onChange={(e) => setTermChecked(e.target.checked)}
          />
        )}
        {balance ? (
          <Button
            variant="contained"
            fullWidth
            style={{
              ...styles.button,
              backgroundColor: termChecked ? color.blue[500] : '',
              color: termChecked ? 'white' : color.blue[300]
            }}
            disabled={!termChecked}
            onClick={form.handleSubmit(onSubmit)}
          >
            Xác nhận
          </Button>
        ) : (
          <Button
            variant="contained"
            fullWidth
            style={{
              ...styles.button,
              backgroundColor: color.blue[500],
              color: 'white'
            }}
            onClick={() => history.replace('/')}
          >
            Quay lại trang chủ
          </Button>
        )}
      </div>
    </div>
  )
}
