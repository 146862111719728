import React from 'react'

import { CommonStyle } from './style'

export default class CommonFeild extends React.Component {
  render() {
    return (
      <CommonStyle>
        <p>Bạn đang mua vé giá phổ thông</p>
        <p>Vui lòng xác thực thông tin để mua vé giá ưu tiên</p>
        <a onClick={(e) => this.props.onChangeRoute()}>Xem chi tiết</a>
      </CommonStyle>
    )
  }
}
