import React, { Component } from 'react'
import { color } from '../../utils/color'
import {
  MonthLyTicketLinkContainerWrapper,
  WrapperText,
  LinkTitle,
  LinkImage,
  LinkWrapper
} from './style'

class MonthlyTicketManagementContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { isBuyTicket } = this.props
    return (
      <MonthLyTicketLinkContainerWrapper>
        <LinkImage src="/img/icons/BookTicketBig.svg" />
        <WrapperText>
          {isBuyTicket ? (
            <>
              <div
                style={{
                  color: color.black[400],
                  fontSize: 18,
                  fontWeight: 'bold'
                }}
              >
                Vé tập hiện đang hết
              </div>
              <div style={{ color: color.black[400], fontSize: 14 }}>
                Vé tập sẽ được bán lại trong thời gian sớm nhất.
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  color: color.black[400],
                  fontSize: 18,
                  fontWeight: 'bold'
                }}
              >
                Bạn chưa có vé tập
              </div>
              <div style={{ color: color.black[400], fontSize: 14 }}>
                Hãy mua vé tập để sử dụng UniPass tiết kiệm hơn!
              </div>
            </>
          )}
        </WrapperText>
        {!isBuyTicket && (
          <LinkWrapper>
            <div onClick={this.props.onBuyMonthlyTicket}>
              <img src="/img/icons/addWhite.svg" alt="" />
              <LinkTitle>Mua vé tập</LinkTitle>
            </div>
          </LinkWrapper>
        )}
      </MonthLyTicketLinkContainerWrapper>
    )
  }
}

export default MonthlyTicketManagementContainer
