import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { phoneOtpVerification } from '../../constants/AppConstants'

import Otp from './otp'
import Phone from './phone'
import Result from './result'

export default function OTPVerification() {
  const location = useLocation()
  const search = location.search
  const params = new URLSearchParams(search)
  const zaloId = params.get('zaloId')
  const loginFlowId = params.get('loginFlowId')
  const muid = params.get('muid')

  const [step, setStep] = useState(phoneOtpVerification.step.phone)
  const [phone, setPhone] = useState(null)
  const [errorData, setErrorData] = useState(null)

  const onChangePhone = ({ phone }) => {
    setStep(phoneOtpVerification.step.otp)
    setPhone(phone)
  }

  return (
    <div className="container">
      {
        {
          phone: (
            <Phone
              onChangePhone={onChangePhone}
              zaloId={zaloId}
              loginFlowId={loginFlowId}
              muid={muid}
              setErrorData={setErrorData}
              setStep={setStep}
            />
          ),
          otp: (
            <Otp
              phone={phone}
              setStep={setStep}
              zaloId={zaloId}
              loginFlowId={loginFlowId}
              muid={muid}
              setErrorData={setErrorData}
            />
          ),
          result: <Result phone={phone} errorData={errorData} />
        }[step]
      }
    </div>
  )
}
