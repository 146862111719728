import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { setTitle, removeBodyBg } from '../utils/helper'
import { COLOR_BLACK, COLOR_BLUE } from '../constants/Styles'

class CreateCardPage extends Component {
  constructor(props) {
    super(props)
    setTitle('Làm thẻ mới')
    removeBodyBg()
  }

  render() {
    return (
      <div className="page page-no-bottom">
        <ul style={{ paddingLeft: 15, textAlign: 'justify' }}>
          <li style={{ marginBottom: 20, color: COLOR_BLUE }}>
            <span style={{ color: COLOR_BLACK }}>
              Mời bạn tới điểm giao dịch tại <strong>Bến xe buýt Công viên 23/09</strong>, mang theo
              CMND để làm thẻ mới
            </span>
          </li>
          <li style={{ marginBottom: 20, color: COLOR_BLUE }}>
            <span style={{ color: COLOR_BLACK }}>
              Nếu bạn là đối tượng được miễn giảm giá vé, vui lòng mang theo giấy tờ chứng minh (Thẻ
              sinh viên, Thẻ thương binh/khuyết tật,...)
            </span>
          </li>
          <li style={{ marginBottom: 20, color: COLOR_BLUE }}>
            <span style={{ color: COLOR_BLACK }}>
              <strong>Địa chỉ</strong>: Số 1, đường Phạm Ngũ Lão, P. Phạm Ngũ Lão, Quận 1, TP. Hồ
              Chí Minh
            </span>
          </li>
        </ul>
        <img
          src="/images/busstation.png"
          alt="bus_station"
          style={{ width: 'calc(100% - 50px)' }}
        />
      </div>
    )
  }
}

export default withRouter(CreateCardPage)
