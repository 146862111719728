import * as Types from './../constants/ActionType'

const initialState = {
  loading: false,
  selectedTab: 0,
  referral: { entered: false, referralCode: '', newCustomer: false },
  checkPhone: {
    account_name: '',
    customer_name: '',
    message: null,
    error: false
  },
  inviteList: {
    receivedGifts: [],
    notReceivedGifts: [],
    totalInvited: 0,
    totalGiftReceived: 0,
    totalNotReceived: 0,
    totalReceived: 0,
    totalTransacted: 0
  }
}

const inviteReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.INVITE.SET_TAB_ID:
      return {
        ...state,
        selectedTab: action.tabId
      }
    case Types.INVITE.CHECK_SHOW_REFERRAL:
      return {
        ...state,
        referral: { entered: false, referralCode: '', newCustomer: false }
      }

    case Types.INVITE.CHECK_SHOW_REFERRAL_DONE:
      return {
        ...state,
        referral: action.data
      }

    case Types.INVITE.CHECK_PHONE_DONE:
      return {
        ...state,
        checkPhone: action.data
      }
    case Types.INVITE.GET_INVITE_LIST:
      return {
        ...state,
        inviteList: {
          receivedGifts: [],
          notReceivedGifts: [],
          totalInvited: 0,
          totalGiftReceived: 0,
          totalNotReceived: 0,
          totalReceived: 0,
          totalTransacted: 0
        }
      }
    case Types.INVITE.GET_INVITE_LIST_DONE:
      return {
        ...state,
        inviteList: action.data
      }
    case Types.INVITE.INVITE_FRIEND_SUCCESS:
      return {
        ...state,
        referral: { ...state.referral, entered: true }
      }
    default:
      return state
  }
}

export default inviteReducer
