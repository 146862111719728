import styled from 'styled-components'

export const ZPUnbindDialogWrapper = styled.div`
  padding: 20px 10px 20px;
`

export const HeaderWrapper = styled.div`
  text-align: center;
  > span {
    color: #333333;
    font-weight: bold;
    font-size: 16px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  padding: 10px 30px;
  align-items: center;
  justify-content: center;
`

export const Icon = styled.img`
  padding: 0 5px;
`

export const InfoWrapper = styled.div`
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CancelButtonStyle = {
  background: '#E8F2FF',
  width: '20%',
  marginRight: '5px',
  color: '#026CFE'
}

export const DoUnbindButtonStyle = {
  background: '#026CFE',
  width: '80%',
  marginLeft: '5px',
  fontWeight: 'bold',
  color: 'white'
}
