import * as Types from './../constants/ActionType'

const initialState = {
  bindingData: {},
  isOpenUnbindDialog: false,
  isBinding: false,
  bindingLoading: false
}

const autodebit = (state = initialState, action) => {
  switch (action.type) {
    // case Types.SET_AUTODEBIT_BINDING_DATA:
    //   state.bindingData = action.bindingData;
    //   return {
    //     ...state,
    //   };
    case Types.CHECK_BINDING:
      return {
        ...state
      }
    case Types.CHECK_BINDING_SUCCESS:
      state.bindingData = action.bindingData
      state.isBinding = true
      return {
        ...state
      }
    case Types.CHECK_BINDING_FAIL:
      state.isBinding = false
      return {
        ...state
      }
    case Types.SET_UNBIND_DIALOG:
      state.isOpenUnbindDialog = action.isOpenUnbindDialog
      return {
        ...state
      }
    case Types.INIT_AUTODEBIT_BINDING_DATA:
      return {
        ...state,
        bindingLoading: true
      }
    case Types.INIT_AUTODEBIT_BINDING_DATA_SUCCESS:
      return {
        ...state,
        bindingLoading: false
      }
    default:
      return state
  }
}

export default autodebit
