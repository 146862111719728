import React from 'react'
import { getMobileOperatingSystem } from '../../../utils/helper'

const ZaloPay = window.ZaloPay

const callAPIToOpenZaloApp = () => {
  if (typeof ZaloPay !== 'undefined' && ZaloPay.isZaloPay) {
    ZaloPay.launchExternalApp({
      url: 'zalo://zaloapp.com/qr/link/3820907403981754700',
      packageId: 'com.zing.zalo'
    })
  }
}

export default (props) => {
  return (
    <>
      {getMobileOperatingSystem() === 'iOS' ? (
        <a href="http://zalo.me/3820907403981754700">{props.children}</a>
      ) : (
        <a href="" onClick={callAPIToOpenZaloApp}>
          {props.children}
        </a>
      )}
    </>
  )
}
