import { withStyles } from '@material-ui/core'
import MobileStepper from '@material-ui/core/MobileStepper'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { addNotification, clearNotification } from '../../actions/notification.action'
import { addTransactions } from '../../actions/transaction.action'
import { REACT_APP_PHONE_WHITELIST } from '../../constants/Env'
import useTransactionFetcher from '../../hooks/TransactionFetcher'
import useWhiteList from '../../hooks/white-list'
import {
  Border,
  ReminderShowAll,
  ReminderWrapper
} from '../../pages/home/NewHomePage/style/new-home-page.style'
import { lastTransactionSelector } from '../../selectors/transaction.selector'
import ReminderItem from '../ReminderItem'

const StyledMobileStepper = withStyles({
  root: {
    justifyContent: 'center',
    background: 'white',
    padding: 0
  }
})(MobileStepper)

const checkForPayableNotification = (props) => {
  if (props.isPayable === false) {
    const isNotificationAdded = props.notifications.find(
      (notification) => notification.id === 'efficientFund'
    )
    if (!isNotificationAdded) {
      props.addNotification({
        id: 'efficientFund',
        iconLink: '/img/icons/payin_now.svg',
        action: () => {
          props.history.push({
            pathname: '/payment',
            state: {
              isHelper: false,
              phone: props.auth.user.phone
            }
          })
        },
        actionText: 'Nạp tiền ngay',
        description: 'Bạn cần ít nhất 7.000đ trong ví ZaloPay để sử dụng dịch vụ.'
      })
    }
  }
}

// const checkForRequestedRefund = (props) => {
//   // if (props.requestedRefund.length) {
//   const isNotificationAdded = props.notifications.find(
//     (notification) => notification.id === 'requestedRefund'
//   )
//   if (!isNotificationAdded) {
//     props.addNotification({
//       id: 'requestedRefund',
//       iconLink: '/img/refund-notice.svg',
//       action: () => {
//         props.history.push({
//           pathname: '/refund'
//         })
//       },
//       actionText: 'Hoàn tiền',
//       description: 'Đăng ký hoàn tiền từ tài khoản UniPass qua ví ZaloPay'
//     })
//   }
//   // }
// }

const checkForPriorityUserNotification = (props) => {
  if (props.auth.user.customer_type !== 4 && props.auth.user.registration_confirm_status !== 1) {
    const isNotificationAdded = props.notifications.find(
      (notification) => notification.id === 'priorityUser'
    )
    if (!isNotificationAdded) {
      props.addNotification({
        id: 'priorityUser',
        iconLink: '/img/icons/authenticate_now.svg',
        action: () => {
          props.history.push('/card/receive')
        },
        actionText: 'Xác thực ngay',
        description:
          'Bạn đã đăng ký tài khoản ưu tiên nhưng chưa được xác thực, vé đang áp dụng giá thường.'
      })
    }
  }
}

const checkForFailedTransactionNotification = (props) => {
  const lastTransaction = props.lastTransaction
  const isNotificationAdded = props.notifications.find(
    (notification) => notification.id === 'failedTransaction'
  )

  const toDetailPage = (props, invoice) => {
    const formattedInvoice = {
      ...invoice,
      date: invoice.created_date_time,
      trans_id: invoice.invoice_code,
      is_cash_out: true
    }
    props.history.push({
      pathname: '/transaction/detail',
      state: {
        invoice: formattedInvoice
      }
    })
  }

  if (lastTransaction && lastTransaction.payment_status !== 2) {
    if (!isNotificationAdded) {
      props.addNotification({
        id: 'failedTransaction',
        iconLink: '/img/icons/repay_now.svg',
        action: () => {
          toDetailPage(props, lastTransaction)
        },
        actionText: 'Thánh toán lại ngay',
        description:
          'Thanh toán gần đây bị lỗi do ví không đủ số dư. Tài khoản đang khóa, vui lòng nạp tiền & thử lại.'
      })
    }
  }
}

const NotificationCenter = (props) => {
  const { showAllNotifications } = props

  const [activeIndex, setActiveIndex] = useState(0)
  const { triggerTransactionFetcher } = useTransactionFetcher()
  const willMount = useRef(true)

  const hasAccess = useWhiteList(REACT_APP_PHONE_WHITELIST)

  useEffect(() => {
    if (willMount.current) {
      props.clearNotification()
      willMount.current = false
    }
  }, [willMount.current])

  useEffect(() => {
    triggerTransactionFetcher()
  }, [])

  useEffect(() => {
    checkForPayableNotification(props)
  }, [props.isPayable])

  // useEffect(() => {
  //   if (hasAccess) checkForRequestedRefund(props)
  // }, [props.requestedRefund])

  useEffect(() => {
    checkForPriorityUserNotification(props)
  }, [props.notifications])

  useEffect(() => {
    checkForFailedTransactionNotification(props)
  }, [props.transactions])

  return (
    <>
      {props.notifications.length > 0 && (
        <ReminderWrapper>
          <ReminderShowAll onClick={showAllNotifications}>
            Xem tất cả thông báo ({props.notifications.length})
          </ReminderShowAll>
          <Border />
          <SwipeableViews
            axis={'x'}
            index={activeIndex}
            onChangeIndex={setActiveIndex}
            enableMouseEvents
          >
            {props.notifications.map((notification, index) => (
              <ReminderItem
                key={index}
                iconLink={notification.iconLink}
                action={notification.action}
                description={notification.description}
                actionText={notification.actionText}
              />
            ))}
          </SwipeableViews>

          {props.notifications.length > 1 && (
            <StyledMobileStepper
              variant="dots"
              steps={props.notifications.length}
              position="static"
              activeStep={activeIndex}
            />
          )}
        </ReminderWrapper>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    notifications: state.notifications,
    // isPayable: efficientFundChecker(state),
    isPayable: state.home.payable,
    isZaloPayBalanceEfficient: state.home.payable,
    transactions: state.transactions,
    bindingData: state.autodebit.bindingData,
    lastTransaction: lastTransactionSelector(state),
    requestedRefund: state.refund.requested
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (notification) => {
      dispatch(addNotification(notification))
    },
    addTransactions: (transactions) => {
      dispatch(addTransactions(transactions))
    },
    clearNotification: () => {
      dispatch(clearNotification())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationCenter))
