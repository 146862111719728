import { ADD_TRANSACTIONS } from '../constants/ActionType'

const initialState = {
  cash: [],
  invoice: [],
  loaded: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TRANSACTIONS:
      return {
        ...state,
        cash: [...action.transactions.cash],
        invoice: [...action.transactions.invoice],
        loaded: true
      }

    default:
      return state
  }
}
