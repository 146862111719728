import React, { Component } from 'react'
import { HeaderWrapper, IconWrapper, Icon, SupportDialogWrapper, CloseButton } from './style'
import ZaloPageLink from '../Link/ZaloPage'
import FBLink from '../Link/FBFanpage'

class SupportDialog extends Component {
  onCloseDialog = () => {
    this.props.onChangeStatus(false)
  }

  render() {
    return (
      <SupportDialogWrapper>
        <HeaderWrapper>
          <span>Vui lòng chọn kênh liên lạc hỗ trợ</span>
        </HeaderWrapper>
        <IconWrapper>
          <FBLink>
            <Icon src="/img/icons/support_facebook.svg" />
          </FBLink>
          <ZaloPageLink>
            <Icon src="/img/icons/support_zalo.svg" />
          </ZaloPageLink>
          <a href="tel:1900545436">
            <Icon src="/img/icons/support_phone.svg" />
          </a>
        </IconWrapper>
        <CloseButton onClick={this.onCloseDialog}>Đóng</CloseButton>
      </SupportDialogWrapper>
    )
  }
}

export default SupportDialog
