import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import React from 'react'

import { OpenForm, ZPDialogUpdateStyle } from './style'

const theme = createTheme({
  typography: {
    useNextVariants: true
  }
})

const ZPDialogUpdate = (props) => {
  const [open, setOpen] = React.useState(false)
  const {
    leftButtonTitle,
    rightButtonTitle,
    // open, description,
    onLeftConfirm,
    onRightConfirmUpdateDialog,
    onCloseClickOut,
    logo = 'notify', // notify, warning, infor
    privatePolicy
  } = props

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const buttonWidth = leftButtonTitle && rightButtonTitle ? '50%' : '100%'

  return (
    <div>
      <OpenForm>
        <div className="open-form" onClick={handleClickOpen}>
          <img className="close-icon" src={`/images/question.png`} />
          <p>Bạn cần hỗ trợ thay đổi thông tin ?</p>
        </div>
      </OpenForm>
      <MuiThemeProvider theme={theme}>
        <ZPDialogUpdateStyle
          open={open}
          onClose={onCloseClickOut ? (e) => onCloseClickOut() : null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          buttonwidth={buttonWidth}
        >
          <img className="close-icon" src={`/images/cross.png`} onClick={handleClose} />
          <DialogContent className="content">
            <img className="shield" src={`/images/shield.png`} />
            <DialogContentText className="description" id="alert-dialog-description">
              <p className="txt-title">
                <b>Liên hệ UniPass để được hỗ trợ</b>
              </p>
              <p>
                Tài khoản sau khi được duyệt sẽ không thể tự thay đổi. Vui lòng chọn mục bên dưới để
                nhận được sự hỗ trợ từ UniPass nhé.
              </p>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <a href="https://zalo.me/3820907403981754700">
                    <img className="zalo" src={`/images/logo_Zalo.png`} />
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="https://fb.com/Unipass.vn">
                    <img className="facebook" src={`/images/logo_Fb.png`} />
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="tel:1900545436">
                    <img className="phone" src={`/images/phone.png`} />
                  </a>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions className="action">
					{leftButtonTitle && (
						<Button className="left" fullWidth onClick={onLeftConfirm} color="primary" autoFocus>
							{leftButtonTitle}
						</Button>
					)}
					{rightButtonTitle && (
						<Button className="right" fullWidth onClick={onRightConfirmUpdateDialog} color="primary" autoFocus>
							{rightButtonTitle}
						</Button>
					)}
				</DialogActions> */}
        </ZPDialogUpdateStyle>
      </MuiThemeProvider>
    </div>
  )
}

export default ZPDialogUpdate
