import '@fortawesome/fontawesome-free/css/all.min.css'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import 'bootstrap-css-only/css/bootstrap.min.css'
// import 'core-js/es6/map'
// import 'core-js/es6/set'
// import 'raf/polyfill'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import App from './App'
import history from './history'
import './index.css'
import './index.scss'
import Toast from './new-components/Toast'
import store from './store'

// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: '#008FE5'
    }
  }
})

const RootApp = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <Toast />
          <App />
        </MuiThemeProvider>
      </Router>
    </Provider>
  )
}

const root = document.getElementById('root')
ReactDOM.createRoot(root).render(<RootApp />)
// unregister()
// registerServiceWorker();
