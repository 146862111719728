import * as Types from './../constants/ActionType'
import { b64DecodeUnicode } from '../utils/helper'
import moment from 'moment'

// const user = localStorage.getItem("user");
const user = null
const customerPhone = ''

const initialState = {
  customerPhone: customerPhone || null,
  token: null,
  user: user !== null ? JSON.parse(b64DecodeUnicode(user)) : null,
  hasLoggedInByZaloOa: false,
  loginToken: '',
  showSplashing: true
} // String, Array, Object,...

const auth = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_SHOW_SPLASHING:
      return {
        ...state,
        showSplashing: action.showSplashing
      }
    case Types.SET_AUTH:
      state.user = {
        ...action.user
      }
      return { ...state }
    case Types.SET_CUSTOMER_PHONE:
      state.customerPhone = action.customerPhone
      localStorage.setItem('cp', state.customerPhone)
      localStorage.setItem('lastLogin', moment().format('YYYY-MM-DD H:m:s'))
      return { ...state }
    case Types.SET_CUSTOMER_INFO:
      const { customerPhone, token } = action.payload
      state.customerPhone = customerPhone
      state.token = token
      state.customerId = action.payload.customerId
      localStorage.setItem('cp', state.customerPhone)
      localStorage.setItem('tk', token)
      localStorage.setItem('lastLogin', moment().format('YYYY-MM-DD H:m:s'))
      return { ...state }
    case Types.LOG_OUT:
      state.user = null
      state.customerPhone = null
      return { ...state }
    case Types.SET_HAS_LOGGEDIN_BY_ZALOOA:
      state.hasLoggedInByZaloOa = action.hasLoggedInByZaloOa
      return { ...state }
    case Types.SET_LOGIN_TOKEN:
      state.loginToken = action.loginToken
      return { ...state }
    case Types.SET_ERROR_ZALOID_NOT_FOUND:
      state.zaloIdNotFound = action.zaloIdNotFound
      return { ...state }
    case Types.AUTH.REQUEST_OTP:
      return { ...state }
    default:
      return state // copy new array and return it
  }
}

export default auth
