import React from 'react'
import Avatar from '../../../components/AvatarCustom'
import { color } from '../../../utils/color'
import { friendlyDate } from '../../../utils/helper'

export default function ReceiveGiftItem({ item, countItem = 0, index }) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <Avatar name={item.customerName} url={item.customerAvatar} />
      <div
        className="d-flex justify-content-between flex-row align-items-center py-2"
        style={{
          width: '83%',
          borderBottom: countItem === 1 || countItem === index ? 'none' : '0.5px solid #E6E6E6'
        }}
      >
        <div className="d-flex flex-column align-items-start">
          <div style={{ fontSize: 15, color: color.black[600] }}>{item.customerName}</div>
          <div style={{ fontSize: 14, color: '#818C99' }}>{item.customerPhone}</div>
        </div>
        <div className="d-flex flex-column align-items-end pb-2">
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-2 text-bold" style={{ color: color.blue[800], marginTop: 2 }}>
              + {item.giftReceived}
            </span>
            <img style={{ height: 16, width: 16 }} src="/img/new-icon-menu/gift-icon.svg" alt="" />
          </div>
          <div style={{ fontSize: 12, color: '#7A7C8B' }}>{friendlyDate(item.receivedDate)}</div>
        </div>
      </div>
    </div>
  )
}
