import { get, post } from '../utils/apiCallerV2'

export const getListGift = () => {
  return get('v2/promotion/gift/list')
}

export const getListGiftDetail = (id) => {
  return get(`v2/promotion/gift/detail/${id}`)
}

export const getListCampaignDetail = (id) => {
  return get(`v2/promotion/campaign/app/detail/${id}`)
}

export const getListCampaign = () => {
  return get(`v2/promotion/campaign/app/list`)
}
