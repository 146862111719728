import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Container,
  Wrapper,
  WrapperText,
  Text,
  ContentText,
  LinkImage,
  ErrorText
} from './styles/monthly-ticket-payment-done.style'
import ZPButton from '../components/ZPButton'
import { Grid } from '@material-ui/core'
import { formatMoney, setTitle } from '../utils/helper'

class MonthlyTicketPaymentDonePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPaymentSucess: true,
      error: {
        code: 0,
        messageVN: ''
      },
      amount: 0
    }
    if (this.props.location.state.data !== null) {
      this.state.isPaymentSucess = this.props.location.state.isPaymentSucess
      this.state.amount = this.props.location.state.amount
      this.state.error = this.props.location.state.error
      this.state.isPaymentSucess
        ? setTitle('Thanh toán thành công')
        : setTitle('Kết quả thanh toán')
    }
  }
  backHome = () => {
    // this.props.history.replace('/monthly_ticket/option')
    window.location.replace('/')
  }
  render() {
    const { isPaymentSucess, amount, error } = this.state
    const srcImage = isPaymentSucess
      ? '/img/icons/payment-success.svg'
      : '/img/icons/payment-fail.svg'
    return (
      <Container>
        <Wrapper>
          <LinkImage isMargin src={srcImage} />
          <WrapperText>
            <Text>{isPaymentSucess ? 'Thanh toán thành công' : 'Thanh toán không thành công'}</Text>
            {isPaymentSucess && <Text> {`${formatMoney(amount)}đ`}</Text>}
          </WrapperText>
        </Wrapper>
        <Wrapper>
          {isPaymentSucess ? (
            <>
              <WrapperText>
                <ContentText isMargin>Xem và quản lý các dịch vụ liên kết tại mục</ContentText>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ alignItems: 'center', marginTop: 10 }}
                  container
                >
                  <Grid item xs={5} md={5}>
                    <Grid item xs={12} md={12}>
                      <LinkImage src="/img/icons/profile.svg" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <ContentText style={{ fontSize: 16 }}>Cá nhân</ContentText>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <LinkImage src="/img/icons/arrow-right.svg" />
                  </Grid>
                  <Grid item xs={5} md={5}>
                    <Grid item xs={12} md={12}>
                      <LinkImage src="/img/icons/bill.svg" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <ContentText style={{ fontSize: 16 }}>Thanh toán tự động</ContentText>
                    </Grid>
                  </Grid>
                </Grid>
              </WrapperText>
            </>
          ) : (
            <>
              <WrapperText>
                <ErrorText>{`Mã lỗi ${error.code}`}</ErrorText>
                <ContentText style={{ textAlign: 'start', margin: 15 }}>
                  {error.messageVN}
                </ContentText>
              </WrapperText>
            </>
          )}
        </Wrapper>
        {/* <Wrapper onClick={this.goDetailPayment}>
                    <Grid item xs={12} md={12} style={{ alignItems: 'center' }} container>
                        <Grid item xs={1} md={1} >
                            <LinkImage src="/img/icons/info.svg" />
                        </Grid>
                        <Grid item xs={10} md={10}>
                            <ContentText style={{ fontSize: 16, textAlign: 'start', marginLeft: 5 }}>Chi tiết giao dịch</ContentText>
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <LinkImage src="/img/icons/right.svg" />
                        </Grid>
                    </Grid>
                </Wrapper> */}
        <div
          className="bottom-fixed-button-wrapper"
          style={{ padding: '12px 0', backgroundColor: '#E5E5E5', zIndex: 1 }}
        >
          <ZPButton onClick={this.backHome} fullWidth style={{ margin: 0 }}>
            Quay lại trang chủ
          </ZPButton>
        </div>
      </Container>
    )
  }
}
export default withRouter(MonthlyTicketPaymentDonePage)
