import { useEffect, useState } from 'react'
import ReviewImage from './ReviewImage'
import Upload from './Upload'
import Result from './Result'

const steps = {
  upload: 'upload',
  review: 'review',
  result: 'result'
}
export default function uploadAndUpload({ user }) {
  const [step, setStep] = useState(steps.upload)
  const [imgSrc, setImgSrc] = useState(null)
  const [newData, setNewData] = useState(undefined)

  useEffect(() => {
    if (
      user?.student_card_image &&
      (user?.reject_description || user?.registration_confirm_status === 0)
    ) {
      setStep(steps.result)
      return
    }
    setStep(steps.upload)
  }, [user])

  return (
    <div className="px-4 py-3 d-flex justify-content-center align-items-center">
      {
        {
          upload: <Upload setStep={setStep} steps={steps} setImgSrc={setImgSrc} />,
          review: (
            <ReviewImage
              setStep={setStep}
              steps={steps}
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              setNewData={setNewData}
            />
          ),
          result: (
            <Result
              setStep={setStep}
              steps={steps}
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              newData={newData}
              user={user}
            />
          )
        }[step]
      }
    </div>
  )
}
