import styled from 'styled-components'

export const ClearWrapper = styled.span`
	&& {
		font-size: 18px;
    position: absolute;
    top: 10px;
    right: 5px;
    transform: translateY(-50%);
		padding: 8px;
		display: ${(props) => (props.isShow ? 'block' : 'none')}
		::before {
			color:#006dff;
		}
	}
`
