import { ADD_NOTIFICATION, CLEAR_NOTIFICAIONS } from '../constants/ActionType'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      state.push(action.notification)
      return [...state]

    case CLEAR_NOTIFICAIONS:
      return []

    default:
      return state
  }
}
