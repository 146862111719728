export const refund = {
  refundType: {
    user: 1,
    admin: 2
  },
  day: 20
}

export const phoneOtpVerification = {
  step: {
    phone: 'phone',
    otp: 'otp',
    result: 'result'
  },
  otpLenght: 6
}
