import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PROMOTION } from '../../constants/Config'
import Terms from './components/Term'
import { getInviteList } from '../../actions/invite.action'
import NoTransactionItem from './components/NoTransactionItem'
import ReceiveGiftItem from './components/ReceiveGiftItem'
import SwipeableNoTransaction from './components/SwipeableNoTransaction'
import SwipeableReceiveGift from './components/SwipeableReceiveGift'
import { setTitle } from '../../utils/helper'
import { color } from '../../utils/color'

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    width: '100vw',
    height: '100vh',
    overflowY: 'auto'
  },
  itemContainer: {
    backgroundColor: 'white',
    margin: '16px 16px 0px 16px',
    borderRadius: 10,
    padding: 12,
    border: '1px solid #E6E6E6'
  },
  description: { textAlign: 'left', marginTop: 6, color: color.black[400] }
}
const Title = ({ name, action, count }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <span style={{ fontSize: 16, color: color.black[500] }} className="text-bold">
        {`${name} ${count > PROMOTION.limitShowNoTransacion ? `(${count})` : ''}`}
      </span>
      <span style={{ fontSize: 13, color: color.blue[500] }} onClick={action}>
        {count > PROMOTION.limitShowNoTransacion ? 'Xem thêm' : ''}
      </span>
    </div>
  )
}
const RenderItemSumary = ({ value, title }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <span style={{ color: '#7A7C8B', fontSize: 14 }}>{title}</span>
      <span style={{ fontSize: 18 }} className="text-bold">
        {value}
      </span>
    </div>
  )
}
const Sumary = ({ totalGiftReceived, totalInvited, totalTransacted, totalReceived }) => {
  return (
    <div>
      <div
        style={{ ...styles.itemContainer, borderRadius: '8px 8px 0px 0px' }}
        className="d-flex flex-column align-items-start"
      >
        <div className="d-flex flex-row justify-content-between w-100">
          <RenderItemSumary title={'Đã giới thiệu'} value={totalInvited} />
          <RenderItemSumary title={'Đã giao dịch'} value={totalTransacted} />
          <RenderItemSumary title={'Nhận thưởng'} value={totalReceived} />
        </div>
      </div>
      <div
        style={{
          ...styles.itemContainer,
          margin: '0px 16px 16px 16px',
          borderTop: 0,
          borderRadius: '0px 0px 8px 8px'
        }}
      >
        <div style={{ color: '#7A7C8B', fontSize: 14 }}>Tổng quà tặng của bạn</div>
        <div className="d-flex align-items-center justify-content-center">
          <img style={{ height: 24, width: 24 }} src="/img/new-icon-menu/gift-icon.svg" alt="" />
          <span className="ml-2 text-bold" style={{ fontSize: 20, marginTop: 2 }}>
            {totalGiftReceived}
          </span>
        </div>
      </div>
    </div>
  )
}

const NoTransaction = ({ countAll = 0, notReceivedGifts = [], action }) => {
  return (
    <div style={{ margin: 16 }}>
      <Title name={'Bạn bè chưa thực hiện giao dịch'} count={countAll} action={action} />
      {notReceivedGifts.map((item, key) => (
        <NoTransactionItem
          countItem={notReceivedGifts.length}
          key={key}
          item={item}
          index={key + 1}
        />
      ))}
    </div>
  )
}

const ReceiveGift = ({ countAll = 0, receivedGifts, action }) => {
  return (
    <div style={{ margin: 16 }}>
      <Title name={'Danh sách nhận quà tặng'} count={countAll} action={action} />
      {receivedGifts.map((item, key) => (
        <ReceiveGiftItem item={item} index={key + 1} key={key} countItem={receivedGifts.length} />
      ))}
    </div>
  )
}
export default function Following() {
  setTitle('Theo dõi')
  const dispatch = useDispatch()
  const [showAllNoTransaction, setShowAllTransaction] = useState(false)
  const [showAllReceiveGift, setShowAllReceiveGift] = useState(false)

  const {
    inviteList: {
      receivedGifts,
      notReceivedGifts,
      totalGiftReceived,
      totalInvited,
      totalReceived,
      totalTransacted
    }
  } = useSelector((state) => state.invite)

  useEffect(() => {
    dispatch(getInviteList())
  }, [])

  const ListShowNoTransaction = useMemo(() => {
    if (notReceivedGifts.length > PROMOTION.limitShowNoTransacion) {
      return notReceivedGifts.slice(0, PROMOTION.limitShowNoTransacion)
    } else {
      return notReceivedGifts
    }
  }, [notReceivedGifts])

  const ListShowReceivedGifts = useMemo(() => {
    if (receivedGifts.length > PROMOTION.limitShowReceiveGift) {
      return receivedGifts.slice(0, PROMOTION.limitShowNoTransacion)
    } else {
      return receivedGifts
    }
  }, [receivedGifts])
  return (
    <div style={styles.container}>
      <Sumary
        totalGiftReceived={totalGiftReceived}
        totalInvited={totalInvited}
        totalReceived={totalReceived}
        totalTransacted={totalTransacted}
      />
      {!!notReceivedGifts.length && (
        <NoTransaction
          notReceivedGifts={ListShowNoTransaction}
          action={() => setShowAllTransaction(true)}
          countAll={notReceivedGifts.length}
        />
      )}
      {!!receivedGifts.length && (
        <ReceiveGift
          receivedGifts={ListShowReceivedGifts}
          action={() => setShowAllReceiveGift(true)}
          countAll={receivedGifts.length}
        />
      )}
      <Terms />
      <SwipeableNoTransaction
        isOpen={showAllNoTransaction}
        onClose={() => setShowAllTransaction(false)}
        notReceivedGifts={notReceivedGifts}
      />
      <SwipeableReceiveGift
        isOpen={showAllReceiveGift}
        onClose={() => setShowAllReceiveGift(false)}
        receivedGifts={receivedGifts}
      />
    </div>
  )
}
