import * as Types from './../constants/ActionType'
import { sumValueInArray } from '../utils/helper'

const initialState = {
  selectedTab: 0,
  quantity: {},
  isDisbleButton: true
}

const monthlyTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_TAB_ID:
      state.selectedTab = action.selectedTab
      return {
        ...state
      }
    case Types.SET_QUANTITY:
      state.quantity = action.quantity
      return {
        ...state
      }
    case Types.CHECK_BUTTON_BUY_TICKET:
      let disable = true
      const value = sumValueInArray(Object.values(state.quantity))
      if (value) {
        disable = false
      } else disable = true
      state.isDisbleButton = disable
      return {
        ...state
      }
    case Types.CLEAR_DATA:
      state.selectedTab = 0
      state.quantity = {}
      state.isDisbleButton = true
      return {
        ...state
      }
    default:
      return state
  }
}

export default monthlyTicketReducer
