import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.css'
import routes from './routes'
import useSentry from './hooks/useSentry'

const App = () => {
  const initSentry = useSentry()
  const mobilecheck = () => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    if (connection && connection.type === 'cellular') {
      return true
    }

    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent || navigator.vendor || window.opera)
  }

  const showPageContent = (routes) => {
    let result = null

    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return <Route key={index} path={route.path} exact={route.exact} component={route.main} />
      })
    }

    return result
  }

  useEffect(() => {
    initSentry()
  }, [])

  return mobilecheck() ? (
    <div className="App">
      <Switch>{showPageContent(routes)}</Switch>
    </div>
  ) : (
    <div style={{ height: '100vh', textAlign: 'center', lineHeight: '100vh' }}>
      Please open the website on mobile!
    </div>
  )
}

export default App
