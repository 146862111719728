import React, { useMemo, useState } from 'react'
import { color } from '../../../../../utils/color'
import { getCardTypeName } from '../../../../../utils/helper'

const styles = {
  container: {
    padding: 16,
    gap: 16
  },
  avatar: { height: 43, width: 43, borderRadius: '50%', objectFit: 'cover' },
  name: {
    fontSize: 16,
    color: '#565869'
  },
  detail: {
    flex: 1,
    gap: 4,
    display: 'flex',
    flexDirection: 'column'
  },
  expire: {
    color: color.black[250]
  },
  cardTypeWrapper: {
    padding: 4,
    color: 'white',
    borderRadius: 3
  },
  cardType: {
    marginLeft: 4
  }
}

const iconOptions = {
  2: {
    iconUrl: '/images/tick-circle-icon.svg',
    backgroundColor: color.green[500]
  },
  7: {
    iconUrl: '/images/tick-circle-icon.svg',
    backgroundColor: color.green[500]
  },
  other: {
    iconUrl: '/images/user-icon.svg',
    backgroundColor: color.blue[500]
  }
}

const User = ({ avatar, name, expire, cardType, onClickCardType = () => {} }) => {
  const [loaded, setLoaded] = useState(false)

  // const cardTypeOption = useMemo(() => {
  //   return iconOptions[cardType] ?? iconOptions.other;
  // }, [cardType]);
  const cardTypeOption = useMemo(() => {
    return iconOptions[cardType] ? iconOptions[cardType] : iconOptions.other
  }, [cardType])

  return (
    <div className="flex-row-center" style={styles.container}>
      <img
        alt={loaded ? 'avatar' : 'cannot load image'}
        src={loaded ? avatar : '/img/icons/user-icon.png'}
        style={styles.avatar}
        onLoad={() => setLoaded(true)}
      />
      <div style={styles.detail}>
        <div style={styles.name} className="ff-sb text-left">
          {name}
        </div>
        <div className="flex-row-between">
          <div
            style={{
              ...styles.cardTypeWrapper,
              backgroundColor: cardTypeOption.backgroundColor
            }}
            className="fs-11 lh-20 flex-row-center"
            onClick={onClickCardType}
          >
            <img src={cardTypeOption.iconUrl} alt="icon card type" style={{ marginBottom: 1 }} />
            <div style={styles.cardType}>{getCardTypeName(cardType).toUpperCase()}</div>
          </div>
          <div style={styles.expire} className="fs-13 flex-row-center">
            Thời hạn: {expire}
          </div>
        </div>
      </div>
    </div>
  )
}
export default User
