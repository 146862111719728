import styled from 'styled-components'

export const AmountWrapper = styled.p`
  font-size: 48px;
  padding-left: 30px;
  .vnd {
    font-size: 16px;
    color: #77818b;
  }
`

export const Description = styled.p`
  color: #77818b;
  font-style: italic;
  font-size: 16px;
`

export const LineDashWrapper = styled.div`
  padding: 0 15px 15px 15px;
`

export const TransactionDetailWrapper = styled.div`
  background: #f8f8f8;
  overflow: scroll;
  //background: green;
  height: 100vh;
  padding: 30px 10px;
`

export const RetryButton = styled.div`
  background: #006dfe;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
`
