import * as Types from './../constants/ActionType'

export const refundProcessing = () => {
  return {
    type: Types.REFUND.PROCESSING
  }
}

export const refundProcessingDone = (data) => {
  return {
    type: Types.REFUND.PROCESSING_DONE,
    data
  }
}

export const requestRefund = (data) => {
  return {
    type: Types.REFUND.REQUEST_REFUND,
    payload: data
  }
}
