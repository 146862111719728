import React, { useState, useEffect } from 'react'
import { SpinnerWrapper, Button, Value } from './style'
import { Grid } from '@material-ui/core'
import { color } from '../../utils/color'
const InputSpinner = (props) => {
  const { maxValue, minValue, valueDefault, onChangeQuatity, skuId } = props
  const [value, setValue] = useState(valueDefault)
  const [colorLeft, setColorLeft] = useState('#F2F2F2')
  const [colorRight, setColorRight] = useState(color.blue[500])
  const [imageLeft, setImageLeft] = useState('/img/icons/SubGray.svg')
  const [imageRight, setImageRight] = useState('/img/icons/addWhite.svg')

  useEffect(() => {
    let warning = false
    if (value > minValue) {
      setColorLeft(color.blue[500])
      setImageLeft('/img/icons/SubWhite.svg')
    } else {
      setColorLeft('#F2F2F2')
      setImageLeft('/img/icons/SubGray.svg')
    }
    if (value === maxValue) {
      setColorRight('#F2F2F2')
      setImageRight('/img/icons/AddGray.svg')
      warning = true
    } else {
      setColorRight(color.blue[500])
      setImageRight('/img/icons/addWhite.svg')
    }
    onChangeQuatity(value, skuId, warning)
  }, [value])

  const onSub = () => {
    if (value <= minValue) {
      return
    }
    setValue(value - 1)
  }
  const onAdd = () => {
    if (value >= maxValue) {
      return
    }
    setValue(value + 1)
  }
  return (
    <SpinnerWrapper>
      <Grid xs={12} md={12} direction={'row'} item container>
        <Grid item xs={4}>
          <Button onClick={() => onSub()} color={colorLeft}>
            <img src={imageLeft} alt="" />
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Value>{value}</Value>
        </Grid>
        <Grid item xs={4}>
          <Button onClick={() => onAdd()} color={colorRight}>
            <img src={imageRight} alt="" />
          </Button>
        </Grid>
      </Grid>
    </SpinnerWrapper>
  )
}

export default InputSpinner
