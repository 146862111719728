import React, { useState } from 'react'
import { X } from 'react-feather'
import MobileStepper from '@material-ui/core/MobileStepper'
import SwipeableViews from 'react-swipeable-views'
import { useHistory } from 'react-router'
import { withStyles } from '@material-ui/core'
import { setStatusPopup } from '../../actions/home.action'
import { useDispatch } from 'react-redux'
const StyledMobileStepper = withStyles({
  root: {
    justifyContent: 'center',
    background: 'white',
    padding: 0,
    marginTop: 10
  }
})(MobileStepper)
const styles = {
  container: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 1
  },
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    opacity: '0.8',
    zIndex: 1
  },
  content: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  item: {
    width: '90%',
    backgroundColor: 'white',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    borderRadius: 8
  },
  img: {
    width: '100%'
  }
}

export default function PopupBroadCast({ isShow = true, data }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const dispatch = useDispatch()
  const history = useHistory()
  const onClose = () => {
    dispatch(setStatusPopup(false))
  }
  return (
    <div style={{ ...styles.container, display: isShow ? 'flex' : 'none' }}>
      <div style={styles.wrapper}></div>
      <div style={styles.content}>
        <div style={styles.item}>
          <X size={24} onClick={onClose} />
          <div>
            <SwipeableViews
              axis={'x'}
              index={activeIndex}
              onChangeIndex={setActiveIndex}
              enableMouseEvents
            >
              {data.map((item, index) => (
                <img
                  key={index}
                  src={item.icon}
                  alt=""
                  style={styles.img}
                  onClick={() => {
                    if (item.to_path) {
                      history.push({
                        pathname: item.to_path,
                        state: { campaign: item }
                      })
                    }
                    onClose()
                  }}
                />
              ))}
            </SwipeableViews>
            {data.length > 1 && (
              <StyledMobileStepper
                variant="dots"
                steps={data.length}
                position="static"
                activeStep={activeIndex}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
